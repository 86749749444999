import React from "react";
import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

function SkeletonHome() {
  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {[1, 2, 3, 4, 5].map((row) => (
        <Box style={{ width: "20%" }}>
          <Skeleton variant="rectangular" height={250} />
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      ))}
    </div>
  );
}

export default SkeletonHome;
