import React from "react";
import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const userId = JSON.parse(localStorage.getItem("userid"));

  return (
    <Route
      {...rest}
      render={(props) => {
        if (userId) {
          return <Route path={props} component={Component} />;
        } else {
          // set redirect url to the current url
          localStorage.setItem("redirectUrl", props.location.pathname);

          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
