import React from "react";

const Amount = (props) => {
  const { amount, className, style } = props;
  return (
    <span className={className} style={style}>
      {amount ? (
        Object.keys(amount.toString()).length > 0 ? (
          Object.keys(amount.toString()).length <= 3 ? (
            <span>{amount.toString()}</span>
          ) : Object.keys(amount.toString()).length == 4 ? (
            <span>
              {amount.toString().substr(-4, 1)}.
              {amount.toString().substr(-3, 3)}
            </span>
          ) : Object.keys(amount.toString()).length == 5 ? (
            <span>
              {amount.toString().substr(-5, 2)}.
              {amount.toString().substr(-3, 3)}
            </span>
          ) : Object.keys(amount.toString()).length == 6 ? (
            <span>
              {amount.toString().substr(-6, 3)}.
              {amount.toString().substr(-3, 3)}
            </span>
          ) : Object.keys(amount.toString()).length == 7 ? (
            <span>
              {amount.toString().substr(-7, 1)}.
              {amount.toString().substr(-6, 3)}.
              {amount.toString().substr(-3, 3)}
            </span>
          ) : Object.keys(amount.toString()).length == 8 ? (
            <span>
              {amount.toString().substr(-8, 2)}.
              {amount.toString().substr(-6, 3)}.
              {amount.toString().substr(-3, 3)}
            </span>
          ) : Object.keys(amount.toString()).length == 9 ? (
            <span>
              {amount.toString().substr(-9, 3)}.
              {amount.toString().substr(-6, 3)}.
              {amount.toString().substr(-3, 3)}
            </span>
          ) : (
            0
          )
        ) : (
          0
        )
      ) : (
        0
      )}
    </span>
  );
};

export default Amount;
