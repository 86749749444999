import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/fontawesome-free-solid";
import Header from "../header/header2";
import "./paymentMethod.css";
// import { getPaymentProductUser } from "../../store/actions/getPaymentActions";
// import { GetPaymentDataResponse } from "../../store/Selectors/getpaymentdata";
import { useHistory } from "react-router-dom";
// import { useDispatch } from "react-redux";
import Loader from "../loader";
import { withNamespaces } from "react-i18next";
import i18n from "../language/localization";
import { ThemeColor1 } from "../themecolor/index1";
// import { GetSplashResponse } from "../../store/Selectors/getSplashData";
// import { getSplashDataUser } from "../../store/actions/getSplashDataActions";
import {
  get as getVendorBankData,
  getCOD,
  getMootaBank as getMootaBankData,
} from "../../api/payment-method";
import { priceFormatLocal } from "../../utils/helper";

const installments = [
  {
    number: "1st",
    amount: "0.00",
  },
  {
    number: "2nd",
    amount: "75.00",
  },
  {
    number: "3rd",
    amount: "75.00",
  },
  {
    number: "4th",
    amount: "75.00",
  },
];

const PaymentMethod = ({ t }) => {
  const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));
  const [true1, setTrue1] = useState(true);
  const [open, setOpen] = useState("");
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [vendorBankDatas, setVendorBankDatas] = useState([]);
  const [mootaBankDatas, setMootaBankDatas] = useState({});
  const [codData, setCodData] = useState({});
  const history = useHistory();
  // const dispatch = useDispatch();

  const getPayment = async () => {
    setTrue1(false);
    const UserId = JSON.parse(localStorage.getItem("userid"));
    const vendorBankDataResult = await getVendorBankData(
      localStorage.getItem("token"),
      `{"customer_id":${UserId}}`
    );
    setVendorBankDatas(vendorBankDataResult);

    const mootaBankDataResult = await getMootaBankData(
      localStorage.getItem("token"),
      `{"customer_id":${UserId}}`
    );
    if (mootaBankDataResult.success) {
      if (mootaBankDataResult.data.status === true) {
        setMootaBankDatas(mootaBankDataResult.data);
      }
    }

    const codDataResult = await getCOD();
    if (codDataResult.success) {
      if (codDataResult.data.is_active === true) {
        setCodData(codDataResult.data);
      }
    }
    setOpen(true);
    setTrue1(true);
  };

  useEffect(() => {
    getPayment();
  }, []);

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
    // let data = new FormData();
    // dispatch(getSplashDataUser(data));
  }, []);

  // const payData = GetPaymentDataResponse();
  // const data = GetSplashResponse()
  const totalprice = JSON.parse(localStorage.getItem("totalprice"));
  const payspot = JSON.parse(localStorage.getItem("pay_spot"));
  return (
    <React.Fragment>
      {true1 ? (
        <React.Fragment>
          <Header title={t("payment_method")} color={ThemeColor} />
          <div
            className="shadow-card ps-2 pe-2"
            style={{
              paddingTop: " 48px",
            }}
          >
            <div
              className="d-flex justify-content-between payment-box pe-2 rounded"
              style={{
                paddingTop: " 0.5rem",
                paddingBottom: " 0.5rem",
                paddingRight: "10px",
                margin: "10px",
                boxShadow: "0px 0px 8px 0px #dbd5d5",
              }}
            >
              <h6
                style={{
                  paddingLeft: "0.5rem",
                  fontSize: "18px",
                  marginBottom: "0px",
                  MozUserSelect: "none",
                  WebkitUserSelect: "none",
                  MsUserSelect: "none",
                  userSelect: "none",
                }}
              >
                Total
              </h6>
              <h6 style={{ fontSize: "18px", marginBottom: "0px" }}>
                <b
                  style={{
                    MozUserSelect: "none",
                    WebkitUserSelect: "none",
                    MsUserSelect: "none",
                    userSelect: "none",
                  }}
                >
                  {priceFormatLocal(totalprice)}
                </b>
              </h6>
            </div>

            {codData && Object.keys(codData).length > 0 ? (
              <div
                className="payment-box rounded"
                style={{ boxShadow: "0px 0px 8px 0px #dbd5d5", margin: "10px" }}
                onClick={() => {
                  Object.assign(codData, { type: "Cash on Delivery" });
                  localStorage.setItem("bankdata", JSON.stringify(codData));
                  history.push("/orderconfirm");
                }}
              >
                <div
                  className="d-flex justify-content-left"
                  style={{
                    marginLeft: "14px",
                    marginBottom: "0rem",
                    MozUserSelect: "none",
                    WebkitUserSelect: "none",
                    MsUserSelect: "none",
                    userSelect: "none",
                  }}
                >
                  <img
                    src={
                      codData.logo
                        ? process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          codData.logo
                        : "/assets/images/dummy.png"
                    }
                    height="35px"
                    width="50px"
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "flex-start",
                      marginTop: "10px",
                    }}
                  />

                  <span
                    style={{
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "center",
                      marginLeft: "16px",
                      width: "100%",
                      MozUserSelect: "none",
                      WebkitUserSelect: "none",
                      MsUserSelect: "none",
                      userSelect: "none",
                    }}
                  >
                    {codData.name}
                  </span>
                  <Radio
                    onClick={() => {
                      Object.assign(codData, { type: "Cash on Delivery" });
                      localStorage.setItem("bankdata", JSON.stringify(codData));
                      history.push("/orderconfirm");
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  />
                </div>
              </div>
            ) : null}

            <div
              className="payment-box p-2"
              style={{ boxShadow: "0px 0px 8px 0px #dbd5d5", margin: "10px" }}
            >
              <div
                className="d-flex justify-content-left"
                style={{
                  marginLeft: "5px",
                  marginBottom: "0rem",
                  MozUserSelect: "none",
                  WebkitUserSelect: "none",
                  MsUserSelect: "none",
                  userSelect: "none",
                }}
              >
                <span
                  class="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                  style={{ marginTop: "20px" }}
                >
                  <h6>{t("bank_transfer_manual_confirm")}</h6>
                </span>
                <Radio
                  // checked={open1 ? true : payspot == "active" ? false : true}
                  style={{
                    color: ThemeColor ? ThemeColor : "#FFFFFF",
                    marginLeft: "auto",
                    marginRight: "0",
                  }}
                  onClick={() => {
                    setOpen1(true);
                    setOpen2(true);
                  }}
                />
              </div>

              {vendorBankDatas.length > 0 ? (
                vendorBankDatas.map((payment, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      localStorage.setItem(
                        "bankdata",
                        JSON.stringify({
                          account_no: payment.account_number,
                          // account_owner_name: payment.account_owner_name,
                          // bank_code: payment.bank_code,
                          bank_name: payment.bank_name,
                          // branch: payment.branch,
                          id: payment.id,
                          // is_active: payment.is_active,
                          // is_auto_active: payment.is_auto_active,
                          // password_ibanking: payment.password_ibanking,
                          picture: payment.bank_image
                            ? process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              payment.bank_image
                            : "/assets/images/dummy.png",
                          type: "Bank Transfer Manual",
                          // pos_active: payment.pos_active,
                          // username_ibanking: payment.username_ibanking,
                        })
                      );
                      history.push("/orderconfirm");
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* <div className="bank-card rounded"></div> */}
                      <img
                        src={
                          payment.bank_image
                            ? process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              payment.bank_image
                            : "/assets/images/dummy.png"
                        }
                        height="35px"
                        width="50px"
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "flex-start",
                        }}
                      />
                      <span
                        style={{
                          fontSize: "15px",
                          display: "flex",
                          alignItems: "center",
                          // justifyContent: "center",
                          marginLeft: "16px",
                          width: "100%",
                          MozUserSelect: "none",
                          WebkitUserSelect: "none",
                          MsUserSelect: "none",
                          userSelect: "none",
                        }}
                      >
                        {/* {payment.account_owner_name}
                      <br /> */}
                        {payment.account_number + " " + payment.bank_name}
                      </span>
                      <Radio
                        onClick={() => {
                          localStorage.setItem(
                            "bankdata",
                            JSON.stringify({
                              account_no: payment.account_number,
                              // account_owner_name: payment.account_owner_name,
                              // bank_code: payment.bank_code,
                              bank_name: payment.bank_name,
                              // branch: payment.branch,
                              id: payment.id,
                              // is_active: payment.is_active,
                              // is_auto_active: payment.is_auto_active,
                              // password_ibanking: payment.password_ibanking,
                              picture: payment.bank_image
                                ? process.env.REACT_APP_FILE_BASE_URL +
                                  "/" +
                                  payment.bank_image
                                : "/assets/images/dummy.png",
                              // pos_active: payment.pos_active,
                              // username_ibanking: payment.username_ibanking,
                            })
                          );
                          history.push("/orderconfirm");
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      />
                    </div>
                    <b>
                      <div
                        style={{
                          borderBottom: "1px solid grey",
                          margin: "5px 0px",
                        }}
                      ></div>
                    </b>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <b>No Data Found</b>
                </div>
              )}
            </div>

            <div
              className="payment-box p-2"
              style={{ boxShadow: "0px 0px 8px 0px #dbd5d5", margin: "10px" }}
            >
              <div
                className="d-flex justify-content-left"
                style={{
                  marginLeft: "5px",
                  marginBottom: "0rem",
                  MozUserSelect: "none",
                  WebkitUserSelect: "none",
                  MsUserSelect: "none",
                  userSelect: "none",
                }}
              >
                <span
                  class="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                  style={{ marginTop: "20px" }}
                >
                  <h6>{t("bank_transfer_auto_confirm")}</h6>
                </span>
                <Radio
                  // checked={open1 ? true : payspot == "active" ? false : true}
                  style={{
                    color: ThemeColor ? ThemeColor : "#FFFFFF",
                    marginLeft: "auto",
                    marginRight: "0",
                  }}
                  onClick={() => {
                    setOpen1(true);
                    setOpen2(true);
                  }}
                />
              </div>

              {mootaBankDatas && Object.keys(mootaBankDatas).length > 0 ? (
                <div
                  key={mootaBankDatas.id}
                  onClick={() => {
                    localStorage.setItem(
                      "bankdata",
                      JSON.stringify({
                        account_no: mootaBankDatas.account_number,
                        // account_owner_name: payment.account_owner_name,
                        // bank_code: payment.bank_code,
                        bank_name: mootaBankDatas.bank_name,
                        // branch: payment.branch,
                        id: mootaBankDatas.id,
                        // is_active: payment.is_active,
                        // is_auto_active: payment.is_auto_active,
                        // password_ibanking: payment.password_ibanking,
                        picture: mootaBankDatas.bank_image
                          ? process.env.REACT_APP_FILE_BASE_URL +
                            "/" +
                            mootaBankDatas.bank_image
                          : "/assets/images/dummy.png",
                        type: "Bank Transfer Otomatis",
                        // pos_active: payment.pos_active,
                        // username_ibanking: payment.username_ibanking,
                      })
                    );
                    history.push("/orderconfirm");
                  }}
                >
                  <div
                    className="d-flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* <div className="bank-card rounded"></div> */}
                    <img
                      src={
                        mootaBankDatas.bank_image
                          ? process.env.REACT_APP_FILE_BASE_URL +
                            "/" +
                            mootaBankDatas.bank_image
                          : "/assets/images/dummy.png"
                      }
                      height="35px"
                      width="50px"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "flex-start",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "15px",
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "center",
                        marginLeft: "16px",
                        width: "100%",
                        MozUserSelect: "none",
                        WebkitUserSelect: "none",
                        MsUserSelect: "none",
                        userSelect: "none",
                      }}
                    >
                      {/* {payment.account_owner_name}
                      <br /> */}
                      {mootaBankDatas.account_number +
                        " " +
                        mootaBankDatas.bank_name}
                    </span>
                    <Radio
                      onClick={() => {
                        localStorage.setItem(
                          "bankdata",
                          JSON.stringify({
                            account_no: mootaBankDatas.account_number,
                            // account_owner_name: payment.account_owner_name,
                            // bank_code: payment.bank_code,
                            bank_name: mootaBankDatas.bank_name,
                            // branch: payment.branch,
                            id: mootaBankDatas.id,
                            // is_active: payment.is_active,
                            // is_auto_active: payment.is_auto_active,
                            // password_ibanking: payment.password_ibanking,
                            picture: mootaBankDatas.bank_image
                              ? process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                mootaBankDatas.bank_image
                              : "/assets/images/dummy.png",
                            type: "Bank Transfer Otomatis",
                            // pos_active: payment.pos_active,
                            // username_ibanking: payment.username_ibanking,
                          })
                        );
                        history.push("/orderconfirm");
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    />
                  </div>
                  <b>
                    <div
                      style={{
                        borderBottom: "1px solid grey",
                        margin: "5px 0px",
                      }}
                    ></div>
                  </b>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <b>No Data Found</b>
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            paddingTop: "250px",
            paddingBottom: "300px",
            flexDirection: "column",
            height: "100vh",
            backgroundColor: "white",
          }}
        >
          <div>
            <Loader />
          </div>
          <div>Loading...</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default withNamespaces()(PaymentMethod);
