// import React from "react";
// import Snackbar from "@material-ui/core/Snackbar";
// // import { useDispatch } from "react-redux";
// // import { SignupResponse } from "../store/Selectors/user";
// import MuiAlert from "@material-ui/lab/Alert";
// import { useHistory } from "react-router-dom";
// // import { setLoginAlert } from "../model/action";
// import { useTheme } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

// const SignupAlert = (props) => {
//   const theme = useTheme();
//   const mobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [state, setState] = React.useState({
//     open: true,
//     vertical: "bottom",
//     horizontal: "right",
//   });
//   const formData = {};
//   const history = useHistory();
//   // const dispatch=useDispatch();
//   // const { vertical, horizontal, open } = state;
//   const landing=JSON.parse(localStorage.getItem("Lanpage"));

//   if (state.open === true) {
//     setTimeout(() => {
//       if (formData.ResponseCode === 1) {
//         // dispatch(setLoginAlert(true));
//         return landing.landing_page===0?history.push("/"):history.push("/");
//       } else {
//         if(mobile)
//         {
//           return landing.landing_page===0?history.push("/login"):history.push("/");
//         }
//         else
//         {
//           return landing.landing_page===0?history.push("/"):history.push("/");
//         }
        
//       }
//       // setState({ ...state, open: false });
//     },1000);
//   }

//   return (
//     <div>
//     {
//       formData.ResponseCode === 1?
//         <Snackbar open={state.open} autoHideDuration={6000}>
//             <Alert severity="success" sx={{ width: "100%" }} >
//               <div style={{paddingTop:"8px"}}>{formData.ResponseMsg}</div>
//             </Alert>
//         </Snackbar>
//       :
//         <Snackbar open={state.open} autoHideDuration={6000}>
//             <Alert severity="error" sx={{ width: "100%" }} >
//             <div style={{paddingTop:"8px"}}>{formData.ResponseMsg}</div>
//             </Alert>
//         </Snackbar>
//     }
//     </div>
//   );
// };
// export default SignupAlert;
