import { createContext, useEffect, useState } from "react";

import { getDahboard, get as getVendorXApiKeyData } from "../api/vendor";
import { get as getLandingPage } from "../api/landing-page";

import Favicon from "react-favicon";
import { getFileUrl } from "../utils/storageHelper";
import { createTheme } from "@material-ui/core/styles";
import i18n from "../containers/language/localization";

const defaultValues = {
  X_API_KEY: null,
  store: null,
  storeSetting: null,
  storeSettingCMS: null,
  landingPage: null,
  webColor: "#000000",
  themeColor: "#000000",
  theme: createTheme({}),
  isMaintenance: false,
  requiredLogin: false,
  initStore: () => Promise.resolve(),
};

const StoreContext = createContext(defaultValues);

const StoreProvider = (props) => {
  const [X_API_KEY, setX_API_KEY] = useState(null);
  const [store, setStore] = useState(null);
  const [storeSetting, setStoreSetting] = useState(null);
  const [storeSettingCMS, setStoreSettingCMS] = useState(null);

  const [landingPage, setLandingPage] = useState(null);

  const [webColor, setWebColor] = useState("#000000");
  const [themeColor, setThemeColor] = useState("#000000");

  const [theme, setTheme] = useState(createTheme({}));

  const [isMaintenance, setIsMaintenance] = useState(false);
  const [requiredLogin, setRequiredLogin] = useState(false);

  const setStoreTitle = (title) => {
    document.title = title;

    localStorage.setItem("app_name", title);
  };

  const getVendorXApiKey = async () => {
    try {
      let baseUrl = window.location.origin;

      console.log(
        "REACT_APP_IS_CUSTOM_DOMAIN_NAME",
        process.env.REACT_APP_IS_CUSTOM_DOMAIN_NAME === "true"
          ? process.env.REACT_APP_CUSTOM_DOMAIN_NAME
          : baseUrl
      );

      if (baseUrl === "http://localhost:3000") {
        baseUrl = "https://store.utechsoft.net";
      }
      const response = await getVendorXApiKeyData(
        `{"url": "${
          process.env.REACT_APP_IS_CUSTOM_DOMAIN_NAME === "true"
            ? process.env.REACT_APP_CUSTOM_DOMAIN_NAME
            : baseUrl
        }/" }`
      );
      if (response.success) {
        setX_API_KEY(response.data.x_api_key);
        localStorage.setItem("X_API_KEY", response.data.x_api_key);
      }
    } catch (error) {
      console.log("Error fetching getVendorXApiKey:", error);
    }
  };

  const initLanguage = () => {
    const lgs =
      JSON.parse(localStorage.getItem("Language")) ||
      localStorage.getItem("lg");
    if (lgs === "id") {
      localStorage.setItem("Language", JSON.stringify("id"));
    } else if (lgs === "en") {
      localStorage.setItem("Language", JSON.stringify("en"));
    } else {
      localStorage.setItem("Language", JSON.stringify("id"));
    }

    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  };

  // set webColor and themeColor after landingPage is set
  const initStore = () => {
    // belum ada api jadi di init store dlu
    localStorage.setItem("websiteColor", JSON.stringify("#000000"));
    localStorage.setItem("themeColor", JSON.stringify("#000000"));

    setTheme(
      createTheme({
        palette: {
          primary: {
            main: themeColor ? themeColor : "#FFFFFF",
            color: "#FFFFFF",
          },
        },
      })
    );

    getVendorXApiKey();
  };

  // get store and landing page data after X_API_KEY is set
  useEffect(() => {
    if (X_API_KEY) {
      // get store data
      getDahboard().then((data) => {
        if (data) {
          setStore(data);
          setStoreTitle(data.name);
        }
      });

      getLandingPage().then((data) => {
        if (data) {
          data.product_categories = data.product_categories.filter(
            (item) => item.is_active === true
          );

          // sortir position
          data.product_categories.sort((a, b) => a.position - b.position);
          localStorage.setItem("landing_page", JSON.stringify(data));
          localStorage.setItem(
            "landing_page_setting",
            JSON.stringify(data.setting)
          );
          setStoreSetting(data.setting);
          setStoreSettingCMS(data.setting_cms);
          setLandingPage(data);
        }
      });
    }
  }, [X_API_KEY]);

  // set webColor and themeColor after landingPage is set
  useEffect(() => {
    if (landingPage) {
      setIsMaintenance(landingPage.setting.is_maintenance);
      setRequiredLogin(landingPage.setting_customer.is_customer_required_login);
    }
  }, [landingPage]);

  // run initStore when component is mounted
  useEffect(() => {
    initLanguage();
    initStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const values = {
    X_API_KEY,
    store,
    storeSetting,
    storeSettingCMS,
    landingPage,
    webColor,
    themeColor,
    theme,
    isMaintenance,
    requiredLogin,
    initStore,
  };

  return (
    <StoreContext.Provider value={values}>
      <Favicon url={store ? getFileUrl(store.logo) : ""} />
      {props.children}
    </StoreContext.Provider>
  );
};

export { StoreContext, StoreProvider };
