import { instance } from "../helper/api";

export const get = async (param) => {
  let paramValue = null;
  if (param) {
    paramValue = JSON.parse(param);
    paramValue = Object.assign(paramValue, { get_all_data: true });
  }

  let resultData = [];
  const axiosConfig = {
    url: "/shopi/v1/product/category/",
    method: "GET",
    params: paramValue,
  };

  try {
    const results = await instance.request(axiosConfig);
    resultData = results.data.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};

export const getDetail = async (param) => {
  let paramValue = null;
  if (param) {
    paramValue = JSON.parse(param);
    paramValue = Object.assign(paramValue, { get_all_data: true });
  }

  let resultData = [];
  const axiosConfig = {
    url: "/shopi/v1/product/category/detail/",
    method: "GET",
    params: paramValue,
  };

  try {
    const results = await instance.request(axiosConfig);
    resultData = results.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};
