import { Button, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const websiteColor = JSON.parse(localStorage.getItem("websiteColor"));

// console.log("websiteColor____________cb", websiteColor);


const ButtonCustom = (props) => {

  console.log("props.bg",props.bg)
  const useStyles = makeStyles({

    root: {
      "& .MuiButton-root:hover": {
        backgroundColor: "#000",
      },
      "& .MuiButton-text": {
        padding: "2px 15px",
      },
      "& .Mui-disabled": {
        backgroundColor: '#00000078 !important'
      },
    },

    btn_fullwidth: {
      display: "block",
      // backgroundColor: "#ff3a59",
      backgroundColor: websiteColor || props.bg || "#ff3a59",
      lineHeight: "40px",
      borderRadius: "4px",
      textAlign: "center",
      color: "#fff !important",
      width: "100%",
      marginBottom: "10px",
      textTransform: "capitalize",
      
    },
  
    btn_normal: {
      display: "block",
      // backgroundColor: "#ff3a59",
      backgroundColor: websiteColor || props.bg || "#ff3a59",
      lineHeight: "40px",
      borderRadius: "4px",
      textAlign: "center",
      color: "#fff !important",
      marginBottom: "10px",
      textTransform: "capitalize",
      
    },
  
    btn_border: {
      border: "1px solid #000 !important",
      // border: "1px solid #dee2e6!important",
      textTransform: "capitalize",
  
      "& svg": {
        margin: "0 !important",
        fontSize: "16px",
        marginRight: "5px !important",
      },
      "&:hover": {
        background: "#000",
        color: '#fff'
      },
    },
  
    btn_back: {
      minWidth: "auto",
      padding: "0",
  
      "& svg": {
        margin: "0px !important",
      },
    },
  });

  const classes = useStyles();

  if (props.width == "full") {
    return (
      <div className={classes.root}>
        <Button
          className={props.className + "  " + classes.btn_fullwidth}
          onClick={props.onClick}
          style={props.style}
          disabled={props.disabled}
        >
          {props.children}
        </Button>
      </div>
    );
  } else if (props.border == "true") {
    return (
      <Button
        className={props.className + "  " + classes.btn_border}
        onClick={props.onClick}
        style={props.style}
        disabled={props.disabled}
      >
        {props.children}
      </Button>
    );
  } else if (props.backArrow == "true") {
    return (
      <Button
        className={props.className + "  " + classes.btn_back}
        onClick={props.onClick}
        style={props.style}
        disabled={props.disabled}
      >
        <ArrowBackIcon />
      </Button>
    );
  } else {
    return (
      <div className={classes.root}>
        <Button
          className={props.className + "  " + classes.btn_normal}
          onClick={props.onClick}
          style={props.style}
          disabled={props.disabled}
        >
          {props.children}
        </Button>
      </div>
    );
  }
};

export default ButtonCustom;
