// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-box {
    /* border: 0.125rem solid #cccccc6b; */
    /* filter: drop-shadow(1px 3px 3px rgba(0, 0, 0, 0.25)); */
    background-color: #fff;
    border-radius: 4px;
  }
  
  .bank-card {
    background-color: #005faf;
    width: 4.5rem;
    height: 2.85rem;
  }`, "",{"version":3,"sources":["webpack://./src/containers/paymentpage/paymentMethod.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,0DAA0D;IAC1D,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,eAAe;EACjB","sourcesContent":[".payment-box {\n    /* border: 0.125rem solid #cccccc6b; */\n    /* filter: drop-shadow(1px 3px 3px rgba(0, 0, 0, 0.25)); */\n    background-color: #fff;\n    border-radius: 4px;\n  }\n  \n  .bank-card {\n    background-color: #005faf;\n    width: 4.5rem;\n    height: 2.85rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
