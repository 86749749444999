import React, { useCallback, useEffect, useState } from "react";
import Banner from "./Banner";
import BannerWeb from "./BannerWeb";
import ScrollableTabsButtonAuto from "./Tab";
import { makeStyles } from "@material-ui/core";
import Searchbar from "./Searchbar";
import ProductSlider from "./Slider";
import ProductSliderWeb from "./SliderWeb";
import ShortcutMenu from "./Shortcutmenu";
import MenuCategories from "./MenuCategories";
import ShortcutMenuWeb from "./ShortcutmenuWeb";
import MenuCategoriesWeb from "./MenuCategoriesWeb";
import BottNavigation from "./bottomnav/NavigationTab";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DeskTopFooter from "./DesktopFooter";
import DesktopHeaderWrap from "../../components/DeskTopHeaderWrap";
import { withNamespaces } from "react-i18next";
import i18n from "../language/localization";
import { useHistory } from "react-router";
// import Loader from "../loader";

// async action redux
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllProducts,
  fetchNewestProducts,
  fetchDiscountProducts,
  fetchBestSellerProducts,
  fetchRandomProducts,
} from "../../stores/features/products";
import { useStore } from "../../hooks/useStore";

const useStyles = makeStyles((theme) => ({
  Root: {
    [theme.breakpoints.down("xs")]: {
      minHeight: "100%",
      border: "none",
      width: "100%",
      maxWidth: "500px",
      // margin: "0px auto",
    },
  },
}));

function Home({ t }) {
  const { landingPage: landingPageData, themeColor } = useStore();

  const dispatch = useDispatch();
  const {
    allProducts,
    newestProducts,
    discountProducts,
    bestSellerProducts,
    randomProducts,
  } = useSelector((state) => state);
  const auth = useSelector((state) => state.auth);

  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [banner, setBanner] = useState([]);
  const classes = useStyles();
  const Usercode = JSON.parse(localStorage.getItem("usercode"));
  const history = useHistory();

  const findRandomCategoriesProduct = useCallback((categoriesProduct) => {
    const results = [];

    for (let index = 1; index <= categoriesProduct.length; index++) {
      const random = Math.floor(Math.random() * categoriesProduct.length);
      results.push(categoriesProduct[random]);
    }

    return results;
  }, []);

  useEffect(() => {
    if (auth.token) {
      dispatch(fetchAllProducts());
      dispatch(fetchNewestProducts());
      dispatch(fetchDiscountProducts());
      dispatch(fetchBestSellerProducts());
    }
  }, [dispatch, auth.token]);

  useEffect(() => {
    if (auth.token && landingPageData) {
      dispatch(
        fetchRandomProducts(landingPageData.product_categories.slice(5))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, findRandomCategoriesProduct, landingPageData, auth.token]);

  useEffect(() => {
    localStorage.setItem("desktopSearch", null);
    localStorage.setItem("searchname", "");
  }, [Usercode]);

  useEffect(() => {
    i18n.changeLanguage("id");
    window.scroll({ top: 0, left: 0, behavior: "instant" });
  }, []);

  const settingCustomer = landingPageData?.setting_customer;

  useEffect(() => {
    if (
      Mobile &&
      landingPageData &&
      settingCustomer?.is_customer_required_login === true &&
      !localStorage.getItem("token")
    ) {
      history.push("/login");
    }

    if (landingPageData && landingPageData?.hasOwnProperty("setting")) {
      const bannerImages = [];
      for (
        let counterBanner = 0;
        counterBanner < landingPageData?.vendor_banner.length;
        counterBanner++
      ) {
        const vendorImages =
          landingPageData?.vendor_banner[counterBanner].banner_images;
        for (
          let counterBannerImage = 0;
          counterBannerImage < vendorImages.length;
          counterBannerImage++
        ) {
          bannerImages.push(vendorImages[counterBannerImage]);
        }
      }
      setBanner(bannerImages);

      localStorage.setItem(
        "tabs",
        JSON.stringify(landingPageData?.product_categories)
      );
    }
  }, [Mobile, history, landingPageData, settingCustomer]);

  const settingCms = landingPageData?.setting_cms;

  // if (loading) {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         textAlign: "center",
  //         paddingTop: "250px",
  //         paddingBottom: "300px",
  //         flexDirection: "column",
  //         backgroundColor: "white",
  //         height: "100vh",
  //       }}
  //     >
  //       <div>
  //         <Loader />
  //       </div>
  //       <div>{t("loadingdot")}</div>
  //     </div>
  //   );
  // }

  return (
    <React.Fragment>
      <div className={classes.Root}>
        <React.Fragment>
          {Mobile ? (
            <React.Fragment>
              {Mobile ? <Searchbar color={themeColor} title="home" /> : null}
              {landingPageData &&
              settingCms &&
              settingCms.is_show_category_in_header === true ? (
                <div
                  style={{
                    position: "fixed",
                    zIndex: "1",
                    width: "100%",
                    marginTop: "45px",
                  }}
                >
                  <ScrollableTabsButtonAuto
                    color={themeColor}
                    categoriesData={landingPageData?.product_categories}
                  />
                </div>
              ) : (
                ""
              )}
            </React.Fragment>
          ) : (
            <DesktopHeaderWrap
              clickTab={() => {}}
              wishlistCount={0}
              isFixed={false}
              showCategory={settingCms && settingCms.is_show_category_in_header}
              categoriesData={landingPageData?.product_categories}
              landingPage={landingPageData}
            />
          )}

          <div style={{ background: Mobile ? "white" : "#f5f5f5" }}>
            {Mobile ? (
              <div>
                {landingPageData &&
                settingCms &&
                settingCms.is_show_banners === true ? (
                  <Banner bannerData={banner} />
                ) : (
                  ""
                )}

                {landingPageData &&
                settingCms &&
                settingCms.is_show_shorcuts === true ? (
                  <ShortcutMenu
                    shortcutsData={landingPageData?.vendor_shortcuts}
                  />
                ) : (
                  ""
                )}

                {landingPageData &&
                settingCms &&
                settingCms.is_show_category_in_body === true ? (
                  <MenuCategories
                    categoriesData={landingPageData?.product_categories}
                  />
                ) : (
                  ""
                )}
                <ProductSlider
                  color={themeColor}
                  productsAllData={allProducts.allProducts || []}
                  productsNewestData={newestProducts.newestProducts || []}
                  productsDiscountData={discountProducts.discountProducts || []}
                  productsBestSellerData={
                    bestSellerProducts.bestSellerProducts || []
                  }
                  productsByCategoriesData={randomProducts.randomProducts || []}
                />

                <BottNavigation />
              </div>
            ) : (
              <div style={{ width: "1200px", margin: "0px auto", zIndex: -1 }}>
                {settingCms && settingCms.is_show_banners === true ? (
                  <BannerWeb bannerData={banner} />
                ) : (
                  ""
                )}

                {landingPageData &&
                settingCms &&
                settingCms.is_show_shorcuts === true ? (
                  <ShortcutMenuWeb
                    shortcutsData={landingPageData?.vendor_shortcuts}
                  />
                ) : (
                  ""
                )}

                {landingPageData &&
                settingCms &&
                settingCms.is_show_category_in_body === true ? (
                  <MenuCategoriesWeb
                    categoriesData={landingPageData?.product_categories}
                  />
                ) : (
                  ""
                )}
                <ProductSliderWeb
                  color={themeColor}
                  productsAllData={allProducts.allProducts || []}
                  productsNewestData={newestProducts.newestProducts || []}
                  productsDiscountData={discountProducts.discountProducts || []}
                  productsBestSellerData={
                    bestSellerProducts.bestSellerProducts || []
                  }
                  productsByCategoriesData={randomProducts.randomProducts}
                />
              </div>
            )}
          </div>
          {Mobile ? null : <DeskTopFooter />}
        </React.Fragment>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(Home);
