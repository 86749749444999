import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { getAddressUser } from "../../store/actions/GetAddressActions";
// import { deleteAddressUser } from "../../store/actions/GetAddressActions";
// import { setDefaultAddressUser } from "../../store/actions/GetAddressActions";
// import { GetAddressResponse } from "../../store/Selectors/getaddress";
// import { DeleteAddressResponse } from "../../store/Selectors/getaddress";
// import { SetDefaultAddressResponse } from "../../store/Selectors/getaddress";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ClipLoader from "react-spinners/ClipLoader";
import Loader from "../loader";
import { withNamespaces } from "react-i18next";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  getDetail as getAddressDetail,
  deleteData as deleteAddressData,
} from "../../api/address";
import { useStore } from "../../hooks/useStore";

function SelectAddress({ t }) {
  const { store } = useStore();

  const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));
  // const dispatch = useDispatch();
  const userid = JSON.parse(localStorage.getItem("userid"));
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [true1, setTrue1] = useState(true);
  const [addressid, setAddressId] = useState(false);
  const [addressDatas, setAddressDatas] = useState([]);
  // const data1 = GetAddressResponse();
  const sitetitle = store?.name || process.env.REACT_APP_NICK_SITE_TITLE;
  const getData = () => {
    //const userid = JSON.parse(localStorage.getItem("userid"));
    let data = new FormData();
    data.append("user_id", userid);
    // dispatch(getAddressUser(data));
  };

  useEffect(() => {
    // getData();
    // setTimeout(() => {
    //   setOpen(true);
    //   setTrue1(true);
    // }, 500);
    const getAddressData = async () => {
      const userID = JSON.parse(localStorage.getItem("userid"));
      try {
        const response = await getAddressDetail(
          localStorage.getItem("token"),
          `{"customer_id":${userID}}`
        );
        setAddressDatas(response);
      } catch (error) {
        console.log("Error fetching getAddress:", error);
      }
    };

    getAddressData();
  }, []);

  const history = useHistory();
  const [checked, setChecked] = React.useState(true);

  const handleChange = (e) => {
    // setOpen2(true);
    // let data = new FormData();
    // data.append('address_id', e);
    // dispatch(setDefaultAddressUser(data));
    // const timer = setTimeout(() => {
    //   getData();
    //   setOpen2(false)
    //   setOpen4(true)
    // }, 500)
    // return () => clearTimeout(timer);
  };
  const deleteAddress = (e) => {
    setAddressId(e);
    setOpen1(true);
  };
  const handleclose1 = (e) => {
    setOpen3(false);
    setOpen2(false);
  };

  const handleclose2 = (e) => {
    setOpen4(false);
  };

  const handleclose = async (e) => {
    if (e == "yes") {
      // let data = new FormData();
      // data.append('address_id', addressid);
      // dispatch(deleteAddressUser(data));
      const data = {
        address_id: addressid,
      };
      const response = await deleteAddressData(
        data,
        localStorage.getItem("token")
      );
      if (response.success) {
        setOpen1(false);
        setOpen2(true);
        // const timer = setTimeout(() => {
        //   getData();
        //   setOpen2(false)
        //   setOpen3(true);
        // }, 300)
        // return () => clearTimeout(timer);
        const userID = JSON.parse(localStorage.getItem("userid"));
        try {
          const response = await getAddressDetail(
            localStorage.getItem("token"),
            `{"customer_id":${userID}}`
          );
          setAddressDatas(response);
          setOpen2(false);
          setOpen3(true);
        } catch (error) {
          console.log("Error fetching getAddress:", error);
        }
      }
    } else {
      setOpen1(false);
    }
  };
  // const deleted = DeleteAddressResponse();
  // const setdefault = SetDefaultAddressResponse();

  const handleClickEditAddress = (addrs) => {
    localStorage.setItem(
      "editaddress",
      JSON.stringify({
        address: addrs.customer_address.address,
        as_default: addrs.customer_address.is_primary,
        city_id: addrs.district.id,
        city_name: addrs.district.name,
        created_date: addrs.created_at,
        district_id: addrs.sub_district.id,
        district_name: addrs.sub_district.name,
        id: addrs.customer_address.id,
        name: addrs.customer_address.name,
        phone: addrs.customer_address.phone,
        postal_code: addrs.customer_address.postal_code,
        province_id: addrs.province.id,
        province_name: addrs.province.name,
        updated_date: addrs.customer_address.updated_at,
        user_id: addrs.customer_address.customer_id,
      })
    );
    history.push(`/addressform?id=${addrs.customer_address.id}`);
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            maxWidth: "500px",
            backgroundColor: ThemeColor ? ThemeColor : "#FFFFFF",
            position: "fixed",
            zIndex: "11111",
            marginBottom: "40px",
            userSelect: "none",
          }}
        >
          <div
            className="title-box"
            style={{
              height: "48px",
              maxWidth: "500px",
              display: "flex",
              justifyContent: "space-between",
              userSelect: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                userSelect: "none",
              }}
            >
              <KeyboardBackspaceIcon
                style={{ color: "white", width: "25px", userSelect: "none" }}
                onClick={() => {
                  history.goBack();
                }}
              />
              <h1
                className="title text-uppercase mb-0"
                style={{ marginTop: "2px", marginLeft: "10px" }}
              >
                {t("select_address")}
              </h1>
            </div>
            <div
              style={{
                color: "white",
              }}
            >
              <AddBoxOutlinedIcon
                onClick={() => {
                  history.push("/addressform");
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "48px", userSelect: "none" }}>
          {addressDatas.length > 0 ? (
            <div style={{ flexGrow: 1, width: "100%" }}>
              {addressDatas.map((addrs, index) => (
                <div
                  key={index}
                  className="border-0 shadow-card settingsCouponBox rounded"
                  style={{
                    padding: "10px",
                    margin: "10px",
                    userSelect: "none",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      userSelect: "none",
                      paddingBottom: "5px",
                    }}
                  >
                    {addrs.customer_address.name}
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      userSelect: "none",
                      paddingBottom: "2px",
                    }}
                  >
                    {addrs.customer_address.address}
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      userSelect: "none",
                      paddingBottom: "2px",
                    }}
                  >
                    {addrs.province.name +
                      ", " +
                      addrs.district.name +
                      ", " +
                      addrs.sub_district.name}
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      userSelect: "none",
                      paddingBottom: "2px",
                    }}
                  >
                    {addrs.phone}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                        left: "0",
                        display: "flex",
                        flexDirection: "row",
                        userSelect: "none",
                      }}
                    >
                      <Checkbox
                        checked={
                          addrs.customer_address.is_primary === true
                            ? true
                            : false
                        }
                        onClick={() => {
                          if (addrs.customer_address.is_primary !== 1) {
                            handleChange(addrs.customer_address.id);
                          }
                        }}
                        style={{
                          marginLeft: "-21px",
                          marginBottom: "-6px",
                          paddingBottom: "0px",
                          color: ThemeColor ? ThemeColor : "#FFFFFF",
                        }}
                      />
                      <div
                        style={{
                          marginLeft: "-10px",
                          marginBottom: "-17px",
                          userSelect: "none",
                        }}
                      >
                        {t("default")}
                      </div>
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                        flexDirection: "row",
                        display: "flex",
                        userSelect: "none",
                        // right:"0"
                      }}
                    >
                      <div
                        style={{
                          color: ThemeColor ? ThemeColor : "#FFFFFF",
                          fontSize: "16px",
                          marginRight: "15px",
                          marginBottom: "-17px",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => {
                          handleClickEditAddress(addrs);
                        }}
                      >
                        {t("edit")}
                      </div>
                      {addrs.customer_address.is_primary === false ? (
                        <div
                          style={{
                            color: ThemeColor ? ThemeColor : "#FFFFFF",
                            marginBottom: "-17px",
                            cursor: "pointer",
                            userSelect: "none",
                          }}
                          onClick={() => {
                            deleteAddress(addrs.customer_address.id);
                          }}
                        >
                          {t("delete1")}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                paddingTop: "250px",
                paddingBottom: "300px",
                flexDirection: "column",
                userSelect: "none",
              }}
            >
              <div>Your address is empty</div>
              <Button
                style={{
                  backgroundColor: ThemeColor ? ThemeColor : "#FFFFFF",
                  marginTop: "5px",
                  color: "white",
                }}
                onClick={() => {
                  history.push("/addressform");
                }}
              >
                ADD NEW ADDRESS
              </Button>
            </div>
          )}
        </div>
      </React.Fragment>

      {open1 ? (
        <div>
          <Dialog
            open={open1}
            onClick={handleclose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="simple-dialog-title">Delete Address</DialogTitle>
            <DialogContent
              id="alert-dialog-description"
              style={{
                color: "black",
                fontWeight: "500",
                marginBottom: "0px",
                marginTop: "-12px",
              }}
            >
              Are you Sure you want to delete?
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  onClick={() => {
                    handleclose("no");
                  }}
                  style={{
                    color: ThemeColor ? ThemeColor : "#FFFFFF",
                    fontWeight: "500",
                    minWidth: "0px",
                  }}
                >
                  No
                </Button>
                <Button
                  onClick={() => {
                    handleclose("yes");
                  }}
                  style={{
                    color: ThemeColor ? ThemeColor : "#FFFFFF",
                    fontWeight: "500",
                    minWidth: "0px",
                  }}
                >
                  Yes
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ""
      )}
      {open2 ? (
        <div>
          <Dialog
            open={open2}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              id="alert-dialog-description"
              style={{
                color: "black",
                fontWeight: "500",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "12px 24px",
              }}
            >
              <div style={{ marginRight: "14px" }}>
                <ClipLoader
                  color={ThemeColor ? ThemeColor : "#FFFFFF"}
                  height={50}
                  style={{
                    paddingLeft: "1px",
                    marginTop: "8px",
                    fontSize: "4",
                  }}
                />{" "}
              </div>
              <div>Loading ...</div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ""
      )}
      {open3 ? (
        <div>
          <Dialog
            open={open3}
            onClick={handleclose1}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="simple-dialog-title">Delete Address</DialogTitle>
            <DialogContent
              id="alert-dialog-description"
              style={{
                color: "black",
                fontWeight: "500",
                marginBottom: "0px",
                marginTop: "-12px",
              }}
            >
              {"delete success"}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  onClick={handleclose1}
                  style={{
                    color: ThemeColor ? ThemeColor : "#FFFFFF",
                    fontWeight: "500",
                    minWidth: "0px",
                  }}
                >
                  Ok
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ""
      )}
      {open4 ? (
        <div>
          <Dialog
            open={open4}
            onClick={handleclose2}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="simple-dialog-title">
              <b>{sitetitle}</b>
            </DialogTitle>
            <DialogContent
              id="alert-dialog-description"
              style={{
                color: "black",
                fontWeight: "500",
                marginBottom: "0px",
                marginTop: "-12px",
              }}
            >
              {"set default success"}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  onClick={handleclose2}
                  style={{
                    color: ThemeColor ? ThemeColor : "#FFFFFF",
                    fontWeight: "500",
                    minWidth: "0px",
                  }}
                >
                  Ok
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default withNamespaces()(SelectAddress);
