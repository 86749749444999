import { Box } from "@material-ui/core";
import React from "react";
import { t } from "i18next";

const NoDataFound = () => {
  return (
    <Box
      style={{
        display: "flex",
        height: "350px",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#fff',
        marginBottom: '30px'
      }}
    >
      <h6 style={{}}>{t('no_data_found_dot')}</h6>
    </Box>
  );
};

export default NoDataFound;
