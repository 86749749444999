// import * as axios from "axios";

// export default class Api {
//   constructor() {
//     this.api_token = null;
//     this.client = null;
//     this.api_url = "http://farhan.lan";
//   }

//   init = () => {
//     // this.api_token = getCookie("ACCESS_TOKEN");

//     let headers = {
//       "Access-Control-Allow-Origin": "*",
//       "key": "c4c5fc54cd5c071f3fe4785b8ae167e0",
//     };

//     // if (this.api_token) {
//     //   headers.Authorization = `Bearer ${this.api_token}`;
//     // }

//     this.client = axios.create({
//       baseURL: this.api_url,
//       timeout: 31000,
//       headers: headers,
//       mode: 'no-cors',
//     });

//     return this.client;
//   };

//   getUserList = (params) => {
//     return this.init().post("/Api/Dashboard/dashboardData", { params: params });
//   };

//   // addNewUser = (data) => {
//   //   return this.init().post("/users", data);
//   // };
// }

import axios from "axios";

const oldApi = "https://api.utechsoft.net";

const hostNamesOldApi = ["localhost", "store.utechsoft.net"];

const baseUrlApi = () => {
  console.log(
    "hostNamesOldApi",
    hostNamesOldApi.includes(window.location.hostname)
  );
  if (hostNamesOldApi.includes(window.location.hostname)) {
    return oldApi;
  }

  return process.env.REACT_APP_API_BASE_URL;
};

const instance = axios.create({
  baseURL: baseUrlApi(),
});

instance.interceptors.request.use((config) => {
  const X_API_KEY = localStorage.getItem("X_API_KEY");
  const token = localStorage.getItem("token");

  if (X_API_KEY) {
    config.headers["X-API-KEY"] = `${X_API_KEY}`;
  }

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  config.timeout = 40000;
  config.responseType = "json";
  config.responseEncoding = "utf8";
  config.headers["Accept"] = "application/json";

  return config;
});

const instance2 = axios.create({
  baseURL: baseUrlApi(),
});

instance2.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  config.timeout = 40000;
  config.responseType = "json";
  config.responseEncoding = "utf8";
  config.headers["Accept"] = "application/json";

  return config;
});

export { instance, instance2, baseUrlApi };
