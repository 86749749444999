import { instance, instance2 } from "../helper/api";

export const getBadgeCart = async (token) => {
  let resultData = {};
  const axiosConfig = {
    url: "/v1/storefront/badge/cart-item/",
    method: "GET",
  };

  try {
    const results = await instance2.request(axiosConfig);
    resultData = results.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};

export const getBadgeOrderByStatus = async (token) => {
  let resultData = {};
  const axiosConfig = {
    url: "/v1/storefront/badge/order-by-status/",
    method: "GET",
  };

  try {
    const results = await instance.request(axiosConfig);
    resultData = results.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};

export const getCountOrderByStatus = async (token) => {
  let resultData = {};
  const axiosConfig = {
    url: "/shopi/v1/order/status-count/",
    method: "GET",
  };

  try {
    const results = await instance.request(axiosConfig);
    resultData = results.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};
