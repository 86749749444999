import React from "react";

import { useStyles } from "./productCard.style";
import FavoriteOutlinedIcon from "@material-ui/icons/FavoriteOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import ButtonCustom from "../ButtonCustom";
import Amount from "../Amount";

const ProductCard = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.grid_item + " " + props.className}>
      <div className="single-product" onClick={props.Quickview}>
        <div className={classes.fav_like}>
          <FavoriteOutlinedIcon />
        </div>
        <div
          className={" product-img " + classes.image_parent}
          style={{
            height: props.name.length < 22 ? "164px" : "146px",
            width: "100%",
          }}
        >
          <img
            src={props.image}
            alt="img"
            style={{
              height: props.name.length < 22 ? "164px" : "146px",
              width: "100%",
            }}
          />
        </div>
        <div className="product-text">
          <div className="quickview_new" onClick={props.Quickview}>
            <VisibilityOutlinedIcon />
            Quick View
          </div>
          <h5
            className={
              "  " + props.name.length > 21
                ? classes.towline_title
                : "  prod-name "
            }
          >
            {props.name.length > 35 ? props.name.slice(0, 35) : props.name}
          </h5>

          <div className={classes.prod_price + "  prod-price"}>
            <span>
              Rp&nbsp;
              <Amount amount={props.price} style={{ fontWeight: "600" }} />
            </span>
            {props.prDiccount == "0" ? null : (
              <span className="product-old-price">
                Rp&nbsp;
                <Amount
                  amount={props.discountPrice}
                  style={{ fontWeight: "600" }}
                />
              </span>
            )}
            {props.prDiccount == "0" ? null : (
              <div className="product_off">{props.prDiccount}% Off</div>
            )}
          </div>
          <ButtonCustom
            width="full"
            className={classes.btn_buy}
            onClick={props.Quickview}
            bg={props.webThemeColor}
          >
            Buy
          </ButtonCustom>
        </div>
        {/* <div className="product-offer-label">
                        <span className="new-lab">New</span>
                        <span className="whlsale-lab">Wholesale</span>
                        <span className="best-lab">Best Seller</span>
                        <span className="discount-lab">50%</span>
                    </div>  */}
      </div>
    </div>
  );
};

export default ProductCard;
