// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*  */
div.scrollmenu {
  background-color: #fff !important;
  overflow: auto;
  white-space: nowrap;
  
}
.scrollmenu::-webkit-scrollbar {
  display: none;
}
.scrollmenu{
    cursor:pointer;
}
.chip-css {
  width: 150px;
  cursor:pointer;
}

/*  menu */
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
 
}

li a {
  display: block;
  color:#000 !important;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li a:hover:not(.active) {
  background-color: #FFf !important;
}

.active{
    /* background-color: #FFF !important; */
    /* color:#EB0003 !important; */
}

/* tab */
.TabIndicatorProps {
  left: 87.8px !important;
  width: 83.8px !important;
  height: 2px;

}`, "",{"version":3,"sources":["webpack://./src/containers/product/women.css"],"names":[],"mappings":"AAAA,KAAK;AACL;EACE,iCAAiC;EACjC,cAAc;EACd,mBAAmB;;AAErB;AACA;EACE,aAAa;AACf;AACA;IACI,cAAc;AAClB;AACA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA,UAAU;AACV;EACE,qBAAqB;EACrB,SAAS;EACT,UAAU;EACV,gBAAgB;AAClB;;AAEA;;AAEA;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;IACI,uCAAuC;IACvC,8BAA8B;AAClC;;AAEA,QAAQ;AACR;EACE,uBAAuB;EACvB,wBAAwB;EACxB,WAAW;;AAEb","sourcesContent":["/*  */\ndiv.scrollmenu {\n  background-color: #fff !important;\n  overflow: auto;\n  white-space: nowrap;\n  \n}\n.scrollmenu::-webkit-scrollbar {\n  display: none;\n}\n.scrollmenu{\n    cursor:pointer;\n}\n.chip-css {\n  width: 150px;\n  cursor:pointer;\n}\n\n/*  menu */\nul {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n  overflow: hidden;\n}\n\nli {\n \n}\n\nli a {\n  display: block;\n  color:#000 !important;\n  text-align: center;\n  padding: 14px 16px;\n  text-decoration: none;\n}\n\nli a:hover:not(.active) {\n  background-color: #FFf !important;\n}\n\n.active{\n    /* background-color: #FFF !important; */\n    /* color:#EB0003 !important; */\n}\n\n/* tab */\n.TabIndicatorProps {\n  left: 87.8px !important;\n  width: 83.8px !important;\n  height: 2px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
