import { Box, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStyles1 } from "../themecolor/FontSize";
import i18n from "../language/localization";
import { useHistory } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { addWishlistProductUser } from "../../store/actions/addWishlistActions";
import SkeletonCircleText from "../../components/common/SkeletonCircleText";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { priceFormatLocal } from "../../utils/helper";
import CardProduct from "../../components/card-product/CardProduct";

const RelatedProduct = ({ dataSimilar, color, t }) => {
  const classes1 = useStyles1();
  const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));
  const membershipData = JSON.parse(localStorage.getItem("membership"));
  const [membershipLevel, setMembershipLevel] = useState(1);
  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
    if (membershipData) {
      setMembershipLevel(membershipData.level);
    }
  }, []);

  const useStyles = makeStyles((theme) => ({
    box: {
      boxShadow: "0px 0px 8px -1px rgb(219 213 213)",
      "& h6": {
        color: color,
      },
    },

    cards_style: {
      transition: "all 0.1s ease-out",

      "& .card": {
        border: "1px solid transparent !important",
        marginTop: "3px",
      },
      "& .card:hover": {
        border: "1px solid " + ThemeColor + " !important",

        transform: "translateY(-2px) scale(1.005) translateZ(0)",
        cursor: "pointer",
      },
    },

    cardTitle: {
      "& .read-more-less--more": {
        display: "none",
      },
    },

    imageslide: {
      "&>.image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail.active":
        {
          border: `1px solid ${color} !important`,
        },
      "&>.image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail:hover":
        {
          border: `3px solid ${color} !important`,
        },
      // "& image-gallery-thumbnail.active": {
      //
      // }
    },
    title: {
      fontSize: "13px",
    },
    avatarfav: {
      backgroundColor: "transparent",
      border: "1px solid transparent",
      marginBottom: "60px",
    },
    avatarwhat: {
      backgroundColor: "rgba(20, 9, 9, 0.3);",
      border: "1px solid #fff",
      marginBottom: "5px",
    },
    sectionHeader: {
      // gap: "10px",
      // display: "flex",
      // backgroundColor: "#d5d5d5",
      // padding: "10px 20px",
      // fontSize: "16px",
      fontSize: "20px",
      fontWeight: "600",
      userSelect: "none",
      paddingLeft: "5px",

      // "& .link": {
      //   color: "#323232",
      //   cursor: "pointer",
      // },
    },
    whatsAppIcon: {
      background: "rgba(37, 211, 102, 1)",
      borderRadius: "50%",
    },
    favBorderIcon: {
      backgroundColor: color,
      marginBottom: "0px",
    },
    wholeSale: {
      color: "rgba(68, 210, 255, 1)",
      fontSize: "10px",
    },
    colorBox: {
      height: "3.4rem",
      width: "3.5rem",
      color: "black",
    },
    icons: {
      color: "#8B8B8B",
    },
    chatBtn: {
      backgroundColor: "#FFF",
      color: "#000",
      border: "none",
      borderRadius: "0px",
      width: "100%",
      height: "100%",
      marginBottom: "9px",
      paddingBottom: "5px",
    },
    buyBtn: {
      backgroundColor: color,
      color: "#fff",
      width: "100%",
      border: "none",
      height: "100%",
      marginBottom: "12px",
      paddingBottom: "12px",
    },
    specificationTable: {
      color: "rgba(139, 139, 139, 1)",
      width: "100%",
      borderCollapse: "collapse",
      "& td": {
        paddingTop: "0.4rem",
        paddingBottom: "0.4rem",
        paddingLeft: "0.25rem",
      },
      "& td:nth-child(1)": {
        width: "40%",
      },
      "& tr:nth-child(odd)": {
        backgroundColor: "#F2F0F1",
      },
    },
    bestSeller: {
      paddingTop: "0.25rem",
      paddingBottom: "0.25rem",
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
      backgroundColor: color,
      color: "#fff",
      fontSize: "0.75rem",
      borderRadius: "0.25rem",
    },
    shareButton: {
      backgroundColor: "#E4E5E9",
      border: "none",
      width: "25%",
      fontSize: "10px",
      // padding: "0.35rem",
      textAlign: "center",
    },
    wishIconDiv: {
      marginRight: "10px",
      [theme.breakpoints.down("xs")]: {
        marginRight: "15px",
      },
      [theme.breakpoints.down(375)]: {
        marginRight: "7px",
      },
    },
    snackbarStyleViaContentProps1: {
      backgroundColor: "black !important",
      textAlign: "center !important",
      color: "white !important",
      boxShadow: "none !important",
      display: "flex !important",
      justifyContent: "center !important",
      fontWeight: "500 !important",
      fontSize: "17px !important",
      borderRadius: "3px !important",
      margin: "0px 30px !important",
    },
    desdiv: {
      "& p": {
        marginBottom: "0px !important",
      },
    },

    mobile_slider_cards: {
      "& .card-body": {
        // minHeight: '117px',
        padding: "5px !important",
      },
      "& .mainImageThum": {
        height: "200px",
      },
      "& .cardTitle ": {
        //  textTransform: 'uppercase',
        fontSize: "13px !important",
      },
    },
  }));
  const [data7, setData7] = useState([]);
  const [related, setReleated] = useState([]);
  const [NewData, setNewData] = useState([]);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const history = useHistory();
  // const dispatch = useDispatch();
  const addWishRed = (e, j) => {
    const usercode = JSON.parse(localStorage.getItem("usercode"));
    let data1 = new FormData();
    data1.append("product_id", e);
    data1.append("user_code", usercode);
    data1.append("date_time", "2018-12-25 10:23:00");
    // dispatch(addWishlistProductUser(data1));
    let tmpArr = Object.assign([], NewData);
    let tmpObj = Object.assign({}, tmpArr[j]);
    tmpObj["is_wishlist"] = "1";
    tmpArr[j] = tmpObj;
    setNewData(tmpArr);
  };

  const getSingleProduct = (e) => {
    // const usercode=JSON.parse(localStorage.getItem("usercode"));
    // const userid=JSON.parse(localStorage.getItem("userid"));
    // var data3 = new FormData();
    // data3.append("product_id", e);
    // if(userid){
    //   data3.append("user_id", userid);
    //   data3.append("user_code", usercode);
    // }
    // dispatch(singleProductDataUser(data3));
    // history.push("/productdetail");
    window.location.href = "/product-detail?" + e + "";
    // history.push(``);
  };
  useEffect(() => {
    singleData();
  }, []);

  const singleData = () => {
    // const url_str = window.location.href;
    // const data11 = url_str.split("/");
    // const ids = data11[3].toString();
    // var myHeaders = new Headers();
    // myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");
    // myHeaders.append("Access-Control-Allow-Origin", "*");
    // const usercode = JSON.parse(localStorage.getItem("usercode"));
    // const userid = JSON.parse(localStorage.getItem("userid"));
    // var formdata = new FormData();
    // if (userid) {
    //   formdata.append("user_id", userid);
    //   formdata.append("user_code", usercode);
    // }
    // formdata.append("product_id", ids.slice(14));
    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // };
    // fetch(`${baseUrlApi()}/Api/Product/singleProductData`, requestOptions)
    //   .then((response) => response.json())
    //   .then((result) => {
    //     setData7(result.similar_product);
    //     setOpen(true);
    //     // setTimeout(() => { getWishlist() }, 100)
    //   })
    //   .catch((error) => console.log("error", error));
  };

  const addWishWhite = (e, j) => {
    const usercode = JSON.parse(localStorage.getItem("usercode"));
    let data1 = new FormData();
    data1.append("product_id", e);
    data1.append("user_code", usercode);
    data1.append("date_time", "2018-12-25 10:23:00");
    // dispatch(addWishlistProductUser(data1));
    let tmpArr = Object.assign([], NewData);
    let tmpObj = Object.assign({}, tmpArr[j]);
    tmpObj["is_wishlist"] = "0";
    tmpArr[j] = tmpObj;
    setNewData(tmpArr);
  };

  return (
    <div>
      <Box className={classes.sectionHeader}>{t("related_products")}</Box>
      <div>
        <div
          className="container11"
          style={{
            paddingRight: "0px",
            paddingLeft: "0px",
            paddingTop: "10px",
            // background: "white",
            paddingBottom: "22px",
            marginBottom: "2rem",

            display: "flex",
            gap: "5px",
          }}
        >
          {dataSimilar.length > 0 ? (
            <div
              className={
                classes.cards_style + " " + classes.mobile_slider_cards
              }
              style={{
                width: "100%",
                display: "flex",
                overflow: "auto",
                flexWrap: "wrap",
                flexDirection: "row",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
                paddingLeft: "0px",
                paddingRight: "1px",
                justifyContent: "start",
                columnGap: "5px",
              }}
            >
              {dataSimilar.map((dt, index) => {
                return (
                  <CardProduct
                    key={index}
                    membershipLevel={membershipLevel}
                    product={dt.product}
                    handleClick={getSingleProduct}
                  />

                  // <div
                  //   key={index}
                  //   className="card border-0 shadow-card 11"
                  //   style={{
                  //     width: "calc(21% - 16.66px)",
                  //     // padding:7,
                  //     marginBottom: "2px",
                  //     // height:"235px",
                  //     clear: "both",
                  //     // minHeight: "288px",
                  //     cursor: "pointer",
                  //   }}
                  //   onClick={() => {
                  //     getSingleProduct(dt.product.id);
                  //   }}
                  // >
                  //   <div
                  //     onClick={() => {
                  //       getSingleProduct(dt.product.id);
                  //     }}
                  //     style={{
                  //       position: "relative",
                  //       height: Mobile
                  //         ? dt.product.name.length < 20
                  //           ? "182px"
                  //           : "164px"
                  //         : dt.product.name.length < 26
                  //         ? "210px"
                  //         : "190px",
                  //       width: "100%",
                  //     }}
                  //   >
                  //     <img
                  //       src={
                  //         dt.product.media && dt.product.media.length > 0
                  //           ? process.env.REACT_APP_FILE_BASE_URL +
                  //             "/" +
                  //             dt.product.media[0]
                  //           : "/assets/images/dummy.png"
                  //       }
                  //       className="card-img-top"
                  //       style={{
                  //         position: "relative",
                  //         height: Mobile
                  //           ? dt.product.name.length < 20
                  //             ? "182px"
                  //             : "164px"
                  //           : dt.product.name.length < 26
                  //           ? "210px"
                  //           : "190px",
                  //         width: "100%",
                  //       }}
                  //       alt="img"
                  //     />
                  //     {dt.stock_status === "Sold Out" ? (
                  //       <div
                  //         className="position-absolute d-flex justify-content-center align-items-center rounded-circle"
                  //         style={{
                  //           width: "4.5rem",
                  //           height: "4.5rem",
                  //           top: "75px",
                  //           left: "75px",
                  //           fontSize: "12px",
                  //           backgroundColor: "rgba(0, 0, 0, 0.5)",
                  //         }}
                  //       >
                  //         <span
                  //           className="text-light"
                  //           style={{ cursor: "pointer" }}
                  //         >
                  //           {t("sold_out")}
                  //           {/* Sold Out */}
                  //         </span>
                  //       </div>
                  //     ) : (
                  //       ""
                  //     )}
                  //   </div>

                  //   <div>
                  //     {/* example is wishlist flag */}
                  //     {/* {dt.is_wishlist == "1" ? (
                  //           <img
                  //             src="/assets/frontend/img/wishlist_fav.png"
                  //             height="22px"
                  //             style={{
                  //               display: "flex",
                  //               justifyContent: "flex-end",
                  //               top: 0,
                  //               position: "absolute",
                  //               cursor: "pointer",
                  //               marginTop: "10px",
                  //               marginLeft: "10px",
                  //             }}
                  //             onClick={() => {
                  //               addWishWhite(dt.id, index);
                  //             }}
                  //           />
                  //         ) : (
                  //           <img
                  //             src="/assets/frontend/img/wishlist_list.png"
                  //             height="22px"
                  //             style={{
                  //               display: "flex",
                  //               justifyContent: "flex-end",
                  //               top: 0,
                  //               position: "absolute",
                  //               cursor: "pointer",
                  //               marginTop: "10px",
                  //               marginLeft: "10px",
                  //             }}
                  //             onClick={() => {
                  //               addWishRed(dt.id, index);
                  //             }}
                  //           />
                  //         )} */}
                  //   </div>

                  //   {dt.product.has_video === true &&
                  //   dt.product.stock_status !== "Sold Out" ? (
                  //     <div
                  //       className="position-absolute d-flex justify-content-center align-items-center rounded-circle "
                  //       style={{
                  //         width: "4.5rem",
                  //         height: "4.5rem",
                  //         backgroundColor: "rgba(0, 0, 0, 0.5)",
                  //         left: "32%",
                  //         top: "32%",
                  //         userSelect: "none",
                  //       }}
                  //     >
                  //       <span
                  //         className="text-light"
                  //         style={{
                  //           fontSize: "12px", //14
                  //           fontWeight: "bolder",
                  //           cursor: "pointer",
                  //           userSelect: "none",
                  //         }}
                  //       >
                  //         <PlayArrowIcon />
                  //       </span>
                  //     </div>
                  //   ) : (
                  //     ""
                  //   )}

                  //   {dt.product.stock_status === "Sold Out" ? (
                  //     <div
                  //       className="position-absolute d-flex justify-content-center align-items-center rounded-circle "
                  //       style={{
                  //         width: "4.5rem",
                  //         height: "4.5rem",
                  //         backgroundColor: "rgba(0, 0, 0, 0.5)",
                  //         left: "32%",
                  //         top: "32%",
                  //         userSelect: "none",
                  //       }}
                  //     >
                  //       <span
                  //         className="text-light"
                  //         style={{
                  //           fontSize: "12px", //14
                  //           fontWeight: "bolder",
                  //           cursor: "pointer",
                  //           userSelect: "none",
                  //         }}
                  //       >
                  //         {t("sold_out")}
                  //       </span>
                  //     </div>
                  //   ) : (
                  //     ""
                  //   )}

                  //   <div
                  //     style={{
                  //       position: "absolute",
                  //       top: "5px",
                  //       right: "5px",
                  //       userSelect: "none",
                  //     }}
                  //   >
                  //     {dt.product.discount_type &&
                  //     dt.product.discount_type === "percentage" ? (
                  //       <div
                  //         style={{
                  //           background: "red",
                  //           display: "flex",
                  //           justifyContent: "center",
                  //           alignItems: "center",
                  //           color: "#fff",
                  //           fontSize: "12px",
                  //           width: "58px",
                  //           height: "20px",
                  //           borderRadius: "3px",
                  //           marginTop: "1px",
                  //           marginBottom: "5px",
                  //           cursor: "pointer",
                  //           userSelect: "none",
                  //         }}
                  //       >
                  //         {dt.product.discount}%
                  //       </div>
                  //     ) : (
                  //       ""
                  //     )}

                  //     {dt.product.discount_type &&
                  //     dt.product.discount_type === "nominal" ? (
                  //       <div
                  //         style={{
                  //           background: "red",
                  //           display: "flex",
                  //           justifyContent: "center",
                  //           alignItems: "center",
                  //           color: "#fff",
                  //           fontSize: "12px",
                  //           width: "58px",
                  //           height: "20px",
                  //           borderRadius: "3px",
                  //           marginTop: "1px",
                  //           marginBottom: "5px",
                  //         }}
                  //       >
                  //         {parseInt(
                  //           (dt.product.discount /
                  //             dt.product.price[membershipLevel]) *
                  //             100
                  //         )}
                  //         %
                  //       </div>
                  //     ) : (
                  //       ""
                  //     )}

                  //     {dt.product.labels &&
                  //       dt.product.labels.length > 0 &&
                  //       dt.product.labels
                  //         .filter(
                  //           (label) =>
                  //             label !== "Sold Out" && label !== "Diskon"
                  //         )
                  //         .map((item, index) => {
                  //           let bgColor = "#fdd535";
                  //           let color = "#fff";
                  //           switch (item) {
                  //             case "Sold Out":
                  //               bgColor = "#1A1818";
                  //               break;

                  //             case "Terbaru":
                  //               bgColor = "#fdd535";
                  //               break;

                  //             case "Terlaris":
                  //               bgColor = "red";
                  //               color = "yellow";
                  //               break;

                  //             case "Grosir":
                  //               bgColor = "#00BFFF";
                  //               break;

                  //             default:
                  //               break;
                  //           }
                  //           return (
                  //             <div
                  //               style={{
                  //                 background: bgColor,
                  //                 display: "flex",
                  //                 justifyContent: "center",
                  //                 alignItems: "center",
                  //                 color: color,
                  //                 fontSize: "12px",
                  //                 width: "58px",
                  //                 height: "20px",
                  //                 borderRadius: "3px",
                  //                 marginTop: "1px",
                  //                 marginBottom: "5px",
                  //               }}
                  //             >
                  //               {item}
                  //             </div>
                  //           );
                  //         })}
                  //   </div>

                  //   <div className="card-body p-0 ps-2 pe-1">
                  //     <div
                  //       className={
                  //         classes1.textfont + " cardTitle " + classes.cardTitle
                  //       }
                  //       style={
                  //         dt.product.name.length >= 26
                  //           ? {
                  //               height: "39px",
                  //               // marginTop: "5px",
                  //               marginBottom: "0.25rem !important",
                  //             }
                  //           : {
                  //               height: "auto",
                  //               marginBottom: "0.25rem !important",
                  //             }
                  //       }
                  //     >
                  //       {dt.product.name.length > 36
                  //         ? dt.product.name.slice(0, 36)
                  //         : dt.product.name}
                  //     </div>
                  //     <div
                  //       className={classes1.pricePadding}
                  //       style={{
                  //         display: "flex",
                  //         justifyContent: "space-between",
                  //         alignItems: "center",
                  //         flexDirection: "row",
                  //         flexShrink: 0,
                  //         overflow: "hidden",
                  //       }}
                  //     >
                  //       <div
                  //         className={classes1.pricediv}
                  //         style={{
                  //           marginRight: "10px",
                  //           display: "flex",
                  //           flexShrink: 0,
                  //         }}
                  //       >
                  //         {/* Rp {dt.product.price[membershipLevel]} */}
                  //         <b style={{ cursor: "pointer" }}>
                  //           {dt.product.discount_type &&
                  //           dt.product.discount_type === "nominal" ? (
                  //             <p
                  //               style={{
                  //                 marginBottom: "0px",
                  //               }}
                  //             >
                  //               {priceFormatLocal(
                  //                 dt.product.price[membershipLevel] -
                  //                   dt.product.discount
                  //               )}
                  //             </p>
                  //           ) : (
                  //             ""
                  //           )}

                  //           {dt.product.discount_type &&
                  //           dt.product.discount_type === "percentage" ? (
                  //             <p
                  //               style={{
                  //                 marginBottom: "0px",
                  //               }}
                  //             >
                  //               {priceFormatLocal(
                  //                 dt.product.price[membershipLevel] -
                  //                   (dt.product.discount / 100) *
                  //                     dt.product.price[membershipLevel]
                  //               )}
                  //             </p>
                  //           ) : (
                  //             ""
                  //           )}

                  //           {dt.product.discount === 0 ? (
                  //             <p
                  //               style={{
                  //                 marginBottom: "0px",
                  //               }}
                  //             >
                  //               {priceFormatLocal(
                  //                 dt.product.price[membershipLevel]
                  //               )}
                  //             </p>
                  //           ) : (
                  //             ""
                  //           )}
                  //         </b>
                  //       </div>
                  //       {dt.product.discount_type ? (
                  //         <div>
                  //           <span className={classes1.cutdiv}>
                  //             {priceFormatLocal(
                  //               dt.product.price[membershipLevel]
                  //             )}
                  //           </span>
                  //         </div>
                  //       ) : (
                  //         ""
                  //       )}
                  //     </div>
                  //   </div>
                  // </div>
                );
              })}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>{t("empty_product")}</b>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default RelatedProduct;
