import { instance2 } from "../helper/api";

export const create = async (data, token) => {
  let resultData = {};
  const axiosConfig = {
    url: `/shopi/v1/customer/cart-checkout-ecommerce/`,
    method: "POST",
    data: data,
  };

  try {
    const results = await instance2.request(axiosConfig);
    resultData = results.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};
