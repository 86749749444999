import { instance } from "../helper/api";

export const get = async (token, param) => {
  let paramValue = null;
  if (param) {
    paramValue = JSON.parse(param);
  }

  let resultData = {};
  const axiosConfig = {
    url: "/shopi/v1/order/",
    method: "GET",
    params: paramValue,
  };

  try {
    const results = await instance.request(axiosConfig);
    resultData = results.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};

export const cancel = async (data, token) => {
  let resultData = {};
  const axiosConfig = {
    url: `/shopi/v1/order/${data.order_id}/`,
    method: "PATCH",
    data: {
      order_status: "CANCELED",
    },
  };

  try {
    const results = await instance.request(axiosConfig);
    resultData = results.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};
