import React, { useState, useEffect } from "react";
import Header from "../header/header";
// import { ThemeColor1 } from "../themecolor/index1";
import Loader from "../loader";

const Viewed = () => {
  const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));
  const [open, setOpen] = useState(true);
  const userid = JSON.parse(localStorage.getItem("userid"));
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 700);
  }, []);
  return (
    <div>
      {
        open ?
          <div>
            <Header title="Last Viewed" color={ThemeColor} />
            <div
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "250px",
                  paddingBottom: "300px",
                  height:"100vh",
                  backgroundColor: "white"
                }}
              >
                {userid ? <h4>Coming Soon</h4> : <h4>No Message Found</h4>}
              </div>
            </div>
          </div> :
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: "250px",
              paddingBottom: "300px",
              flexDirection: "column",
              height:"100vh",
              backgroundColor: "white"
            }}
          >
            <div><Loader /></div>
            <div>Loading...</div>
          </div>
      }
    </div>
  );
};

export default Viewed;