import { Box } from "@material-ui/core";
import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

const Loading = () => {
  return (
    <Box
      style={{
        display: "flex",
        height: "350px",
        justifyContent: "center",
        alignItems: "center",
        width: '100%',
      }}
    >
      {/* <h6 style={{}}>Loading...</h6> */}
      <div style={{width: '20%'}}>

      <LinearProgress />
      </div>
    </Box>
  );
  
};

export default Loading;
