import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// import Tabs from "@material-ui/core/Tabs";
import NavigationTab from "../home/bottomnav/NavigationTab";
import "./women.css";
import Header from "../header/header";
// import Tab from "@material-ui/core/Tab";
import { styled } from "@material-ui/core/styles";
// import { GetProductDataResponse } from "../../store/Selectors/getproductdata";
// import { getProductData } from "../../store/actions/GetProductdataActions";
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import New from "./productview/new";
import Discount from "./productview/discount";
import Bestseller from "./productview/bestseller";
import PriceLow from "./productview/pricelow";
import PriceHigh from "./productview/pricehigh";
// import { getCategoryDataProduct } from "../../store/actions/getCategoryActions";
// import { GetCategoryResponse } from "../../store/Selectors/getCategory";
import Loader from "../loader";
import { Tabs, Tab } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import { withNamespaces } from "react-i18next";
import i18n from "../language/localization";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
// import { addWishlistProductUser } from "../../store/actions/addWishlistActions";
import { useStyles1 } from "../themecolor/FontSize";

const ProductView = ({ t }) => {
  const classes1 = useStyles1();
  const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));
  let data = [
    { label: "New", location: "" },
    { label: "Discount", location: "" },
    { label: "Best Seller", location: "" },
    { label: "Price", location: "" },
  ];
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : "#FFFFFF",
        color: "#000",
      },
    },
  });
  const [value, setValue] = React.useState("zero");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 3) {
      handleValue();
    }
  };
  const AntTabs = styled(Tabs)({
    "& .MuiTabs-indicator": {
      backgroundColor: "#fff",
      // backgroundColor: color,
    },
  });

  const AntTab = styled((d) => <Tab disableRipple {...d} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    borderRight: "1px solid #c8c8c8",
    height: "0.4rem",
    paddingRight: "12px",
    marginRight: "-1px",
    marginLeft: "0px",
    fontSize: "0.8rem !important",
    [theme.breakpoints.up("sm")]: {
      minWidth: 100,
    },

    "&.Mui-selected": {
      // margin:"10px",
      color: "#000 !important",
      marginRight: "-1px",
      marginLeft: "0px",
      // color: `${color} !important`,
      textTransform: "uppercase !important",
      fontSize: "0.9rem !important",
      height: "0.4rem",
      paddingRight: "12px",
      // fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#fff",
    },
  }));

  const [true1, setTrue1] = useState(false);

  const history = useHistory();
  // const dispatch = useDispatch();
  const [val, setVal] = useState(false);
  const handleValue = () => {
    setVal(!val);
  };
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const viewname = JSON.parse(localStorage.getItem("procatname"));
  const viewid = JSON.parse(localStorage.getItem("procatid"));
  let [responseData, setResponseData] = React.useState([]);
  let [isNext, isNextFunc] = React.useState(false);
  let [pageCount, setCount] = React.useState(1);
  let [page, setPage] = React.useState("");
  let [status, setStatus] = React.useState(false);
  let [open, setOpen] = React.useState(false);
  const loadMoreData = () => {
    setTimeout(() => {
      setCount(pageCount + 1);
    }, 500);
  };
  React.useEffect(() => {
    if (pageCount !== 1) isNextFunc(true);
    const usercode = JSON.parse(localStorage.getItem("usercode"));
    var data3 = new FormData();
    data3.append("page", pageCount);
    data3.append("category", viewid);
    data3.append("user_code", usercode);
    // dispatch(getProductData(data3));
    setTimeout(() => {
      setOpen(true);
    }, 500);
  }, [pageCount]);

  useEffect(() => {
    let isMounted = true;
    setTimeout(() => {
      setTrue1(true);
    }, 700);
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
    return () => { isMounted = false }
  }, []);

  useEffect(() => {
    // getSubCategory();
    const timer = setTimeout(() => {
      getSubCategory();
      setStatus(true);
    }, 500);
    return () => clearTimeout(timer);
  }, [status]);

  // const dataProduct = GetProductDataResponse();
  if (open === true) {
    // setResponseData([...responseData, ...dataProduct.data]);
    isNextFunc(true);
    // setPage(dataProduct.total_page);
    setOpen(false);
  }

  const addWishRed = (e, j) => {
    const usercode = JSON.parse(localStorage.getItem("usercode"));
    let data1 = new FormData();
    data1.append("product_id", e);
    data1.append("user_code", usercode);
    data1.append("date_time", "2018-12-25 10:23:00");
    // dispatch(addWishlistProductUser(data1));
    let tmpArr = Object.assign([], responseData)
    let tmpObj = Object.assign({}, tmpArr[j])
    tmpObj["is_wishlist"] = '1'
    tmpArr[j] = tmpObj;
    setResponseData(tmpArr);
  };

  const addWishWhite = (e, j) => {
    const usercode = JSON.parse(localStorage.getItem("usercode"));
    let data1 = new FormData();
    data1.append("product_id", e);
    data1.append("user_code", usercode);
    data1.append("date_time", "2018-12-25 10:23:00");
    // dispatch(addWishlistProductUser(data1));
    let tmpArr = Object.assign([], responseData)
    let tmpObj = Object.assign({}, tmpArr[j])
    tmpObj["is_wishlist"] = '0'
    tmpArr[j] = tmpObj;
    setResponseData(tmpArr);
  };

  const getSingleProduct = (e) => {
    // const usercode=JSON.parse(localStorage.getItem("usercode"));
    // const userid=JSON.parse(localStorage.getItem("userid"));
    // var data3 = new FormData();
    // localStorage.setItem("productsid",JSON.stringify([e]));
    // data3.append("product_id", e);
    // data3.append("user_id", userid);
    // data3.append("user_code", usercode);
    // dispatch(singleProductDataUser(data3));
    // history.push("/productdetail");
    history.push(`/productdetail?${e}`);
  };

  const getSubCategory = () => {
    let data2 = new FormData();
    data2.append('parent_id', viewid);
    // dispatch(getCategoryDataProduct(data2));
  }

  const [field1, setField1] = useState(false);
  const [field2, setField2] = useState(false);
  const [field3, setField3] = useState(false);
  const [field4, setField4] = useState(false);

  const [inc, setInc] = useState(0);

  const categoryData = {};
  // console.log(categoryData);

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 120) {
      setVisible(true)
    }
    else if (scrolled <= 120) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);
  return (
    <ThemeProvider theme={theme}>
      {
        true1 ?
          <div>
            <Header title={viewname} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                maxWidth: "500px",
                backgroundColor: ThemeColor ? ThemeColor : "#FFFFFF",
                position: "fixed",
                zIndex: "11111",
                marginBottom: "40px",
              }}
            >
              <div className="title-box" style={{ height: "48px", maxWidth: "500px" }}>
                <KeyboardBackspaceIcon
                  style={{ color: "white", width: "25px" }}
                  onClick={() => {
                    history.goBack();
                  }}
                />
                <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%"
                }}>
                  <h1 className="title text-uppercase mb-0">{viewname}</h1>
                  <div style={{
                    paddingRight: "10px"
                  }}>
                    <img
                      src={"/assets/frontend/img/search_white_small.png"}
                      style={{
                        width: "22px",
                        height: "22px",
                        color: "white",
                        fontSize: "28px",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        history.push("/search");
                      }}
                    />
                    <img
                      src={"/assets/frontend/img/cart_white.png"}
                      style={{
                        height: "27px",
                        color: "white",
                        fontSize: "28px",
                        cursor: "pointer",
                        marginLeft: "20px",
                        marginBottom: "5px",
                      }}
                      onClick={() => {
                        history.push("/cart");
                      }}
                    />
                    <img
                      src={"/assets/frontend/img/chat_white.png"}
                      height="26px"
                      width="25px"
                      style={{
                        color: "white",
                        fontSize: "27px",
                        marginLeft: "20px",
                        marginRight: "7px",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        history.push("/chat");
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bg-white"
              style={{ paddingTop: "50px", boxShadow: "none" }}
            >
              <div className="mb-1">
                <div
                  style={{
                    display: "flex",
                    // borderBottom: "1px solid lightgray",
                    boxShadow: "0px 0px 8px -1px #dbd5d5"
                  }}
                >
                  <div
                    onClick={() => {
                      setField1(!field1);
                      if (field1 == false) {
                        setValue("one");
                      } else {
                        setValue("zero");
                      }
                      setField2(false);
                      setField3(false);
                      setField4(false);
                    }}
                    style={{
                      textAlign: "center",
                      textTransform: " uppercase",
                      width: "25% ",
                      marginTop: "4px",
                      background: "white ",
                      fontSize: "12px ",
                      fontWeight: "600 ",
                    }}
                  >
                    <div style={{
                      borderRight: "1px solid lightgray ",
                      color: value == "one" ? ThemeColor ? ThemeColor : "#FFFFFF" : "black",
                      position: value == "zero" ? "unset" : "relative",
                      marginTop: "6px",
                      marginBottom: "8px",
                      padding: "3px"
                    }}>{t("new")}</div>
                    <div style={{
                      borderBottom:
                        value == "one" ? `3px solid ${ThemeColor ? ThemeColor : "#FFFFFF"}` : "none",
                      width: Mobile ? "70%" : "77%",
                      marginLeft: Mobile ? "15px" : "17px"
                    }}>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setField2(!field2);
                      if (field2 == false) {
                        setValue("two");
                      } else {
                        setValue("zero");
                      }
                      setField1(false);
                      setField3(false);
                      setField4(false);
                    }}
                    style={{
                      textAlign: "center",
                      textTransform: " uppercase",
                      width: "25% ",
                      marginTop: "4px",
                      background: "white ",
                      fontSize: "12px ",
                      fontWeight: "600 ",
                    }}
                  >
                    <div style={{
                      borderRight: "1px solid lightgray ",
                      color: value == "two" ? ThemeColor ? ThemeColor : "#FFFFFF" : "black",
                      position: value == "zero" ? "unset" : "relative",
                      marginTop: "6px",
                      marginBottom: "8px",
                      padding: "3px"
                    }}>{t("discount")}</div>
                    <div style={{
                      borderBottom:
                        value == "two" ? `3px solid ${ThemeColor ? ThemeColor : "#FFFFFF"}` : "none",
                      width: Mobile ? "70%" : "77%",
                      marginLeft: Mobile ? "15px" : "17px"
                    }}>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setField3(!field3);
                      if (field3 == false) {
                        setValue("three");
                      } else {
                        setValue("zero");
                      }
                      setField1(false);
                      setField2(false);
                      setField4(false);
                    }}
                    style={{
                      textAlign: "center",
                      textTransform: " uppercase",
                      width: "25% ",
                      marginTop: "4px",
                      background: "white ",
                      fontSize: "12px ",
                      fontWeight: "600 ",
                    }}
                  >
                    <div style={{
                      borderRight: "1px solid lightgray ",
                      color: value == "three" ? ThemeColor ? ThemeColor : "#FFFFFF" : "black",
                      position: value == "zero" ? "unset" : "relative",
                      marginTop: "6px",
                      marginBottom: "8px",
                      padding: "3px"
                    }}>{t("best_seller")}</div>
                    <div style={{
                      borderBottom:
                        value == "three" ? `3px solid ${ThemeColor ? ThemeColor : "#FFFFFF"}` : "none",
                      width: Mobile ? "70%" : "77%",
                      marginLeft: Mobile ? "15px" : "17px"
                    }}>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setVal(!val);
                      setInc(inc + 1);
                      if (inc > 1) {
                        setValue("zero");
                        setInc(0);
                        setVal(false);
                      } else {
                        setValue("four");
                      }
                      setField1(false);
                      setField2(false);
                      setField3(false);
                    }}
                    style={{
                      textAlign: "center",
                      textTransform: " uppercase",
                      width: "25% ",
                      marginTop: "4px",
                      background: "white ",
                      fontSize: "12px ",
                      fontWeight: "600 ",
                    }}
                  >
                    <div style={{
                      color: value == "four" ? ThemeColor ? ThemeColor : "#FFFFFF" : "black",
                      position: value == "zero" ? "unset" : "relative",
                      marginTop: "6px",
                      marginBottom: "8px",
                      padding: "3px"
                    }}>{t("price")}
                      {value == "four" ? (
                        val ? (
                          <img
                            src="/assets/frontend/img/Icon/ic_prices_down.png"
                            height="13px"
                          />
                        ) : (
                          <img
                            src="/assets/frontend/img/Icon/ic_prices_up.png"
                            height="13px"
                          />
                        )
                      ) : (
                        ""
                      )}</div>
                    <div style={{
                      borderBottom:
                        value == "four" ? `3px solid ${ThemeColor ? ThemeColor : "#FFFFFF"}` : "none",
                      width: Mobile ? "70%" : "77%",
                      marginLeft: Mobile ? "15px" : "17px"
                    }}>
                    </div>
                  </div>
                </div>
                {/* <Tabs
          defaultTab="zero"
          onChange={(tabId) => {setValue(tabId)}}
        >
           <TabList className="product_filter_tab">
            <Tab tabFor="one" className="text-uppercase women-tab" style={{color:value=="one"?ThemeColor ? ThemeColor : "#FFFFFF":"black",position:value=="zero"?"unset":"relative"}} 
              onClick={()=>{setField1(!field1);field1==false?setValue("one"):setValue("zero");
              setField2(false);
              setField3(false);
              setField4(false);
            }}
            >{t("new")}</Tab>
            <Tab tabFor="two" className="text-uppercase women-tab" style={{color:value=="two"?ThemeColor ? ThemeColor : "#FFFFFF":"black",position:value=="zero"?"unset":"relative"}} 
              onClick={()=>{setField2(!field2);field2==false?setValue("two"):setValue("zero");
              setField1(false);
              setField3(false);
              setField4(false);
            }}
            >{t("discount")}</Tab>
            <Tab tabFor="three" className="text-uppercase women-tab" style={{color:value=="three"?ThemeColor ? ThemeColor : "#FFFFFF":"black",position:value=="zero"?"unset":"relative"}}
              onClick={()=>{setField3(!field3);field3==false?setValue("three"):setValue("zero");
              setField1(false);
              setField2(false);
              setField4(false);
            }}
             >{t("best_seller")}</Tab>
            <Tab tabFor="four" className="text-uppercase women-tab" style={{color:value=="four"?ThemeColor ? ThemeColor : "#FFFFFF":"black",position:value=="zero"?"unset":"relative"}} 
            onClick={()=>{
              setVal(!val);
              setInc(inc+1);
              if(inc>1){
                setValue("zero");
                setInc(0);
                setVal(false);
              }else{
                setValue("four")
              }
              setField1(false);
              setField2(false);
              setField3(false);
            }}
            >
              {t("price")}{value=="four"?val?<img src="/assets/frontend/img/Icon/ic_prices_down.png" height="13px"/>:<img src="/assets/frontend/img/Icon/ic_prices_up.png" height="13px"/>:""}
              </Tab>
          </TabList>
        </Tabs> */}
                {value == "zero" ? (
                  <div
                    className="container bg-white"
                    style={{ paddingLeft: "0px", paddingRight: "0px", boxShadow: "none" }}
                  >
                    <div>
                      {status ? (
                        responseData.length > 0 ? (
                          <InfiniteScroll
                            style={{
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                              flexDirection: "row",
                              marginLeft: "0px",
                              marginRight: "0px",
                              marginTop: "0px",
                              paddingLeft: "0px",
                              paddingRight: "5px",
                              paddingBottom: Mobile ? "0px" : "50px"
                            }}
                            dataLength={responseData.length}
                            next={loadMoreData}
                            hasMore={isNext}
                            loader={
                              <div style={{ height: "50%", overflow: "hidden" }}>
                                {
                                  pageCount < page ? (
                                    <div style={{ color: ThemeColor ? ThemeColor : "#FFFFFF", textAlign: "center", marginLeft: "160px" }}>{t('loadingdot')}</div>
                                  ) : (
                                    ""
                                  )
                                }
                              </div>
                            }
                          >
                            {responseData.map((product, index) => (
                              <div style={{
                                width: "50%",
                                paddingLeft: "5px",
                                marginBottom: "6px"
                              }} key={index}>
                                <div className="card border-0 shadow-card">
                                  <div>
                                    <img
                                      src={product.main_image != "" ? product.main_image : "/assets/images/dummy.png"}
                                      className="card-img-top"
                                      style={{
                                        position: "relative",
                                        height:
                                          Mobile ? product.name.length < 20 ? "182px" : "164px"
                                            :
                                            product.name.length < 26 ? "210px" : "190px",
                                        width: "100%"
                                      }}
                                      // height={Mobile ? Object.keys(product.name).length > 18 ? "170px" : "180px" : Object.keys(product.name).length > 18
                                      //   ? "200px"
                                      //   : "220px"}
                                      // width="100%"
                                      alt="..."
                                      onClick={() => {
                                        getSingleProduct(product.id)
                                      }}
                                    />
                                    {product.stock_status === 'Sold Out' ? (
                                      <div
                                        className="position-absolute d-flex justify-content-center align-items-center rounded-circle"
                                        style={{
                                          width: Mobile ? "5rem" : "8rem",
                                          height: Mobile ? "5rem" : "8rem",
                                          top: Mobile ? "47px" : "53px",
                                          left: Mobile ? "45px" : "56px",
                                          fontSize: "14px",
                                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                                        }}
                                      >
                                        <span
                                          className="text-light"
                                        >
                                          {t("sold_out")}
                                        </span>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  {product.is_wishlist == 1 ? (
                                    <img src="/assets/frontend/img/wishlist_fav.png" height="22px" style={{ display: "flex", justifyContent: "flex-end", top: 0, position: "absolute", cursor: "pointer", marginTop: "10px", marginLeft: "10px" }} onClick={() => {
                                      addWishWhite(product.id, index)
                                    }} />
                                  ) : (
                                    <img src="/assets/frontend/img/wishlist_list.png" height="22px" style={{ display: "flex", justifyContent: "flex-end", top: 0, position: "absolute", cursor: "pointer", marginTop: "10px", marginLeft: "10px" }} onClick={() => {
                                      addWishRed(product.id, index)
                                    }} />
                                  )}
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "5px",
                                      right: "5px",
                                    }}
                                  >
                                    {product.new_product == "1" ? (
                                      <div
                                        style={{
                                          background: " #fdd535",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          color: "#fff",
                                          fontSize: "12px",
                                          width: "58px",
                                          height: "20px",
                                          borderRadius: "3px",
                                          marginTop: "1px",
                                          marginBottom: "5px"
                                        }}
                                      >
                                        {t("new1")}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {product.product_wholesale == 1 ? (
                                      <div
                                        style={{
                                          background: "#00BFFF",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          color: "#fff",
                                          fontSize: "11px",
                                          width: "58px",
                                          height: "20px",
                                          borderRadius: "3px",
                                          marginTop: "1px",
                                          marginBottom: "5px"
                                        }}
                                      >
                                        {t("wholesale")}
                                      </div>
                                    ) :
                                      ""
                                    }
                                    {product.product_cod == "1" ? (
                                      <div
                                        style={{
                                          background: "#49d16e",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          color: "#fff",
                                          fontSize: "12px",
                                          width: "58px",
                                          height: "20px",
                                          borderRadius: "3px",
                                          marginTop: "1px",
                                          marginBottom: "5px"
                                        }}
                                      >
                                        {t("COD")}
                                      </div>
                                    ) :
                                      ""
                                    }
                                    {product.percent_discount != "0" ? (
                                      <div
                                        style={{
                                          background: "red",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          color: "#fff",
                                          fontSize: "12px",
                                          width: "58px",
                                          height: "20px",
                                          borderRadius: "3px",
                                          marginTop: "1px",
                                          marginBottom: "5px"
                                        }}
                                      >
                                        {product.percent_discount}%
                                      </div>
                                    ) :
                                      ""
                                    }
                                    {product.product_preorder == "1" ? (
                                      <div
                                        style={{
                                          background: "orange",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          color: "#fff",
                                          fontSize: "10px",
                                          width: "58px",
                                          height: "20px",
                                          borderRadius: "3px",
                                          marginTop: "1px",
                                          marginBottom: "5px"
                                        }}
                                      >
                                        PO
                                      </div>
                                    ) :
                                      ""
                                    }
                                    {product.best_seller == "1" ? (
                                      <div
                                        style={{
                                          background: "red",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          color: "yellow",
                                          fontSize: "10px",
                                          width: "58px",
                                          height: "20px",
                                          borderRadius: "3px",
                                          marginTop: "1px",
                                          marginBottom: "5px"
                                        }}
                                      >
                                        {t("best_seller1")}
                                      </div>
                                    ) :
                                      ""
                                    }
                                  </div>
                                  <div className="card-body p-0 ps-2 pe-1" style={{ marginTop: "5px" }}>
                                    <p className={classes1.textfont} style={{
                                      marginBottom: "0.25rem !important"
                                    }}>{product.name}</p>
                                    <div className={classes1.pricePadding} style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden"
                                    }}>
                                      <div
                                        className={classes1.pricediv}
                                        style={{ whiteSpace: "nowrap", marginRight: "10px" }}
                                      >
                                        <b>{Object.keys(product.price.toString()).length >
                                          0 ? (
                                          Object.keys(product.price.toString())
                                            .length <= 3 ? (
                                            <p style={{ marginBottom: "0px" }}>
                                              Rp {product.price.toString()}
                                            </p>
                                          ) : Object.keys(product.price.toString())
                                            .length == 4 ? (
                                            <p style={{ marginBottom: "0px" }}>
                                              Rp{" "}
                                              {product.price.toString().substr(-4, 1)}.
                                              {product.price.toString().substr(-3, 3)}
                                            </p>
                                          ) : Object.keys(product.price.toString())
                                            .length == 5 ? (
                                            <p style={{ marginBottom: "0px" }}>
                                              Rp{" "}
                                              {product.price.toString().substr(-5, 2)}.
                                              {product.price.toString().substr(-3, 3)}
                                            </p>
                                          ) : Object.keys(product.price.toString())
                                            .length == 6 ? (
                                            <p style={{ marginBottom: "0px" }}>
                                              Rp{" "}
                                              {product.price.toString().substr(-6, 3)}.
                                              {product.price.toString().substr(-3, 3)}
                                            </p>
                                          ) : Object.keys(product.price.toString())
                                            .length == 7 ? (
                                            <p style={{ marginBottom: "0px" }}>
                                              Rp{" "}
                                              {product.price.toString().substr(-7, 1)}.
                                              {product.price.toString().substr(-6, 3)}.
                                              {product.price.toString().substr(-3, 3)}
                                            </p>
                                          ) : Object.keys(product.price.toString())
                                            .length == 8 ? (
                                            <p style={{ marginBottom: "0px" }}>
                                              Rp{" "}
                                              {product.price.toString().substr(-8, 2)}.
                                              {product.price.toString().substr(-6, 3)}.
                                              {product.price.toString().substr(-3, 3)}
                                            </p>
                                          ) : Object.keys(product.price.toString())
                                            .length == 9 ? (
                                            <p style={{ marginBottom: "0px" }}>
                                              Rp{" "}
                                              {product.price.toString().substr(-9, 3)}.
                                              {product.price.toString().substr(-6, 3)}.
                                              {product.price.toString().substr(-3, 3)}
                                            </p>
                                          ) : (
                                            ""
                                          )
                                        ) : (
                                          ""
                                        )}</b>
                                      </div>
                                      <div>
                                        <b>
                                          {product.selling_price ===
                                            JSON.parse(product.price) ? (
                                            ""
                                          ) : Object.keys(product.selling_price.toString())
                                            .length > 0 ? (
                                            Object.keys(product.selling_price.toString())
                                              .length == 4 ? (
                                              <p
                                                className={classes1.cutdiv}
                                              >
                                                Rp{" "}
                                                {product.selling_price
                                                  .toString()
                                                  .substr(-4, 1)}
                                                .
                                                {product.selling_price
                                                  .toString()
                                                  .substr(-3, 3)}
                                              </p>
                                            ) : Object.keys(
                                              product.selling_price.toString()
                                            ).length == 5 ? (
                                              <p
                                                className={classes1.cutdiv}
                                              >
                                                Rp{" "}
                                                {product.selling_price
                                                  .toString()
                                                  .substr(-5, 2)}
                                                .
                                                {product.selling_price
                                                  .toString()
                                                  .substr(-3, 3)}
                                              </p>
                                            ) : Object.keys(
                                              product.selling_price.toString()
                                            ).length == 6 ? (
                                              <p
                                                className={classes1.cutdiv}
                                              >
                                                Rp{" "}
                                                {product.selling_price
                                                  .toString()
                                                  .substr(-6, 3)}
                                                .
                                                {product.selling_price
                                                  .toString()
                                                  .substr(-3, 3)}
                                              </p>
                                            ) : Object.keys(
                                              product.selling_price.toString()
                                            ).length == 7 ? (
                                              <p
                                                className={classes1.cutdiv}
                                              >
                                                Rp{" "}
                                                {product.selling_price
                                                  .toString()
                                                  .substr(-7, 1)}
                                                .
                                                {product.selling_price
                                                  .toString()
                                                  .substr(-6, 3)}
                                                .
                                                {product.selling_price
                                                  .toString()
                                                  .substr(-3, 3)}
                                              </p>
                                            ) : Object.keys(
                                              product.selling_price.toString()
                                            ).length == 8 ? (
                                              <p
                                                className={classes1.cutdiv}
                                              >
                                                Rp{" "}
                                                {product.selling_price
                                                  .toString()
                                                  .substr(-8, 2)}
                                                .
                                                {product.selling_price
                                                  .toString()
                                                  .substr(-6, 3)}
                                                .
                                                {product.selling_price
                                                  .toString()
                                                  .substr(-3, 3)}
                                              </p>
                                            ) : Object.keys(
                                              product.selling_price.toString()
                                            ).length == 9 ? (
                                              <p
                                                className={classes1.cutdiv}
                                              >
                                                Rp{" "}
                                                {product.selling_price
                                                  .toString()
                                                  .substr(-9, 3)}
                                                .
                                                {product.selling_price
                                                  .toString()
                                                  .substr(-6, 3)}
                                                .
                                                {product.selling_price
                                                  .toString()
                                                  .substr(-3, 3)}
                                              </p>
                                            ) : (
                                              ""
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </b>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </InfiniteScroll>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              paddingTop: "40px",
                              paddingBottom: "40px",
                              backgroundColor: "white"
                            }}
                          >
                            {t('no_data_found')}
                          </div>
                        )
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "250px",
                            paddingBottom: "300px",
                            flexDirection: "column",
                            height:"100vh",
                            backgroundColor: "white"
                          }}
                        >
                          <div><Loader /></div>
                          <div>{t('loadingdot')}</div>
                        </div>
                      )}
                    </div>
                    <NavigationTab />
                  </div>
                ) : (
                  ""
                )}
                {value == "one" ? <New /> : ""}
                {value == "two" ? <Discount /> : ""}
                {value == "three" ? <Bestseller /> : ""}
                {value == "four" ? val ? <PriceLow /> : <PriceHigh /> : ""}
              </div>
            </div>
            <div onClick={scrollToTop} style={{
              display: visible ? 'inline' : 'none',
              height: Mobile ? "39px" : "41px",
              padding: Mobile ? "10px" : "11px",
              fontSize: "1rem",
              zIndex: "1",
              cursor: "pointer",
              color: "red",
              background: "white",
              borderRadius: "50px",
              boxShadow: "0px 0px 8px -1px #6c757dd9",
              position: "fixed",
              right: Mobile ? 0 : "",
              left: Mobile ? "" : "50%",
              bottom: Mobile ? "64px" : "38px",
              marginRight: Mobile ? "10px" : "",
              transform: Mobile ? "" : "translate(466%, -50%)",
              margin: "0px auto"
            }} >
              <img
                src={"/assets/frontend/img/top_arrow.png"}
                style={{
                  height: Mobile ? "19px" : "20px",
                  width: Mobile ? "19px" : "20px",
                  marginBottom: Mobile ? "10px" : "8px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          :
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: "250px",
              paddingBottom: "300px",
              flexDirection: "column",
              height:"100vh",
              backgroundColor: "white"
            }}
          >
            <div><Loader /></div>
            <div>{t('loadingdot')}</div>
          </div>
      }
    </ThemeProvider>
  );
};

export default withNamespaces()(ProductView);
