import React, { useEffect, useRef } from "react";
import {
  Box,
  Container,
  Dialog,
  Grid,
  Snackbar,
  TextField,
  IconButton,
} from "@material-ui/core";
import DesktopHeaderWrap from "../../components/DeskTopHeaderWrap";
import DeskTopFooter from "../home/DesktopFooter";
import { useStyles } from "./SingleProductDetail.style";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// import { Link } from "react-scroll";
import { Link } from "react-router-dom";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import ImageZoom from "./ImageZoom";
import { useState } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useHistory } from "react-router-dom";
// import { useDispatch } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import ErrorIcon from "@material-ui/icons/Error";
// import { AddToCartResponse } from "../../store/Selectors/AddCart";
import { withNamespaces } from "react-i18next";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Card from "../../components/common/Card";
// import { addWishlistProductUser } from "../../store/actions/addWishlistActions";
import RelatedProduct from "./RelatedProduct";
// import { addToCartProductUser } from "../../store/actions/AddToCartActions";
import ValidationLoginModal from "../../components/ValidationLoginModal";
import Amount from "../../components/common/Amount";
import i18n from "../language/localization";
// import { GetMyCartProductResponse } from "../../store/Selectors/mycart";
import SkeletonCircleText from "../../components/common/SkeletonCircleText";
import ButtonCustom from "../../components/common/ButtonCustom";
import LoaderDots from "../../components/common/LoaderDots";
// import { addNotes } from "../../store/actions/AddNoteActions";
import { baseUrlApi } from "../../helper/api";
import { getByClient as getProductsDetail } from "../../api/products";
import { get as getCart, create as createToCart } from "../../api/cart";
import { getBadgeCart } from "../../api/badge";
import { priceFormatLocal } from "../../utils/helper";
import { showPricing, isDiscountValid } from "../../utils/pricing";

import { useDispatch } from "react-redux";
import { increaseCounter } from "../../stores/features/cart/cart";
import { setSnackbar } from "../../stores/features/snackbar/snackbar";
import { useAuth } from "../../hooks/useAuth";

// const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));

// import temp1 from "../../../../../../public/assets/images/bglogin.jpg";

// const sizeData = [28, 29, 30, 32];
// const colorData = [
//   { image: "http://malaman.github.io/react-image-zoom/example/1.jpg" },
//   { color: "Red" },
//   { color: "Dark Blue" },
// ];

const SingleProductDetail = ({ t, color, webThemeColor }) => {
  const { refreshCart } = useAuth();

  const dispatch = useDispatch();

  const classes = useStyles();
  // const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  // const [isSize, setSize] = useState(0);
  const [isColor, setColor] = useState("");
  const [AddToWishList, setAddToWishList] = useState(true);

  const [defaultImage, setDefaultImage] = useState("");

  // const ThemeColor = ThemeColor1();
  const history = useHistory();

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  }, []);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "instant" });
  }, []);

  // Quantity Increment Decrement

  let incNum = () => {
    if (quantity < total) {
      setQuantity(Number(quantity) + 1);
    }
  };
  let decNum = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  };

  const products = JSON.parse(localStorage.getItem("productsid"));
  const landing = JSON.parse(localStorage.getItem("Lanpage"));
  const usercode = JSON.parse(localStorage.getItem("usercode"));
  const userid = JSON.parse(localStorage.getItem("userid"));
  const [productdetails, setProductDetails] = useState("");
  const [productids, setProductIds] = useState([]);
  const [imag, setImag] = useState("");
  const [message, setMessage] = useState("");
  const [detailstab, setDetailsTab] = useState("");
  const [dataDetail, setDataDetail] = useState([]);
  const [data3, setData3] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);

  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);
  const [data7, setData7] = useState([]);
  const [similar_product, setProduct] = useState([]);
  const [isErrorOpen, setErrorOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [images, setImages] = useState([]);
  const [resOfCart, setResOfCart] = useState({
    success: "",
    message: "",
    open_popup: false,
  });

  const [copyIconSrc, setCopyIconSrc] = useState("/images/copy.svg");
  const originalCopyIconSrc = "/images/copy.svg";

  // if (open1 && open) {
  //   if (data4) {

  //     console.log("data4_", data4)
  //     for (var i = 0; i < data4.images.length; i++) {
  //       images.push({
  //         original: data4.images[i].file_name,
  //         thumbnail: data4.images[i].file_name,
  //       });
  //     }
  //   }
  //   setOpen1(false);
  // }

  // const [scroll_active, setScroll_Active] = React.useState();
  // const changebg = () => {
  //   if (window.scrollY >= 10) {
  //     setScroll_Active(true);
  //   } else {
  //     setScroll_Active(false);
  //   }
  // };
  // window.addEventListener("scroll", changebg);

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
    // setTimeout(() => { getWishlist() }, 3000)
  }, []);

  const opens = JSON.parse(localStorage.getItem("open"));
  if (opens !== null) {
    if (opens == false) {
      setOpen(false);
      images.splice(0);
      setOpen1(true);
      localStorage.setItem("open", JSON.stringify(true));
    }
  }
  const urlStr = window.location.href;
  const data11 = urlStr.split("/");
  const ids = data11[3].toString();

  // useEffect(() => {
  //   const usercode = JSON.parse(localStorage.getItem("usercode"));
  //   const userid = JSON.parse(localStorage.getItem("userid"));
  //   var data3 = new FormData();
  //   if (userid) {
  //     data3.append("user_id", userid);
  //     data3.append("user_code", usercode);
  //   }
  //   data3.append("product_id", ids.slice(14));
  //   dispatch(singleProductDataUser(data3));
  //   const timer = setTimeout(() => {
  //     setProductDetails(dataDetail);
  //     // if(dataDetail){
  //     setOpen(true);
  //     // }
  //     // console.log(dataDetail);
  //   }, 900);
  //   return () => clearTimeout(timer);
  // }, [open]);

  const [related, setReleated] = useState([]);
  const [sizecolorData, setSizeColorData] = useState([]);
  const [productVariant, setProductVariant] = useState([]);
  const [stock, setStock] = useState([]);
  const [isAuth, setAuth] = useState(false);
  const [size_color_id, setId] = useState();
  const [relatedProductsData, setrelatedProductsData] = useState([]);
  const [list_stockData, setListStockData] = useState([]);
  const [data4, setData4] = useState([]);
  const [sizeHowData, setSizeShowData] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [dataImage, setDataImage] = useState([]);
  // true hardcode
  const [isActiveButtonAddToCart, setActiveButtonAddToCart] = useState(true);
  const [categoriesData, setCategoriesData] = useState([]);
  const [membershipLevel, setMembershipLevel] = useState(1);

  const membershipData = JSON.parse(localStorage.getItem("membership"));
  const landingPageValue = JSON.parse(localStorage.getItem("landing_page"));

  const singleData = () => {
    const productID = ids.slice(15);
    const urlStr = window.location.href;
    const data11 = urlStr.split("/");
    const ids1 = data11[3].toString();
    // var myHeaders = new Headers();
    // myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");
    // myHeaders.append("Access-Control-Allow-Origin", "*");

    const usercode = JSON.parse(localStorage.getItem("usercode"));
    const userid = JSON.parse(localStorage.getItem("userid"));
    // var formdata = new FormData();
    // if (userid) {
    //   formdata.append("user_id", userid);
    //   formdata.append("user_code", usercode);
    // }
    // formdata.append("product_id", ids1.slice(15));

    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // };

    // fetch(`${baseUrlApi()}/Api/Product/singleProductData`, requestOptions)
    //   .then((response) => response.json())
    //   .then((result) => {

    //     setLoading(false);

    //     setDataCategory(result.category[0])
    //     setDefaultImage(result.images[0].file_name);
    //     setDataImage(result.images);
    //     setrelatedProductsData(result.similar_product);
    //     setListStockData(result.list_stock);
    //     setDataDetail(result.product_details);
    //     setSizeShowData(result);
    //     setData3(result.unique_color);
    //     setSizeColorData(result.size_color_data.size_color);
    //     setProductSize(result.size_color_data.size);
    //     setStock(result.product_details);

    //     setData4(result);

    //     setData5(result.unique_size);
    //     setData6(result.list_stock);
    //     setData7(result.similar_product);
    //     setOpen(true);
    //     setOpen1(true);

    //     // getWishlist();
    //     // setTimeout(() => { getWishlist() }, 100)
    //   })
    //   .catch((error) => console.log("error", error));
    setLoading(true);
    const getProductDetail = async () => {
      try {
        const response = await getProductsDetail(`{"id":${productID}}`);
        if (response.success) {
          setLoading(false);
          // setting active button add to cart, if soldout all variant, hide button
          // for(let counterStock = 0; counterStock<result.list_stock.length;counterStock++){
          //   if(result.list_stock[counterStock].stock_status!=='Sold Out'){
          // setActiveButtonAddToCart(true)
          // break;
          //   }
          // }
          if (response.data[0].product.stock_status !== "Sold Out") {
            setActiveButtonAddToCart(true);
          }

          setDataCategory(response.data[0].category);
          let defaultImage = "/assets/images/dummy.png";
          let sortedMedia = null;
          if (
            response.data[0].product.media &&
            response.data[0].product.media.length > 0
          ) {
            let mp4Path = null;
            for (const item of response.data[0].product.media) {
              if (item.endsWith(".mp4")) {
                mp4Path = item;
                break;
              }
            }

            if (mp4Path === null) {
              defaultImage =
                process.env.REACT_APP_FILE_BASE_URL +
                "/" +
                response.data[0].product.media[0];
            } else {
              defaultImage =
                process.env.REACT_APP_FILE_BASE_URL + "/" + mp4Path;
            }

            sortedMedia = response.data[0].product.media
              .slice()
              .sort((a, b) => {
                if (a.endsWith(".mp4") && !b.endsWith(".mp4")) {
                  return -1;
                } else if (!a.endsWith(".mp4") && b.endsWith(".mp4")) {
                  return 1;
                } else {
                  return 0;
                }
              });
          }
          setDefaultImage(defaultImage);
          setDataImage(sortedMedia);
          setDataDetail(response.data[0].product);
          const findBestSellerLabel =
            response.data[0].product.labels &&
            response.data[0].product.labels.length > 0
              ? response.data[0].product.labels.filter(
                  (data) => data === "Terlaris"
                )
              : null;
          if (findBestSellerLabel && findBestSellerLabel.length > 0) {
            setbestSellerFlag(findBestSellerLabel[0]);
          } else {
            setbestSellerFlag("");
          }
          // setSizeShowData(response.data[0]);
          if (response.data[0].variants) {
            setProductVariant(response.data[0].variants);
          }

          setData4(response.data[0]);

          // setData5(result.unique_size);
          // setData6(result.list_stock);
          // setData7(result.similar_product);

          if (landingPageValue.setting_cms.is_show_related_products === true) {
            let responseRelatedProductResult = await getProductsDetail(
              `{"page": 1, "limit": 25, "category_id":${response.data[0].product.category_id} }`
            );

            if (
              responseRelatedProductResult.data &&
              responseRelatedProductResult.data.length > 0
            ) {
              responseRelatedProductResult =
                responseRelatedProductResult.data.filter(
                  (data) => data.product.id !== response.data[0].product.id
                );
            }
            setrelatedProductsData(responseRelatedProductResult);
          }

          setOpen(true);
          setOpen1(true);
          // data stock per variant
          // setListStockData([
          //   {
          //       "id": 9184,
          //       "product_id": 895,
          //       "size": "",
          //       "color": "BLACK 37",
          //       "first_total": 2,
          //       "total": 2,
          //       "keep": 0,
          //       "dropship": 0,
          //       "maximum_total_qty_product_in_trolley": 10,
          //       "sold": 0,
          //       "sku": "",
          //       "price": "501000",
          //       "image": "",
          //       "order_by": "0",
          //       "stock_status": "Ready"
          //   },
          //   {
          //       "id": 9183,
          //       "product_id": 895,
          //       "size": "",
          //       "color": "black 38",
          //       "first_total": 2,
          //       "total": 2,
          //       "keep": 0,
          //       "dropship": 0,
          //       "maximum_total_qty_product_in_trolley": 10,
          //       "sold": 0,
          //       "sku": "",
          //       "price": "501000",
          //       "image": "",
          //       "order_by": "1",
          //       "stock_status": "Ready"
          //   },
          //   {
          //       "id": 9177,
          //       "product_id": 895,
          //       "size": "",
          //       "color": "BROWN 36",
          //       "first_total": 3,
          //       "total": 3,
          //       "keep": 0,
          //       "dropship": 0,
          //       "maximum_total_qty_product_in_trolley": 10,
          //       "sold": 0,
          //       "sku": "",
          //       "price": "501000",
          //       "image": "",
          //       "order_by": "2",
          //       "stock_status": "Ready"
          //   },
          //   {
          //       "id": 9176,
          //       "product_id": 895,
          //       "size": "",
          //       "color": "BROWN 37",
          //       "first_total": 5,
          //       "total": 5,
          //       "keep": 0,
          //       "dropship": 0,
          //       "maximum_total_qty_product_in_trolley": 10,
          //       "sold": 0,
          //       "sku": "",
          //       "price": "501000",
          //       "image": "",
          //       "order_by": "3",
          //       "stock_status": "Ready"
          //   },
          //   {
          //       "id": 9175,
          //       "product_id": 895,
          //       "size": "",
          //       "color": "brown 38",
          //       "first_total": 4,
          //       "total": 4,
          //       "keep": 0,
          //       "dropship": 0,
          //       "maximum_total_qty_product_in_trolley": 10,
          //       "sold": 0,
          //       "sku": "",
          //       "price": "501000",
          //       "image": "",
          //       "order_by": "4",
          //       "stock_status": "Ready"
          //   },
          //   {
          //       "id": 9178,
          //       "product_id": 895,
          //       "size": "",
          //       "color": "BROWN 39",
          //       "first_total": 5,
          //       "total": 5,
          //       "keep": 0,
          //       "dropship": 0,
          //       "maximum_total_qty_product_in_trolley": 10,
          //       "sold": 0,
          //       "sku": "",
          //       "price": "501000",
          //       "image": "",
          //       "order_by": "5",
          //       "stock_status": "Ready"
          //   },
          //   {
          //       "id": 9181,
          //       "product_id": 895,
          //       "size": "",
          //       "color": "WHITE 35",
          //       "first_total": 1,
          //       "total": 1,
          //       "keep": 0,
          //       "dropship": 0,
          //       "maximum_total_qty_product_in_trolley": 10,
          //       "sold": 0,
          //       "sku": "",
          //       "price": "501000",
          //       "image": "",
          //       "order_by": "6",
          //       "stock_status": "Limited"
          //   },
          //   {
          //       "id": 9182,
          //       "product_id": 895,
          //       "size": "",
          //       "color": "WHITE 36",
          //       "first_total": 2,
          //       "total": 2,
          //       "keep": 0,
          //       "dropship": 0,
          //       "maximum_total_qty_product_in_trolley": 10,
          //       "sold": 0,
          //       "sku": "",
          //       "price": "501000",
          //       "image": "",
          //       "order_by": "7",
          //       "stock_status": "Ready"
          //   },
          //   {
          //       "id": 9179,
          //       "product_id": 895,
          //       "size": "",
          //       "color": "white 37",
          //       "first_total": 4,
          //       "total": 4,
          //       "keep": 0,
          //       "dropship": 0,
          //       "maximum_total_qty_product_in_trolley": 10,
          //       "sold": 0,
          //       "sku": "",
          //       "price": "501000",
          //       "image": "",
          //       "order_by": "8",
          //       "stock_status": "Ready"
          //   },
          //   {
          //       "id": 9180,
          //       "product_id": 895,
          //       "size": "",
          //       "color": "WHITE 39",
          //       "first_total": 3,
          //       "total": 3,
          //       "keep": 0,
          //       "dropship": 0,
          //       "maximum_total_qty_product_in_trolley": 10,
          //       "sold": 0,
          //       "sku": "",
          //       "price": "501000",
          //       "image": "",
          //       "order_by": "9",
          //       "stock_status": "Ready"
          //   }
          // ]);

          // data color
          // setData3([
          //   {
          //       "color": "BLACK 37",
          //       "image": ""
          //   },
          //   {
          //       "color": "black 38",
          //       "image": ""
          //   },
          //   {
          //       "color": "BROWN 36",
          //       "image": ""
          //   },
          //   {
          //       "color": "BROWN 37",
          //       "image": ""
          //   },
          //   {
          //       "color": "brown 38",
          //       "image": ""
          //   },
          //   {
          //       "color": "BROWN 39",
          //       "image": ""
          //   },
          //   {
          //       "color": "WHITE 35",
          //       "image": ""
          //   },
          //   {
          //       "color": "WHITE 36",
          //       "image": ""
          //   },
          //   {
          //       "color": "white 37",
          //       "image": ""
          //   },
          //   {
          //       "color": "WHITE 39",
          //       "image": ""
          //   }
          // ]);
          // data size color
          //   setSizeColorData([
          //     {
          //         "color": "BLACK 37",
          //         "image": "",
          //         "size": [
          //             {
          //                 "size_color_id": 9184,
          //                 "size": "",
          //                 "total": 2,
          //                 "sku": "",
          //                 "price": "501000"
          //             }
          //         ]
          //     },
          //     {
          //         "color": "black 38",
          //         "image": "",
          //         "size": [
          //             {
          //                 "size_color_id": 9183,
          //                 "size": "",
          //                 "total": 2,
          //                 "sku": "",
          //                 "price": "501000"
          //             }
          //         ]
          //     },
          //     {
          //         "color": "BROWN 36",
          //         "image": "",
          //         "size": [
          //             {
          //                 "size_color_id": 9177,
          //                 "size": "",
          //                 "total": 3,
          //                 "sku": "",
          //                 "price": "501000"
          //             }
          //         ]
          //     },
          //     {
          //         "color": "BROWN 37",
          //         "image": "",
          //         "size": [
          //             {
          //                 "size_color_id": 9176,
          //                 "size": "",
          //                 "total": 5,
          //                 "sku": "",
          //                 "price": "501000"
          //             }
          //         ]
          //     },
          //     {
          //         "color": "brown 38",
          //         "image": "",
          //         "size": [
          //             {
          //                 "size_color_id": 9175,
          //                 "size": "",
          //                 "total": 4,
          //                 "sku": "",
          //                 "price": "501000"
          //             }
          //         ]
          //     },
          //     {
          //         "color": "BROWN 39",
          //         "image": "",
          //         "size": [
          //             {
          //                 "size_color_id": 9178,
          //                 "size": "",
          //                 "total": 5,
          //                 "sku": "",
          //                 "price": "501000"
          //             }
          //         ]
          //     },
          //     {
          //         "color": "WHITE 35",
          //         "image": "",
          //         "size": [
          //             {
          //                 "size_color_id": 9181,
          //                 "size": "",
          //                 "total": 1,
          //                 "sku": "",
          //                 "price": "501000"
          //             }
          //         ]
          //     },
          //     {
          //         "color": "WHITE 36",
          //         "image": "",
          //         "size": [
          //             {
          //                 "size_color_id": 9182,
          //                 "size": "",
          //                 "total": 2,
          //                 "sku": "",
          //                 "price": "501000"
          //             }
          //         ]
          //     },
          //     {
          //         "color": "white 37",
          //         "image": "",
          //         "size": [
          //             {
          //                 "size_color_id": 9179,
          //                 "size": "",
          //                 "total": 4,
          //                 "sku": "",
          //                 "price": "501000"
          //             }
          //         ]
          //     },
          //     {
          //         "color": "WHITE 39",
          //         "image": "",
          //         "size": [
          //             {
          //                 "size_color_id": 9180,
          //                 "size": "",
          //                 "total": 3,
          //                 "sku": "",
          //                 "price": "501000"
          //             }
          //         ]
          //     }
          // ]);
        }
      } catch (error) {
        console.log("Error fetching getDetailData:", error);
      }
    };

    // PR image list product
    // fetch(`${baseUrlApi()}/Api/Product/singleProductData`, requestOptions)
    // .then((response) => response.json())
    // .then((result) => {
    //   for (var i = 0; i <= result.images.length; i++) {
    //     imageData.push({
    //       original: result.images[i].file_name,
    //       thumbnail: result.images[i].file_name,
    //     });
    //   }
    // })
    // .catch((error) => console.log("error", error));

    getProductDetail();

    if (localStorage.getItem("landing_page")) {
      setCategoriesData(
        JSON.parse(localStorage.getItem("landing_page"))["product_categories"]
      );
    }
  };

  // const singleData2 = () => {
  //   console.log("ids.slice(15)", ids.slice(15));
  //   const urlStr = window.location.href;
  //   const data11 = urlStr.split("/");
  //   const ids1 = data11[3].toString();
  //   var myHeaders = new Headers();
  //   myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");
  //   myHeaders.append("Access-Control-Allow-Origin", "*");

  //   const usercode = JSON.parse(localStorage.getItem("usercode"));
  //   const userid = JSON.parse(localStorage.getItem("userid"));
  //   var formdata = new FormData();
  //   if (userid) {
  //     formdata.append("user_id", userid);
  //     formdata.append("user_code", usercode);
  //   }
  //   formdata.append("product_id", ids1.slice(15));

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: "follow",
  //   };

  //   fetch(`${baseUrlApi()}/Api/Product/singleProductData`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       for (var i = 0; i <= result.images.length; i++) {
  //         imageData.push({
  //           original: result.images[i].file_name,
  //           thumbnail: result.images[i].file_name,
  //         });
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // };
  useEffect(() => {
    singleData();
    // singleData2();
  }, [imageData]);

  const singleRelatedData = () => {
    console.log("ids.slice(15)", ids.slice(15));
    var myHeaders = new Headers();
    myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");
    myHeaders.append("Access-Control-Allow-Origin", "*");

    const usercode = JSON.parse(localStorage.getItem("usercode"));
    const userid = JSON.parse(localStorage.getItem("userid"));
    var formdata = new FormData();
    if (userid) {
      formdata.append("user_id", userid);
      formdata.append("user_code", usercode);
    }
    formdata.append("product_id", ids.slice(15));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    // fetch(`${baseUrlApi()}/Api/Product/singleProductData`, requestOptions)
    //   .then((response) => response.json())
    //   .then((result) => {
    //     // setLoading(true)
    //     // console.log("result__", result);

    //     setDataDetail(result.product_details);

    //     setData3(result.unique_color);
    //     setData4(result);

    //     // for (var i = 0; i < result.images.length; i++) {
    //     //   images.push({
    //     //     original: result.images[i].file_name,
    //     //     thumbnail: result.images[i].file_name,
    //     //   });
    //     // }

    //     setData5(result.unique_size);
    //     setData6(result.list_stock);
    //     setData7(result.similar_product);
    //     setOpen(true);
    //     // setLoading(false)
    //   })
    //   .catch((error) => console.log("error", error));
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 440, min: 0 },
      items: 1,
    },
  };

  const getSingleProduct = (e) => {
    images.splice(0);
    history.push(`/productdetail?${e}`);

    // productids.push(products[0])
    // productids.push(e);
    // const uniqueNames = productids.filter((val,id,array) => array.indexOf(val) == id);
    // localStorage.setItem("productsid",JSON.stringify(uniqueNames));
    // var data3 = new FormData();
    // data3.append("product_id", e);
    // if(userid){
    //   data3.append("user_id", userid);
    //   data3.append("user_code", usercode);
    // }
    // dispatch(singleProductDataUser(data3));
    // images.splice(0);
    // history.push("/productdetail");
    setOpen(false);
    setOpen1(true);
    related.splice(0, related.length);
    // singleData();
  };

  const [selectfav, Setselectfav] = useState([]);
  const [heart, setheart] = useState(false);
  const [productid, setProductId] = useState("");
  const [total, setTotal] = useState(0);
  const [isSuccses, setSucsess] = useState(false);

  const addWish = (e) => {
    setProductId("");
    let data1 = new FormData();
    data1.append("product_id", e);
    data1.append("user_code", usercode);
    data1.append("date_time", "2018-12-25 10:23:00");
    // dispatch(addWishlistProductUser(data1));
    setTimeout(() => {
      getWishlist();
    }, 100);
    // setOpen(false);
    // setOpen1(false);
    // localStorage.setItem("open", JSON.stringify(false));
  };

  const addWishRed = (e, j) => {
    const usercode = JSON.parse(localStorage.getItem("usercode"));
    let data1 = new FormData();
    data1.append("product_id", e);
    data1.append("user_code", usercode);
    data1.append("date_time", "2018-12-25 10:23:00");
    // dispatch(addWishlistProductUser(data1));
    let tmpArr = Object.assign([], data7);
    let tmpObj = Object.assign({}, tmpArr[j]);
    tmpObj["is_wishlist"] = "1";
    tmpArr[j] = tmpObj;
    setData7(tmpArr);
  };

  const addWishWhite = (e, j) => {
    const usercode = JSON.parse(localStorage.getItem("usercode"));
    let data1 = new FormData();
    data1.append("product_id", e);
    data1.append("user_code", usercode);
    data1.append("date_time", "2018-12-25 10:23:00");
    // dispatch(addWishlistProductUser(data1));
    let tmpArr = Object.assign([], data7);
    let tmpObj = Object.assign({}, tmpArr[j]);
    tmpObj["is_wishlist"] = "0";
    tmpArr[j] = tmpObj;
    setData7(tmpArr);
  };

  const RelatedaddWish = (e) => {
    let data1 = new FormData();
    data1.append("product_id", e);
    data1.append("user_code", usercode);
    data1.append("date_time", "2018-12-25 10:23:00");
    // dispatch(addWishlistProductUser(data1));
    singleRelatedData();
    // localStorage.setItem("open", JSON.stringify(false));
    // setOpen(false);
    // setOpen1(false);
  };

  const getWishlist = () => {
    selectfav.splice(0, selectfav.length);
    setheart(false);
    let data = new FormData();
    data.append("user_code", JSON.parse(localStorage.getItem("usercode")));
    fetch(`${baseUrlApi()}/Api/Product/getWishlist`, {
      method: "POST",
      headers: {
        key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
        "Access-Control-Allow-Origin": "*",
      },
      body: data,
    })
      .then((response) => response.json())
      .then((json) => {
        // setTimeout(() => {
        for (var i = 0; i < json.data.length; i++) {
          if (dataDetail.id == json.data[i].id) {
            selectfav.push(dataDetail.id);
            setAddToWishList(false);
            setheart(true);
          }
        }
        // }, 1500)
      });
  };

  const QuantityForProduct = (productID, quantity, price, isInput) => {
    if (quantityPri.length > 0) {
      const datass = quantityPri.find(function (p) {
        if (p.id !== productID) return false;
        p.qty = isInput ? parseInt(quantity) : (p.qty += parseInt(quantity));
        return true;
      });
      if (datass == undefined) {
        setQuantityPri((quantityPri) => [
          ...quantityPri,
          {
            id: productID,
            qty: parseInt(quantity) < 0 ? 0 : parseInt(quantity),
            colorid: productID,
            total: price * (parseInt(quantity) < 0 ? 0 : parseInt(quantity)),
          },
        ]);
      } else {
        const findIndex = quantityPri.findIndex(
          (data) => data.id === productID
        );
        let updateData = [...quantityPri];
        updateData[findIndex].qty = datass.qty < 0 ? 0 : datass.qty;
        updateData[findIndex].total = price * parseInt(datass.qty);
        setQuantityPri(updateData);
      }
    } else {
      setQuantityPri((quantityPri) => [
        ...quantityPri,
        {
          id: productID,
          qty: parseInt(quantity) < 0 ? 0 : parseInt(quantity),
          colorid: productID,
          total: price * (parseInt(quantity) < 0 ? 0 : parseInt(quantity)),
        },
      ]);
    }
  };

  const vertical = "bottom",
    horizontal = "center";
  const [trueopen, setTrueOpen] = useState(false);
  const [quantityPri, setQuantityPri] = useState([]);
  const [totalPri, setTotalPri] = useState([]);
  const [totalQty, setTotalQty] = useState([]);
  const [coloridPri, setColoridPri] = useState([]);
  const [loading, setLoading] = useState(true);

  const [sizeColorid, setSizeColorid] = useState([]);
  const [qty, setQty] = useState([]);
  const [qtyValue, setQtyValue] = useState([]);
  const [cartNumber, setCartNumber] = useState(0);
  const [bestSellerFlag, setbestSellerFlag] = useState("");

  // const resOfCart = AddToCartResponse();

  const onChnagQty = (e) => {
    setQty({ ...qty, [e.target.name]: e.target.value });
  };
  // const CartData = {};
  // const Usercode = JSON.parse(localStorage.getItem("usercode"));
  // const mycart = async () => {
  //   if (localStorage.getItem("token")) {
  //     const cartDataResult = await getBadgeCart(
  //       localStorage.getItem("token")
  //     );
  //     if (cartDataResult.success) {
  //       setCartNumber(cartDataResult.data);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   mycart();
  // }, [Usercode]);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const AddToCartTableProduct = async () => {
    const UserId = JSON.parse(localStorage.getItem("userid"));
    // const Usercode = JSON.parse(localStorage.getItem("usercode"));
    let data = {};
    if (UserId) {
      if (quantityPri.length === 0 && productVariant.length > 0) {
        setErrorOpen(true);
        return;
      }
      // setLoadingBtn(true);
      for (var i = 0; i < quantityPri.length; i++) {
        if (quantityPri[i].qty !== "") {
          setTotalQty((prev) => prev, ...[quantityPri[i].qty]);
          setTotalPri((prev) => prev, ...[quantityPri[i].total]);
          setColoridPri((prev) => prev, ...[quantityPri[i].colorid]);

          // totalQty.push(quantityPri[i].qty);
          // totalPri.push(quantityPri[i].total);
          // coloridPri.push(quantityPri[i].colorid);
        }
      }

      setLoadingBtn(true);
      setTrueOpen(true);

      if (quantityPri.length > 0) {
        for (
          let counterInsert = 0;
          counterInsert < quantityPri.length;
          counterInsert++
        ) {
          data = {
            product_id: parseInt(ids.slice(15)),
            product_variant_id: quantityPri[counterInsert].id,
            quantity: quantityPri[counterInsert].qty,
            checked_out_quantity: quantityPri[counterInsert].qty,
            note: valueTextFieldNotedForSale,
          };

          try {
            const response = await createToCart(
              data,
              localStorage.getItem("token")
            );
            if (response.success) {
              refreshCart();

              setResOfCart({
                success: response.success,
                message: response.message,
                open_popup: true,
              });
              dispatch(increaseCounter());

              setTotalPri([]);
              setTotalQty([]);
              setColoridPri([]);
              setQuantityPri([]);

              // totalPri.splice(0);
              // totalQty.splice(0);
              // coloridPri.splice(0);
              setTrueOpen(false);
              setOpen4(true);
              setSucsess(true);
              setLoadingBtn(false);
              setTimeout(() => {
                setResOfCart({
                  success: response.success,
                  message: response.message,
                  open_popup: false,
                });
              }, 1000);
            } else {
              dispatch(setSnackbar({ show: true, message: response.message }));
              // setTimeout(() => {
              //   setResOfCart({
              //     success: response.success,
              //     message: response.message,
              //     open_popup: false,
              //   });
              // }, 1000);
            }
          } catch (error) {
            console.log(error);
          } finally {
            setLoadingBtn(false);
          }
        }
      } else {
        data = {
          product_id: parseInt(ids.slice(15)),
          product_variant_id: null,
          quantity: quantity,
          checked_out_quantity: quantity,
          note: valueTextFieldNotedForSale,
        };

        try {
          const response = await createToCart(
            data,
            localStorage.getItem("token")
          );
          setResOfCart({
            success: response.success,
            message: response.message,
            open_popup: true,
          });
          if (response.success) {
            refreshCart();

            totalPri.splice(0);
            totalQty.splice(0);
            coloridPri.splice(0);
            setTrueOpen(false);
            setOpen4(true);
            setSucsess(true);
            setLoadingBtn(false);
            setTimeout(() => {
              setResOfCart({
                success: response.success,
                message: response.message,
                open_popup: false,
              });
            }, 1500);
          } else {
            dispatch(setSnackbar({ show: true, message: response.message }));
            // setTimeout(() => {
            //   setResOfCart({
            //     success: response.success,
            //     message: response.message,
            //     open_popup: false,
            //   });
            // }, 1500);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingBtn(false);
        }
      }
    } else {
      setAuth(true);
    }
  };

  const AddToCartProduct = async () => {
    const UserId = JSON.parse(localStorage.getItem("userid"));
    // mycart();
    if (UserId) {
      if (isColor) {
        // let data = new FormData();
        // const Usercode = JSON.parse(localStorage.getItem("usercode"));
        // for (var i = 0; i <= data6.length; i++) {
        //   if (data6[i]) {
        //     if (data6[i].color == isColor) {
        //       data.append("size_color_id", data6[i].id);
        //     }
        //   }
        // }

        // data.append("user_code", Usercode);
        // data.append("product_id", ids.slice(15));
        // data.append("weight", 400);
        // data.append("grand_total", dataDetail.price * quantity);
        // data.append("qty", quantity);

        // data.append("date", moment().format("YYYY-MM-DD hh:mm:ss"));
        // data.append("user_id", UserId);
        // data.append("note", "");
        // dispatch(addToCartProductUser(data));
        setTrueOpen(true);
        const data = {
          product_id: parseInt(ids.slice(15)),
          product_variant_id: null,
          quantity: quantity,
          checked_out_quantity: quantity,
          note: valueTextFieldNotedForSale,
        };

        const response = await createToCart(
          data,
          localStorage.getItem("token")
        );
        setResOfCart({
          success: response.success,
          message: response.message,
          open_popup: true,
        });
        if (response.success) {
          totalPri.splice(0);
          totalQty.splice(0);
          coloridPri.splice(0);
          setTrueOpen(false);
          setOpen4(true);
          setSucsess(true);
          setTimeout(() => {
            setResOfCart({
              success: response.success,
              message: response.message,
              open_popup: false,
            });
          }, 1500);
        } else {
          setTimeout(() => {
            setResOfCart({
              success: response.success,
              message: response.message,
              open_popup: false,
            });
          }, 1500);
        }
      } else {
        setErrorOpen(true);
      }
    } else {
      setAuth(true);
    }
  };

  // handleChangeTextFieldNotedForSale
  const [valueTextFieldNotedForSale, setValueTextFieldNotedForSale] =
    useState("");
  const handleChangeTextFieldNotedForSale = async (value) => {
    setValueTextFieldNotedForSale(value);
  };
  // if (open4) {
  //   if (resOfCart&&resOfCart.success == false) {
  //     // setMessage(resOfCart.ResponseMsg);
  //     totalPri.splice(0);
  //     totalQty.splice(0);
  //     coloridPri.splice(0);
  //   } else {
  //     if(valueTextFieldNotedForSale){
  //       const cartIds = resOfCart.cart_id.split(',')
  //       for(let counterCartIds = 0; counterCartIds < cartIds.length; counterCartIds++){
  //         let data = new FormData();
  //         data.append("cart_id", cartIds[counterCartIds]);
  //         data.append("note", valueTextFieldNotedForSale);
  //         dispatch(addNotes(data));
  //       }
  //       setSucsess(false);
  //     }
  //   }
  //   setOpen4(false);
  // }

  const handleclose = () => {
    setOpen2(false);
  };

  const handleclose1 = () => {
    setOpen3(false);
  };
  // const AddToCartClick=()=>
  // {
  //   const Usercode = JSON.parse(localStorage.getItem("usercode"));
  //         let data = new FormData();
  //         data.append("user_code", Usercode);
  //         data.append("product_id", dataDetail.id);
  //         data.append("weight", dataDetail.weight);
  //         data.append("grand_total", total[0]);
  //         data.append("qty", quant);
  //         data.append("size_color_id", stockId[0]);
  //         data.append("date", "2019-03-12 15:33:15");
  //         data.append("user_id", UserId);
  //         data.append("note", notes);
  //         dispatch(addToCartProductUser(data));
  // }
  // Fetch Data End

  // console.log("data6____",data6)

  // Theme Color Start

  const ThemeColor = JSON.parse(localStorage.getItem("websiteColor"));

  // console.log("ThemeColor_", ThemeColor);

  // Theme Color End

  const onClickRouteChangeToCategory = async (catId) => {
    localStorage.setItem("CatId", catId);
    history.push("/product-by-category");
  };

  // success copy product description
  const [
    isOpenDialogSuccessCopyProductDescription,
    setIsOpenDialogSuccessCopyProductDescription,
  ] = useState(false);

  return (
    <div className={classes.body}>
      {
        // console.log("dataImage---",dataImage)
      }

      <DesktopHeaderWrap
        // clickTab={() => setInlineCategory(false)}
        themeColor={"rgb(231, 0, 116)"}
        cartNumber={cartNumber}
        isFixed={false}
        showCategory
        categoriesData={categoriesData}
      />

      <Container maxWidth="lg">
        <Box className={classes.root_single_product}>
          <Box className={classes.breadcrumbs}>
            <h5
              className="title_bread active"
              style={{ color: ThemeColor }}
              onClick={() => history.push("/")}
            >
              {t("home1")}
            </h5>
            <ArrowForwardIosIcon />
            <h5
              className="title_bread_click"
              style={{ color: ThemeColor }}
              onClick={() => onClickRouteChangeToCategory(dataCategory.id)}
            >
              {dataCategory.name}
            </h5>
            <ArrowForwardIosIcon />
            <h5 className="title_bread ">{dataDetail.name}</h5>
          </Box>

          <Card className={classes.cardStyle}>
            {/* Skeleton for Top Section */}
            {loading ? (
              <Grid container spacing={2}>
                <Grid item md={5}>
                  {/* <Loading /> */}
                  <SkeletonCircleText
                    style={{
                      width: "94%",
                      height: "250px",
                      margin: "auto",
                      marginBottom: "20px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      gap: "5",
                      width: "90%",
                      margin: "0 auto",
                    }}
                  >
                    {["", "", "", ""].map((el) => (
                      <SkeletonCircleText
                        style={{
                          width: "92%",
                          height: "80px",
                          margin: "auto",
                        }}
                      />
                    ))}
                  </div>
                </Grid>
                <Grid item md={7}>
                  <SkeletonCircleText
                    style={{
                      height: "15px",
                      marginBottom: "15px",
                    }}
                  />
                  <SkeletonCircleText
                    style={{
                      height: "15px",
                      width: "80%",
                      marginBottom: "20px",
                    }}
                  />
                  <SkeletonCircleText
                    style={{
                      height: "180px",
                      marginBottom: "25px",
                    }}
                  />
                  <SkeletonCircleText
                    style={{
                      height: "50px",
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <ImageZoom
                  images={dataImage}
                  defaultImage={defaultImage}
                  productName={dataDetail.name}
                />

                <Grid item md={7}>
                  <Box className={classes.product_right}>
                    <div className={classes.product_heading}>
                      <h2 className="title_product">
                        {bestSellerFlag && (
                          <span className="best_seller">{bestSellerFlag}</span>
                        )}
                        {dataDetail.name}
                      </h2>
                    </div>
                    {dataDetail && dataDetail.price ? (
                      <div className={classes.price_box}>
                        <div className={classes.priceText}>
                          <h4
                            className="redText"
                            style={{ color: ThemeColor, fontSize: "30px" }}
                          >
                            {isDiscountValid(
                              dataDetail.discount,
                              dataDetail.discount_start_date,
                              dataDetail.discount_end_date
                            ) &&
                              dataDetail.discount_type === "nominal" && (
                                <p
                                  style={{
                                    marginBottom: "0px",
                                  }}
                                >
                                  {priceFormatLocal(
                                    showPricing(
                                      dataDetail.price,
                                      membershipLevel
                                    ) - dataDetail.discount
                                  )}
                                </p>
                              )}

                            {isDiscountValid(
                              dataDetail.discount,
                              dataDetail.discount_start_date,
                              dataDetail.discount_end_date
                            ) &&
                              dataDetail.discount_type === "percentage" && (
                                <p
                                  style={{
                                    marginBottom: "0px",
                                  }}
                                >
                                  {priceFormatLocal(
                                    showPricing(
                                      dataDetail.price,
                                      membershipLevel
                                    ) -
                                      (dataDetail.discount / 100) *
                                        showPricing(
                                          dataDetail.price,
                                          membershipLevel
                                        )
                                  )}
                                </p>
                              )}

                            {!isDiscountValid(
                              dataDetail.discount,
                              dataDetail.discount_start_date,
                              dataDetail.discount_end_date
                            ) && (
                              <p
                                style={{
                                  marginBottom: "0px",
                                }}
                              >
                                {priceFormatLocal(
                                  showPricing(dataDetail.price, membershipLevel)
                                )}
                              </p>
                            )}
                          </h4>

                          {isDiscountValid(
                            dataDetail.discount,
                            dataDetail.discount_start_date,
                            dataDetail.discount_end_date
                          ) && dataDetail.discount_type === "percentage" ? (
                            <span className="off">{dataDetail.discount}%</span>
                          ) : (
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          )}

                          {isDiscountValid(
                            dataDetail.discount,
                            dataDetail.discount_start_date,
                            dataDetail.discount_end_date
                          ) && dataDetail.discount_type === "nominal" ? (
                            <span className="off">
                              {parseInt(
                                (dataDetail.discount /
                                  showPricing(
                                    dataDetail.price,
                                    membershipLevel
                                  )) *
                                  100
                              )}
                              %
                            </span>
                          ) : (
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          )}

                          {isDiscountValid(
                            dataDetail.discount,
                            dataDetail.discount_start_date,
                            dataDetail.discount_end_date
                          ) && (
                            <div className="price-cancel">
                              {priceFormatLocal(
                                showPricing(dataDetail.price, membershipLevel)
                              )}
                            </div>
                          )}
                        </div>
                        {/* <div className="share">
                          <ShareIcon />
                        </div> */}
                      </div>
                    ) : null}

                    <ul className={classes.product_detail_content}>
                      {productVariant && productVariant.length > 0 ? (
                        <div className={classes.tables_container}>
                          <div className="leftWidth"></div>
                          <table
                            className={
                              classes.tables_style +
                              " " +
                              " table table-bordered"
                            }
                          >
                            <tr
                              className="head"
                              style={{ background: ThemeColor }}
                            >
                              <th
                                style={{
                                  color: "#fff",
                                  border: `1px solid #f8d9d9`,
                                  borderRight: "none",
                                  borderCollapse: "collapse",
                                  padding: "5px 5px",
                                }}
                              >
                                {t("color")}
                              </th>
                              <th
                                style={{
                                  color: "#fff",
                                  border: `1px solid #f8d9d9`,
                                  borderRight: "none",
                                  borderCollapse: "collapse",
                                  padding: "5px 5px",
                                }}
                              >
                                {t("stock")}
                              </th>
                              <th
                                style={{
                                  color: "#fff",
                                  border: `1px solid #f8d9d9`,
                                  borderRight: "none",
                                  borderCollapse: "collapse",
                                  padding: "5px 5px",
                                }}
                              >
                                {t("price")}
                              </th>
                              <th
                                style={{
                                  color: "#fff",
                                  border: `1px solid #f8d9d9`,
                                  borderRight: "none",
                                  borderCollapse: "collapse",
                                  padding: "5px 5px",
                                }}
                              >
                                Qty
                              </th>
                            </tr>

                            {productVariant &&
                              productVariant.map((itemVariant) => {
                                const findIndex = quantityPri.findIndex(
                                  (data) => data.id === itemVariant.id
                                );
                                const valueQuantity =
                                  findIndex >= 0
                                    ? quantityPri[findIndex].qty
                                    : 0;
                                const priceValue =
                                  dataDetail.discount_type === "nominal"
                                    ? itemVariant.price[membershipLevel] -
                                      dataDetail.discount
                                    : dataDetail.discount_type === "percentage"
                                      ? itemVariant.price[membershipLevel] -
                                        (dataDetail.discount / 100) *
                                          itemVariant.price[membershipLevel]
                                      : itemVariant.price[membershipLevel];
                                return (
                                  <tr
                                    style={{
                                      border: `0.1px solid #edebeb`,
                                      borderCollapse: "collapse",
                                      padding: "5px 5px",
                                      height: "40px",
                                    }}
                                  >
                                    <td
                                      style={{
                                        padding: "5px 5px",
                                        textAlign: "left",
                                      }}
                                      width="150"
                                    >
                                      {itemVariant.attributes[0].value}
                                    </td>
                                    <td
                                      style={{
                                        padding: "5px 5px",
                                        textAlign: "left",
                                        color:
                                          itemVariant.stock_status == "Limited"
                                            ? "red"
                                            : null,
                                      }}
                                      width="60"
                                    >
                                      {itemVariant.stock_status}
                                    </td>
                                    <td
                                      style={{
                                        padding: "5px 5px",
                                        textAlign: "left",
                                      }}
                                      width="70"
                                    >
                                      {priceFormatLocal(priceValue)}
                                    </td>
                                    <td
                                      style={{
                                        padding: "5px 5px",
                                        textAlign: "center",
                                      }}
                                      width="50"
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-evenly",
                                        }}
                                        className={classes.qtyinput}
                                      >
                                        <IconButton
                                          disabled={
                                            itemVariant.stock_status ===
                                            "Sold Out"
                                          }
                                          style={{
                                            padding: 0,
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        >
                                          <RemoveIcon
                                            fontSize="small"
                                            onClick={(e) => {
                                              QuantityForProduct(
                                                itemVariant.id,
                                                -1,
                                                priceValue,
                                                false
                                              );
                                            }}
                                            style={{
                                              width: "15px",
                                              height: "15px",
                                              fill: "black",
                                            }}
                                          />
                                        </IconButton>
                                        <input
                                          style={{
                                            width: "25px",
                                            border: "none",
                                            textAlign: "center",
                                          }}
                                          type="number"
                                          onChange={(e) => {
                                            QuantityForProduct(
                                              itemVariant.id,
                                              e.target.value,
                                              priceValue,
                                              true
                                            );
                                          }}
                                          value={valueQuantity}
                                          disabled={
                                            itemVariant.stock_status ===
                                            "Sold Out"
                                          }
                                        />
                                        <IconButton
                                          disabled={
                                            itemVariant.stock_status ===
                                            "Sold Out"
                                          }
                                          style={{
                                            padding: 0,
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        >
                                          <AddIcon
                                            fontSize="small"
                                            onClick={(e) => {
                                              QuantityForProduct(
                                                itemVariant.id,
                                                +1,
                                                priceValue,
                                                false
                                              );
                                            }}
                                            style={{
                                              width: "15px",
                                              height: "15px",
                                              fill: "black",
                                            }}
                                          />
                                        </IconButton>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </table>
                        </div>
                      ) : null}

                      {/* catatan untuk penjual */}
                      <TextField
                        id="textfield-noted-for-sale"
                        label="Catatan untuk penjual"
                        variant="outlined"
                        size="small"
                        className={classes.styleTextfieldNotedForSale}
                        onChange={(e) => {
                          handleChangeTextFieldNotedForSale(e.target.value);
                        }}
                      />

                      {/* Buttons */}
                      <li className={classes.buttons_group_cart}>
                        {/* <span className="blankspace">&nbsp; </span> */}
                        <div
                          className={
                            classes.btn_group + " product-detail-content-right "
                          }
                        >
                          {/* <ButtonCustom bg={webThemeColor}>
                            <ChatBubbleOutlinedIcon />
                            {t("chat")}
                          </ButtonCustom> */}
                          <ButtonCustom
                            border="true"
                            className={classes.btn_add_fav11 + " btn-add-fav11"}
                            onClick={() => {
                              setAddToWishList(!AddToWishList);
                              addWish(dataDetail.id);
                            }}
                          >
                            {AddToWishList ? (
                              <FavoriteBorderIcon />
                            ) : (
                              <div className={classes.heartRed}>
                                <FavoriteIcon />
                              </div>
                            )}
                            {t("add_to_wishlist")}
                          </ButtonCustom>
                          {/* {data4.is_table_view == "0" ? (
                            <ButtonCustom
                              bg={webThemeColor}
                              className={classes.NavLink_style_none11}
                              onClick={AddToCartProduct}
                              disabled={isActiveButtonAddToCart === false ? true:false}
                            >
                              <ShoppingCartIcon />
                              {t('add_to_cart')}
                            </ButtonCustom>
                          ) : (
                            <ButtonCustom
                              bg={webThemeColor}
                              className={classes.NavLink_style_none11}
                              onClick={AddToCartTableProduct}
                              // disabled={loadingBtn ? true  : false}
                              disabled={loadingBtn ? true : isActiveButtonAddToCart===false ? true : false}
                            >
                              {loadingBtn ? null :  <ShoppingCartIcon />}
                              {loadingBtn ? <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<LoaderDots />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> : t('add_to_cart')}
                            </ButtonCustom>
                          )} */}

                          <ButtonCustom
                            bg={webThemeColor}
                            className={classes.NavLink_style_none11}
                            onClick={AddToCartTableProduct}
                            // disabled={loadingBtn ? true  : false}
                            disabled={
                              loadingBtn
                                ? true
                                : isActiveButtonAddToCart === false
                                  ? true
                                  : dataDetail.stock_status === "Sold Out"
                                    ? true
                                    : false
                            }
                          >
                            {loadingBtn ? null : <ShoppingCartIcon />}
                            {loadingBtn ? (
                              <div>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <LoaderDots />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </div>
                            ) : (
                              t("add_to_cart")
                            )}
                          </ButtonCustom>
                        </div>
                      </li>
                    </ul>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Card>
          <Card classNames={classes.card_sectionCall}>
            <div style={{ height: "2px" }}></div>
            <Box className={classes.sectionHeader}>
              <Link
                className="link"
                to="productDescription"
                offset={-170}
                // spy={true}
                // smooth={true}
                activeClassName="active"
              >
                {t("description_product")}
              </Link>
              {/* <Link
                className="link"
                to="productSpecification"
                offset={-170}
                // spy={true}
                // smooth={true}
              >
                Product Specification
              </Link> */}
              {dataDetail && dataDetail.detail && (
                <div
                  onClick={(e) => {
                    const sanitizedDescription = dataDetail.detail.replaceAll(
                      /<\/?p[^>]*>|&nbsp;/g,
                      "\n"
                    );
                    const textArea = document.createElement("textarea");
                    textArea.value = sanitizedDescription;
                    document.body.appendChild(textArea);
                    textArea.focus();
                    textArea.select();
                    try {
                      document.execCommand("copy");
                    } catch (err) {
                      console.error("Unable to copy to clipboard", err);
                    }
                    document.body.removeChild(textArea);

                    setCopyIconSrc("/images/copysuccess.svg");
                    setIsOpenDialogSuccessCopyProductDescription(true);
                    setTimeout(() => {
                      setCopyIconSrc(originalCopyIconSrc);
                      setIsOpenDialogSuccessCopyProductDescription(false);
                    }, 1000);
                  }}
                >
                  <label>{t("copy")}&nbsp;</label>
                  <img src={copyIconSrc} />
                </div>
              )}
            </Box>
            <Box className={classes.sectionDestination} id="productDescription">
              {/* <Box className="sectionHeader flex-between" style={{ display: 'flex', 'justifyContent': 'space-between', 'alignItems': 'center' }}>
                <h4>Product Description</h4>
                
              </Box> */}
              <Box className="sectionBody">
                {dataDetail ? (
                  dataDetail.detail ? (
                    <div
                      className={classes.desdiv}
                      dangerouslySetInnerHTML={{
                        __html: dataDetail.detail.replaceAll(
                          "<p>&nbsp;</p>",
                          ""
                        ),
                      }}
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignContent: "center",
                        justifyContent: "center",
                        textAlign: "left",
                        marginBottom: "0px !important",
                      }}
                    >
                      {/* {dataDetail.detail.replace(/ /g, "\u00A0")} */}
                    </div>
                  ) : (
                    <div>
                      <SkeletonCircleText />
                      <SkeletonCircleText />
                      <SkeletonCircleText />
                      <SkeletonCircleText />
                      <SkeletonCircleText style={{ width: "80%" }} />
                    </div>
                  )
                ) : null}
              </Box>
            </Box>
            {/* <Box
              className={classes.sectionDestination}
              id="productSpecification"
            >
              <Box className="sectionHeader">
                <h4>Product Specification</h4>
              </Box>
              <Box className="sectionBody"></Box>
            </Box> */}
          </Card>

          <RelatedProduct
            dataSimilar={relatedProductsData}
            color={ThemeColor}
            t={t}
          />
        </Box>
      </Container>
      <DeskTopFooter />
      {isAuth ? <ValidationLoginModal /> : null}
      <Snackbar
        open={isErrorOpen}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setErrorOpen(false)}
        autoHideDuration={2000}
      >
        <MuiAlert severity="error">please insert qty</MuiAlert>
      </Snackbar>
      {resOfCart.success === true ? (
        <Dialog open={resOfCart.open_popup} className={classes.modalRoot}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
              color: "black",
              fontSize: 19,
              fontWeight: 700,
            }}
          >
            <CheckCircleIcon style={{ fontSize: 80, color: "green" }} />
            {resOfCart.message}
          </div>
        </Dialog>
      ) : null}

      {resOfCart.success === false ? (
        <Dialog open={resOfCart.open_popup} className={classes.modalRoot}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
              color: "black",
              fontSize: 19,
              fontWeight: 700,
            }}
          >
            <ErrorIcon style={{ fontSize: 80, color: "red" }} />
            {resOfCart.message}
          </div>
        </Dialog>
      ) : null}

      <Dialog
        open={isOpenDialogSuccessCopyProductDescription}
        className={classes.modalRoot}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "100%",
            color: "black",
            fontSize: 19,
            fontWeight: 700,
          }}
        >
          <CheckCircleIcon style={{ fontSize: 80, color: "green" }} />
          {t("success_copy")}
        </div>
      </Dialog>
    </div>
  );
};

// export default SingleProductDetail;
export default withNamespaces()(SingleProductDetail);
