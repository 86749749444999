import { Box, Container } from "@material-ui/core";
import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Amount from "../../components/common/Amount";
import ButtonCustom from "../../components/common/ButtonCustom";
import Card from "../../components/common/Card";
import DesktopHeaderWrap from "../../components/DeskTopHeaderWrap";
// import { getSplashDataUser } from "../../store/actions/getSplashDataActions";
// import { myOrderProductUser } from "../../store/actions/myOrderActions";
// import { MyOrderProductResponse } from "../../store/Selectors/myorder";
import DeskTopFooter from "../home/DesktopFooter";
import i18n from "../language/localization";
import { useStyles } from "./SuccessOrderPay.style";
import Countdown, { zeroPad } from "react-countdown";
import { t } from "i18next";
import SkeletonCircleText from "../../components/common/SkeletonCircleText";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import { baseUrlApi } from "../../helper/api";
import { get as getOrder } from "../../api/order";
import { getBadgeCart } from "../../api/badge";
import { useDispatch } from "react-redux";
import { setCounter } from "../../stores/features/cart/cart";

const SuccessOrderPay = ({ setActive, webThemeColor }) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  let history = useHistory();
  const [itemsCart, setItems] = useState(0);
  const Usercode1 = JSON.parse(localStorage.getItem("usercode"));
  const mycartdata = async () => {
    // let data = new FormData();
    // data.append("user_code", Usercode1);
    // data.append("current_date", moment().format("YYYY-MM-DD hh:mm:ss"));
    // dispatch(getMyCartProductUser(data));

    // fetch(`${baseUrlApi()}/Api/Order/myCart`, {
    //   method: "POST",
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   body: data,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     console.log("getSearchProduct", res.data);
    //     setItems(res.data.length)
    //   });

    if (localStorage.getItem("token")) {
      const cartDataResult = await getBadgeCart(localStorage.getItem("token"));
      if (cartDataResult.success) {
        setItems(cartDataResult.data);
        dispatch(setCounter(cartDataResult.data));
      }
    }
  };
  useEffect(() => {
    mycartdata();
  }, [Usercode1]);
  const handleHistoryOrder = () => {
    setActive("successOrderPay");
    history.push("/my-profile");
  };

  const renderer = ({ hours, minutes, seconds, days, completed }) => {
    if (completed) {
      // return <Completionist />;
      return null;
    } else {
      return (
        <span>
          <span className="text-white count">{zeroPad(days)}</span>
          <span className=" text-white count">{zeroPad(hours)}</span>:
          <span className="text-white count ">{zeroPad(minutes)}</span>:
          <span className="text-white count">{zeroPad(seconds)}</span>
        </span>
      );
    }
  };

  // const orderRes = MyOrderProductResponse();
  // const dispatch = useDispatch();
  const userid = JSON.parse(localStorage.getItem("userid"));

  const orderdata = () => {
    let data = new FormData();
    data.append("user_id", userid);
    // dispatch(getSplashDataUser(data));
  };

  const [open, setOpen] = useState(true);
  const [myOrderData, setMyOrderData] = useState({});
  const [isDelay, setDelay] = useState(true);
  const [landingPage, setLandingPage] = useState({});
  useEffect(() => {
    // var data3 = new FormData();
    // data3.append("user_id", userid);
    // data3.append("type", "UNPAID");
    // data3.append("page", 1);
    // dispatch(myOrderProductUser(data3));
    // fetch(`${baseUrlApi()}/Api/Order/myOrder`, {
    //   method: "POST",
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     token: "81506e6ff4a12e7f0e207a52e16d6735",
    //     "Access-Control-Allow-Origin": "*",
    //     "X-localization" : localStorage.getItem("Language"),
    //   },
    //   body: data3,
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     // console.log("appi time",res.data.length);
    //     // setField([...field,...res.data]);
    //     // console.log("resposneshiping", res);
    //     setMyOrderData(res.UNPAID[0]);
    //   });
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));

    const getOrderData = async () => {
      try {
        const orderID = localStorage.getItem("order_id");
        const response = await getOrder(
          localStorage.getItem("token"),
          `{"limit":1, "page":1, "customer_id":${userid}, "id": ${orderID} }`
        );

        if (response.success) {
          console.log("response getOrderData", response.data[0]);
          setMyOrderData(response.data[0]);
        }
      } catch (error) {
        console.log("Error fetching getOrderData:", error);
      }
    };

    getOrderData();

    // setTimeout(() => setOpen(true), 1000);
    // setTimeout(() => setDelay(false), 1500);
  }, []);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (localStorage.getItem("landing_page")) {
      setLandingPage(JSON.parse(localStorage.getItem("landing_page")));
    }
  }, []);

  return (
    <div className={classes.body}>
      <DesktopHeaderWrap
        // clickTab={() => setInlineCategory(false)}
        themeColor={"rgb(231, 0, 116)"}
        cartNumber={itemsCart}
        wishlistCount={0}
        isFixed={true}
        categoriesData={landingPage ? landingPage.product_categories : []}
      />

      <Container>
        <Box className={classes.root_sucessorderpay}>
          <Card className={classes.card_style}>
            <header className={classes.header}>
              {/* <ButtonCustom
                backArrow="true"
                onClick={() => history.push("checkout")}
              /> */}
              <h6>{t("successfull_order")}</h6>
            </header>
            <Box className={classes.card_content}>
              {/* <h1>{myOrderData.total}</h1> */}
              <p className={classes.title_descrition}>
                {t("you_have_successfully_placed_an_order")} <br />
                <b>
                  {myOrderData && myOrderData.order ? (
                    moment
                      .utc(myOrderData.order.created_at)
                      .format("YYYY-MM-DD HH:mm:ss")
                  ) : (
                    <SkeletonCircleText />
                  )}
                </b>{" "}
                {t("with")} {t("bank_transfer")} {t("otherwise_cancelled_auto")}
              </p>
              <Box className={classes.lable_amount}>
                <h6 className="lable">{t("order_time")}</h6>
                <h6 className="value">
                  {myOrderData && myOrderData.order ? (
                    moment
                      .utc(myOrderData.order.created_at)
                      .format("YYYY-MM-DD HH:mm:ss")
                  ) : (
                    <SkeletonCircleText />
                  )}
                </h6>
              </Box>
              <Box className={classes.lable_amount}>
                <h6 className="lable">{t("auto_cancel")}</h6>
                <h6 className="value">
                  {myOrderData && myOrderData.order ? (
                    <Countdown
                      date={moment
                        .utc(myOrderData.order.expired_at)
                        .format("YYYY-MM-DD HH:mm:ss")}
                      renderer={renderer}
                      zeroPadTime={2}
                      zeroPadDays={3}
                    />
                  ) : null}
                </h6>
              </Box>
              <Box className={classes.lable_amount}>
                <h6 className="lable">{t("transfer_total")}</h6>
                <h6 className="value">
                  <Amount
                    amount={
                      myOrderData && myOrderData.order ? (
                        myOrderData.order.grand_total
                      ) : (
                        <SkeletonCircleText />
                      )
                    }
                  />
                </h6>
              </Box>
              <p className={classes.note_descrition}>
                {t("please_make_sure_last_3_digits")}
              </p>
              <div>
                {myOrderData && myOrderData.order ? (
                  myOrderData.order.payment_method == "COD" ? null : myOrderData
                      .order.payment_method_detail ? (
                    <div>
                      <Box className={classes.lable_amount}>
                        <h6 className="lable">
                          {myOrderData.order.payment_method_detail
                            .account_number
                            ? `${t("account_number")}`
                            : null}
                        </h6>
                        <h6 className="value">
                          {
                            myOrderData.order.payment_method_detail
                              .account_number
                          }
                        </h6>
                      </Box>
                      <Box className={classes.lable_amount}>
                        <h6 className="lable">{t("account_name")}</h6>
                        <h6 className="value">
                          {myOrderData.order.payment_method_detail.account_name}
                        </h6>
                      </Box>
                      <Box className={classes.lable_amount}>
                        <div>
                          <h6 className="lable">{t("account_bank")}</h6>
                          <h6 className="value">
                            {myOrderData.order.payment_method_detail.bank_name}
                          </h6>
                        </div>
                        <img
                          src={
                            myOrderData.order.payment_method_detail.bank_image
                              ? process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                myOrderData.order.payment_method_detail
                                  .bank_image
                              : "/assets/images/dummy.png"
                          }
                          style={{ width: "70px" }}
                        />
                      </Box>
                    </div>
                  ) : null
                ) : null}
              </div>

              <Box className={classes.btn_group}>
                {myOrderData &&
                myOrderData.order &&
                myOrderData.order.is_moota === false ? (
                  <ButtonCustom bg={webThemeColor}>
                    {t("payment_confirm")}
                  </ButtonCustom>
                ) : null}

                <ButtonCustom bg={webThemeColor} onClick={handleHistoryOrder}>
                  {t("history_order")}
                </ButtonCustom>
              </Box>
            </Box>
          </Card>
        </Box>
      </Container>

      <DeskTopFooter themeColor={"rgb(231, 0, 116)"} />
    </div>
  );
};

export default SuccessOrderPay;
