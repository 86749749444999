import React, { useState, useContext } from "react";
import Sidebar from "./Sidebar";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { IoReorderThreeSharp } from "react-icons/io5";
import Badge from "@material-ui/core/Badge";
import { useStore } from "../../hooks/useStore";
import { AuthContext } from "../../context/AuthContext";

const Searchbar = ({ props, color, title }) => {
  const { store } = useStore();
  const { countCart } = useContext(AuthContext);

  const useStyles = makeStyles((theme) => ({
    badgeStyle: {
      "&>.MuiBadge-anchorOriginTopRightRectangle": {
        top: 10,
      },
    },
    Root: {
      width: "100%",
      height: "48px",
      background: color,
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      userSelect: "none",
    },
    Title: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "17px",
      color: "white",
      cursor: "pointer",
      userSelect: "none",
    },
    Icon_div: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingRight: "10px",
      userSelect: "none",
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const userdata = JSON.parse(localStorage.getItem("userdata"));

  const sitetitle = store?.name || process.env.REACT_APP_NICK_SITE_TITLE;

  const [scroll_active, setScroll_Active] = useState();

  const changebg = () => {
    if (window.scrollY >= 193) {
      setScroll_Active(true);
    } else {
      setScroll_Active(false);
    }
  };
  window.addEventListener("scroll", changebg);
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <React.Fragment>
      <div
        // className={`${scroll_active ? "onscroll-bg" : ""}`}
        style={{
          width: "100%",
          height: "48px",
          maxWidth: Mobile ? "500px" : "100%",
          position: "fixed",
          zIndex: "1",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: color,
        }}
      >
        <div style={{ paddingLeft: "10px" }}>
          {Mobile ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Sidebar ThemeColor={color} />
              <div className={classes.Title}>{sitetitle}</div>
              {/* <div className={classes.Title}>{REACT_APP_NAME}</div> */}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                userSelect: "none",
              }}
            >
              <IoReorderThreeSharp
                onClick={() => {
                  history.push("/sidebar");
                }}
                style={{
                  width: "30px",
                  height: "41px",
                  color: "white",
                  userSelect: "none",
                }}
              />
              <div className={classes.Title}>{sitetitle}</div>
            </div>
          )}
        </div>
        <div className={classes.Icon_div}>
          <svg
            style={{
              fill: "transparent",
              cursor: "pointer",
              height: "24px",
              width: "20px",
            }}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              history.push("/search");
            }}
          >
            <path
              d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
              stroke="#F5F5F5"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M22 22L20 20"
              stroke="#F5F5F5"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <Badge
            badgeContent={countCart}
            color="error"
            onClick={() => {
              if (userdata) {
                history.push("/cart");
              } else {
                history.push("/login");
              }
            }}
            className={classes.badgeStyle}
          >
            <svg
              style={{
                fill: "transparent",
                cursor: "pointer",
                height: "24px",
                width: "20px",
              }}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"
                stroke="#F5F5F5"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
                stroke="#F5F5F5"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
                stroke="#F5F5F5"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 8H21"
                stroke="#F5F5F5"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Badge>

          <svg
            style={{
              fill: "transparent",
              cursor: "pointer",
              height: "24px",
              width: "20px",
            }}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              history.push("/chat");
            }}
          >
            <path
              d="M8.5 19H8C4 19 2 18 2 13V8C2 4 4 2 8 2H16C20 2 22 4 22 8V13C22 17 20 19 16 19H15.5C15.19 19 14.89 19.15 14.7 19.4L13.2 21.4C12.54 22.28 11.46 22.28 10.8 21.4L9.3 19.4C9.14 19.18 8.77 19 8.5 19Z"
              stroke="#F5F5F5"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.9965 11H16.0054"
              stroke="#F5F5F5"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.9955 11H12.0045"
              stroke="#F5F5F5"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.99451 11H8.00349"
              stroke="#F5F5F5"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Searchbar;
