import React, { useState, useEffect, useRef } from "react";
import CardProduct from "../../../components/card-product/CardProduct";
import Skeleton from "@material-ui/lab/Skeleton";
import Paper from "@material-ui/core/Paper";
// import { GetWishlistSelector } from "../../../store/Selectors/GetWishlistSelector";
// import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStyles1 } from "../../themecolor/FontSize";
import Pagination from "@material-ui/lab/Pagination";
import { getByClient as getProductsDetail } from "../../../api/products";

const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));

const useCSS = makeStyles((theme) => ({
  Main: {
    "&>ul li Button": {
      border: "1px solid " + ThemeColor + "",
      color: "black",
    },
    "&>ul li .MuiPaginationItem-page.Mui-selected": {
      border: "1px solid " + ThemeColor + "",
      background: ThemeColor,
      color: "#fff",
    },
  },
  hoverCard: {
    border: "1px solid #e8e7ea !important",
    transition: "all 0.1s ease-out",
    marginTop: "3px",
    "&:hover": {
      border: "0.5px solid " + ThemeColor + " !important",
      transform: "translateY(-2px) scale(1.005) translateZ(0)",
      cursor: "pointer",
    },
  },
}));

const usercode = JSON.parse(localStorage.getItem("usercode"));
const ProductData = ({ t, catId, sortBy, pageNumber, membershipLevel }) => {
  const classes1 = useStyles1();
  const classes = useCSS();
  // const data = GetProductDataResponse();
  const [productid, setProductId] = useState("");
  const [color, setColor] = useState(false);
  const [heart, setheart] = useState(true);
  const [data2, setData2] = useState([]);
  const [pageScroll, setPageScroll] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  // console.log("data", data);
  // const classes = useStyles();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);
  const [MyData, setMyData] = useState([]);
  const [MyPage, setMyPage] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [NewData, setNewData] = useState([]);
  const [find, setFind] = useState(false);
  // const dispatch = useDispatch();
  // const whislist = GetWishlistSelector();
  const history = useHistory();
  const routeParams = useParams();

  const getUnique = (array, key) => {
    if (typeof key !== "function") {
      const property = key;
      key = function (item) {
        return item[property];
      };
    }
    return Array.from(
      array
        .reduce(function (map, item) {
          const k = key(item);
          if (!map.has(k)) map.set(k, item);
          return map;
        }, new Map())
        .values()
    );
  };
  const CatId = JSON.parse(localStorage.getItem("CatId"));
  const UserId = localStorage.getItem("userid");
  const Usercode = localStorage.getItem("usercode");
  // const searchname = JSON.parse(routeParams.searchvalue);
  const searchname = JSON.parse(localStorage.getItem("desktopSearch"));

  useEffect(() => {
    if (searchname) {
      const getProductsDetailData = async () => {
        try {
          let sortByValue = null;
          let sortByAscDesc = null;
          switch (sortBy) {
            // case 1:
            //   sortByValue = `"order": "created_at"`
            //   sortByAscDesc = `"sort": "desc"`
            //   break;
            case 1:
              sortByValue = `"labels": "['Terbaru']"`;
              break;
            case 2:
              sortByValue = `"labels": "['Terbaru']"`;
              break;
            case 3:
              sortByValue = `"order": "price.${membershipLevel}"`;
              sortByAscDesc = `"sort": "asc"`;
              break;
            case 4:
              sortByValue = `"order": "price.${membershipLevel}"`;
              sortByAscDesc = `"sort": "desc"`;
              break;
            case 5:
              sortByValue = `"labels": "['Diskon']"`;
              break;
            default:
              break;
          }

          const response = await getProductsDetail(`{
            "name":"${searchname}"
            ${sortByValue ? `,${sortByValue}` : ""}
            ${sortByAscDesc ? `,${sortByAscDesc}` : ""}
          }`);
          setMyPage(response.meta.total_pages);
          // setMyData(response.data);
          setNewData(response.data);
          setFind(false);
          if (response.data.length < 5) {
            setFind(true);
          }
        } catch (error) {
          console.log("Error fetching getProductsDetail:", error);
        }
      };

      getProductsDetailData();
    }
  }, [searchname, catId, sortBy, pageCount, pageNumber]);

  const FetchData = () => {
    // var myHeaders = new Headers();
    // myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");
    // var formdata = new FormData();
    // // formdata.append("user_id", UserId);
    // // formdata.append("user_code", Usercode);
    // formdata.append("page", pageNumber);
    // formdata.append("search", searchname);
    // // if(catId)
    // // {
    // //     formdata.append("category", catId);
    // // }
    // if (sortBy) {
    //   formdata.append("sort_by", sortBy);
    // }
    //   if(searchname)
    //   {
    //   const getProductsDetailData = async () => {
    //     try {
    //       const response = await getProductsDetail(`{"name":"${searchname}"}`);
    //       setMyPage(response.meta.total_pages);
    //       setMyData(response.data);
    //       setNewData(response.data);
    //       setFind(false);
    //       if (response.data.length < 5) {
    //         setFind(true);
    //       }
    //     } catch (error) {
    //       console.log('Error fetching getProductsDetail:', error);
    //     }
    //   };
    //   getProductsDetailData();
    // }
    // if(searchname)
    // {
    //   formdata.append("search", searchname);
    // }
    // fetch(`${baseUrlApi()}/Api/Product/getProductData`, {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setMyPage(res.total_page);
    //     setMyData(res.data);
    //     setNewData(res.data);
    //     setFind(false);
    //     if (res.data.length < 5) {
    //       setFind(true);
    //     }
    //   });
  };

  const [true1, setTrue1] = useState(false);
  // useEffect(() => {
  //   if(true1)
  //   {
  //     const dt = setInterval(() => {
  //       setPageCount((prev) => {
  //         if (prev == 3) {
  //           clearInterval(dt);

  //         }

  //         return prev + 1;
  //       });

  //     }, 100);
  //   }

  // }, [true1]);

  // useEffect(() => {
  //   let myData = new FormData();
  //   myData.append("user_code", usercode);
  //   // belum done add wishlist
  //   // dispatch(GetWishList(myData));
  //   setTimeout(() => {
  //     setTrue1(true);
  //   }, 100);
  // }, []);

  const addWishRed = (e, j) => {
    const usercode = JSON.parse(localStorage.getItem("usercode"));
    let data1 = new FormData();
    data1.append("product_id", e);
    data1.append("user_code", usercode);
    data1.append("date_time", "2018-12-25 10:23:00");
    // belum done add wishlist
    // dispatch(addWishlistProductUser(data1));
    let tmpArr = Object.assign([], NewData);
    let tmpObj = Object.assign({}, tmpArr[j]);
    tmpObj["is_wishlist"] = "1";
    tmpArr[j] = tmpObj;
    setNewData(tmpArr);
  };

  const addWishWhite = (e, j) => {
    const usercode = JSON.parse(localStorage.getItem("usercode"));
    let data1 = new FormData();
    data1.append("product_id", e);
    data1.append("user_code", usercode);
    data1.append("date_time", "2018-12-25 10:23:00");
    // belum done add wishlist
    // dispatch(addWishlistProductUser(data1));
    let tmpArr = Object.assign([], NewData);
    let tmpObj = Object.assign({}, tmpArr[j]);
    tmpObj["is_wishlist"] = "0";
    tmpArr[j] = tmpObj;
    setNewData(tmpArr);
  };

  const pageChange = (event, value) => {
    console.log("Pagination", event);
    setPageNo(value);
    var myHeaders = new Headers();
    myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");
    var formdata = new FormData();
    // formdata.append("user_id", UserId);
    // formdata.append("user_code", Usercode);
    formdata.append("page", value);
    formdata.append("search", searchname);
    //
    // fetch(`${baseUrlApi()}/Api/Product/getProductData`, {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setNewData(res.data);
    //     // for (var i = 0; i < res.data.length; i++) {
    //     //   // console.log("iii", res.data[i]);
    //     //   setNewData(res.data[i]);
    //     // //   if (!MyData.includes(res.data[i].id)) {
    //     // //     MyData.push(res.data[i]);

    //     // //     setNewData(getUnique(MyData, "id"));
    //     // //   }
    //     // }
    //   });
  };

  const naigateToDetailProduct = (idProduct) => {
    if (Mobile) history.push(`/productdetail?${idProduct}`);
    else history.push(`/product-detail?${idProduct}`);
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <h1>{pageCount}</h1> */}
        <div
          className="container"
          style={{
            paddingRight: "0px",
            paddingLeft: "0px",
            maxWidth: "100%",
          }}
        >
          {NewData !== "" ? (
            NewData && NewData.length > 0 ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  overflow: "auto",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  marginLeft: "0px",
                  marginRight: "0px",
                  marginTop: "0px",
                  paddingLeft: "0px",
                  paddingRight: "1px",
                  height: find ? "auto" : "auto",
                  clear: "both",
                  paddingBottom: Mobile ? "0px" : "50px",
                  // columnGap: "7px",
                  // gap: "8px",
                  rowGap: "6px",
                }}
              >
                {NewData.map((dt, index) => {
                  return (
                    <CardProduct
                      key={index}
                      membershipLevel={membershipLevel}
                      limitTitle={25}
                      product={dt.product}
                      heightWrapperImage="180px"
                      handleClick={naigateToDetailProduct}
                    />
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
              >
                <b>{t("product_not_found")}</b>
              </div>
            )
          ) : (
            <Paper>
              <Skeleton variant="rect" height={100} />
              <Skeleton variant="rect" height={100} />
              <Skeleton variant="rect" height={100} />
              <Skeleton variant="rect" height={100} />
            </Paper>
          )}
        </div>
        <Pagination
          count={MyPage}
          variant="outlined"
          shape="rounded"
          page={pageNo}
          onChange={pageChange}
          className={classes.Main}
        />
      </div>
    </React.Fragment>
  );
};

export default withNamespaces()(ProductData);
