import { makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MoreHorizIcon from "@material-ui/icons//MoreHoriz";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import SkeletonCircleText from "../../components/common/SkeletonCircleText";
// import { DashboardResponse } from "../../store/Selectors/dashboard";
import i18n from "../language/localization";
const CardDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 25%;
  flex-basis: 25%;
  user-select: none;
`;

const useStyles = makeStyles((theme) => ({
  Root11: {
    display: "flex",
    flexWrap: "wrap",
    height: "155px",
    paddingLeft: "10px",
    alignItems: "center",
    justifyContent: "space-between",
    background: "white",
    [theme.breakpoints.down("xs")]: {
      // height: "180px",
      paddingLeft: "0px",
      paddingTop: "0px",
    },
  },
  Root: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    height: "155px",
    paddingLeft: "10px",
    alignItems: "center",
    // justifyContent: "space-between",
    background: "white",
    [theme.breakpoints.down("xs")]: {
      borderTop: "0.5px solid #d3cfcf",
      borderBottom: "0.5px solid #d3cfcf",
      height: "180px",
      paddingLeft: "0px",
      paddingTop: "10px",
    },
  },
  Container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100px",
    height: "100px",
    marginRight: "10px",
    // marginLeft: "10px",
    userSelect: "none",
    [theme.breakpoints.down("xs")]: {
      width: "69px",
      height: "75px",
      // marginRight: "auto",
      // marginLeft: "auto",
    },
  },
  Para: {
    fontSize: "12px",
    fontWeight: 500,
    textAlign: "center",
    paddingTop: "12px",
    marginBottom: "5px",
    cursor: "pointer",
    width: "max-content",
    userSelect: "none",
  },
  All_div: {
    backgroundColor: "#e6e6e6",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
}));

const ShortcutMenu = ({ t, categoriesData }) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  // const data = DashboardResponse();

  const go = (e, j, k) => {
    localStorage.setItem("viewproid", e);
    localStorage.setItem("viewproname", j);
    localStorage.setItem("viewprochild", JSON.stringify(k));
    // localStorage.setItem("viewtabs", JSON.stringify("zero"));
    history.push("/products");
    // localStorage.setItem("CatId", e);
    // history.push("/categorylistdata");
  };

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  }, []);

  return (
    <div>
      <div style={{ background: Mobile ? "transperent" : "white" }}>
        {Mobile ? null : (
          <h5
            style={{
              marginTop: "1REM",
              paddingBottom: "-10REM",
              marginLeft: "1rem",
              paddingTop: "1.5rem",
            }}
          >
            {t("categories")}
          </h5>
        )}
        <div className={classes.Root11}>
          {categoriesData != null ? (
            <div className={classes.Root}>
              {categoriesData.length >= 7
                ? categoriesData.slice(0, 7).map((item, index) => {
                    return (
                      <CardDiv
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          go(item.id, item.name, null);
                        }}
                      >
                        <img
                          src={
                            item.image
                              ? process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                item.image
                              : "/assets/images/dummy.png"
                          }
                          alt="Icon"
                          style={{
                            borderRadius: "50%",
                            width: Mobile ? "40px" : 90,
                            height: Mobile ? "39px" : 90,
                          }}
                        />
                        <div>
                          <p className={classes.Para}>
                            {item.name.length >= 7
                              ? item.name.slice(0, 7) + "..."
                              : item.name}
                          </p>
                        </div>
                      </CardDiv>
                    );
                  })
                : categoriesData.map((item, index) => {
                    return (
                      <CardDiv
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          go(item.id, item.name, null);
                        }}
                      >
                        <img
                          src={
                            item.image
                              ? process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                item.image
                              : "/assets/images/dummy.png"
                          }
                          alt="Icon"
                          style={{
                            borderRadius: "50%",
                            width: Mobile ? "40px" : 90,
                            height: Mobile ? "39px" : 90,
                            userSelect: "none",
                          }}
                        />
                        <div>
                          <p className={classes.Para}>{item.name}</p>
                        </div>
                      </CardDiv>
                    );
                  })}
              {categoriesData.length >= 7 ? (
                <CardDiv>
                  <div
                    className={classes.All_div}
                    onClick={() => {
                      history.push("/category");
                    }}
                  >
                    <MoreHorizIcon style={{ color: "black" }} />
                  </div>
                  <p className={classes.Para}>{t("all_category")}</p>
                </CardDiv>
              ) : (
                ""
              )}
            </div>
          ) : (
            ["", "", "", "", "", "", "", ""].map((el) => (
              <div style={{ margin: "0 20px" }}>
                <SkeletonCircleText
                  animation="wave"
                  variant="circle"
                  style={{
                    width: "100px",
                    height: "100px",
                    margin: "auto",
                  }}
                />
                <SkeletonCircleText animation="wave" variant="text" />
              </div>
            ))
          )}
        </div>
        <hr
          style={{ width: "100%", marginBottom: "0.8rem", marginTop: "0px" }}
        />
      </div>
    </div>
  );
};

export default withNamespaces()(ShortcutMenu);
