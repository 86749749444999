import React from "react";
import DeskTopHeader from "../../containers/home/DesktopHeader";
import DesktopNevigationBar from "../../containers/home/DesktopNevigation";
const DesktopHeaderWrap = ({
  clickTab,
  cartNumber,
  wishlistCount,
  isFixed = true,
  showCategory,
  categoriesData,
  landingPage,
}) => {
  return (
    <div>
      <DeskTopHeader
        cartNumber={cartNumber}
        wishlistCount={wishlistCount}
        isFixed={isFixed}
        landingPage={landingPage}
      />
      {showCategory && (
        <DesktopNevigationBar
          clickTab={clickTab}
          isFixed={isFixed}
          categoriesData={categoriesData}
        />
      )}
    </div>
  );
};
export default DesktopHeaderWrap;
