import { Skeleton } from "@material-ui/lab";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    marginBottom: '10px'
  },
});

const SkeletonCircleText = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root + '  ' + props.className} style={props.style}>
      <Skeleton
        width={'100%'}
        height={'15px'}
        animation={props.animation || 'wave'}
        variant={props.variant || 'rect'}
        // animation="wave"
        // variant="circle"
        style={props.style}
      />
    </div>
  );
};

export default SkeletonCircleText;
