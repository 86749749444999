import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  counter: 0,
  checkoutCartItem: [],
  takeAwayCartItem: [],
  isCheckout: false,
};

export const cart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCounter: (state, action) => {
      state.counter = action.payload;
    },

    increaseCounter: (state) => {
      state.counter = state.counter + 1;
    },

    decreaseCounter: (state) => {
      state.counter = state.counter - 1;
    },

    setCheckoutCartItem: (state, action) => {
      state.checkoutCartItem = action.payload;
    },

    setTakeAwayCartItem: (state, action) => {
      state.takeAwayCartItem = action.payload;
    },

    setIsCheckout: (state, action) => {
      state.isCheckout = action.payload;
    },
  },
});

export const {
  setCounter,
  increaseCounter,
  decreaseCounter,
  setCheckoutCartItem,
  setTakeAwayCartItem,
  setIsCheckout,
} = cart.actions;

export default cart.reducer;
