import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    height: "100vh",
    maxWidth: "100%",
    backgroundSize: "cover",
    backgroundImage: `url(/assets/frontend/img/ic_main_landscape.png)`,
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "cover",
      height: "100vh",
      backgroundImage: `url(/assets/frontend/img/ic_main_landscape.png)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "contain",
      height: "100vh",
      backgroundImage: `url(/assets/frontend/img/ic_main_mode.png)`,
      backgroundRepeat: "no-repeat",
    },
  },
}));

const Maintenance = () => {
  const classes = useStyles();
  return (
    <div className={classes.Root}>
      {/* <img
        src={`/assets/frontend/img/ic_main_landscape.png`}
        className={classes.Root}
      /> */}
    </div>
  );
};

export default Maintenance;
