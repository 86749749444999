import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  body: {
    "& h1, h2, h3, h4, h5, h6, p": {
      marginBottom: "0",
    },

    "& header": {
      backgroundColor: "transparent !important",

      "& h4": {
        fontSize: "18px",
        color: "#545454",
        fontWeight: "600",
        marginBottom: "10px",
      },
    },
  },

  cards_container: {
    display: "flex",
    gap: "5px",
    flexFlow:'wrap',

    "& .product-text": {
      padding: "10px",
    },
    "& .prod-name": {
      fontSize: "12px",
      color: "#000",
      fontWeight: "600",
      lineHeight: "20px",
      marginBottom: "5px",
    },
    "& .prod-price": {
      display: "flex",
      gap: "5px",
      fontSize: "12px",
      color: "#333",
      fontWeight: "600",
    },

    "& .product-old-price": {
      color: "#999",
      textDecoration: "line-through",
    },
    "& .product_off": {
      color: "#ff3a59",
      fontWeight: "300",
      display: "none",
    },
  },

  grid_item: {
    width: "calc(21.2% - 16.66px)",
    maxWidth: "100%",
    overflow: "hidden",
    background: "#fff",
    border: "1px solid #e8e7ea",
    borderRadius: "4px",

    "&:hover": {
      borderColor: "#ff3a59",
      WebkitTransition: "all 0.3s linear",
      transition: "all 0.3s linear",
    },

    "& .quickview_new": {
      position: "absolute",
      display: "flex",
      gap: "5px",
      alignItems: "center",
      padding: "5px 14px",
      borderRadius: "4px",
      background: "#000",
      fontSize: "12px",
      fontWeight: "400",
      color: "#fff !important",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      width: "fit-content",
      marginLeft: "auto",
      marginRight: "auto",
      visibility: "hidden",
      opacity: '0',


      "& svg": {
        margin: "0 !important",
        fontSize: "15px",
      },
    },

    "&:hover .quickview_new": {
      position: "absolute",
      visibility: "visible",
      opacity: "1",
      WebkitTransition: "all 0.5s linear",
      transition: "all 0.5s linear",
      marginTop: "-48px",
      marginLeft: "20px",
      cursor: 'pointer',
      opacity: '1',
    },
  },

  btn_buy: {
    marginBottom: "0",
    marginTop: "10px",
  },

  fav_like: {
    border: "1px solid red",
    position: "absolute",
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2px 0px 0px 0px",
    marginLeft: "5px",
    marginTop: "5px",

    "& svg": {
      margin: "0 !important",
      color: "red",
      fontSize: "15px",
    },
  },
});

export { useStyles };
