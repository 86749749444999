import React, { useState, useEffect } from "react";
// import { addWishlistProductUser } from "../../store/actions/addWishlistActions";
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStyles1 } from "../themecolor/FontSize";
import Pagination from "@material-ui/lab/Pagination";
import { getByClient as getProductsDetail } from "../../api/products";
import { priceFormatLocal } from "../../utils/helper";
import { isDiscountValid } from "../../utils/pricing";
import CardProduct from "../../components/card-product/CardProduct";

const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));

const useCSS = makeStyles(() => ({
  Main: {
    "&>ul li Button": {
      border: "1px solid " + ThemeColor + "",
      color: "black",
    },
    "&>ul li .MuiPaginationItem-page.Mui-selected": {
      border: "1px solid " + ThemeColor + "",
      background: ThemeColor,
      color: "#fff",
    },
  },
  hoverCard: {
    border: "1px solid #e8e7ea !important",
    transition: "all 0.1s ease-out",
    marginTop: "3px",
    "&:hover": {
      border: "0.5px solid " + ThemeColor + " !important",
      transform: "translateY(-2px) scale(1.005) translateZ(0)",
      cursor: "pointer",
    },
  },
}));

const usercode = JSON.parse(localStorage.getItem("usercode"));
const ProductByCategory = ({
  t,
  catId,
  sortBy,
  titleCategory,
  membershipLevel,
}) => {
  const classes1 = useStyles1();
  const classes = useCSS();
  const [pageNo, setPageNo] = useState(1);

  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [MyData, setMyData] = useState([]);
  const [MyPage, setMyPage] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [NewData, setNewData] = useState([]);
  const [find, setFind] = useState(false);
  // const dispatch = useDispatch();
  const history = useHistory();

  const categoryID = localStorage.getItem("viewproid");
  const searchname = JSON.parse(localStorage.getItem("desktopSearch"));

  useEffect(() => {
    const getProductsDetailData = async () => {
      try {
        let sortByValue = null;
        let sortByAscDesc = null;

        switch (sortBy) {
          // case 1:
          //   sortByValue = `"order": "created_at"`
          //   sortByAscDesc = `"sort": "desc"`
          //   break;
          case 1:
            sortByValue = `"labels": "['Terbaru']"`;
            break;
          case 2:
            sortByValue = `"labels": "['Terlaris']"`;
            break;
          case 3:
            sortByValue = `"order": "price.${membershipLevel}"`;
            sortByAscDesc = `"sort": "asc"`;
            break;
          case 4:
            sortByValue = `"order": "price.${membershipLevel}"`;
            sortByAscDesc = `"sort": "desc"`;
            break;
          case 5:
            sortByValue = `"labels": "['Diskon']"`;
            break;
          default:
            break;
        }

        const response = await getProductsDetail(
          `{
              "page":${pageCount}, "limit": 15, "name":"${searchname || ""}"
              ${categoryID ? `,"category_id":${categoryID}` : ""}
              ${sortByValue ? `,${sortByValue}` : ""}
              ${sortByAscDesc ? `,${sortByAscDesc}` : ""}
            }`
        );
        setMyPage(response.meta.total_pages);
        // setMyData(response.data);
        setNewData(response.data);
        setFind(false);

        if (response.data && response.data.length < 5) {
          setFind(true);
        }
      } catch (error) {
        console.log("Error fetching getProductsDetail:", error);
      }
    };

    getProductsDetailData();

    // FetchData();
  }, [categoryID, catId, sortBy, pageCount, searchname, membershipLevel]);

  const addWishRed = (e, j) => {
    const usercode = JSON.parse(localStorage.getItem("usercode"));
    let data1 = new FormData();
    data1.append("product_id", e);
    data1.append("user_code", usercode);
    data1.append("date_time", "2018-12-25 10:23:00");
    // dispatch(addWishlistProductUser(data1));
    let tmpArr = Object.assign([], NewData);
    let tmpObj = Object.assign({}, tmpArr[j]);
    tmpObj["is_wishlist"] = "1";
    tmpArr[j] = tmpObj;
    setNewData(tmpArr);
  };

  const addWishWhite = (e, j) => {
    const usercode = JSON.parse(localStorage.getItem("usercode"));
    let data1 = new FormData();
    data1.append("product_id", e);
    data1.append("user_code", usercode);
    data1.append("date_time", "2018-12-25 10:23:00");
    // dispatch(addWishlistProductUser(data1));
    let tmpArr = Object.assign([], NewData);
    let tmpObj = Object.assign({}, tmpArr[j]);
    tmpObj["is_wishlist"] = "0";
    tmpArr[j] = tmpObj;
    setNewData(tmpArr);
  };

  const pageChange = async (event, value) => {
    // console.log("Pagination", event);
    // console.log("value", value);
    setPageNo(value);
    // var myHeaders = new Headers();
    // myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");
    // var formdata = new FormData();
    // // formdata.append("user_id", UserId);
    // // formdata.append("user_code", Usercode);
    // formdata.append("page", value);
    // if(categoryID)
    // {
    //     formdata.append("category", categoryID);
    // }
    //
    // fetch(`${baseUrlApi()}/Api/Product/getProductData`, {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setNewData(res.data);
    //     // for (var i = 0; i < res.data.length; i++) {
    //     //   // console.log("iii", res.data[i]);
    //     //   setNewData(res.data[i]);
    //     // //   if (!MyData.includes(res.data[i].id)) {
    //     // //     MyData.push(res.data[i]);

    //     // //     setNewData(getUnique(MyData, "id"));
    //     // //   }
    //     // }
    //   });
    let sortByValue = null;
    let sortByAscDesc = null;
    try {
      switch (sortBy) {
        // case 1:
        //   sortByValue = `"order": "created_at"`
        //   sortByAscDesc = `"sort": "desc"`
        //   break;
        case 1:
          sortByValue = `"labels": "['Terbaru']"`;
          break;
        case 2:
          sortByValue = `"order": "sold_quantity"`;
          sortByAscDesc = `"sort": "desc"`;
          break;
        case 3:
          sortByValue = `"order": "price.${membershipLevel}"`;
          sortByAscDesc = `"sort": "asc"`;
          break;
        case 4:
          sortByValue = `"order": "price.${membershipLevel}"`;
          sortByAscDesc = `"sort": "desc"`;
          break;
        case 5:
          sortByValue = `"labels": "['Diskon']"`;
          break;
        default:
          break;
      }
      const response = await getProductsDetail(
        `{
          "page":${value}, "limit": 15
          "name":"${searchname}"
          ${categoryID ? `,"category_id":${categoryID}` : ""}
          ${sortByValue ? `,${sortByValue}` : ""}
          ${sortByAscDesc ? `,${sortByAscDesc}` : ""}
        }`
      );
      setMyPage(response.meta.total_pages);
      setNewData(response.data);
    } catch (error) {
      console.log("Error fetching getProductsDetail:", error);
    }
  };

  const getSingleProduct = (e) => {
    // const usercode=JSON.parse(localStorage.getItem("usercode"));
    // const userid=JSON.parse(localStorage.getItem("userid"));
    // var data3 = new FormData();
    // data3.append("product_id", e);
    // if(userid){
    //   data3.append("user_id", userid);
    //   data3.append("user_code", usercode);
    // }
    // dispatch(singleProductDataUser(data3));
    // history.push("/productdetail");
    history.push(`/productdetail?${e}`);
  };
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <h1>{pageCount}</h1> */}
        <div
          className="container"
          style={{
            paddingRight: "0px",
            paddingLeft: "0px",
            maxWidth: "100%",
          }}
        >
          {NewData && NewData.length > 0 ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                overflow: "auto",
                flexWrap: "wrap",
                flexDirection: "row",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
                paddingLeft: "0px",
                paddingRight: "1px",
                height: find ? "auto" : "auto",
                clear: "both",
                paddingBottom: Mobile ? "0px" : "50px",
                // columnGap: "7px",
                gap: "8px",
                rowGap: "6px",
              }}
            >
              {NewData.map((dt, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      width: "calc(17.5% - 16.66px)",
                      clear: "both",
                    }}
                  >
                    <CardProduct
                      membershipLevel={membershipLevel}
                      product={dt.product}
                      limitTitle={25}
                      width="100%"
                      heightWrapperImage="180px"
                      handleClick={() =>
                        history.push(`/product-detail?${dt.product.id}`)
                      }
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <b>{t("empty_product")}</b>
            </div>
          )}
        </div>
        <Pagination
          count={MyPage}
          variant="outlined"
          shape="rounded"
          page={pageNo}
          onChange={pageChange}
          className={classes.Main}
        />
      </div>
    </React.Fragment>
  );
};

export default withNamespaces()(ProductByCategory);
