import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  message: "",
};

export const snackbar = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    setSnackbar: (state, action) => {
      state.show = action.payload.show;
      state.message = action.payload.message;
    },
  },
});

export const { setSnackbar } = snackbar.actions;

export default snackbar.reducer;
