import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  body: {},
  content: {
    paddingTop: "50px",
  },
  title: {
    fontSize: "18px",
    color: "#545454",
    fontWeight: "700",
    marginBottom: "10px",
  },


  input_style: {
    "& input": {
     
    },
   
    "& .label": {
      width: '32.4%'
    },
    "& input:focus": {
      boxShadow: "none",
      border: props => "1px solid "+props.themeColor,
    },
   
  },
});

export { useStyles };
