import React from "react";
import { withNamespaces } from "react-i18next";
import { useStore } from "../../hooks/useStore";
import { makeStyles } from "@material-ui/core";
import { ThemeColor } from "../themecolor";

const useStyles = makeStyles((theme) => ({
  box: {
    boxShadow: "0px 0px 8px -1px rgb(219 213 213)",
    "& h6": {
      color: ThemeColor,
    },
  },
}));

const Description = ({ t, product }) => {
  const { themeColor } = useStore();
  const classes = useStyles();

  const handleClickCopyProductDescription = () => {
    const sanitizedDescription = product.product.detail.replaceAll(
      /<\/?p[^>]*>|&nbsp;/g,
      "\n"
    );
    const textArea = document.createElement("textarea");
    textArea.value = sanitizedDescription;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
    // setOpenSnackbarCopyDescriptionProduct(true);
    // setTimeout(() => {
    //   setOpenSnackbarCopyDescriptionProduct(false);
    // }, 1000);
  };

  return (
    <div
      className={`${classes.box}`}
      style={{
        backgroundColor: "white",
        marginTop: "0px",
        paddingTop: "50px",
        paddingLeft: "7px",
        paddingRight: "7px",
        paddingBottom: "7px",
        minHeight: "700px",
        cursor: "pointer",
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div style={{ cursor: "pointer", fontWeight: "bold" }}>
          {t("description_product")}
        </div>
        <svg
          style={{ width: "24px", height: "24px" }}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={handleClickCopyProductDescription}
        >
          <path
            d="M17.5 13.4V16.4C17.5 20.4 15.9 22 11.9 22H8.1C4.1 22 2.5 20.4 2.5 16.4V12.6C2.5 8.6 4.1 7 8.1 7H11.1"
            stroke={themeColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.5001 13.4H14.3001C11.9001 13.4 11.1001 12.6 11.1001 10.2V7L17.5001 13.4Z"
            stroke={themeColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.1001 2H16.1001"
            stroke={themeColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.5 5C7.5 3.34 8.84 2 10.5 2H13.12"
            stroke={themeColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M22.4999 8V14.19C22.4999 15.74 21.2399 17 19.6899 17"
            stroke={themeColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M22.5 8H19.5C17.25 8 16.5 7.25 16.5 5V2L22.5 8Z"
            stroke={themeColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div
        className={classes.desdiv}
        dangerouslySetInnerHTML={{ __html: product.product.detail }}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          textAlign: "left",
          cursor: "pointer",
          marginBottom: "0px !important",
        }}
      ></div>
    </div>
  );
};
export default withNamespaces()(Description);
