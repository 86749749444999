import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ButtonCustom from "../../../../components/common/ButtonCustom";
import Input from "../../../../components/common/form/Input";
import { useStyles } from "./addAddress.style";
// import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { t } from "i18next";
import LoaderDots from "../../../../components/common/LoaderDots";
import {
  getOneDetail as getOneAddressDetailData,
  update as updateAddressData,
} from "../../../../api/address";
import {
  getProvince as getProvinceData,
  getCity as getCityData,
  getDistrict as getDistrictData,
} from "../../../../api/region";

const UpdateAddress = (props) => {
  const { webThemeColor, themeColor } = props;
  const classes = useStyles(props);
  const history = useHistory();
  // const parameterQuery = useParams();
  const [addressDataDefault, setAddressDataDefault] = useState({});
  const [provinceDataDefault, setProvinceDataDefault] = useState({});
  const [cityDataDefault, setCityDataDefault] = useState({});
  const [districtDataDefault, setDistrictDataDefault] = useState({});
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  // const getData = () => {
  //   const userid = JSON.parse(localStorage.getItem("userid"));
  //   let data = new FormData();
  //   data.append("user_id", userid);
  //   dispatch(getAddressUser(data));
  // };
  useEffect(() => {
    const getProvince = async () => {
      try {
        const response = await getProvinceData(null);
        setProvinceData(response);
      } catch (error) {
        console.log("Error fetching getProvinceData:", error);
      }
    };

    const getAddress = async () => {
      try {
        const urlString = window.location.href;
        const dataUrlSplit = urlString.split("/");
        const addressID = dataUrlSplit[4].toString();

        const response = await getOneAddressDetailData(
          localStorage.getItem("token"),
          addressID
        );
        setAddressDataDefault(response.customer_address);
        setProvinceDataDefault(response.province);
        setCityDataDefault(response.district);
        setDistrictDataDefault(response.sub_district);
        if (response.customer_address.is_primary === true) {
          setDefault(true);
        }

        //         dispatch(getCityDataUser(data4));
        // dispatch(getDistrictDataUser(data4));

        try {
          const responseCity = await getCityData(
            `{"province_id":${response.province.id}}`
          );

          setCityData(responseCity);
        } catch (error) {
          console.log("Error fetching getCityData:", error);
        }

        try {
          const responseDistrict = await getDistrictData(
            `{"district_id":${response.district.id}}`
          );

          setDistrictData(responseDistrict);
        } catch (error) {
          console.log("Error fetching getDistrictData:", error);
        }
      } catch (error) {
        console.log("Error fetching getAddressDetailData:", error);
      }
    };

    getAddress();
    getProvince();
  }, []);
  // const AddressData = GetAddressResponse();
  // const data = AddressData
  //   ? AddressData.data.filter((ad) => ad.id == parameterQuery.id)
  //   : null;
  const [age, setAge] = React.useState("");
  const [name, setName] = React.useState();
  const [address, setAddress] = React.useState();
  const [provinceId, setProvinceId] = React.useState();
  const [provinceName, setProvinceName] = React.useState();
  const [isLoading, setLoading] = React.useState(false);
  const [CityId, setCityId] = React.useState();
  const [CityName, setCityName] = React.useState();
  const [districtId, setdistrictId] = React.useState();
  const [districtName, setdistrictName] = React.useState();
  const [postalCode, setPostalCode] = React.useState();
  const [phone, setPhone] = React.useState();
  const [isDefault, setDefault] = React.useState(false);
  // const [formdata, setFormData] = React.useState(addressDataDefault ? addressDataDefault : null);

  const onChangeName = (e) => {
    setAddressDataDefault({ ...addressDataDefault, name: e.target.value });
  };
  const onChangeAdddress = (e) => {
    setAddressDataDefault({ ...addressDataDefault, address: e.target.value });
  };
  const onChangePostCode = (e) => {
    setAddressDataDefault({
      ...addressDataDefault,
      postal_code: e.target.value,
    });
  };
  const onChangePhone = (e) => {
    setAddressDataDefault({ ...addressDataDefault, phone: e.target.value });
  };

  // const dispatch = useDispatch();
  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  // const getProvince = () => {
  //   let data12 = new FormData();
  //   dispatch(getProvinceDataUser(data12));
  // };
  const getDistric = async (e) => {
    // let data4 = new FormData();
    setAddressDataDefault({
      ...addressDataDefault,
      province_id: e.target.value,
    });
    // setAddressDataDefault({
    //   ...addressDataDefault,
    //   province_name: e.target[e.target.selectedIndex].text,
    // });
    // data4.append("province_id", e.target.value);
    // dispatch(getCityDataUser(data4));
    try {
      const response = await getCityData(`{"province_id":${e.target.value}}`);

      setCityData(response);
    } catch (error) {
      console.log("Error fetching getCityData:", error);
    }
  };
  const getSubDistrict = async (e) => {
    // let data5 = new FormData();
    setAddressDataDefault({
      ...addressDataDefault,
      district_id: e.target.value,
    });
    // setAddressDataDefault({
    //   ...addressDataDefault,
    //   city_name: e.target[e.target.selectedIndex].text,
    // });
    // data5.append("city_id", e.target.value);
    // dispatch(getDistrictDataUser(data5));
    try {
      const response = await getDistrictData(
        `{"district_id":${e.target.value}}`
      );

      setDistrictData(response);
    } catch (error) {
      console.log("Error fetching getDistrictData:", error);
    }
  };
  const subDistChange = (e) => {
    setAddressDataDefault({
      ...addressDataDefault,
      subdistrict_id: e.target.value,
    });
    // setAddressDataDefault({
    //   ...addressDataDefault,
    //   district_name: e.target[e.target.selectedIndex].text,
    // });
  };
  const OnChangeDefault = (e) => {
    if (e.target.checked) {
      setDefault(true);
    } else {
      setDefault(false);
    }
  };
  // React.useEffect(() => {
  //   getProvince();
  //   if (formdata.province_id) {
  //     setTimeout(() => {
  //       let data4 = new FormData();
  //     data4.append("province_id", formdata.province_id);
  //     dispatch(getCityDataUser(data4));
  //     }, 1000);

  //   }
  //   if (formdata.city_id) {
  //     setTimeout(() => {
  //       let data4 = new FormData();
  //     data4.append("city_id", formdata.city_id);
  //     dispatch(getDistrictDataUser(data4));
  //     }, 1500);

  //   }
  // }, []);
  const EditAddress = async () => {
    const userID = JSON.parse(localStorage.getItem("userid"));
    setLoading(true);
    const data = {
      address_id: addressDataDefault.id,
      customer_id: parseInt(userID),
      name: addressDataDefault.name,
      phone: addressDataDefault.phone,
      address: addressDataDefault.address,
      province_id: parseInt(addressDataDefault.province_id),
      district_id: parseInt(addressDataDefault.district_id),
      subdistrict_id: parseInt(addressDataDefault.subdistrict_id),
      postal_code: addressDataDefault.postal_code,
      // hardcode
      country_id: 62,
      village_id: 3201130010,
      latitude: -6.534398,
      longitude: 106.821891,
      note: "",
      label: "",
      is_primary: isDefault,
    };

    const response = await updateAddressData(
      data,
      localStorage.getItem("token")
    );
    if (response.success) {
      props.setNavigate("myAddress");
      setLoading(false);
    } else {
      console.log("error updateAddressData", response);
    }
  };
  const goBack = () => {
    props.setNavigate("myAddress");
    history.push(`/my-profile`);
  };
  // const ProvinceData = GetProvinceResponse();
  // const cityData = GetCityResponse();
  // const SubDistData = GetDistrictResponse();
  return (
    <div className={classes.body}>
      {console.log("addressDataDefault", addressDataDefault)}
      {addressDataDefault && Object.keys(addressDataDefault).length > 0 ? (
        <React.Fragment>
          <header>
            <ButtonCustom backArrow="true" onClick={goBack} />
            <h3 className={classes.title}>{t("edit_address")}</h3>
          </header>
          <Grid container spacing={2} className={classes.content}>
            <Input
              type="text"
              leftLabel={t("name")}
              className={classes.input_style}
              value={addressDataDefault.name}
              onChange={onChangeName}
            />
            <Input
              type="text"
              leftLabel={t("address")}
              className={classes.input_style}
              value={addressDataDefault.address}
              onChange={onChangeAdddress}
            />
            {/* <div style={{ width: "100%", display: "flex", gap: 15 }}>
              <p style={{ width: "32.4%" }}>Province</p>
              <FormControl variant="outlined" className={classes.input_style}>
                <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={age}
                  onChange={handleChange}
                  label="Age"
                  style={{width:330}}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </div> */}
            <div className={classes.input_leftTitle}>
              <p className={classes.label + "  label"}>{t("province")}</p>
              <select
                className={classes.select_style}
                onChange={getDistric}
                placeholder="test"
              >
                <option>{provinceDataDefault.name}</option>
                {provinceData.length > 0
                  ? provinceData.map((item) => {
                      return (
                        <option
                          value={item.id}
                          name={item.name}
                          // selected={dt.province_id == formdata.province_id}
                        >
                          {item.name}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
            <div className={classes.input_leftTitle}>
              <p className={classes.label + "  label"}>{t("district/city")}</p>
              <select
                className={classes.select_style}
                onChange={getSubDistrict}
              >
                <option>{cityDataDefault.name}</option>
                {cityData.length > 0
                  ? cityData.map((item) => {
                      return (
                        <option
                          value={item.id}
                          name={item.name}
                          // selected={ct.city_id == formdata.city_id}
                        >
                          {item.name}
                          {/* <h3>{ct.city_id == formdata.city_id ? ct.city_name : null}</h3> */}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
            <div className={classes.input_leftTitle}>
              <p className={classes.label + "  label"}>{t("subdistricts")}</p>
              <select className={classes.select_style} onChange={subDistChange}>
                <option>{districtDataDefault.name}</option>
                {districtData.length > 0
                  ? districtData.map((item) => {
                      return (
                        <option
                          value={item.id}
                          name={item.name}
                          // selected={sd.subdistrict_name == formdata.district_name}
                        >
                          {item.name}
                          {/* <h3>{sd.subdistrict_name == formdata.district_name ? sd.subdistrict_name : null}</h3> */}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>

            {/* <Input
              type="text"
              leftLabel="District / City"
              className={classes.input_style}
            />
            <Input
              type="text"
              leftLabel="Subdistricts"
              className={classes.input_style}
            /> */}
            <Input
              type="text"
              leftLabel={t("postcode")}
              className={classes.input_style}
              value={addressDataDefault.postal_code}
              onChange={onChangePostCode}
            />
            <Input
              type="text"
              leftLabel={t("phone_number")}
              className={classes.input_style}
              value={addressDataDefault.phone}
              onChange={onChangePhone}
            />
            <Grid item md={4}></Grid>
            <Grid item md={8}>
              <Input
                type="checkbox"
                label={t("set_as_default_address")}
                name="setDefault"
                id="setDefault"
                //   className={classes.input_style}
                onChange={OnChangeDefault}
              />
            </Grid>
            <Grid item md={4}></Grid>
            <Grid item md={8}>
              {/* {isLoading ? (
              <ClipLoader size={27} color="#ff3a59" />
            ) : ( */}
              <ButtonCustom
                onClick={EditAddress}
                disabled={isLoading ? true : false}
              >
                {t("edit_address")}
                {isLoading ? <LoaderDots /> : null}{" "}
              </ButtonCustom>
              {/* )} */}
            </Grid>
          </Grid>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default UpdateAddress;
