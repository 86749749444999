import React,{useState,useEffect} from "react";
import Header from "../header/header";
import Loader from "../loader";
import { ThemeColor1 } from "../themecolor/index1";

const Chat = () => {
  const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));
  const [open, setOpen] = useState(true);
  const userid = JSON.parse(localStorage.getItem("userid")); 
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 700);
  }, []);
  return (
    <React.Fragment>
      <Header title="Chat" color={ThemeColor} />
        <div
          style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'white'
          }}
        >
          {userid ? <h4>Coming Soon</h4> : <h4>No Message Found</h4>}
      </div>
    </React.Fragment>
  );
};

export default Chat;
