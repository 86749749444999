import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import ButtonCustom from "../../../../components/common/ButtonCustom";
import Input from "../../../../components/common/form/Input";
import { useStyles } from "./addAddress.style";
// import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { t } from "i18next";
import i18n from "../../../language/localization";
import {
  getProvince as getProvinceData,
  getCity as getCityData,
  getDistrict as getDistrictData,
} from "../../../../api/region";
import { create as addAddress } from "../../../../api/address";

const AddAddress = (props) => {
  const { webThemeColor, themeColor } = props;
  const classes = useStyles(props);
  const [age, setAge] = React.useState("");
  const [name, setName] = React.useState();
  const [address, setAddress] = React.useState();
  const [provinceId, setProvinceId] = React.useState();
  const [provinceName, setProvinceName] = React.useState();
  const [isLoading, setLoading] = React.useState(false);
  const [CityId, setCityId] = React.useState();
  const [CityName, setCityName] = React.useState();
  const [districtId, setdistrictId] = React.useState();
  const [districtName, setdistrictName] = React.useState();
  const [postalCode, setPostalCode] = React.useState();
  const [phone, setPhone] = React.useState();
  const [isDefault, setDefault] = React.useState(false);
  const [provinceData, setProvinceData] = React.useState([]);
  const [cityData, setCityData] = React.useState([]);
  const [districtData, setDistrictData] = React.useState([]);

  const onChangeName = (e) => {
    setName(e.target.value);
  };
  const onChangeAdddress = (e) => {
    setAddress(e.target.value);
  };
  const onChangePostCode = (e) => {
    setPostalCode(e.target.value);
  };
  const onChangePhone = (e) => {
    setPhone(e.target.value);
  };

  // const dispatch = useDispatch();
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const getDistric = async (e) => {
    let data4 = new FormData();
    setProvinceId(e.target.value);
    setProvinceName(e.target[e.target.selectedIndex].text);
    // data4.append("province_id", e.target.value);
    // dispatch(getCityDataUser(data4));
    try {
      const response = await getCityData(`{"province_id":${e.target.value}}`);

      setCityData(response);
    } catch (error) {
      console.log("Error fetching getCityData:", error);
    }
  };
  const getSubDistrict = async (e) => {
    let data5 = new FormData();
    setCityId(e.target.value);
    setCityName(e.target[e.target.selectedIndex].text);
    // data5.append("city_id", e.target.value);
    // dispatch(getDistrictDataUser(data5));
    try {
      const response = await getDistrictData(
        `{"district_id":${e.target.value}}`
      );

      setDistrictData(response);
    } catch (error) {
      console.log("Error fetching getDistrictData:", error);
    }
  };
  const subDistChange = (e) => {
    setdistrictId(e.target.value);
    setdistrictName(e.target[e.target.selectedIndex].text);
  };
  const OnChangeDefult = (e) => {
    if (e.target.checked) {
      setDefault(true);
    } else {
      setDefault(false);
    }
  };

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  }, []);

  React.useEffect(() => {
    const getProvince = async () => {
      try {
        const response = await getProvinceData(null);
        setProvinceData(response);
      } catch (error) {
        console.log("Error fetching getProvinceData:", error);
      }
    };
    getProvince();
  }, []);
  const saveAddress = async () => {
    const userID = JSON.parse(localStorage.getItem("userid"));
    // var formdata = new FormData();
    // formdata.append("user_id", userid);
    // formdata.append("name", name);
    // formdata.append("address", address);
    // formdata.append("province_id", provinceId);
    // formdata.append("province_name", provinceName);
    // formdata.append("city_id", CityId);
    // formdata.append("city_name", CityName);
    // formdata.append("district_id", districtId);
    // formdata.append("district_name", districtName);
    // formdata.append("postal_code", postalCode);
    // formdata.append("phone", phone);
    // formdata.append("as_default", isDefault);
    // dispatch(addAddressUser(formdata));
    // setLoading(true)
    // setTimeout(() => {
    //   props.setNavigate("myAddress")
    //   setLoading(false)
    // }, 500);
    setLoading(true);
    const data = {
      customer_id: parseInt(userID),
      name: name,
      phone: phone,
      address: address,
      province_id: parseInt(provinceId),
      district_id: parseInt(CityId),
      subdistrict_id: parseInt(districtId),
      postal_code: postalCode,
      // hardcode
      country_id: 62,
      village_id: 3201130010,
      latitude: -6.534398,
      longitude: 106.821891,
      note: "",
      label: "",
      is_primary: isDefault,
    };

    const response = await addAddress(data, localStorage.getItem("token"));
    if (response.success) {
      props.setNavigate("myAddress");
      setLoading(false);
    } else {
      console.log("error addAddress", response);
    }
  };
  // const ProvinceData=GetProvinceResponse()
  // const cityData=GetCityResponse()
  // const SubDistData = GetDistrictResponse();
  return (
    <div className={classes.body}>
      <header>
        <ButtonCustom
          backArrow="true"
          onClick={() => props.setNavigate("myAddress")}
        />
        <h3 className={classes.title}>{t("add_new_address")}</h3>
      </header>
      <Grid container spacing={2} className={classes.content}>
        <Input
          type="text"
          leftLabel={t("name")}
          className={classes.input_style}
          value={name}
          onChange={onChangeName}
        />
        <Input
          type="text"
          leftLabel={t("address")}
          className={classes.input_style}
          value={address}
          onChange={onChangeAdddress}
        />
        {/* <div style={{ width: "100%", display: "flex", gap: 15 }}>
          <p style={{ width: "32.4%" }}>Province</p>
          <FormControl variant="outlined" className={classes.input_style}>
            <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={age}
              onChange={handleChange}
              label="Age"
              style={{width:330}}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </div> */}
        <div className={classes.input_leftTitle}>
          <p className={classes.label + "  label"}>{t("province")}</p>
          <select className={classes.select_style} onChange={getDistric}>
            <option>{t("select_province")}</option>
            {provinceData.length > 0
              ? provinceData.map((item) => {
                  return (
                    <option value={item.id} name={item.name}>
                      {item.name}
                    </option>
                  );
                })
              : null}
          </select>
        </div>
        <div className={classes.input_leftTitle}>
          <p className={classes.label + "  label"}>{t("district/city")}</p>
          <select className={classes.select_style} onChange={getSubDistrict}>
            <option>{t("district/city")}</option>
            {cityData.length > 0
              ? cityData.map((item) => {
                  return (
                    <option value={item.id} name={item.name}>
                      {item.name}
                    </option>
                  );
                })
              : null}
          </select>
        </div>
        <div className={classes.input_leftTitle}>
          <p className={classes.label + "  label"}>{t("subdistricts")}</p>
          <select className={classes.select_style} onChange={subDistChange}>
            <option>{t("subdistricts")}</option>
            {districtData.length > 0
              ? districtData.map((item) => {
                  return (
                    <option value={item.id} name={item.name}>
                      {item.name}
                    </option>
                  );
                })
              : null}
          </select>
        </div>

        {/* <Input
          type="text"
          leftLabel="District / City"
          className={classes.input_style}
        />
        <Input
          type="text"
          leftLabel="Subdistricts"
          className={classes.input_style}
        /> */}
        <Input
          type="text"
          leftLabel={t("postcode")}
          className={classes.input_style}
          value={postalCode}
          onChange={onChangePostCode}
        />
        <Input
          type="text"
          leftLabel={t("phone_number")}
          className={classes.input_style}
          value={phone}
          onChange={onChangePhone}
        />
        <Grid item md={4}></Grid>
        <Grid item md={8}>
          <Input
            type="checkbox"
            label={t("set_as_default_address")}
            name="setDefault"
            id="setDefault"
            //   className={classes.input_style}
            onChange={OnChangeDefult}
          />
        </Grid>
        <Grid item md={4}></Grid>
        <Grid item md={8}>
          {isLoading ? (
            <ClipLoader size={27} color="#ff3a59" />
          ) : (
            <ButtonCustom bg={webThemeColor} onClick={saveAddress}>
              {t("add_new_address")}
            </ButtonCustom>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default AddAddress;
