import React, { useState, useEffect } from "react";
import Header from "../header/header2";
// import { GetWishList } from "../../store/actions/GetWishlist";
// import { addWishlistProductUser } from "../../store/actions/addWishlistActions";
// import { GetWishlistSelector } from "../../store/Selectors/GetWishlistSelector";
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import ClipLoader from "react-spinners/ClipLoader";
import Loader from "../loader";
import { useStyles1 } from "../themecolor/FontSize";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core";
import { withNamespaces } from "react-i18next";
import i18n from "../language/localization";

const useStyles = makeStyles((theme) => ({
  // Main: {
  //   width: "100%",
  //   height: "220px"
  // },

  cards_style: {
    "& .card:hover": {
      // border: "1px solid #ff3a59 !important",
    },
  },
  carwishitle: {
    marginBottom: "3px",
    "& .read-more-less--more": {
      display: "none",
    },
  },

  mobile_slider_cards: {
    "& .card-body": {
      // minHeight: '117px',
      padding: "5px 2px 0px 6px !important",
    },
    "& .mainImageThum": {
      height: "200px",
    },
    "& .carwishitle ": {
      //  textTransform: 'uppercase',
      fontSize: "13px !important",
      // wordBreak: "break-all",
    },
    "& .TowLineText ": {
      height: "38px",
    },
  },
}));

const Wishlist = ({ t }) => {
  const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));
  // const dispatch = useDispatch();
  const history = useHistory();
  // const [true1, setTrue1] = useState(true);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  // const [productid, setProductId] = useState(false);
  // const usercode = JSON.parse(localStorage.getItem("usercode"));
  const classes1 = useStyles1();
  const classes = useStyles();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));

  // const getWishlist = () => {
  //   let data = new FormData();
  //   data.append("user_code", usercode);
  //   // dispatch(GetWishList(data));
  // };
  useEffect(() => {
    // getWishlist();
    // setTimeout(() => {
    //   setTrue1(true);
    // }, 100);
    const timer = setTimeout(() => {
      setOpen(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  // const deleteWish = (e) => {
  //   setProductId(e);
  //   setOpen1(true);
  // };
  const handleClose = () => {
    setOpen1(false);
  };
  const handleClose1 = () => {
    // setOpen1(false);
    // let data1 = new FormData();
    // data1.append("product_id", productid);
    // data1.append("user_code", usercode);
    // data1.append("date_time", "2018-12-25 10:23:00");
    // // dispatch(addWishlistProductUser(data1));
    setOpen2(true);
    // setTimeout(() => (setOpen2(false), getWishlist()), 100);
  };

  const getSingleProduct = (e) => {
    // const usercode = JSON.parse(localStorage.getItem("usercode"));
    // const userid = JSON.parse(localStorage.getItem("userid"));
    // localStorage.setItem("productsid",JSON.stringify([e]));
    // var data3 = new FormData();
    // data3.append("product_id", e);
    // data3.append("user_id", userid);
    // data3.append("user_code", usercode);
    // dispatch(singleProductDataUser(data3));
    history.push(`/productdetail?${e}`);
  };

  const res = {}
  console.log("res:",res)

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  }, []);

  return (
    <div>
     
        <div>
          <Header
            title="Wishlist"
            color={ThemeColor ? ThemeColor : "#FFFFFF"}
          />
          <div style={{ paddingTop: "50px" }}>
            {open ? (
              res ? (
                res.data ? (
                res.data.length > 0 ? (
                  <div
                    className={classes.cards_style}
                    style={{
                      width: "100%",
                      display: "flex",
                      overflow: "auto",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      marginLeft: "0px",
                      marginRight: "0px",
                      marginTop: "9px",
                      paddingLeft: "0px",
                      paddingRight: "1px",
                      // height: find ? "auto" : "750px",
                      height: "750px",
                      clear: "both",
                      paddingBottom: Mobile ? "0px" : "50px",
                    }}
                  >
                    {res.data.map((wish, index) => {
                      return (
                        <div
                          style={{
                            width: Mobile?"50%":"28%",
                            paddingLeft: "5px",
                            marginBottom: (index + 1 === res.data.length && res.data.length>=6?"60px":"6px"),
                          }}
                          key={index}
                        >
                          <div
                            className="card border-0 shadow-card"
                            style={{
                              // minHeight: "248px",
                              minHeight: "100%",
                            }}
                          >
                            <React.Fragment>
                              <img
                                src={wish.main_image !== "" ? wish.main_image : "/assets/images/dummy.png"}
                                className="card-img-top"
                                style={{
                                  position: "relative",
                                  height: "170px",
                                    // Mobile ? wish.name.length < 20 ? "182px" : "164px"
                                    //   :
                                    //   wish.name.length < 26 ? "210px" : "190px",
                                  width: "100%"
                                  
                                }}
                                alt=""
                                // height={
                                //   Mobile
                                //     ? Object.keys(wish.name).length > 18
                                //       ? "170px"
                                //       : "180px"
                                //     : Object.keys(wish.name).length > 18
                                //       ? "200px"
                                //       : "220px"
                                // }
                                // width="100%"
                                onClick={() => {
                                  getSingleProduct(wish.id);
                                }}
                              />
                              {wish.stock_status === 'Sold Out' ? (
                                <div
                                  className="position-absolute d-flex justify-content-center align-items-center rounded-circle"
                                  style={{
                                    width: Mobile ? "5rem" : "8rem",
                                    height: Mobile ? "5rem" : "8rem",
                                    top: Mobile ? "47px" : "53px",
                                    left: Mobile ? "45px" : "56px",
                                    fontSize: "14px",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                  }}
                                >
                                  <span className="text-light">{t("sold_out")}</span>
                                </div>
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                            {wish.is_wishlist === 1 ? (
                              <img src="/assets/frontend/img/wishlist_fav.png" height="22px" style={{ display: "flex", justifyContent: "flex-end", top: 0, position: "absolute", cursor: "pointer", marginTop: "10px", marginLeft: "10px" }} alt="" 
                              // onClick={() => {
                              //   addWishWhite(wish.id, index)
                              // }} 
                              />
                            ) : (
                              <img src="/assets/frontend/img/wishlist_list.png" height="22px" style={{ display: "flex", justifyContent: "flex-end", top: 0, position: "absolute", cursor: "pointer", marginTop: "10px", marginLeft: "10px" }} alt="" 
                              // onClick={() => {
                              //   addWishRed(wish.id, index)
                              // }} 
                              />
                            )}
                            <div
                              style={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                              }}
                            >
                              {wish.new_product === "1" ? (
                                <div
                                  style={{
                                    background: " #fdd535",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#fff",
                                    fontSize: "12px",
                                    width: "58px",
                                    height: "20px",
                                    borderRadius: "3px",
                                    marginTop: "1px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {t("new1")}
                                </div>
                              ) : (
                                ""
                              )}
                              {wish.product_wholesale === 1 ? (
                                <div
                                  style={{
                                    background: "#00BFFF",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#fff",
                                    fontSize: "11px",
                                    width: "58px",
                                    height: "20px",
                                    borderRadius: "3px",
                                    marginBottom: "5px",
                                    marginTop: "1px",
                                  }}
                                >
                                  {t("wholesale")}
                                </div>
                              ) : (
                                ""
                              )}
                              {wish.product_cod === "1" ? (
                                <div
                                  style={{
                                    background: "#49d16e",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#fff",
                                    fontSize: "12px",
                                    width: "58px",
                                    height: "20px",
                                    borderRadius: "3px",
                                    marginTop: "1px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {t("COD")}
                                </div>
                              ) : (
                                ""
                              )}
                              {wish.percent_discount !== "0" ? (
                                <div
                                  style={{
                                    background: "red",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#fff",
                                    fontSize: "12px",
                                    width: "58px",
                                    height: "20px",
                                    borderRadius: "3px",
                                    marginTop: "1px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {wish.percent_discount}%
                                </div>
                              ) : (
                                ""
                              )}
                              {wish.product_preorder === "1" ? (
                                <div
                                  style={{
                                    background: "orange",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#fff",
                                    fontSize: "10px",
                                    width: "58px",
                                    height: "20px",
                                    borderRadius: "3px",
                                    marginTop: "1px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  PO
                                </div>
                              ) : (
                                ""
                              )}
                              {wish.best_seller === "1" ? (
                                <div
                                  style={{
                                    background: "red",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "yellow",
                                    fontSize: "10px",
                                    width: "58px",
                                    height: "20px",
                                    borderRadius: "3px",
                                    marginTop: "1px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {t("best_seller1")}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className="card-body p-2"
                            >
                              <p className={classes1.textfont}>
                              {wish.name}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  height: "22px",
                                  marginTop: '5px'
                                }}
                              >
                                <div
                                  className={classes1.pricediv}
                                  style={{
                                    whiteSpace: "nowrap",
                                    marginRight: "10px",
                                  }}
                                >
                                  <b>
                                    {Object.keys(wish.price.toString()).length >
                                      0 ? (
                                      Object.keys(wish.price.toString()).length <=
                                        3 ? (
                                        <p style={{ marginBottom: "0px" }}>
                                          Rp {wish.price.toString()}
                                        </p>
                                      ) : Object.keys(wish.price.toString())
                                        .length === 4 ? (
                                        <p style={{ marginBottom: "0px" }}>
                                          Rp {wish.price.toString().substr(-4, 1)}.
                                          {wish.price.toString().substr(-3, 3)}
                                        </p>
                                      ) : Object.keys(wish.price.toString())
                                        .length === 5 ? (
                                        <p style={{ marginBottom: "0px" }}>
                                          Rp {wish.price.toString().substr(-5, 2)}.
                                          {wish.price.toString().substr(-3, 3)}
                                        </p>
                                      ) : Object.keys(wish.price.toString())
                                        .length === 6 ? (
                                        <p style={{ marginBottom: "0px" }}>
                                          Rp {wish.price.toString().substr(-6, 3)}.
                                          {wish.price.toString().substr(-3, 3)}
                                        </p>
                                      ) : Object.keys(wish.price.toString())
                                        .length === 7 ? (
                                        <p style={{ marginBottom: "0px" }}>
                                          Rp {wish.price.toString().substr(-7, 1)}.
                                          {wish.price.toString().substr(-6, 3)}.
                                          {wish.price.toString().substr(-3, 3)}
                                        </p>
                                      ) : Object.keys(wish.price.toString())
                                        .length === 8 ? (
                                        <p style={{ marginBottom: "0px" }}>
                                          Rp {wish.price.toString().substr(-8, 2)}.
                                          {wish.price.toString().substr(-6, 3)}.
                                          {wish.price.toString().substr(-3, 3)}
                                        </p>
                                      ) : Object.keys(wish.price.toString())
                                        .length === 9 ? (
                                        <p style={{ marginBottom: "0px" }}>
                                          Rp {wish.price.toString().substr(-9, 3)}.
                                          {wish.price.toString().substr(-6, 3)}.
                                          {wish.price.toString().substr(-3, 3)}
                                        </p>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </b>
                                </div>
                                <div>
                                  <b>
                                    {wish.selling_price ===
                                      JSON.parse(wish.price) ? (
                                      ""
                                    ) : Object.keys(wish.selling_price.toString())
                                      .length > 0 ? (
                                      Object.keys(wish.selling_price.toString())
                                        .length === 4 ? (
                                        <p
                                          className={classes1.cutdiv}
                                        >
                                          Rp{" "}
                                          {wish.selling_price.toString().substr(-4, 1)}
                                          .
                                          {wish.selling_price.toString().substr(-3, 3)}
                                        </p>
                                      ) : Object.keys(wish.selling_price.toString())
                                        .length === 5 ? (
                                        <p
                                          className={classes1.cutdiv}
                                        >
                                          Rp{" "}
                                          {wish.selling_price.toString().substr(-5, 2)}
                                          .
                                          {wish.selling_price.toString().substr(-3, 3)}
                                        </p>
                                      ) : Object.keys(wish.selling_price.toString())
                                        .length === 6 ? (
                                        <p
                                          className={classes1.cutdiv}
                                        >
                                          Rp{" "}
                                          {wish.selling_price.toString().substr(-6, 3)}
                                          .
                                          {wish.selling_price.toString().substr(-3, 3)}
                                        </p>
                                      ) : Object.keys(wish.selling_price.toString())
                                        .length === 7 ? (
                                        <p
                                          className={classes1.cutdiv}
                                        >
                                          Rp{" "}
                                          {wish.selling_price.toString().substr(-7, 1)}
                                          .
                                          {wish.selling_price.toString().substr(-6, 3)}
                                          .
                                          {wish.selling_price.toString().substr(-3, 3)}
                                        </p>
                                      ) : Object.keys(wish.selling_price.toString())
                                        .length === 8 ? (
                                        <p
                                          className={classes1.cutdiv}
                                        >
                                          Rp{" "}
                                          {wish.selling_price.toString().substr(-8, 2)}
                                          .
                                          {wish.selling_price.toString().substr(-6, 3)}
                                          .
                                          {wish.selling_price.toString().substr(-3, 3)}
                                        </p>
                                      ) : Object.keys(wish.selling_price.toString())
                                        .length === 9 ? (
                                        <p
                                          className={classes1.cutdiv}
                                        >
                                          Rp{" "}
                                          {wish.selling_price.toString().substr(-9, 3)}
                                          .
                                          {wish.selling_price.toString().substr(-6, 3)}
                                          .
                                          {wish.selling_price.toString().substr(-3, 3)}
                                        </p>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      paddingTop: "250px",
                      paddingBottom: "300px",
                      height:"100vh",
                      backgroundColor: "white"
                    }}
                  >
                    No Product Found
                  </div>
                )
              ) : (
                <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      paddingTop: "250px",
                      paddingBottom: "300px",
                      height:"100vh",
                      backgroundColor: "white"
                    }}
                  >
                    No Product Found
                  </div>
              )
              ) : null
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "250px",
                  paddingBottom: "300px",
                  flexDirection: "column",
                  height: "100vh",
                  backgroundColor: "white",
                }}
              >
                <div>
                  <Loader />
                </div>
                <div>Loading...</div>
              </div>
            )}
          </div>
        </div>
      
      {open1 ? (
        <div>
          <Dialog
            open={open1}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ paddingBottom: "0px" }}
            >
              <b>Remove passions?</b>
            </DialogTitle>
            <DialogContent
              id="alert-dialog-description"
              style={{ marginBottom: "0px" }}
            >
              <b>The product will disappear from the wishlist</b>
            </DialogContent>
            <DialogActions style={{ paddingTop: "0px" }}>
              <Button
                onClick={handleClose}
                style={{
                  color: ThemeColor ? ThemeColor : "#FFFFFF",
                  fontWeight: "500",
                }}
              >
                CANCEL
              </Button>
              <Button
                onClick={handleClose1}
                style={{
                  color: ThemeColor ? ThemeColor : "#FFFFFF",
                  fontWeight: "500",
                }}
              >
                DELETE
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        ""
      )}
      {open2 ? (
        <div>
          <Dialog
            open={open2}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              id="alert-dialog-description"
              style={{
                color: "black",
                fontWeight: "500",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "12px 24px",
              }}
            >
              <div style={{ marginRight: "14px" }}>
                <ClipLoader
                  color={ThemeColor ? ThemeColor : "#FFFFFF"}
                  height={50}
                  style={{
                    paddingLeft: "1px",
                    marginTop: "8px",
                    fontSize: "4",
                  }}
                />{" "}
              </div>
              <div>Loading ...</div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default withNamespaces()(Wishlist);
