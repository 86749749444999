import React, { useState, useEffect, useRef } from "react";
import Header from "../header/header2";
import {
  IconButton,
  InputAdornment,
  makeStyles,
  styled,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
// import { changePasswordUser } from "../../store/actions/ChangePassActions";
// import { ChangePassResponse } from "../../store/Selectors/changepass";
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ClipLoader from "react-spinners/ClipLoader";
import Button from "@material-ui/core/Button";
import { withNamespaces } from "react-i18next";
import i18n from "../language/localization";
import Loader from "../loader";
import { updatePassword } from "../../api/authorization";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const IconButtonStyled = styled(IconButton)({
  padding: "0px !important",
  marginRight: "-4px !important",
});

const ChangePassword = ({ t }) => {
  const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));

  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : "#FFFFFF",
      },
    },
  });
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100%",
      // backgroundColor: theme.palette.background.paper
    },
    filterBox: {
      border: "0.125rem solid #fff",
      backgroundColor: "#fff",
      margin: "12px 8px 12px 8px",
    },
    buttonColor: {
      "&> .finish-btn": {
        height: "2.7rem",
        backgroundColor: `${ThemeColor ? ThemeColor : "#FFFFFF"} !important`,
      },
    },
  }));
  const classes = useStyles();
  // const dispatch = useDispatch();

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  const [pass, setPass] = useState({
    old: "",
    new: "",
    cnew: "",
  });
  const [passErr, setPassErr] = useState({
    oldErr: "",
    newErr: "",
    cnewErr: "",
  });
  const imageRef = useRef();
  const [match, setMatch] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [resultUpdatePassword, setResultUpdatePassword] = useState({});
  const handleChange = (e) => {
    setPass({ ...pass, [e.target.name]: e.target.value });
  };
  // const datas = ChangePassResponse();
  // const changePass = () => {
  //   if (pass.new !== "" && pass.cnew !== "", pass.old !== "") {
  //     if (pass.new == pass.cnew) {
  //       setMatch(false)
  //       const userid = JSON.parse(localStorage.getItem("userid"));
  //       let data = new FormData();
  //       data.append('user_id', userid);
  //       data.append('old_password', pass.old);
  //       data.append('new_password', pass.new);
  //       dispatch(changePasswordUser(data));
  //       setOpen(true);
  //       setTimeout(() => (setOpen(false), setOpen2(true)), 1000);
  //     }
  //     else {
  //       setMatch(true)
  //     }
  //   } else {
  //     setPassErr({
  //       ...passErr,
  //       oldErr: "Please enter old password.",
  //       newErr: "Please enter new password.",
  //       cnewErr: "Please enter retype password."
  //     })
  //   }
  // }
  const changePass = async () => {
    if (pass.new == pass.cnew) {
      setMatch(false);
      const data = {
        password: pass.new,
      };
      const response = await updatePassword(
        data,
        localStorage.getItem("token")
      );
      if (response.success) {
        setResultUpdatePassword(response);
        setOpen(false);
        setOpen2(true);
      }
    } else {
      setMatch(true);
    }
  };

  const handleclose = () => {
    setOpen2(false);
  };

  const submit = () => {
    //
  };

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  }, []);

  const handleClickEditPassword = () => {
    imageRef.current.click();
    changePass();
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <Header title={t("change_password1")} color={ThemeColor} />
        <div style={{ paddingTop: "46px" }}>
          <ThemeProvider theme={theme}>
            <div className={classes.root}>
              <div
                className={`${classes.filterBox} p-0 border-0 shadow-card card d-flex align-items-center justify-content-center`}
              >
                <ValidatorForm className="w-100" onSubmit={submit}>
                  {/* <Grid item xs={12} style={{ margin: "15px 15px" }}>
                        <TextValidator
                          label={t("old_password")}
                          fullWidth={true}
                          onChange={handleChange}
                          name="old"
                          value={pass.old}
                          variant="standard"
                          validators={["required"]}
                          errorMessages={["Please enter old password."]}
                          InputLabelProps={{ style: { fontSize: 15 } }}
                        />
                        
                      </Grid> */}

                  <Grid item xs={12} style={{ margin: "15px 15px" }}>
                    <TextValidator
                      label={t("new_password")}
                      fullWidth={true}
                      onChange={handleChange}
                      name="new"
                      type={showPassword ? "text" : "password"}
                      value={pass.new}
                      variant="standard"
                      validators={["required"]}
                      errorMessages={["Please enter new password."]}
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButtonStyled
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButtonStyled>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ margin: "15px 15px" }}>
                    <TextValidator
                      label={t("retype_new_password")}
                      fullWidth={true}
                      onChange={handleChange}
                      name="cnew"
                      type={showNewPassword ? "text" : "password"}
                      value={pass.cnew}
                      variant="standard"
                      validators={["required"]}
                      errorMessages={["Please enter retype password."]}
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButtonStyled
                              aria-label="toggle password visibility"
                              onClick={handleClickShowNewPassword}
                              onMouseDown={handleMouseDownNewPassword}
                              edge="end"
                            >
                              {showNewPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButtonStyled>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* {
                    pass.cnew==""?<div style={{color:"#E70074", fontSize: "13px"}}>{passErr.cnewErr}</div>:""
                  } */}
                    {match ? (
                      <div
                        style={{
                          color: ThemeColor ? ThemeColor : "#FFFFFF",
                          fontSize: "13px",
                        }}
                      >
                        Password and retype password does not match
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <button style={{ display: "none" }} ref={imageRef}>
                    Ok
                  </button>
                </ValidatorForm>
              </div>
            </div>
            <div className={classes.buttonColor + " " + " finish"}>
              <button
                className="finish-btn mt-1 text-uppercase"
                onClick={handleClickEditPassword}
              >
                {t("update")}
              </button>
            </div>
          </ThemeProvider>
        </div>
      </React.Fragment>
      {open ? (
        <div>
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              id="alert-dialog-description"
              style={{
                color: "black",
                fontWeight: "500",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "12px 24px",
              }}
            >
              <div style={{ marginRight: "14px" }}>
                <ClipLoader
                  color={ThemeColor ? ThemeColor : "#FFFFFF"}
                  height={50}
                  style={{
                    paddingLeft: "1px",
                    marginTop: "8px",
                    fontSize: "4",
                  }}
                />{" "}
              </div>
              <div>Loading ...</div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ""
      )}
      {open2 ? (
        <div>
          <Dialog
            open={open2}
            onClick={handleclose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              id="alert-dialog-description"
              style={{
                color: "black",
                fontWeight: "500",
              }}
            >
              {resultUpdatePassword.message}
              <br />
              <Button
                onClick={handleclose}
                style={{
                  color: ThemeColor ? ThemeColor : "#FFFFFF",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                Ok
              </Button>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withNamespaces()(ChangePassword);
