export const showPricing = (prices, membershipLevel) => {
  return prices[membershipLevel] || prices[1];
};

export const isDiscountValid = (
  discount,
  discountStartDate,
  discountEndDate
) => {
  const currentDate = new Date();

  if (discount && discount > 0) {
    if (!discountStartDate && !discountEndDate) {
      return true; // => discount is true
    } else if (
      new Date(discountStartDate) < currentDate &&
      new Date(discountEndDate) > currentDate
    ) {
      return true; // => discount is true
    } else {
      return false;
    }
  }

  return false;
};
