import React, { useState, useEffect, useRef } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { ThemeProvider } from "@material-ui/core/styles";
import { styled } from "@material-ui/core/styles";
// import { DashboardResponse } from "../../store/Selectors/dashboard";
import Skeleton from "@material-ui/lab/Skeleton";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
// import { getProductData } from "../../store/actions/GetProductdataActions";
// import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import i18n from "../language/localization";
import $ from "jquery";

import useMediaQuery from "@material-ui/core/useMediaQuery";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <React.Fragment>
          <Box>{children}</Box>
        </React.Fragment>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const styles = makeStyles({
  myDiv: {
    background: "white",
  },
  myButton: {
    backgroundColor: "white",
  },
});

function ScrollableTabsButtonAuto(props) {
  const {categoriesData} = props
  // const tabdata = JSON.parse(localStorage.getItem("tabs"));
  const [images, setImages] = useState("");
  // const data = DashboardResponse();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [catId, setCatId] = React.useState();
  const history = useHistory();
  const url_str = window.location.href;
  const data4 = url_str.split("/");
  const [Page, setPage] = useState(false);
  const valser = data4[3].toString();

  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  // const dispatch = useDispatch();
  useEffect(() => {
    if (valser === "productlist") {
      const vals = JSON.parse(localStorage.getItem("dastab"));
      if (vals > 2) {
        $("#content").animate({
          scrollLeft: `+=${vals - 1}00px`,
        });
      }
      setTimeout(
        () => setValue(vals),
        // localStorage.removeItem("dastab")
        200
      );
      // setValue(vals);
      // if(vals==null){
      //   setValue(tabsval);
      // }
    }
    GetPagination(props.id);
    //  console.log(props.id)
  }, [props.id]);

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  }, []);

  const GetPagination = (e) => {
    if (e > 0 && e < 3) {
      const usercode = JSON.parse(localStorage.getItem("usercode"));
      var data3 = new FormData();
      data3.append("page", e);
      data3.append("category", "150");
      data3.append("user_code", usercode);
      // dispatch(getProductData(data3));
    }
  };
  const getProduct = (e) => {
    const usercode = JSON.parse(localStorage.getItem("usercode"));
    var data3 = new FormData();
    data3.append("page", "1");
    data3.append("category", e);
    data3.append("user_code", usercode);
    // dispatch(getProductData(data3));
  };



  const tabCenter = (event) => {
    // console.log(value,event);
    if (event !== 2 || (value !== 1 && value !== 0)) {
      if (event > value) {
        $("#content").animate({
          scrollLeft: "+=100px",
        });
        setValue(event);
        // console.log("right");
      } else {
        $("#content").animate({
          scrollLeft: "-=100px",
        });
        setValue(event);
        // console.log("left");
      }
      // console.log("if part",value,event);
    } else {
      // console.log("else part",value,event);
      setValue(event);
    }
  };
  // };
  const landing = JSON.parse(localStorage.getItem("Lanpage"));
  return (
    <ThemeProvider theme={theme}>
       
      <Box className="text-white" sx={{ maxWidth: Mobile?500:'100%' }}>
        {categoriesData!=null ? (
          <div id="content" 
            style={{ backgroundColor: props.color,
            width: '100%',
            height: '48px',
            overflow: 'auto',
            whiteSpace: 'nowrap',
            color: 'white',
            position: 'sticky',
            cursor: 'pointer', textAlign: 'center', }}>
            <div
              style={{
                borderBottom: 0 == value ? "2px solid" : "",
                fontWeight: 0 == value ? "600" : "400",
                textTransform: "uppercase",
                height: '100%',
                width: "85px",
                display: 'inline-block',
                paddingTop: '16px',
                fontSize: '13px',
                cursor: 'pointer',
                userSelect: 'none',
                marginLeft:'5px',
                marginRight:'5px'
              }}
              onClick={() => {
                // if (landing&&landing.landing_page == 1) {
                //   history.push("/dashboard");
                //   tabCenter(0);
                //   setValue(0);
                // } else {
                //   history.push("/");
                //   tabCenter(0);
                //   setValue(0);
                // }
                history.push("/");
                tabCenter(0);
                setValue(0);

              }}
            >
              {props.t("home")}
            </div>
            {categoriesData.map((item, index) => {
              return (
                <div
                  key={index}
                  id={index}
                  style={{
                    borderBottom: index + 1 == value ? "2px solid" : "",
                    fontWeight: index + 1 == value ? "600" : "400",
                    textTransform: "uppercase",
                    height: '100%',
                    // width: (item.name.length<4 ? "70px" : item.name.length<6 ? "80px" : item.name.length<8 ? "85px" : item.name.length<12 ? "105px" : item.name.length<15 ? "115px" : item.name.length<18 ? "125px" : item.name.length<21 ? "135px" : "145px"),
                    width: (item.name.length*10),
                    display: 'inline-block',
                    paddingTop: '16px',
                    fontSize: '13px',
                    cursor: 'pointer',
                    userSelect: 'none',
                    marginLeft:'5px',
                    marginRight:'5px'
                  }}
                  onClick={() => {
                    tabCenter(index + 1);
                    history.push("/product-list");
                    localStorage.setItem("tabval", JSON.stringify(index + 1));
                    localStorage.setItem("dastab", JSON.stringify(index + 1));
                    getProduct(item.id);
                    setCatId(item.id);
                    localStorage.setItem("CatId", item.id);
                    setValue(index + 1);
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        ) : categoriesData!=null ? (
          <div id="content" style={{ backgroundColor: props.color,
            width: '100%',
            height: '48px',
            overflow: 'auto',
            whiteSpace: 'nowrap',
            color: 'white',
            position: 'sticky',
            cursor: 'pointer',textAlign: 'center', }}>
            <div
              style={{
                borderBottom: 0 == value ? "2px solid" : "",
                fontWeight: 0 == value ? "600" : "400",
                textTransform: "uppercase",
                height: '100%',
                width: "85px",
                display: 'inline-block',
                paddingTop: '16px',
                fontSize: '13px',
                cursor: 'pointer',
                userSelect: 'none',
                marginLeft:'5px',
                marginRight:'5px'
              }}
              onClick={() => {
                history.push("/");
                setValue(0);
                tabCenter(0);
              }}
            >
              {props.t("home")}
            </div>
            {categoriesData.map((item, index) => {
              return (
                <div
                  key={index}
                  id={index}
                  style={{
                    borderBottom: index + 1 == value ? "2px solid" : "",
                    fontWeight: index + 1 == value ? "600" : "400",
                    textTransform: "uppercase",
                    height: '100%',
                    // width: (item.name.length<4 ? "70px" : item.name.length<6 ? "80px" : item.name.length<8 ? "85px" : item.name.length<12 ? "105px" : item.name.length<15 ? "115px" : item.name.length<18 ? "125px" : item.name.length<21 ? "135px" : "145px"),
                    width: (item.name.length*10),
                    display: 'inline-block',
                    paddingTop: '16px',
                    fontSize: '13px',
                    cursor: 'pointer',
                    userSelect: 'none',
                    marginLeft:'5px',
                    marginRight:'5px'
                  }}
                  onClick={() => {
                    tabCenter(index + 1);
                    history.push("/product-list");
                    localStorage.setItem("tabval", JSON.stringify(index + 1));
                    localStorage.setItem("dastab", JSON.stringify(index + 1));
                    getProduct(item.id);
                    setCatId(item.id);
                    localStorage.setItem("CatId", item.id);
                    setValue(index + 1);
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        ) : (
          <Paper>
            <Skeleton variant="rect" height={200} />
          </Paper>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default withNamespaces()(ScrollableTabsButtonAuto);
