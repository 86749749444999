import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStyles } from "./myAddress.style";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ButtonCustom from "../../../../components/common/ButtonCustom";
import Input from "../../../../components/common/form/Input";
import DialogAlert from "../../../../components/common/DialogAlert";
// import {
//   deleteAddressUser,
//   getAddressUser,
//   setDefaultAddressUser,
// } from "../../../../store/actions/GetAddressActions";
// import { GetAddressResponse } from "../../../../store/Selectors/getaddress";
// import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { useHistory } from "react-router";
import { t } from "i18next";
import i18n from "../../../language/localization";
import {
  get as getAddress,
  deleteData as deleteAddressData,
} from "../../../../api/address";

const MyAddress = (props) => {
  const { webThemeColor, themeColor } = props;
  const classes = useStyles(props);
  // const dispatch = useDispatch();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [SetDefaultOpen, setSetDefaultOpen] = React.useState(false);
  const [isEffect, setEffect] = React.useState(true);
  const [isLoading, setLoading] = React.useState(false);
  const [AddressId, setAddressId] = React.useState();
  const [myIndex, setIndex] = React.useState();
  const history = useHistory();
  // const getData = () => {
  //   const userid = JSON.parse(localStorage.getItem("userid"));
  //   let data = new FormData();
  //   data.append("user_id", userid);
  //   dispatch(getAddressUser(data));
  // };
  // const AddressData = GetAddressResponse();
  const [addressDatas, setAddressDatas] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  }, []);

  useEffect(() => {
    if (isEffect) {
      // getData();
      const getAddressData = async () => {
        const userID = JSON.parse(localStorage.getItem("userid"));
        try {
          const response = await getAddress(
            localStorage.getItem("token"),
            `{"customer_id":${userID}}`
          );
          setAddressDatas(response);
        } catch (error) {
          console.log("Error fetching getAddress:", error);
        }
      };

      getAddressData();
    }
    return () => {
      setEffect(false);
    };
  }, [isEffect ? addressDatas : null]);

  const handleClickDeleteOpen = (id) => {
    setDeleteOpen(true);
    setAddressId(id);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const handleClickSetDefaultOpen = () => {
    setSetDefaultOpen(true);
  };

  const handleSetDefaultClose = () => {
    setSetDefaultOpen(false);
  };
  const DefaultAddress = (id, index) => {
    // const data = new FormData();
    // data.append("address_id", id);
    // dispatch(setDefaultAddressUser(data));
    setIndex(index);
    setLoading(true);
    setTimeout(() => {
      setEffect(true);
      setLoading(false);
      setSetDefaultOpen(true);
    }, 1000);
  };
  const DeleteAddress = async () => {
    const data = {
      address_id: AddressId,
    };
    const response = await deleteAddressData(
      data,
      localStorage.getItem("token")
    );
    if (response.success) {
      setEffect(true);
      setDeleteOpen(false);
    }
  };
  const UpdateAddress = (id) => {
    props.setNavigate("updateAddress");
    history.push(`/my-profile/${id}`);
  };
  return (
    <div className={classes.body}>
      <header>
        <Grid container spacing={2} className={""}>
          <Grid item md={3}>
            <h3 className={classes.title}>{t("my_address")}</h3>
          </Grid>
          <Grid item md={9}>
            <div className={classes.inputsTop}>
              {/* <label className={classes.sortBy}>Sort by</label>
              <select className={classes.select_style}>
                <option></option>
              </select>

              <Input
                type="text"
                search="true"
                //   leftLabel="Email"
                className={classes.input_style}
              /> */}

              <ButtonCustom
                bg={webThemeColor}
                onClick={() => props.setNavigate("addAddress")}
                className={""}
              >
                {t("add_new_address")}
              </ButtonCustom>
            </div>
          </Grid>
        </Grid>
        <hr />
      </header>

      {/* <Grid container spacing={2} className={classes.header_table}>
        <Grid item md={1}></Grid>
        <Grid item md={2}>
          <h5 className="heading">
            Receiver
            {t("receiver_name")}
          </h5>
        </Grid>
        <Grid item md={3}>
          <h5 className="heading">{t("delivery_address")}</h5>
        </Grid>
        <Grid item md={2}>
          <h5 className="heading">Delivery Area</h5>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={3}>
          <h5 className="heading">Option</h5>
        </Grid>
      </Grid> */}
      {/* {AddressData
        ? AddressData.data
          ? AddressData.data.map((dt, index) => {
              return (
                <Grid container spacing={2} className={classes.bodyContent}>
                  <Grid item md={1}>
                    <div>
                      <input
                        type="radio"
                        onChange={handleChangeRadio}
                        name="radio_paymentOption"
                        checked={dt.as_default == 1 ? true : false}
                      />
                    </div>
                  </Grid>
                  <Grid item md={2}>
                    <h5 className="heading">{dt.name}</h5>
                    <p className="">#00001</p>
                    <span className={classes.defaultSpan}>default</span>
                  </Grid>
                  <Grid item md={3}>
                    <p className="">
                      {dt.address},{dt.city_name}, <br />
                      {dt.province_name}-{dt.postal_code}
                    </p>
                  </Grid>
                  <Grid item md={2}>
                    <p className="">
                      {dt.district_name}, {dt.province_name}
                    </p>
                  </Grid>
                  <Grid item md={1}>
                    {index == myIndex && isLoading ? (
                      <ClipLoader size={27} color="#ff3a59" />
                    ) : (
                      <ButtonCustom
                        bg={webThemeColor}
                        className={classes.btn_default}
                        onClick={() => DefaultAddress(dt.id, index)}
                      >
                        Set Default
                      </ButtonCustom>
                    )}
                  </Grid>
                  <Grid item md={3}>
                    <div className={classes.btns}>
                      <ButtonCustom
                        bg={webThemeColor}
                        border="true"
                        onClick={() => UpdateAddress(dt.id)}
                      >
                        <EditIcon /> {t("edit")}
                      </ButtonCustom>
                      <ButtonCustom
                        bg={webThemeColor}
                        border="true"
                        onClick={() => handleClickDeleteOpen(dt.id)}
                      >
                        <DeleteIcon /> {t("delete")}
                      </ButtonCustom>
                    </div>
                  </Grid>
                </Grid>
              );
            })
          : null
        : null} */}

      {addressDatas && addressDatas.length > 0
        ? addressDatas.map((item, index) => {
            return (
              <div
                style={{
                  borderBottom: "1px solid #EBEBEB",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                <Grid container>
                  <Grid item md={2}>
                    {item.name}
                  </Grid>
                  <Grid item md={2}>
                    {item.phone}
                  </Grid>
                  <Grid item md={1} style={{}}>
                    {item.is_primary === true ? (
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        style={{
                          color: "#DC6803",
                          border: "1px #DC6803 solid",
                        }}
                      >
                        {t("default_unbold")}
                      </Button>
                    ) : null}
                  </Grid>
                  <Grid item md={7}>
                    <div className={classes.btns}>
                      <Button
                        style={{
                          background: "transparent",
                          color: webThemeColor,
                        }}
                        onClick={() => UpdateAddress(item.id)}
                        size="small"
                      >
                        {t("edit")}
                      </Button>

                      {item.is_primary === true ? null : (
                        <Button
                          style={{
                            background: "transparent",
                            color: webThemeColor,
                          }}
                          onClick={() => handleClickDeleteOpen(item.id)}
                          size="small"
                        >
                          {t("delete")}
                        </Button>
                      )}
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item md={6}>
                    {item.address}
                  </Grid>
                  <Grid item md={6} className={classes.btnsBottom}>
                    {index == myIndex && isLoading ? (
                      <ClipLoader size={27} color={webThemeColor} />
                    ) : null}

                    {item.is_primary === true ||
                    (index == myIndex && isLoading) ? null : (
                      <Button
                        style={{
                          color: webThemeColor,
                          background: "transparent",
                          border: "1px " + webThemeColor + " solid",
                          height: "25px",
                        }}
                        onClick={() => DefaultAddress(item.id, index)}
                        size="small"
                      >
                        {t("set_as_default_address")}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </div>
            );
          })
        : null}

      <DialogAlert
        open={deleteOpen}
        close={handleDeleteClose}
        yesno="true"
        no={handleDeleteClose}
        ok={DeleteAddress}
      >
        {t("ask_delete")}
      </DialogAlert>
      <DialogAlert
        open={SetDefaultOpen}
        close={handleSetDefaultClose}
        ok="true"
      >
        {t("address_set_as_default_successfully")}
      </DialogAlert>
    </div>
  );
};

export default MyAddress;
