import { Box } from "@material-ui/core";
import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ProductCard from "../../../../components/common/productCard/ProductCard";
import SkeletonCircleText from "../../../../components/common/SkeletonCircleText";
// import { addWishlistProductUser } from "../../../../store/actions/addWishlistActions";
// import { GetWishList } from "../../../../store/actions/GetWishlist";
// import { GetWishlistSelector } from "../../../../store/Selectors/GetWishlistSelector";
import { useStyles } from "./MyWishlist.style";
import { t } from "i18next";
import i18n from "../../../language/localization";

const MyWishlist = (props) => {
  const { webThemeColor, themeColor } = props;
  const classes = useStyles(props);

  // const dispatch = useDispatch();
  const history = useHistory();
  const [true1, setTrue1] = useState(true);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [productid, setProductId] = useState(false);
  const usercode = JSON.parse(localStorage.getItem("usercode"));
  const getWishlist = () => {
    let data = new FormData();
    data.append("user_code", usercode);
    // dispatch(GetWishList(data));
  };

  useEffect(() => {
    // GetWishList();
    setTimeout(() => {
      setTrue1(true);
    }, 1000);
    const timer = setTimeout(() => {
      setOpen(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const deleteWish = (e) => {
    setProductId(e);
    setOpen1(true);
  };
  const handleClose = () => {
    setOpen1(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
    let data1 = new FormData();
    data1.append("product_id", productid);
    data1.append("user_code", usercode);
    data1.append("date_time", "2018-12-25 10:23:00");
    // dispatch(addWishlistProductUser(data1));
    setOpen2(true);
    // setTimeout(() => (setOpen2(false), getWishlist()), 500);
  };
  useEffect(() => {
    handleClose1();
  }, []);
  // const getSingleProduct = (e) => {
  //   const usercode = JSON.parse(localStorage.getItem("usercode"));
  //   const userid = JSON.parse(localStorage.getItem("userid"));
  //   localStorage.setItem("productsid",JSON.stringify([e]));
  //   var data3 = new FormData();
  //   data3.append("product_id", e);
  //   data3.append("user_id", userid);
  //   data3.append("user_code", usercode);
  //   dispatch(singleProductDataUser(data3));
  //   history.push(`/productdetail?${e}`);
  // };

  const res = {};

  {
    console.log("res____", res.data);
  }
  const gotoSinglePage = (id) => {
    history.push(`/product-detail?${id}`);
  };

  useEffect(() => {
    // console.log("is_ useEffect");
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  }, []);
  
  return (
    <Box className={classes.body}>
      <header>
        <h4>{t('my_wishlist')}</h4>
      </header>
      <Box className={classes.cards_container}>
        {res.data ? (
          res.data.length > 0 ? (
            res.data.map((val, index) => (
              <ProductCard
                name={val.name}
                image={val.main_image}
                price={val.price}
                discountPrice={val.selling_price}
                prDiccount={val.percent_discount}
                Quickview={() => gotoSinglePage(val.id)}
                webThemeColor={webThemeColor}
              />
            ))
          ) : null
        ) : (
          <div style={{display: 'flex', gap: '10px', width: '100%'}}>
            {["", "", "", "", ""].map(() => (
              <SkeletonCircleText
                style={{
                  width: "100%",
                  height: "250px",
                }}
              />
            ))}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default MyWishlist;
