import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import PlaylistAddCheckOutlinedIcon from "@material-ui/icons/PlaylistAddCheckOutlined";
import SettingsIcon from '@material-ui/icons/Settings';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { useHistory } from "react-router-dom";
import { Avatar, makeStyles } from "@material-ui/core";
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
// import { getSplashDataUser } from "../../store/actions/getSplashDataActions";
// import { useDispatch } from "react-redux";
// import { ThemeColor } from "../themecolor";
import { withNamespaces } from "react-i18next";
import Loader from "../loader";

const drawerWidth = 240;

function Sidebar({ t }) {
  // const { window } = props;
  const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : "#FFFFFF",
        color: "#FFFFFF",
        userSelect: "none"
      },
    },
  });
  const Data = [
    { id: 0, text: "Home", icon: HomeOutlinedIcon },
    { id: 1, text: "Category", icon: DashboardIcon },
    { id: 3, text: "All Products", icon: DynamicFeedIcon },
    { id: 4, text: "Cart", icon: ShoppingCartOutlinedIcon },
    { id: 5, text: "Wishlist", icon: FavoriteBorderOutlinedIcon },
    { id: 6, text: "History Order", icon: AssignmentIcon },
    { id: 7, text: "Promotion", icon: PlaylistAddCheckOutlinedIcon },
    { id: 8, text: "Information", icon: VolumeUpIcon },
    { id: 9, text: "Settings", icon: SettingsIcon },
  ];
  const datauser = JSON.parse(localStorage.getItem("userdata"));
  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  // const dispatch = useDispatch();
  const userid = JSON.parse(localStorage.getItem("userid"));
  const orderdata = () => {
    // let data = new FormData();
    // data.append('user_id', userid);
    // dispatch(getSplashDataUser(data));
  }
  // useEffect(() => {
  //   setTimeout(() => {
  //     setOpen(true);
  //   }, 1000);
  // }, []);
  const landing = JSON.parse(localStorage.getItem("Lanpage"));
  const useStyles = makeStyles((theme) => ({
    customListItemText: {
      marginTop: "7px",
    },
  }));
  const classes = useStyles();

  const handleClickSetting = () => {
    if(datauser){
      orderdata()
      history.push("/setting")
    }else{
      history.push("/login")
    }
  }

  return (
    <ThemeProvider theme={theme}>

      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: "none" } }}
          style={{ color: "white" }}
        >
          <MenuIcon />
        </IconButton>

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
          style={{ display: "none" }}
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            // container={container}
            variant="temporary"
            open={mobileOpen}
            // style={{display:"none !important"}}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth + 40,
              },
            }}
          >
            <React.Fragment>
              <List
                style={{ backgroundColor: ThemeColor ? ThemeColor : "#FFFFFF", position: "sticky", top: "0px" }}
              >
                <ListItem className="p-0" style={{ width: "230px", marginLeft: '5px' }}>
                  {
                    datauser ?
                      <ListItemIcon>
                        <Avatar src={process.env.REACT_APP_FILE_BASE_URL + '/' + datauser.profile_picture} sx={{ width: 50, height: 50 }} style={{
                          height: "50px",
                          width: "50px",
                          marginTop: "5px",
                          marginBottom: "8px"
                        }} onClick={() => { history.push("/editprofile") }} />
                      </ListItemIcon>
                      :
                      <ListItemIcon>
                        <Avatar
                          src="/assets/frontend/img/Icon/userdefault.png"
                          sx={{ width: 50, height: 50 }}
                          style={{
                            height: "50px",
                            width: "50px",
                            marginTop: "5px",
                            marginBottom: "8px",
                            color: "white"
                          }} />
                        {/* <AccountCircleOutlinedIcon
                    style={{ fontSize: "85px", color: "white" }}
                  /> */}
                      </ListItemIcon>
                  }
                  {
                    datauser ?
                      <ListItemText >
                        <div className="text-light m-0">{datauser.name}</div>
                        <div className="m-0 text-light" style={{ wordBreak: "break-all" }}>{datauser.email}</div>
                      </ListItemText>
                      :
                      <ListItemText onClick={() => {
                        // if (landing&&landing.landing_page == 0) {
                        //   history.push("/login")
                        // } else {
                        //   history.push("/")
                        // }
                        history.push("/login")
                      }} >
                        <div className="text-light m-0">{t("welcome_guest")}</div>
                        <div className="m-0 text-light">{t("login")}  |  {t("register")}</div>
                      </ListItemText>
                  }
                </ListItem>
              </List>
              <List>

                <ListItem button className="m-0 p-0 list" style={{ height: '50px'}} onClick={() => {
                  handleDrawerToggle();
                  // if (landing&&landing.landing_page == 0) {
                  //   history.push("/")
                  // } else {
                  //   history.push("/dashboard")
                  // }
                  history.push('/');
                }}>
                  <ListItemIcon style={{ marginLeft: "5px", minWidth: "0px" }}>
                    <svg style={{width: '24px', height: '24px', fill:"none"}} className=" my-2 mx-2" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.02 2.84004L3.63 7.04004C2.73 7.74004 2 9.23004 2 10.36V17.77C2 20.09 3.89 21.99 6.21 21.99H17.79C20.11 21.99 22 20.09 22 17.78V10.5C22 9.29004 21.19 7.74004 20.2 7.05004L14.02 2.72004C12.62 1.74004 10.37 1.79004 9.02 2.84004Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 17.99V14.99" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                  </ListItemIcon>
                  <ListItemText primary={t("home1")} className={classes.customListItemText} />
                  {/* <Badge badgeContent={4} className="me-3" color="primary"></Badge> */}
                </ListItem>

                <ListItem button className="m-0 p-0 list" style={{ height: '50px'}} onClick={() => { history.push("/category") }}>
                  <ListItemIcon style={{ marginLeft: "5px", minWidth: "0px" }}>
                  <svg style={{width: '24px', height: '24px', fill:"none"}} className=" my-2 mx-2" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 10H19C21 10 22 9 22 7V5C22 3 21 2 19 2H17C15 2 14 3 14 5V7C14 9 15 10 17 10Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5 22H7C9 22 10 21 10 19V17C10 15 9 14 7 14H5C3 14 2 15 2 17V19C2 21 3 22 5 22Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18 22C20.2091 22 22 20.2091 22 18C22 15.7909 20.2091 14 18 14C15.7909 14 14 15.7909 14 18C14 20.2091 15.7909 22 18 22Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </ListItemIcon>
                  <ListItemText primary={t("category")} className={classes.customListItemText} />
                  {/* <Badge badgeContent={4} className="me-3" color="primary"></Badge> */}
                </ListItem>

                <ListItem button className="m-0 p-0 list" style={{ height: '50px'}} onClick={() => {
                  localStorage.setItem("viewproname", JSON.stringify("All Product"));
                  localStorage.setItem("viewproid", JSON.stringify(""));
                  localStorage.setItem("viewprochild", JSON.stringify(""));
                  history.push("/products")
                }}>
                  <ListItemIcon style={{ marginLeft: "5px", minWidth: "0px" }}>
                  <svg style={{width: '24px', height: '24px', fill:"none"}} className=" my-2 mx-2" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.17004 7.43994L12 12.5499L20.77 7.46991" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 21.6099V12.5399" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.92999 2.48L4.59 5.45003C3.38 6.12003 2.39001 7.80001 2.39001 9.18001V14.83C2.39001 16.21 3.38 17.89 4.59 18.56L9.92999 21.53C11.07 22.16 12.94 22.16 14.08 21.53L19.42 18.56C20.63 17.89 21.62 16.21 21.62 14.83V9.18001C21.62 7.80001 20.63 6.12003 19.42 5.45003L14.08 2.48C12.93 1.84 11.07 1.84 9.92999 2.48Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M17 13.24V9.58002L7.51001 4.09998" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </ListItemIcon>
                  <ListItemText primary={t("all_product")} className={classes.customListItemText} />
                  {/* <Badge badgeContent={4} className="me-3" color="primary"></Badge> */}
                </ListItem>

                <ListItem button className="m-0 p-0 list" style={{ height: '50px'}} onClick={() => {
                  if (datauser) {
                    history.push("/cart")
                  } else {
                    // if (landing&&landing.landing_page == 0) {
                    //   history.push("/login")
                    // } else {
                    //   history.push("/")
                    // }
                    history.push("/login");
                  }
                }}>
                  <ListItemIcon style={{ marginLeft: "5px", minWidth: "0px" }}>
                  <svg style={{width: '24px', height: '24px', fill:"none"}} className=" my-2 mx-2" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9 8H21" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  </ListItemIcon>
                  <ListItemText primary={t("cart")} className={classes.customListItemText} />
                  {/* <Badge badgeContent={4} className="me-3" color="primary"></Badge> */}
                </ListItem>

                <ListItem button className="m-0 p-0 list" style={{ height: '50px'}} onClick={() => {
                  if (datauser) {
                    // history.push("/wishlist")
                    history.push("/comingsoon-mobile")
                  } else {
                    // if (landing&&landing.landing_page == 0) {
                    //   history.push("/login")
                    // } else {
                    //   history.push("/")
                    // }
                    history.push("/login");
                  }
                }}>
                  <ListItemIcon style={{ marginLeft: "5px", minWidth: "0px" }}>
                  <svg style={{width: '24px', height: '24px', fill:"none"}} className=" my-2 mx-2" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  </ListItemIcon>
                  <ListItemText primary={t("wishlist")} className={classes.customListItemText} />
                  {/* <Badge badgeContent={4} className="me-3" color="primary"></Badge> */}
                </ListItem>

                <ListItem button className="m-0 p-0 list" style={{ height: '50px'}} onClick={() => {
                  if (datauser) {
                    orderdata()
                    localStorage.setItem("ordertab", JSON.stringify(1))
                    history.push("/orderhistory")
                  } else {
                    // if (landing&&landing.landing_page == 0) {
                    //   history.push("/login")
                    // } else {
                    //   history.push("/")
                    // }
                    history.push("/login");
                  }
                }}>
                  <ListItemIcon style={{ marginLeft: "5px", minWidth: "0px" }}>
                  <svg style={{width: '24px', height: '24px', fill:"none"}} className=" my-2 mx-2" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 12.2H15" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8 16.2H12.38" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 6H14C16 6 16 5 16 4C16 2 15 2 14 2H10C9 2 8 2 8 4C8 6 9 6 10 6Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16 4.02002C19.33 4.20002 21 5.43002 21 10V16C21 20 20 22 15 22H9C4 22 3 20 3 16V10C3 5.44002 4.67 4.20002 8 4.02002" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </ListItemIcon>
                  <ListItemText primary={t("history_order")} className={classes.customListItemText} />
                  {/* <Badge badgeContent={4} className="me-3" color="primary"></Badge> */}
                </ListItem>

                {/* <ListItem button className="m-0 p-0 list">
              <ListItemIcon>
                <PlaylistAddCheckOutlinedIcon fontSize="large" className=" my-2 mx-2" />
              </ListItemIcon>
              <ListItemText primary="Promotion" className="" />
              <Badge badgeContent={4} className="me-3" color="primary"></Badge>
            </ListItem>
          
            <ListItem button className="m-0 p-0 list">
              <ListItemIcon>
                <VolumeUpIcon fontSize="large" className=" my-2 mx-2" />
              </ListItemIcon>
              <ListItemText primary="Information" className="" />
              <Badge badgeContent={4} className="me-3" color="primary"></Badge>
            </ListItem> */}

                <ListItem button className="m-0 p-0 list" style={{ height: '50px'}} onClick={handleClickSetting}>
                  <ListItemIcon style={{ marginLeft: "5px", minWidth: "0px" }}>
                    {/* <SettingsIcon fontSize="large" className=" my-2 mx-2" /> */}
                    {/* <img src={"/assets/frontend/img/SIDEBAR MENU/settings.png"} height="25px" fontSize="large" className=" my-2 mx-2" /> */}
                    <svg style={{width: '24px', height: '24px', fill:"none"}} className=" my-2 mx-2" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#2B2B2B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2 12.8801V11.1201C2 10.0801 2.85 9.22006 3.9 9.22006C5.71 9.22006 6.45 7.94006 5.54 6.37006C5.02 5.47006 5.33 4.30006 6.24 3.78006L7.97 2.79006C8.76 2.32006 9.78 2.60006 10.25 3.39006L10.36 3.58006C11.26 5.15006 12.74 5.15006 13.65 3.58006L13.76 3.39006C14.23 2.60006 15.25 2.32006 16.04 2.79006L17.77 3.78006C18.68 4.30006 18.99 5.47006 18.47 6.37006C17.56 7.94006 18.3 9.22006 20.11 9.22006C21.15 9.22006 22.01 10.0701 22.01 11.1201V12.8801C22.01 13.9201 21.16 14.7801 20.11 14.7801C18.3 14.7801 17.56 16.0601 18.47 17.6301C18.99 18.5401 18.68 19.7001 17.77 20.2201L16.04 21.2101C15.25 21.6801 14.23 21.4001 13.76 20.6101L13.65 20.4201C12.75 18.8501 11.27 18.8501 10.36 20.4201L10.25 20.6101C9.78 21.4001 8.76 21.6801 7.97 21.2101L6.24 20.2201C5.33 19.7001 5.02 18.5301 5.54 17.6301C6.45 16.0601 5.71 14.7801 3.9 14.7801C2.85 14.7801 2 13.9201 2 12.8801Z" stroke="#2B2B2B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                  </ListItemIcon>
                  <ListItemText primary={t("settings1")} className={classes.customListItemText} />
                  {/* <Badge badgeContent={4} className="me-3" color="primary"></Badge> */}
                </ListItem>

              </List>
            </React.Fragment>
          </Drawer>
          {/* <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer> */}
        </Box>
      </Box>

    </ThemeProvider>
  );
}

export default withNamespaces()(Sidebar);
