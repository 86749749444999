import { makeStyles } from "@material-ui/core";

const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));


const useStyles = makeStyles({
  body: {
    backgroundColor: "#f5f5f5",
    WebkitUserSelect: 'text', MozUserSelect: 'auto', msUserSelect: 'auto', userSelect: 'auto',
  },
 modalRoot:{
  "& .MuiDialog-paper":{
    width:400,
    height:280
  }
 },
  btn_add_fav: {
    boxShadow: "inset 0px 0px 0px 1px grey",
  },
  heartRed: {
    "& svg": {
      color: "red",
    },
  },
  btn_group: {
    display: 'flex !important',
    gap: '7px',
    flexWrap: 'wrap',
    "& .MuiButton-root": {
      marginRight: '0 !important',
      marginBottom: '0 !important'
    }
    
  },

  root_single_product: {
    padding: "30px",
    paddingTop: "1rem",
  },

  breadcrumbs: {
    gap: '10px',
    display: 'flex',
    padding: '10px 0 10px',
    alignItems: 'center',

    "& .title_bread": {
      display: "inline-block",
      color: "#575757",
      fontSize: "15px",
      marginBottom: "0",
    },
    "& .title_bread_click": {
      display: "inline-block",
      fontSize: "15px",
      marginBottom: "0",
      cursor: "pointer"
    },
    "& .title_bread.active": {
      fontSize: "14px",
    },
    "& .MuiSvgIcon-root": {
      margin: "0 !important",
      fontSize: "10px",
    },
  },

  cardStyle: {
    padding: "15px",
  },

  //   product_right Style

  product_right: {},

  product_heading: {
    "& .best_seller": {
      // background: "red",
      // color: "yellow",
      // fontSize: ".75rem",
      // display: "inline-block",
      // // margin: "0 0 10px",
      // padding: "3px 6px",
      // float: "left",
      // lineHeight: "20px",
      // marginRight: "5px",
      borderRadius: '3px',
      color: 'yellow',
      float: 'left',
      display: 'inline-block',
      padding: '0px 10px',
      fontSize: '.75rem',
      background: 'red',
      lineHeight: '20px',
      marginRight: '5px',
      marginTop: '5px'
    },

    "& .title_product": {
      // display: "flex",
      display: 'inline-block',
      alignItems: "center",
      lineHeight: "33px",
      fontSize: "1.25rem",
      margin: "0 0 10px",
    },
  },

  price_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 0 5px",

    "& .redText": {
      fontSize: "20px",
      fontWeight: "700",
      display: "inline-block",
      margin: "0",
      lineHeight: "30px",
      float: "left",
    },

    "& .off": {
      // background: "red",
      // color: "#fff",
      // fontSize: "11px",
      // display: "inline-block",
      // margin: "3px 15px",
      // padding: "3px 6px",
      // float: "left",
      // lineHeight: "20px",
      borderRadius: '3px',
      color: '#fff',
      float: 'left',
      margin: '2px 15px',
      display: 'inline-block',
      padding: '2px 20px',
      fontSize: '15px',
      background: 'red',
      lineHeight: '20px'
    },

    "& .price-cancel": {
      // textDecoration: "line-through",
      // display: "inline-block",
      // fontSize: "15px",
      // lineHeight: "28px",
      display: 'inline-block',
      fontSize: '25px',
      lineHeight: '30px',
      textDecoration: 'line-through'
    },

    "& .share": {
      border: "1px solid #ff3a59",
      borderRadius: "50%",
      padding: "0px",
      color: "#ff3a59",
      cursor: "pointer",

      "& .MuiSvgIcon-root": {
        fontSize: "18px",
      },
    },
    "& .share:hover": {
      color: "#000",
      border: "1px solid #000",
    },
  },

  priceText: {
    padding: '6px 5px', width: '85%', float: 'left'
  },

  product_detail_content: {
    display: "inline-block",
    width: "100%",
  },

  product_size: {
    marginBottom: "20px",
    "& .MuiButton-root": {
      minWidth: "auto",
    },
    "& .sizelable": {
      display: "inline-block",
      float: "left",
      width: "130px",
    },
    "& .product-detail-content-right": {
      display: "inline-block",
      width: "calc(100% - 130px)",
    },
    "& .selected": {
      float: "left",
      margin: "0",
      // color: "#ff3a59",
      color: "black",
    },
    "& .size-check": {
      clear: "both",
      margin: "10px 0 0 0",
      float: "left",
      "& .active": {
        border: "1px solid "+ThemeColor,
        // color: "#fff",
      },
    },
    "& .MuiButton-root": {
      lineHeight: "15px",
      borderRadius: "4px",
      // color: "#ff3a59",
      border: "1px solid #ccc",
      padding: "5px 10px",
      marginRight: "10px",
      minWidth: "auto",
    },
  },

  quantity: {
    border: "1px solid",
    borderColor: '#d6d6d6',
    // borderRadius: "4px",
  },
  numberOfpcs: {
    marginBottom: "20px",

    "& .product-detail-content-right": {
      // display: "inline-block",
      width: "calc(100% - 130px)",
    },
    "& .blankspace": {
      display: "inline-block",
      float: "left",
      width: "130px",
    },
    "& .qty-more": {
      display: "flex",
      alignItems: "center",

      "& .minus-qty": {
        float: "left",
        width: "40px",
        textAlign: "center",
        fontWeight: "700",
        fontSize: "20px",

        "& svg.MuiSvgIcon-root": {
          margin: "0px !important",
          color: '#9a9a9a'
        },
      },
      "& .qty1": { border: "1px solid", borderColor: '#eaeaea', fontSize: '20px', fontWeight: '100' },
      "& .plus-qty": {
        "& svg.MuiSvgIcon-root": {
          margin: "0px !important",
          color: '#9a9a9a'
        },
      },
      "& .leftPcsTitle": {
        // marginLeft: "15px",
        // textAlign: "center",
        // fontWeight: "700",
        // fontSize: "20px",
        // display: "block",
        display: 'block',
        fontSize: '16px',
        textAlign: 'center',
        fontWeight: '100',
        marginLeft: '15px',
      },
    },
  },

  buttons_group_cart: {
    marginBottom: "10px",

    "& .product-detail-content-right": {
      display: "inline-block",
      width: "calc(100% - 130px)",
    },
    "& .blankspace": {
      display: "inline-block",
      float: "left",
      width: "130px",
    },

    "& .MuiButton-root": {
      "& svg": {
        margin: "0 !important",
        marginRight: "10px !important",
        fontSize: "18px !important",
      },
    },
    // "& button": {
    //   // background: "#ff3a59",
    //   border: "none",
    //   padding: "10px 20px",
    //   color: "#fff",
    //   borderRadius: "4px",
    //   marginRight: "5px",

    //   "&:hover": {
    //     background: "#000",
    //   },
    // },
    "& i": {
      marginRight: "10px",
    },
  },

  NavLink_style_none: {
    lineHeight: "0 !important",
    borderRadius: "0 !important",
    border: "none !important",
    padding: "0 !important",
    marginRight: "0 !important",
  },

  colorSelect: {
    marginBottom: "10px",

    "& .color-check li": {
      marginRight: "10px",
    },
    "& .product-detail-content-right": {
      display: "inline-block",
      width: "calc(100% - 130px)",
    },

    "& .sizelable": {
      display: "inline-block",
      float: "left",
      width: "130px",
    },

    "& .colorLableBox": {
      // height: "60px",
      // width: "60px",
      // padding: "0",
      // border: "1px solid #ccc",
      // overflow: "hidden",
      // display: "grid",
      // alignItems: "center",
      // textAlign: "center",
      // fontSize: "12px",
      // fontWeight: "bold",
      // color: "#575757 !important",
      color: '#000000 !important',
      width: '60px',
      border: '1px solid #ccc',
      height: '60px',
      display: 'grid',
      padding: '1px',
      overflow: 'hidden',
      fontSize: '12px',
      textAlign: 'center',
      alignItems: 'center',
      fontWeight: 'normal',
    },
    "& .active": {
      "& .colorLableBox": {
        border: "1px solid "+ThemeColor,
      },
    },
  },

  // Section Call Start
  card_sectionCall: {},

  sectionHeader: {
    justifyContent: "space-between",
    display: "flex",
    backgroundColor: "rgba(0,0,0,.05)",
    // backgroundColor: "white",
    padding: "10px 10px",
    margin: "12px",
    fontSize: "16px",

    // "& .link": {
    //   color: "#323232",
    //   cursor: "pointer",
    // },
  },

  sectionDestination: {
    "& .sectionHeader": {
      backgroundColor: "#f8f8f8",
      padding: "15px 20px",
      "& h4": {
        fontSize: "14px",
        marginBottom: "0",
        fontWeight: "600",
      },
    },
    "& .sectionBody": {
      padding: "20px",
    },
  },

  myPortal: {
    position: "absolute",
  },

  myPortal_Style: {
    "& div:first-child": {
      // position: 'absolute',
      // right: '0',
    },
    "& img": {
      // transform: "translate(0px, -72px) !important",
    },
  },

  product_left: {
    // display: "flex",
    gap: "10px",

    "& .thumbs": {
      width: "450px",
      maxHeight: '85px'
      // border: "1px solid lightgrey",
      

      ,"& .MuiButton-root": {
        // width: "160px",
        // display: "block",
        minWidth: "95px",
        "& img": {
          // maxWidth: '90px',
          maxHeight: "80px",
        },
      },
    },

    // "& .js-image-zoom__zoomed-image": {
    //   width: "650px !important",
    //   height: "350px !important",
    //   // border: "1px solid lightgrey",
    //   // boxShadow: "0px 0 5px 0",
    //   borderLeft: "50px solid #fff",
    //   // background: "#fff",
    //   zIndex: 1,
    // },
  },

  product_main_image: {
    // boxShadow: "0px 0 2px 0",
    display: "flex",
    padding: "10px",
    minHeight: "270px",
    alignItems: "center",
    margin: "0 auto",
    marginBottom: "15px",
    justifyContent: "center",
    width: "fit-content",
    borderRadius: "2px",

    "& img": {
      maxWidth: "100% !important",
      // height: "auto !important",
    },
  },

  sliderContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "1200px",
    margin: "0 auto",
    // marginBottom: '20px',
    position: 'relative',

    "& .prev": {
      borderRadius: "50%",
      color: "black",
      // fontSize: "24px",
      cursor: "pointer",
      // display: "flex",
      // alignItems: "center",
      // marginBottom: "0px",
      // "& .MuiSvgIcon-root": {
      //   fontSize: "18px",
      //   minWidth: "30px",
      //   textAlign: 'center',
      //   magrinRight: '20px'
      // },
      // "& .MuiButton-root": {
      //   minWidth: "30px",
      // },
      position: 'absolute',
      background: 'white',
      left: '-2%'
    },
    "& .next": {
      borderRadius: "50%",
      color: "black",
      // fontSize: "24px",
      cursor: "pointer",
      // display: "flex",
      // alignItems: "center",
      // marginBottom: "0px",
      // "& .MuiSvgIcon-root": {
      //   fontSize: "18px",
      //   minWidth: "30px",
      //   textAlign: 'center',
      //   magrinRight: '20px'
      // },
      // "& .MuiButton-root": {
      //   minWidth: "30px",
      // },
      position: 'absolute',
      background: 'white',
      right: '-2%'
    },

    "& .MuiButton-text.prev, .MuiButton-text.next": {
      padding: "0",
      minWidth: "30px",
    },
    "& .dNone": {
      display: "none",
    },

    // "& .next": {
    //   position: 'relative',
    //   left: '-80px',
    // },

    "& .sliderParent": {
      display: "flex",
      alignItems: "center",
      listStyle: "none",
      maxWidth: "100%",
      overflowX: "scroll",
      scrollBehavior: "smooth",
      margin: "unset",
      gap: '10px'
      
      , "&::-webkit-scrollbar": {
        background: "transparent",
        WebkitAppearance: "none",
        width: "0",
        height: "0",
      },

      "& li": {
        background: "#2185D0",
        color: "#fff",
        padding: "5px 15px",
        borderRadius: "20px",
        margin: "0 4px",
        whiteSpace: "nowrap",
      },
    },
    "& .sliderItemImage": {
      width: '85px'
      , height: '85px'
      
    },
    "& .sliderItemImage:hover": {
        border: '1px solid '+ThemeColor
    },
  },

  table_style_color: {
    width: '230px',
    "& .head": {
      // background: `${themeColor} !important`,
      // background: `${JSON.parse(localStorage.getItem("themeColor"))} !important`,
      color: '#fff',
      border: `1px solid #f8d9d9`,
      borderRight: 'none',
      borderCollapse: 'collapse',
    },
    "& th": {
      padding: '5px 5px',
    },
    "& td": {
      border: `0.1px solid #edebeb`,
      // borderRight: 'none',
      borderCollapse: 'collapse',
      padding: '5px 5px',
      height: '40px',
      // width: '300px'
    },

    
  },
  table_style_qty: {
    width: '100px',
    "& .head": {
      // background: `${themeColor} !important`,
      // background: `${JSON.parse(localStorage.getItem("themeColor"))} !important`,
      color: '#fff',
      border: `1px solid #f8d9d9`,
      borderRight: 'none',
      borderCollapse: 'collapse',
    },
    "& th": {
      padding: '5px 5px',
    },
    "& td": {
      border: `0.1px solid #edebeb`,
      // borderRight: 'none',
      borderCollapse: 'collapse',
      padding: '5px 5px',
      height: '40px',
      // width: '100px'
    },

    
  },
  table_style: {
    width: '100%',
    "& .head": {
      // background: `${themeColor} !important`,
      // background: `${JSON.parse(localStorage.getItem("themeColor"))} !important`,
      color: '#fff',
      border: `1px solid #f8d9d9`,
      borderRight: 'none',
      borderCollapse: 'collapse',
    },
    "& th": {
      padding: '5px 5px',
    },
    "& td": {
      border: `0.1px solid #edebeb`,
      // borderRight: 'none',
      borderCollapse: 'collapse',
      padding: '5px 5px',
      height: '40px',
      width: '200px'
    },

    
  },
  tables_style: {
    width: '100%',
    marginBottom: '10px',
    border: '1px solid #edebeb',

    "& td": {
      // display: 'flex',
      // border: `1px solid #cdcdcd`,
      // height: '55px',
      // width: '15px',
      borderCollapse: 'collapse',
      fontSize: '17px'
    },
    "& .head:first-child": {
      borderLeft: `1px solid #cdcdcd`,
      borderCollapse: 'collapse',
      fontSize: '17px'
    },
  },

  qtyinput: {
    outline: 'none',
    border: '1px solid #dcd4d4',
    marginLeft: '5px',
    marginRight: '5px',
    '& .MuiInputBase-root':{
      height: '28px',
      border: 'none'
    },
    '& input': {
        width: '40px',
        // border: '1px solid #dcd4d4',
        WebkitAppearance: 'textfield',
        mozAppearance: 'textfield',
        appearance: 'textfield',
        textAlign: 'center'
      },
      '& input::-webkit-inner-spin-button': { 
        WebkitAppearance: 'none'
      },
      '& input::-webkit-outer-spin-button': { 
        WebkitAppearance: 'none'
      },
      "& input:disabled": {
        // background: '#ededed',
        cursor: 'not-allowed'
      }
    },

    tables_container: {
      display: 'flex',
      // "& .leftWidth": {
      //   width: '162px',
      // }
    },

    qty_td_width: {
      width: '200px',
      borderRight: '1px solid #dcd4d4',
      "& .head": {
        border: `1px solid #f8d9d9`,
        borderRight: '1px solid #dcd4d4',
        borderCollapse: 'collapse',
      },
    },

    paper: {
      minWidth: '784px'
      , minHeight: '604px'
      , borderRadius: '2px'
    },
    imageListParent: {
      overflowY: "auto",
      maxHeight: "522px"
    },
    styleTextfieldNotedForSale: {
      marginBottom: '20px'
      , width: '100%'
      ,
      '& label.Mui-focused': {
        color: ThemeColor,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: ThemeColor,
      },
      '& .MuiOutlinedInput-root': {
        
        '&.Mui-focused fieldset': {
          borderColor: ThemeColor,
        },
      },
    }
});

export { useStyles };
