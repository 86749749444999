import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  body: {
    backgroundColor: "#f5f5f5",
  },

  root_checkout: {
    padding: "0 18px",
    paddingTop: "135px",
    paddingBottom: "30px",
  },

  pageTitle: {
    fontSize: "16px",
    color: "black",
    marginTop: "20px",
    fontWeight: "600",
    marginBottom: "20px",
  },
});

export { useStyles };
