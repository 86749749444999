import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles, Snackbar } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons//Visibility";
import VisibilityOff from "@material-ui/icons//VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
// import { signupUser, loginUser } from "../../store/actions/userActions";
// import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import LoginAlert from "../../snackbars/loginsnackbar";
import SignupAlert from "../../snackbars/signupsnackbar";
// import { ValResponse } from "../../store/Selectors/user";
import { ThemeColor1 } from "../themecolor/index1";
// import { getSplashDataUser } from "../../store/actions/getSplashDataActions";
// import { GetSplashResponse } from "../../store/Selectors/getSplashData";
import { withNamespaces } from "react-i18next";
import i18n from "../language/localization";
import Cookies from "universal-cookie";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GTranslateIcon from "@material-ui/icons/GTranslate";
import Loader from "../loader";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
// import FacebookIcon from '@material-ui/icons/Facebook';
// import GoogleIcon from '@mui/icons-material/Google';
import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";
import axios from "axios";
// import { GoogleLogin } from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
// import { LoginResponse } from "../../store/Selectors/user";
import { signIn, getProfile, signUp } from "../../api/authorization";
import { Alert } from "../../components/alert/alert";
import { addCodeNumber } from "../../utils/addCodeNumberPhone";
import BasicModal from "../../components/modal/BasicModal";
import { useAuth } from "../../hooks/useAuth";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      <Box p={3} style={{ padding: "0px" }}>
        {children}
      </Box>
    </Typography>
  );
}

const Form = ({ t }) => {
  const auth = useAuth();
  const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));
  // const ThemeColor = "#00000";
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : "#FFFFFF",
      },
    },
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: "#fff",
      height: "100vh",
      // minHeight:"700px",
      // border: "1px solid #d3d3d3a3"
      // backgroundColor: theme.palette.background.paper
    },
    buttonColor: {
      "&> .finish-btn": {
        height: "2.7rem",
        backgroundColor: `${ThemeColor ? ThemeColor : "#FFFFFF"} !important`,
      },
    },
    select: {
      "&> .MuiTabs-fixed.MuiTabs-flexContainer.Mui-selected": {
        color: `${ThemeColor ? ThemeColor : "#FFFFFF"} !important`,
      },
    },
    googlebutton: {
      "& button": {
        display: "none",
      },
    },

    remebmerMe_row: {
      "& .MuiButtonBase-root": {
        padding: "0 !important",
        marginRight: "15px",
      },
    },

    login_signUp_password: {
      "& .MuiButtonBase-root": {
        padding: "0",
      },
      "& .MuiSvgIcon-root": {
        margin: "0 !important",
      },
    },
  }));
  let type = "password";
  const classes = useStyles();
  const history = useHistory();
  const googleref1 = useRef(null);
  const [value, setValue] = React.useState(0);
  const [divop, setDivop] = React.useState(true);
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const [login, setLogin] = React.useState({
    user: "",
    password: "",
  });
  const [signup, setSignup] = React.useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    language_id: 1,
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => {
    if (showPassword2) {
      setShowPassword2(false);
    } else {
      setShowPassword2(true);
    }
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangeSignup = (e) => {
    setSignup({ ...signup, [e.target.name]: e.target.value });
  };
  const handleChangeLogin = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };
  const [loginMessage, setloginMessage] = useState(false);
  const [loginMessageValue, setloginMessageValue] = useState(false);
  // const [signupmsg, setSignupMsg] = useState(false);
  const [signupmsg, setSignupMsg] = useState({
    open: false,
    message_type: "",
    message: "",
  });
  const landingPageValue = JSON.parse(localStorage.getItem("landing_page"));

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }
  const handleSubmit = (props) => {
    alert("signup done");
  };

  const cookies = new Cookies();
  const rememberfun = (e) => {
    setCheck(e.target.checked);
  };

  function handleChangeTab(event, newValue) {
    setValue(newValue);
  }

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [check, setCheck] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));

  // const dispatch = useDispatch();
  const random = () => {
    return Math.floor(1000 + Math.random() * 90000000);
  };

  const handleSubmitSignUp = async () => {
    if (
      signup.name !== "" &&
      signup.email !== "" &&
      signup.phone !== "" &&
      signup.password !== ""
    ) {
      // show loading
      setOpen2(true);

      const data = {
        name: signup.name,
        email: signup.email,
        phone: addCodeNumber(signup.phone),
        password: signup.password,
        // hardcode
        language_id: signup.language_id,
      };

      const response = await signUp(data);
      if (response.success) {
        setSignupMsg({
          open: true,
          message_type: "success",
          message:
            "Pendaftaran berhasil, silahkan login kembali untuk mengakses halaman utama",
        });
        setSignup({
          ...signup,
          name: "",
          email: "",
          phone: "",
          password: "",
          language_id: 1,
        });
        setValue(0);
      } else {
        setSignupMsg({
          open: true,
          message_type: "error",
          message: response.message,
        });
      }

      // hide loading
      setOpen2(false);
    }
  };

  // hardcode profile
  const profileHardcode = {
    customer: {
      id: 1,
      vendor_id: 102,
      name: "John Doee",
      username: "johndoee",
      code: "1d646a6a8d9c9a675b3a6d270fd3f333",
      email: "johndoe@example.com",
      phone: "555-12345888",
      profile_picture:
        "customers/profile_picture/f8fe1a06-5d60-4a1c-a0bd-2603e5af0356.jpg",
      status: "Active",
      language_id: 1,
      points: 0,
      membership_id: 740,
      maximum_order_qty_product_in_cart: 100,
      firebase_token:
        "dBM18ilFToeOFdDJfZ_SGd:APA91bEAWP460KnQ5C75b7J_PvJiBvo0QpD5J-ymYsUD62e0RB2GEeXYGA62VLkf_4G9eK8AgvN-zHIbZBpDCFH31CXKoSP5nbIuJ4cj3MdxTs3A28PKf83xntC1jV1KTEDaf4mi5gGG",
      last_login: "2024-02-02T15:51:41.434487Z",
      deleted_at: null,
      created_at: "2023-09-11T05:25:50.791144Z",
      created_by: null,
      updated_at: "2024-01-21T00:23:33.729701Z",
      updated_by: 125,
    },
    language: {
      id: 1,
      code: "ind",
      name: "Indonesian",
      native_name: "Bahasa Indonesia",
      created_at: "2023-09-11T05:25:49.539132Z",
      created_by: 0,
      updated_at: "0001-01-01T00:00:00Z",
      updated_by: null,
    },
    membership: {
      id: 740,
      vendor_id: 102,
      level: 1,
      name: "Selling Price",
      points: 0,
      is_active: true,
      created_at: "2023-11-27T06:10:11.389268Z",
      created_by: null,
      updated_at: "2023-11-28T09:22:57.860696Z",
      updated_by: 125,
    },
  };

  const Login = async () => {
    setOpen1(true);
    let user = login.user;
    let password = login.password;

    if (user && password) {
      const data = {
        user: addCodeNumber(user),
        password: password,
      };

      const response = await auth.login(data.user, data.password);

      if (!response.success) {
        setloginMessage(true);
        setloginMessageValue(response.data);
      } else {
        if (check) {
          cookies.set("remember", "true");
          cookies.set("user", login.user);
          cookies.set("password", login.password);
        } else {
          cookies.remove("remember");
          cookies.remove("user");
          cookies.remove("password");
        }

        // redirectUrl localstorage
        const redirectUrl = localStorage.getItem("redirectUrl");

        if (redirectUrl) {
          localStorage.removeItem("redirectUrl");
          history.push(redirectUrl);
        } else {
          history.push("/");
        }
      }
    }

    // dispatch(loginUser(formdata,setIsMobileLoginAlready)).then((response)=>{
    //   if(response.ResponseCode!==1){
    //     setloginMessage(true);
    //     setloginMessageValue(response.ResponseMsg);
    //   }else{
    //     setloginMessage(false);
    //     setloginMessageValue("");
    //     history.push('/')
    //   }
    // });
    setOpen1(false);
  };

  // if (open1 === true) {
  //   setTimeout(() => {
  //     const LoginResponseResult = LoginResponse();
  //       console.log("LoginResponseResult",LoginResponseResult)
  //       if (LoginResponseResult.ResponseCode !== 1) {
  //         setloginMessage(true);
  //       }else{
  //         setloginMessage(false);
  //         history.push('/')
  //       }
  //       setOpen1(false);

  // }, 500);
  // }

  // if (open2 === true) {
  //   setTimeout(() => {
  //     setSignupMsg(true);
  //     setOpen2(false);
  //   }, 300);
  // }

  // if (loginMessage === true) {
  //   setTimeout(() => {
  //     setloginMessage(false);
  //   }, 2500);
  // }

  const data = {};
  // if (signupmsg === true) {
  //   setTimeout(() => {
  //     setSignupMsg(false);
  //     if (data === true) {
  //       setSignup({
  //         ...signup,
  //         username: "",
  //         phone: "",
  //         password: "",
  //       });
  //       setValue(0);
  //     }
  //   }, 2000);
  // }

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  useEffect(() => {
    let data = new FormData();
    // dispatch(getSplashDataUser(data));
    // setTimeout(() => {
    setDivop(true);
    // }, 700);
  }, []);
  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  }, []);
  // const landingPageValue = GetSplashResponse();
  // const landingPageValue = undefined;
  const landing = JSON.parse(localStorage.getItem("Lanpage"));
  const lang = JSON.parse(localStorage.getItem("Language"));

  // const responseGoogle = (response) => {
  //   console.log(response);
  // }

  // const [login, setLogin] = useState(false);
  // const [data, setData] = useState({});
  // const [picture, setPicture] = useState('');

  // const responseFacebook = (response) => {
  //   console.log(response);
  //   // setData(response);
  //   // setPicture(response.picture.data.url);
  //   // if (response.accessToken) {
  //   //   setLogin(true);
  //   // } else {
  //   //   setLogin(false);
  //   // }
  // }

  // <GoogleLogin
  //   clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
  //   // buttonText="Login"
  //   onSuccess={responseGoogle}
  //   onFailure={responseGoogle}
  //   cookiePolicy={'single_host_origin'}
  //   style={{ backgroundColor: "yellow !important" }}
  //   ref={googleref1}
  // />
  // <FacebookLogin
  //   appId="921201001964201"
  //   autoLoad={true}
  //   fields="name,email,picture"
  //   scope="public_profile,user_friends"
  //   callback={responseFacebook}
  //   icon="fa-facebook" />

  useEffect(() => {
    // check remember me
    if (cookies.get("remember") == "true") {
      setLogin({
        user: cookies.get("user"),
        password: cookies.get("password"),
      });

      setCheck(true);
    }
  }, []);

  return (
    <div>
      {divop ? (
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {landingPageValue ? (
                landingPageValue.setting_customer.is_customer_required_login ===
                false ? (
                  <div
                    style={{
                      borderBottom: `2px solid ${
                        ThemeColor ? ThemeColor : "#FFFFFF"
                      }`,
                      boxShadow:
                        "0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 2%)",
                    }}
                  >
                    <KeyboardBackspaceIcon
                      style={{
                        color: ThemeColor ? ThemeColor : "#FFFFFF",
                        width: "25px",
                      }}
                      onClick={() => {
                        history.goBack();
                      }}
                    />
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              <AppBar
                position="static"
                color="transparent"
                className="shadow-card"
                elevation={1}
                style={{
                  width: landingPageValue
                    ? landingPageValue.setting_customer
                        .is_customer_required_login === false
                      ? "90%"
                      : "100%"
                    : "",
                  boxShadow:
                    "0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 2%)",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  // textColor="pink"
                  style={{ color: ThemeColor ? ThemeColor : "#FFFFFF" }}
                >
                  <Tab
                    label={t("login1")}
                    className={classes.select}
                    {...a11yProps(1)}
                    style={{ width: "50%" }}
                  />
                  <Tab
                    className={classes.select}
                    label={t("register1")}
                    {...a11yProps(1)}
                    style={{ width: "50%" }}
                  />
                </Tabs>
              </AppBar>
            </div>
            <div className="p-0 d-flex align-items-center justify-content-center">
              <TabPanel value={value} index={0} className="w-100">
                {whatsapp ? (
                  <ValidatorForm
                    onSubmit={() => {
                      console.log("hello");
                    }}
                  >
                    <Grid item xs={12} style={{ margin: "15px 15px" }}>
                      <TextValidator
                        label="Phone Number"
                        fullWidth={true}
                        // onChange={handleChangeLogin}
                        name="phonenumber"
                        variant="standard"
                        validators={["required"]}
                        errorMessages={["Please enter phone number"]}
                        InputLabelProps={{ style: { fontSize: 15 } }}
                      />
                    </Grid>

                    <div className={classes.buttonColor + " " + " finish"}>
                      <button className="finish-btn text-uppercase mt-1">
                        {open1 === true ? (
                          <div style={{ paddingTop: "3px" }}>
                            <BeatLoader size={12} color={"white"} />
                          </div>
                        ) : (
                          "Continue"
                        )}
                      </button>
                    </div>
                  </ValidatorForm>
                ) : (
                  <ValidatorForm
                    // useRef="form"
                    onSubmit={() => {
                      Login();
                    }}
                  >
                    <Grid item xs={12} style={{ margin: "15px 15px" }}>
                      <TextValidator
                        label={t("phonenumber_email_username")}
                        fullWidth={true}
                        onChange={handleChangeLogin}
                        name="user"
                        value={login.user}
                        variant="standard"
                        validators={["required"]}
                        errorMessages={["Please enter username"]}
                        InputLabelProps={{ style: { fontSize: 15 } }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ margin: "15px 15px" }}>
                      <TextValidator
                        className={classes.login_signUp_password}
                        label={t("password")}
                        fullWidth={true}
                        onChange={handleChangeLogin}
                        variant="standard"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        validators={["required"]}
                        errorMessages={["Please enter password"]}
                        value={login.password}
                        InputLabelProps={{ style: { fontSize: 15 } }}
                        InputProps={{
                          endAdornment:
                            type === "password" ? (
                              <InputAdornment
                                position="start"
                                classes={{ positionStart: "0px" }}
                              >
                                <IconButton
                                  onClick={handleClickShowPassword}
                                  style={{
                                    color: ThemeColor ? ThemeColor : "#FFFFFF",
                                  }}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} className="mb-0 mx-1" style={{}}>
                      <div
                        className={
                          classes.remebmerMe_row +
                          " d-flex justify-content-between "
                        }
                      >
                        <div
                          style={{
                            marginTop: "-16px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            {...label}
                            checked={check}
                            onChange={rememberfun}
                            style={{
                              color: ThemeColor ? ThemeColor : "#FFFFFF",
                            }}
                          />
                          <span
                            className="pt-1 "
                            style={{ marginLeft: "-16px", marginTop: "-4px" }}
                          >
                            {t("remember_me")}
                          </span>
                        </div>
                        <p
                          className=" me-2"
                          onClick={() => {
                            history.push("/forgot-password");
                          }}
                          style={{
                            color: ThemeColor ? ThemeColor : "#FFFFFF",
                            backgroundColor: "white",
                            cursor: "pointer",
                            marginTop: "2px",
                          }}
                        >
                          {t("forgot_password")}
                        </p>
                      </div>
                    </Grid>
                    <div className={classes.buttonColor + " " + " finish"}>
                      <button
                        className="finish-btn text-uppercase mt-1"
                        // onClick={() => {
                        //   Login();
                        // }}
                      >
                        {open1 === true ? (
                          <div style={{ paddingTop: "3px" }}>
                            <BeatLoader size={12} color={"white"} />
                          </div>
                        ) : (
                          t("login1")
                        )}
                      </button>
                    </div>
                    {/* belum done flag socmed login */}
                    {/* {landingPageValue&&landingPageValue.data?landingPageValue.data.login_register_whatsapp === 1 ||
                    landingPageValue.data.facebook_login == 1 ||
                    landingPageValue.data.google_login == 1 ? (
                      <div style={{ margin: "14px" }}>
                        <div style={{ borderBottom: "1px solid grey" }}></div>
                        <div style={{ marginTop: "-12px", display: "flex" }}>
                          <div
                            style={{
                              backgroundColor: "transparent",
                              width: "42.5%",
                            }}
                          ></div>
                          <div
                            style={{
                              borderRadius: "50px",
                              backgroundColor: "white",
                              width: "15%",
                              textAlign: "center",
                            }}
                          >
                            OR
                          </div>
                          <div
                            style={{
                              backgroundColor: "transparent",
                              width: "42.5%",
                            }}
                          ></div>
                        </div>
                      </div>
                    ) : null:null} */}
                    {/* <div
                      style={{ margin: "14px" }}
                      className={classes.googlebutton}
                    >
                      {landingPageValue&&landingPageValue.data?landingPageValue.data.login_register_whatsapp === 1 ? (
                        <div
                          // onClick={() => {
                          //   // setWhatsapp(false);
                          // }}
                          style={{
                            // height: "36px",
                            height: "2.7rem",
                            borderRadius: "3px",
                            backgroundColor: "#4acd32",
                            color: "white",
                            textTransform: "capitalize",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "10%" }}>
                            <WhatsAppIcon />
                          </div>
                          <div
                            style={{
                              width: "90%",
                              textAlign: "left",
                              marginLeft: "50px",
                            }}
                          >
                            Login Using WhatsApp
                          </div>
                        </div>
                      ) : null:null}
                      {landingPageValue?landingPageValue.data.facebook_login == 1 ? (
                        <div
                          style={{
                            // height: "36px",
                            height: "2.7rem",
                            borderRadius: "3px",
                            backgroundColor: "#455bcd",
                            color: "white",
                            textTransform: "capitalize",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <div style={{ width: "10%" }}>
                            <BsFacebook style={{ width: "24px" }} />
                          </div>
                          <div
                            style={{
                              width: "90%",
                              textAlign: "left",
                              marginLeft: "50px",
                            }}
                          >
                            Login Using FaceBook
                          </div>
                        </div>
                      ) : null:null}
                      {landingPageValue?landingPageValue.data.google_login == 1 ? (
                        <div
                          style={{
                            // height: "36px",
                            height: "2.7rem",
                            borderRadius: "3px",
                            backgroundColor: "white",
                            color: "black",
                            textTransform: "capitalize",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid grey",
                            marginTop: "10px",
                          }}
                        >
                          <div style={{ width: "10%" }}>
                            <FcGoogle style={{ width: "24px" }} />
                          </div>
                          <div
                            style={{
                              width: "90%",
                              textAlign: "left",
                              marginLeft: "50px",
                            }}
                          >
                            Login Using Google
                          </div>
                        </div>
                      ) : null:null}
                    </div> */}

                    <div
                      onClick={() => {
                        if (lang == "id") {
                          localStorage.setItem("lg", JSON.stringify("en"));
                          localStorage.setItem(
                            "Language",
                            JSON.stringify("en")
                          );
                          if (landing && landing.landing_page == 1) {
                            window.location.href = "/";
                          } else {
                            window.location.href = "/login";
                          }
                        } else {
                          localStorage.setItem("lg", JSON.stringify("id"));
                          localStorage.setItem(
                            "Language",
                            JSON.stringify("id")
                          );
                          if (
                            landingPageValue &&
                            landingPageValue.setting_customer
                              .is_customer_required_login === true
                          ) {
                            window.location.href = "/";
                          } else {
                            window.location.href = "/login";
                          }
                        }
                      }}
                      style={{
                        bottom: 0,
                        position: "absolute",
                        marginBottom: "40px",
                        width: Mobile ? "100%" : "",
                        marginLeft: Mobile ? "" : "211px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <GTranslateIcon size="25" style={{ color: "gray" }} />
                      {lang == "id" ? "English" : "Bahasa"}
                    </div>
                    <div
                      style={{
                        bottom: 0,
                        position: "absolute",
                        marginBottom: "20px",
                        width: Mobile ? "100%" : "",
                        marginLeft: Mobile ? "" : "138px",
                        textAlign: "center",
                      }}
                    >
                      {t("need_help")} ?{" "}
                      <a
                        href={`https://wa.me/${
                          landingPageValue
                            ? landingPageValue.user_activation
                            : ""
                        }`}
                        target="_blank"
                        style={{
                          color: `${
                            ThemeColor ? ThemeColor : "#FFFFFF"
                          } !important`,
                          marginLeft: "5px",
                        }}
                      >
                        <span
                          style={{ color: ThemeColor ? ThemeColor : "#FFFFFF" }}
                        >
                          {t("contact_us")}.
                        </span>
                      </a>
                    </div>
                  </ValidatorForm>
                )}
              </TabPanel>
              <TabPanel value={value} index={1} className="w-100">
                {whatsapp ? (
                  <ValidatorForm
                    onSubmit={() => {
                      console.log("hello");
                    }}
                  >
                    <Grid item xs={12} style={{ margin: "15px 15px" }}>
                      <TextValidator
                        label="Phone Number"
                        fullWidth={true}
                        // onChange={handleChangeLogin}
                        name="phonenumber"
                        variant="standard"
                        validators={["required"]}
                        errorMessages={["Please enter phone number"]}
                        InputLabelProps={{ style: { fontSize: 15 } }}
                      />
                    </Grid>
                    <div className={classes.buttonColor + " " + " finish"}>
                      <button className="finish-btn text-uppercase mt-1">
                        {open1 === true ? (
                          <div style={{ paddingTop: "3px" }}>
                            <BeatLoader size={12} color={"white"} />
                          </div>
                        ) : (
                          "Continue"
                        )}
                      </button>
                    </div>
                  </ValidatorForm>
                ) : (
                  <ValidatorForm
                    // useRef="form"
                    onSubmit={handleSubmitSignUp}
                    noValidate={true}
                  >
                    <Grid item xs={12} style={{ margin: "15px 15px" }}>
                      <TextValidator
                        id="standard-basic"
                        label={t("name")}
                        variant="standard"
                        fullWidth={true}
                        onChange={handleChangeSignup}
                        name="name"
                        value={signup.name}
                        validators={["required"]}
                        errorMessages={["Please enter name"]}
                        InputLabelProps={{ style: { fontSize: 15 } }}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ margin: "15px 15px" }}>
                      <TextValidator
                        id="standard-basic"
                        label={t("email")}
                        variant="standard"
                        fullWidth={true}
                        onChange={handleChangeSignup}
                        name="email"
                        value={signup.email}
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          "Please enter email",
                          "Please enter valid email",
                        ]}
                        InputLabelProps={{ style: { fontSize: 15 } }}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ margin: "15px 15px" }}>
                      <TextValidator
                        label={t("phone_number")}
                        onChange={handleChangeSignup}
                        name="phone"
                        fullWidth={true}
                        value={signup.phone}
                        variant="standard"
                        validators={[
                          "required",
                          "minStringLength:10",
                          "maxStringLength:20",
                        ]}
                        errorMessages={[
                          "Please enter phone number",
                          "Please enter valid phone number",
                          "Please enter valid phone number",
                        ]}
                        InputLabelProps={{ style: { fontSize: 15 } }}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ margin: "15px 15px" }}>
                      <TextValidator
                        className={classes.login_signUp_password}
                        label={t("password")}
                        fullWidth={true}
                        onChange={handleChangeSignup}
                        name="password"
                        type={showPassword2 ? "text" : "password"}
                        value={signup.password}
                        variant="standard"
                        validators={["required"]}
                        errorMessages={["Please enter password"]}
                        InputLabelProps={{ style: { fontSize: 15 } }}
                        InputProps={{
                          endAdornment:
                            type === "password" ? (
                              <InputAdornment
                                position="start"
                                classes={{ positionStart: "0px" }}
                              >
                                <IconButton
                                  onClick={handleClickShowPassword2}
                                  style={{
                                    color: ThemeColor ? ThemeColor : "#FFFFFF",
                                  }}
                                >
                                  {showPassword2 ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} className="mb-0 mx-1" style={{}}>
                      <div
                        // className="d-flex justify-content-between"
                        style={{
                          marginLeft: "13px",
                          marginRight: "13px",
                          fontSize: "13px",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            flexShrink: 0,
                            flexWrap: "wrap",
                          }}
                        >
                          <span>{t("click_to_register")}</span>
                          <span
                            style={{
                              color: ThemeColor ? ThemeColor : "#FFFFFF",
                              display: "flex",
                              flexShrink: 0,
                              borderBottom: `0.5px solid ${
                                ThemeColor ? ThemeColor : "#FFFFFF"
                              }`,
                              marginLeft: "5px",
                              cursor: "pointer",
                            }}
                          >
                            {t("terms")}
                          </span>
                          <span
                            style={{
                              color: ThemeColor ? ThemeColor : "#FFFFFF",
                              display: "flex",
                              flexShrink: 0,
                              borderBottom: `0.5px solid ${
                                ThemeColor ? ThemeColor : "#FFFFFF"
                              }`,
                              marginRight: "5px",
                              cursor: "pointer",
                            }}
                          >
                            {t("condition")}
                          </span>
                          {t("also")}
                          <span
                            style={{
                              color: ThemeColor ? ThemeColor : "#FFFFFF",
                              display: "flex",
                              flexShrink: 0,
                              borderBottom: `0.5px solid ${
                                ThemeColor ? ThemeColor : "#FFFFFF"
                              }`,
                              marginLeft: "5px",
                              cursor: "pointer",
                              marginRight:
                                JSON.parse(localStorage.getItem("Language")) ==
                                "id"
                                  ? "5px"
                                  : 0,
                            }}
                          >
                            {t("privacy")}
                          </span>
                          {t("our_shop")}
                        </span>
                      </div>
                    </Grid>
                    <div className={classes.buttonColor + " " + " finish"}>
                      <button
                        className="finish-btn mt-3 text-uppercase"
                        // onClick={() => {
                        //   signUp();
                        // }}
                        // type="submit"
                      >
                        {open2 === true ? (
                          <div style={{ paddingTop: "3px" }}>
                            <BeatLoader size={12} color={"white"} />
                          </div>
                        ) : (
                          t("register1")
                        )}
                      </button>
                    </div>
                    {/* {landingPageValue&&landingPageValue.data?landingPageValue.data.login_register_whatsapp === 1 ||
                    landingPageValue.data.facebook_login == 1 ||
                    landingPageValue.data.google_login == 1 ? (
                      <div style={{ margin: "14px" }}>
                        <div style={{ borderBottom: "1px solid grey" }}></div>
                        <div style={{ marginTop: "-12px", display: "flex" }}>
                          <div
                            style={{
                              backgroundColor: "transparent",
                              width: "42.5%",
                            }}
                          ></div>
                          <div
                            style={{
                              borderRadius: "50px",
                              backgroundColor: "white",
                              width: "15%",
                              textAlign: "center",
                            }}
                          >
                            OR
                          </div>
                          <div
                            style={{
                              backgroundColor: "transparent",
                              width: "42.5%",
                            }}
                          ></div>
                        </div>
                      </div>
                    ) : null:null}
                    <div
                      style={{ margin: "14px" }}
                      className={classes.googlebutton}
                    >
                      {landingPageValue&&landingPageValue.data?landingPageValue.data.login_register_whatsapp === 1 ? (
                        <div
                          // onClick={() => {
                          //   // setWhatsapp(false);
                          // }}
                          style={{
                            // height: "36px",
                            height: "2.7rem",
                            borderRadius: "3px",
                            backgroundColor: "#4acd32",
                            color: "white",
                            textTransform: "capitalize",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "10%" }}>
                            <WhatsAppIcon />
                          </div>
                          <div
                            style={{
                              width: "90%",
                              textAlign: "left",
                              marginLeft: "50px",
                            }}
                          >
                            Login Using WhatsApp
                          </div>
                        </div>
                      ) : null:null}
                      {landingPageValue?landingPageValue.data.facebook_login == 1 ? (
                        <div
                          style={{
                            // height: "36px",
                            height: "2.7rem",
                            borderRadius: "3px",
                            backgroundColor: "#455bcd",
                            color: "white",
                            textTransform: "capitalize",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <div style={{ width: "10%" }}>
                            <BsFacebook style={{ width: "24px" }} />
                          </div>
                          <div
                            style={{
                              width: "90%",
                              textAlign: "left",
                              marginLeft: "50px",
                            }}
                          >
                            Login Using FaceBook
                          </div>
                        </div>
                      ) : null:null}
                      {landingPageValue?landingPageValue.data.google_login == 1 ? (
                        <div
                          style={{
                            // height: "36px",
                            height: "2.7rem",
                            borderRadius: "3px",
                            backgroundColor: "white",
                            color: "black",
                            textTransform: "capitalize",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid grey",
                            marginTop: "10px",
                          }}
                        >
                          <div style={{ width: "10%" }}>
                            <FcGoogle style={{ width: "24px" }} />
                          </div>
                          <div
                            style={{
                              width: "90%",
                              textAlign: "left",
                              marginLeft: "50px",
                            }}
                          >
                            Login Using Google
                          </div>
                        </div>
                      ) : null:null}
                    </div> */}
                  </ValidatorForm>
                )}
              </TabPanel>
            </div>
          </div>
          {loginMessage ? (
            <BasicModal
              isOpen={true}
              title="Login"
              content={loginMessageValue}
              onClose={() => setloginMessage(false)}
            />
          ) : null}
          {/* {signupmsg ? <SignupAlert /> : ""} */}
          {signupmsg.open ? (
            <BasicModal
              isOpen={true}
              title="Register"
              content={signupmsg.message}
              onClose={() =>
                setSignupMsg({
                  open: false,
                  message_type: "",
                  message: "",
                })
              }
            />
          ) : null}
        </ThemeProvider>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            paddingTop: "250px",
            paddingBottom: "300px",
            flexDirection: "column",
            height: "100vh",
            backgroundColor: "white",
          }}
        >
          <div>
            <Loader />
          </div>
          <div>Loading...</div>
        </div>
      )}
    </div>
  );
};
export default withNamespaces()(Form);
