import { instance } from "../helper/api";

export const signIn = async (data) => {
  let resultData = {};
  const axiosConfig = {
    url: "/shopi/v1/customer/sign-in/",
    method: "POST",
    data: data,
  };

  try {
    const results = await instance.request(axiosConfig);
    resultData = results.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};

export const getProfile = async (token) => {
  let resultData = {};
  const axiosConfig = {
    url: "/shopi/v1/customer/profile/",
    method: "GET",
  };

  try {
    const results = await instance.request(axiosConfig);
    resultData = results.data.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};

export const getProfileDetail = async (token) => {
  let resultData = {};
  const axiosConfig = {
    url: "/shopi/v1/customer/profile/",
    method: "GET",
  };

  try {
    const results = await instance.request(axiosConfig);
    resultData = results.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};

export const signUp = async (data) => {
  let resultData = {};
  const axiosConfig = {
    url: "/shopi/v1/customer/sign-up/",
    method: "POST",
    data: data,
  };

  try {
    const results = await instance.request(axiosConfig);
    resultData = results.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};

export const updateProfile = async (data, token) => {
  let resultData = {};
  const axiosConfig = {
    url: "/shopi/v1/customer/profile/",
    method: "PATCH",
    data: data,
  };

  try {
    const results = await instance.request(axiosConfig);
    resultData = results.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};

export const updateProfilePicture = async (data, token) => {
  let resultData = {};
  const axiosConfig = {
    url: "/shopi/v1/customer/profile/picture/",
    method: "PATCH",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  try {
    const results = await instance.request(axiosConfig);
    resultData = results.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};

export const updatePassword = async (data, token) => {
  let resultData = {};
  const axiosConfig = {
    url: "/shopi/v1/customer/profile/password/",
    method: "PATCH",
    data: data,
  };

  try {
    const results = await instance.request(axiosConfig);
    resultData = results.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};
