import { createSlice } from "@reduxjs/toolkit";
import { getProducts } from "../../../api/products";

const initialState = {
  allProducts: [],
  loading: "idle",
};

export const allProducts = createSlice({
  name: "allProducts",
  initialState,
  reducers: {
    allProductsLoading(state, action) {
      state.loading = action.payload;
    },

    allProductsReceived(state, action) {
      state.allProducts = action.payload;
    },
  },
});

export const { allProductsLoading, allProductsReceived } = allProducts.actions;

const fetchAllProducts = () => async (dispatch) => {
  try {
    dispatch(allProductsLoading("loading"));
    const products = await getProducts(
      JSON.stringify({
        page: 1,
        limit: 20,
      })
    );

    if (products.success) {
      dispatch(allProductsReceived(products.data == null ? [] : products.data));
      dispatch(allProductsLoading("success"));
    } else {
      dispatch(allProductsLoading("error"));
    }
  } catch (error) {
    console.log("async reducer ", error);
    dispatch(allProductsLoading("error"));
  }
};

export { fetchAllProducts };

export default allProducts.reducer;
