import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Countdown, { zeroPad } from "react-countdown";
import NavigationTab from "../home/bottomnav/NavigationTab";
import { useHistory } from "react-router-dom";
// import { myOrderProductUser } from "../../store/actions/myOrderActions";
// import { MyOrderProductResponse } from "../../store/Selectors/myorder";
import { CircularProgress } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import { ThemeColor } from "../themecolor";
import Loader from "../loader";
import { withNamespaces } from "react-i18next";
import i18n from "../language/localization";
import { get as getOrders } from "../../api/order";

const Completionist = () => <span>You are good to go!</span>;

const renderer = ({ hours, minutes, seconds, days, completed }) => {
  if (completed) {
    return <Completionist />;
  } else {
    return (
      <span>
        <span className="text-white count">{zeroPad(days)}</span>
        <span className=" text-white count">{zeroPad(hours)}</span>:
        <span className="text-white count ">{zeroPad(minutes)}</span>:
        <span className="text-white count">{zeroPad(seconds)}</span>
      </span>
    );
  }
};

const useStyles = makeStyles((theme) => ({
  orderBox: {
    border: "0.125rem solid #fff",
    minHeight: "6.25rem",
    backgroundColor: "#fff",
    borderRadius: "4px",
    fontWeight: "400",
    marginLeft: "10px !important",
    marginRight: "10px !important",
    "& h5": {
      fontSize: "14px",
      margin: "0.25rem 0",
    },
  },
  orderTable: {
    width: "100%",
    fontSize: "14px",
    "& tr td:nth-child(1)": {
      width: "45%",
      paddingLeft: "0.5rem",
    },
    "& tr:nth-child(1)": {
      color: "rgba(58, 120, 58, 1)",
    },
    "& td": {
      fontWeight: "500",
    },
    "& tr td:nth-child(3)": {
      paddingLeft: "0.5rem",
    },
  },
  itemSpace: {
    "& span": {
      // marginLeft: "0.5rem",
    },
  },
  myDiv: {
    background: "white",
  },
  myButton: {
    backgroundColor: "white",
  },
}));

const Finish = ({ t, color }) => {
  const classes = useStyles();
  const history = useHistory();
  let [responseData, setResponseData] = React.useState([]);
  let [isNext, isNextFunc] = React.useState(false);
  let [pageCount, setCount] = React.useState(1);
  let [page, setPage] = React.useState("");
  let [status, setStatus] = React.useState(false);
  let [open, setOpen] = React.useState(false);
  const loadMoreData = () => {
    setCount(pageCount + 1);
  };
  React.useEffect(() => {
    if (pageCount !== 1) isNextFunc(true);
    // const userid = JSON.parse(localStorage.getItem("userid"));
    // var data3 = new FormData();
    // data3.append('user_id', userid);
    // data3.append('type', 'FINISH');
    // data3.append('page', pageCount);
    // dispatch(myOrderProductUser(data3));
    // setTimeout(() => {
    //   setOpen(true);
    // }, 700)
    const getOrderData = async () => {
      try {
        // belum done hardcode status
        const response = await getOrders(
          localStorage.getItem("token"),
          `{"order_status": "COMPLETED", "page": ${pageCount}, "limit": 15}`
        );
        if (response.success) {
          if (response.data && response.data.length > 0) {
            if (pageCount === 1) {
              setResponseData(response.data);
            } else {
              const dataValue = responseData.concat(response.data);
              setResponseData(dataValue);
            }
            isNextFunc(true);
            setPage(response.meta.total_pages);
            localStorage.setItem(
              "finishorder",
              JSON.stringify(response.meta.total_count)
            );
          }
        }
      } catch (error) {
        console.log("Error fetching get order:", error);
      }
    };
    getOrderData();
  }, [pageCount]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setStatus(true)
  //   }, 1200);
  //   return () => clearTimeout(timer);
  // }, []);

  // const data1 = MyOrderProductResponse();
  // console.log(data1);
  // if (open === true) {
  //   setResponseData([...responseData, ...data1.FINISH]);
  //   isNextFunc(true);
  //   setPage(data1.total_page);
  //   setOpen(false);
  //   localStorage.setItem("finishorder", JSON.stringify(data1.total_record));
  // }

  const [orderId, setOrderId] = useState([]);
  const setOrder = (e) => {
    console.log("responseData[i].id", responseData);
    console.log("responseData[i] e", e);
    for (var i = 0; i < responseData.length; i++) {
      if (responseData[i].id === e) {
        setOrderId(responseData[i]);
      }
    }
  };

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  }, []);
  return (
    <React.Fragment>
      {responseData.length > 0 ? (
        <InfiniteScroll
          style={{ minHeight: "750px", backgroundColor: "#FAFAFA" }}
          dataLength={responseData.length}
          next={loadMoreData}
          hasMore={isNext}
          // loader={
          //   <div style={{ height: "30%", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "8px" }}>
          //     {pageCount < page ? (
          //       <ClipLoader color={ThemeColor} height={50} style={{ marginTop: "8px", marginBottom: "8px", fontSize: "7", fontWeight: "600" }} />
          //     ) : (
          //       ""
          //     )}
          //   </div>
          // }
        >
          {responseData.map((order, idx) => (
            <div
              key={idx}
              className={`${classes.orderBox} p-2 py-1 mt-2 mb-3 shadow-card rounded mb-3`}
            >
              <table className={classes.orderTable}>
                <tbody>
                  <tr>
                    <td>{t("no_invoice")}</td>
                    <td>:</td>
                    <td>
                      {order.order.order_number !== ""
                        ? "#" + order.order.order_number
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("receiver_name")}</td>
                    <td>:</td>
                    <td>
                      <div
                        style={{
                          width: "100%",
                          height: "22px",
                          overflow: "hidden",
                          display: "flex",
                          flexShrink: "0",
                          fontSize: "14px",
                        }}
                      >
                        {order.order.customer_address
                          ? order.order.customer_address.address
                          : ""}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("date")}</td>
                    <td>:</td>
                    <td>
                      {moment(order.order.created_at).format(
                        "DD-MM-YYYY hh:mm"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("shipping")}</td>
                    <td>:</td>
                    {/* belum done shipping data */}
                    {/* <td>
                      {Object.keys(order.order.selfpickup).length > 0 ?
                        order.order.selfpickup.name :
                        Object.keys(order.order.selfcourier).length > 0 ?
                          order.order.selfcourier.name :
                          Object.keys(order.order.shippingdetail).length > 0 ?
                            order.order.shippingdetail.courier_code + " " + order.order.shippingdetail.service_code + "  (" + order.order.shippingdetail.etd + " Day)"
                            : ""
                      }
                    </td> */}
                  </tr>
                  <tr>
                    <td>{t("payment")}</td>
                    <td>:</td>
                    {/* if payment bank transfer black or installment badge  */}
                    {/* {order.order.payment !== "Installment" ? (
                      <td>{order.order.payment}</td>
                    ) : (
                      <td>
                        <div className="d-flex ms-1 my-1 justify-content-start gap-1">
                          <p className="mb-0 mx-1 rounded-pill px-2 installment">
                            Installment
                          </p>
                          <p className="border px-3 mx-1 my-0 rounded-pill details">
                            Details
                          </p>
                        </div>
                      </td>
                    )} */}
                    <td>{order.order.payment_method}</td>
                  </tr>
                  <tr>
                    <td>{t("status")}</td>
                    <td>:</td>
                    {/* <div className=""> */}
                    <td className="text-uppercase status">
                      {order.order.order_status}
                    </td>
                    {/* </div> */}
                  </tr>
                  {/* <tr className="">
                <td>Auto Cancel</td>
                <td>:</td>
                <td>
                  <div className="my-1">
                    <Countdown
                      date={Date.now() +  JSON.parse(order.order.order_timer*1000)}
                      renderer={renderer}
                      zeroPadTime={2}
                      zeroPadDays={3}
                    />
                  </div>
                </td>
              </tr> */}
                </tbody>
              </table>
              <div
                className="d-flex justify-content-between p-2 rounded grey-box mt-2"
                style={{ backgroundColor: "#F0F4F7" }}
              >
                <h5 className={classes.itemSpace}>
                  Total Qty: <span>{order.order.item_qty} PCS</span>
                </h5>
                <h5 className={classes.itemSpace}>
                  {t("payment")} Rp.
                  <b>
                    {Object.keys(order.order.grand_total.toString()).length >
                    0 ? (
                      Object.keys(order.order.grand_total.toString()).length <=
                      3 ? (
                        <span>{order.order.grand_total.toString()}</span>
                      ) : Object.keys(order.order.grand_total.toString())
                          .length == 4 ? (
                        <span>
                          {order.order.grand_total.toString().substr(-4, 1)}.
                          {order.order.grand_total.toString().substr(-3, 3)}
                        </span>
                      ) : Object.keys(order.order.grand_total.toString())
                          .length == 5 ? (
                        <span>
                          {order.order.grand_total.toString().substr(-5, 2)}.
                          {order.order.grand_total.toString().substr(-3, 3)}
                        </span>
                      ) : Object.keys(order.order.grand_total.toString())
                          .length == 6 ? (
                        <span>
                          {order.order.grand_total.toString().substr(-6, 3)}.
                          {order.order.grand_total.toString().substr(-3, 3)}
                        </span>
                      ) : Object.keys(order.order.grand_total.toString())
                          .length == 7 ? (
                        <span>
                          {order.order.grand_total.toString().substr(-7, 1)}.
                          {order.order.grand_total.toString().substr(-6, 3)}.
                          {order.order.grand_total.toString().substr(-3, 3)}
                        </span>
                      ) : Object.keys(order.order.grand_total.toString())
                          .length == 8 ? (
                        <span>
                          {order.order.grand_total.toString().substr(-8, 2)}.
                          {order.order.grand_total.toString().substr(-6, 3)}.
                          {order.order.grand_total.toString().substr(-3, 3)}
                        </span>
                      ) : Object.keys(order.order.grand_total.toString())
                          .length == 9 ? (
                        <span>
                          {order.order.grand_total.toString().substr(-9, 3)}.
                          {order.order.grand_total.toString().substr(-6, 3)}.
                          {order.order.grand_total.toString().substr(-3, 3)}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </b>
                </h5>
              </div>
              <div
                className="d-flex justify-content-evenly mt-2"
                style={{ gap: "10px" }}
              >
                {order.order.payment_status === "paid" ? (
                  <Button
                    variant="contained"
                    className="w-100 text-uppercase bg-success"
                    style={{ color: "white", position: "unset" }}
                    // if confirmed green if not pink
                  >
                    Tracking Order
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="w-100 text-uppercase"
                    style={{
                      color: "white",
                      position: "unset",
                      background: color,
                    }}
                  >
                    Tracking Order
                  </Button>
                )}

                <Button
                  variant="contained"
                  className="w-100 shadow-card"
                  style={{
                    color: "white",
                    position: "unset",
                    background: color,
                  }}
                  onClick={() => {
                    localStorage.setItem("ordersdata", JSON.stringify([order]));
                    localStorage.setItem("ordertab", JSON.stringify(4));
                    history.push("/orderdetail");
                  }}
                >
                  {t("order_details")}
                </Button>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "40px",
            paddingBottom: "40px",
            backgroundColor: "#f8f9fa",
            height: "100%",
            minHeight: "740px",
          }}
        >
          No Data Found
        </div>
      )}
      {/* <br />
      <br />
      <NavigationTab /> */}
    </React.Fragment>
  );
};

export default withNamespaces()(Finish);
