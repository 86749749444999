import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  body: {
    "& header": {
      display: "flex",
      gap: "15px",
      alignItems: "center",
    },
    "& .checkbox": {
      display: "flex !important",
      gap: "10px",
      alignItems: "center",
    },
    "& .css-label": {
      marginBottom: "0",
    },
    "& input[type='checkbox']": {
      accentColor: "#ff3a59",
      height: "20px",
      width: "20px",
      cursor: "pointer",
    },
  },
  content: {
    paddingTop: "30px",
  },
  title: {
    fontSize: "18px",
    color: "#545454",
    fontWeight: "700",
    marginBottom: "10px",
  },

  input_style: {
    "& input": {
      width: '300px',

    },

    "& .label": {
      width: "32.4%",
    },
    "& input:focus": {
      boxShadow: "none",
      border: props => "1px solid "+props.themeColor,
    },
  },

  select_style: {
    marginBottom: "15px",
    // "& select": {
    display: "block",
    width: "332px",
    // marginLeft: "34%",
    // height: "28px",
    padding: "10px 10px",
    fontSize: "1rem",
    lineHeight: "1.5",
    color: "#495057",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    // },
    "& .MuiInput-underline:before, .MuiInput-underline:after": {
      display: "none",
    },
    "& input:focus": {
      boxShadow: "none",
      border: props => "1px solid "+props.themeColor,
    },
  },

  input_leftTitle: {
    display: "flex",
    gap: "15px",
    width: "100%",
  },

  label: {
    fontSize: "14px",
    color: "#575757",
    marginBottom: "10px",
    textAlign: "right",
    width: "32.4%",
  },
});

export { useStyles };
