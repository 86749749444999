import { Box, Container, Grid } from "@material-ui/core";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import DesktopHeaderWrap from "../../components/DeskTopHeaderWrap";
import DeskTopFooter from "../home/DesktopFooter";
import { useStyles } from "./AddToCart.style";
import CartLeft from "./compo/left/CartLeft";
import CartRight from "./compo/right/CartRight";
import { baseUrlApi } from "../../helper/api";
import { get as getCart } from "../../api/cart";
import { getBadgeCart } from "../../api/badge";

const AddToCart = (props) => {
  const { webThemeColor } = props;
  const classes = useStyles();
  const [itemsCart, setItems] = useState(0);
  const [categoriesData, setCategoriesData] = useState([]);
  const Usercode1 = JSON.parse(localStorage.getItem("usercode"));
  const mycartdata = async () => {
    // let data = new FormData();
    // data.append("user_code", Usercode1);
    // data.append("current_date", moment().format("YYYY-MM-DD hh:mm:ss"));
    // dispatch(getMyCartProductUser(data));

    // fetch(`${baseUrlApi()}/Api/Order/myCart`, {
    //   method: "POST",
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   body: data,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setItems(res.data.length)
    //   });
    // const UserId = JSON.parse(localStorage.getItem("userid"));
    if (localStorage.getItem("token")) {
      const cartDataResult = await getBadgeCart(localStorage.getItem("token"));
      if (cartDataResult.success) {
        setItems(cartDataResult.data);
      }
    }
  };

  useEffect(() => {
    mycartdata();

    if (localStorage.getItem("landing_page")) {
      setCategoriesData(
        JSON.parse(localStorage.getItem("landing_page"))["product_categories"]
      );
    }
  }, [Usercode1]);

  useEffect(() => {
    // console.log("is_ useEffect");
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className={classes.body}>
      <DesktopHeaderWrap
        // clickTab={() => setInlineCategory(false)}
        themeColor={"rgb(231, 0, 116)"}
        cartNumber={itemsCart}
        categoriesData={categoriesData}
      />
      <Container>
        <Box className={classes.root_addtocart}>
          <h5 className={classes.pageTitle}></h5>

          <Grid container spacing={2}>
            <CartLeft webThemeColor={webThemeColor} />
          </Grid>
        </Box>
      </Container>
      <DeskTopFooter />
    </div>
  );
};

export default AddToCart;
