import { Button, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DesktopHeaderWrap from "../../../components/DeskTopHeaderWrap";
import DeskTopFooter from "../../home/DesktopFooter";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ProductData from "./ProductData";
import { t } from "i18next";
import i18n from "../../language/localization";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { get as getLandingPage } from "../../../api/landing-page";
import { getByClient as getProductsDetail } from "../../../api/products";
import { getBadgeCart } from "../../../api/badge";

const themeColor = JSON.parse(localStorage.getItem("themeColor"));

// import FillterBy from "./FilterBy";
const useStyles = makeStyles(() => ({
  MainRoot: {
    paddingTop: "1rem",
    zIndex: -1,
    display: "flex",
    alignItems: "start",
    columnGap: "20px",
    width: "1200px",
    margin: "0px auto",
    paddingBottom: "6rem",
  },
  FilterRoot: {
    width: "290px",
    padding: "15px",
    flexFlow: "column",
    background: "#fff",
    height: "100vh",
  },
  filterHead: {
    fontSize: "22px",
    color: "#333",
    margin: "0 0 25px",
    fontWeight: 700,
    lineHeight: "28px",
  },
  nav: {
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: "0",
    marginBottom: "0",
    listStyle: "none",
  },
  nav_list: {
    width: "100%",
    margin: "0 0 20px",
  },
  labelText: {
    fontSize: "14px",
    borderBottom: "2px solid #ff3a59",
    padding: "0 0 10px",
    cursor: "pointer",
    position: "relative",
    fontFamily: '"Open Sans", sans-serif',
    display: "block",
    margin: "0 0 15px",
    color: "#ff3a59",
    transition: "all 0.25s ease-in",
  },
  hoverPointer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  sortByRoot: {
    color: "#000",
    borderRadius: "3px",
    fontSize: "14px",
    border: "1px solid #ccc",
    padding: "3px 10px",
    "&:hover": {
      background: themeColor || "#ffce0b",
      color: "#fff",
      cursor: "pointer",
    },
    height: "2.125rem",
  },

  sortByAv: {
    borderRadius: "3px",
    fontSize: "14px",
    border: "1px solid #ccc",
    padding: "3px 10px",
    background: themeColor || "#ffce0b",
    color: "#fff",
    cursor: "default",
    "&>svg": {
      margin: "0px !important",
      fontSize: "18px !important",
      marginLeft: "6px !important",
    },
    height: "2.125rem",
  },

  sortByRootIcon: {
    color: "#000",
    borderRadius: "3px",
    height: "2.125rem",
    fontSize: "14px",
    border: "1px solid #ccc",
    padding: "3px 10px",
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    "&>svg": {
      margin: "0px !important",
      fontSize: "18px !important",
    },
    "&:hover": {
      background: themeColor || "#ffce0b",
      color: "#fff",
      border: "1px solid #ffce0b",
      cursor: "pointer",
    },
  },
  NaviPageItems: {
    "& button": {
      border: "none",
      background: "none",
      "& i": {
        // color: "#999",
        margin: "0 10px",
      },
      "& span": {
        border: "1px solid #707070",
        borderRadius: "50%",
        padding: "4px 8px",
        marginRight: "5px",
      },
    },
    "& .btnNext": {
      marginLeft: "5px",
    },
  },
  Main: {
    "&>ul li Button": {
      border: "1px solid " + themeColor + "",
      color: "black",
    },
    "&>ul li .MuiPaginationItem-page.Mui-selected": {
      border: "1px solid " + themeColor + "",
      background: themeColor,
      color: "#fff",
    },
    // "&>ul li .MuiPaginationItem-page": {
    //   display: "none"
    // },
  },
}));

const ProductListSearch = (props) => {
  const { webThemeColor } = props;
  // console.log("webThemeColor___ser", webThemeColor);
  // console.log("webThemeColor___themeColor", themeColor);
  const classes = useStyles();
  const CatId2 = JSON.parse(localStorage.getItem("CatId"));
  const [catId, setCatId] = useState();
  const [sortBy, setSortBy] = useState();
  const [isPrice, setIsPrice] = useState(false);
  const [isBestSeller, setIsBestSeller] = useState(false);
  const [isDiscount, setIsDiscount] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [inlineCategory, setInlineCategory] = useState(false);

  const [newPageNo, setNewPageNo] = useState();
  const [MyPage, setMyPage] = useState();
  const [totalRecord, setTotalRecord] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [membershipLevel, setMembershipLevel] = useState(1);
  const [categoriesData, setCategoriesData] = useState([]);
  const [TotalCartItems, setItems] = useState(0);
  const pageChangeMinus = () => {
    setPageNo(pageNo - 1);
    setNewPageNo(pageNo - 1);
  };
  const pageChangePlus = () => {
    setPageNo(pageNo + 1);
    setNewPageNo(pageNo + 1);
  };

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  }, []);

  const searchname = JSON.parse(localStorage.getItem("desktopSearch"));
  const membershipData = JSON.parse(localStorage.getItem("membership"));

  const mycart = async () => {
    if (localStorage.getItem("token")) {
      const cartDataResult = await getBadgeCart(localStorage.getItem("token"));
      if (cartDataResult.success) {
        setItems(cartDataResult.data);
      }
    }
  };

  useEffect(() => {
    // const UserId = localStorage.getItem("userid");
    // const Usercode = localStorage.getItem("usercode");

    // var myHeaders = new Headers();
    // myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");

    // var formdata = new FormData();
    // formdata.append("user_id", UserId);
    // formdata.append("user_code", Usercode);
    // formdata.append("page", "1");
    // formdata.append("search", searchname);

    const getProductsDetailData = async () => {
      try {
        const response = await getProductsDetail(
          `${searchname ? `{"name":"${searchname}"}` : null}`
        );
        setTotalRecord(response.meta.total_count);
        setMyPage(response.meta.total_pages);
      } catch (error) {
        console.log("Error fetching getProductsDetail on productview:", error);
      }
    };

    getProductsDetailData();

    if (localStorage.getItem("landing_page")) {
      setCategoriesData(
        JSON.parse(localStorage.getItem("landing_page"))["product_categories"]
      );
    }

    if (membershipData) {
      setMembershipLevel(membershipData.level);
    }

    mycart();
  }, []);

  const handleClickSetSortByIsNew = (sortByValue, isNewValue) => {
    setSortBy(sortByValue);
    setIsNew(isNewValue);
  };

  const handleClickSetSortByIsDiscount = (sortByValue, isDiscountValue) => {
    setSortBy(sortByValue);
    setIsDiscount(isDiscountValue);
  };

  const handleClickSetSortByBestSeller = (sortByValue, isBestSellerValue) => {
    setSortBy(sortByValue);
    setIsDiscount(isBestSellerValue);
  };

  const handleClickSetSortByPrice = (sortByValue, isPriceValue) => {
    setSortBy(sortByValue);
    setIsPrice(isPriceValue);
  };

  return (
    <div
      style={{ background: "#f5f5f5", height: 750, overflow: "auto" }}
      //   onScroll={firstEvent}
    >
      <DesktopHeaderWrap
        clickTab={() => setInlineCategory(false)}
        cartNumber={TotalCartItems}
        themeColor={"rgb(231, 0, 116)"}
        isFixed={false}
        categoriesData={categoriesData}
      />
      <div className={classes.MainRoot}>
        <div style={{ width: "100%" }}>
          <div
            style={{
              background: "#fff",
              padding: "7px",
              margin: "0 0 5px",
              display: "FLEX",
              columnGap: "10PX",
              alignItems: "left",
              justifyContent: "left",
            }}
          >
            <label
              style={{
                padding: "7px",
                color: "#333",
                fontSize: "20px",
              }}
            >
              {searchname}
            </label>
            <label
              style={{
                paddingTop: "13px",
                color: "#393D4E",
                fontSize: "15px",
              }}
            >
              {totalRecord} {t("product")}
            </label>
            <div
              style={{
                background: "#fff",
                paddingLeft: "160px",
                margin: "0 0 5px",
                display: "FLEX",
                columnGap: "10PX",
                alignItems: "CENTER",
                justifyContent: "center",
              }}
            >
              {/* <div
                    style={{
                      color: "#333",
                      fontWeight: 700,
                    }}
                  >
                    Sort by
                  </div> */}

              {isNew ? (
                <div
                  className={
                    sortBy == 1 ? classes.sortByAv : classes.sortByRoot
                  }
                  onClick={() => {
                    handleClickSetSortByIsNew(null, !isNew);
                  }}
                >
                  {t("new")}
                </div>
              ) : (
                <div
                  className={classes.sortByRootIcon}
                  onClick={() => {
                    handleClickSetSortByIsNew(1, !isNew);
                  }}
                >
                  {t("new")}
                </div>
              )}

              {isDiscount ? (
                <div
                  className={
                    sortBy == 5 ? classes.sortByAv : classes.sortByRoot
                  }
                  onClick={() => {
                    handleClickSetSortByIsDiscount(null, !isDiscount);
                  }}
                >
                  {t("discount")}
                </div>
              ) : (
                <div
                  className={classes.sortByRootIcon}
                  onClick={() => {
                    handleClickSetSortByIsDiscount(5, !isDiscount);
                  }}
                >
                  {t("discount")}
                </div>
              )}

              {isBestSeller ? (
                <div
                  className={
                    sortBy == 2 ? classes.sortByAv : classes.sortByRoot
                  }
                  onClick={() => {
                    handleClickSetSortByBestSeller(null, !isBestSeller);
                  }}
                >
                  {t("best_seller")}
                </div>
              ) : (
                <div
                  className={classes.sortByRootIcon}
                  onClick={() => {
                    handleClickSetSortByBestSeller(2, !isBestSeller);
                  }}
                >
                  {t("best_seller")}
                </div>
              )}

              {isPrice ? (
                <div
                  className={
                    isPrice
                      ? classes.sortByAv
                      : sortBy == 4
                      ? classes.sortByAv
                      : classes.sortByRootIcon
                  }
                  onClick={() => {
                    handleClickSetSortByPrice(4, !isPrice);
                  }}
                >
                  {t("price")} <ArrowDownwardIcon />
                </div>
              ) : (
                <div
                  className={classes.sortByRootIcon}
                  onClick={() => {
                    handleClickSetSortByPrice(3, !isPrice);
                  }}
                >
                  {t("price")} <ArrowUpwardIcon />
                </div>
              )}
            </div>

            <div
              style={{
                display: "FLEX",
                alignItems: "center",
                justifyContent: "right",
                width: "38%",
              }}
            >
              <label style={{ paddingRight: "5px", paddingTop: "10px" }}>
                {pageNo} / {MyPage}
              </label>
              <Button
                variant="outlined"
                style={{
                  maxWidth: "40px",
                  maxHeight: "40px",
                  minWidth: "40px",
                  minHeight: "40px",
                }}
                onClick={pageChangeMinus}
                disabled={pageNo === 1 ? true : false}
              >
                <ArrowBackIosIcon
                  style={{ height: "15px", paddingLeft: "5px" }}
                />
              </Button>
              <Button
                variant="outlined"
                style={{
                  maxWidth: "40px",
                  maxHeight: "40px",
                  minWidth: "40px",
                  minHeight: "40px",
                }}
                onClick={pageChangePlus}
                disabled={pageNo === MyPage ? true : false}
              >
                <ArrowForwardIosIcon
                  style={{ height: "15px", paddingLeft: "5px" }}
                />
              </Button>
            </div>
          </div>

          <div>
            {inlineCategory ? (
              <ProductData
                catId={catId}
                sortBy={sortBy}
                pageNumber={pageNo}
                membershipLevel={membershipLevel}
              />
            ) : (
              <ProductData
                catId={CatId2}
                sortBy={sortBy}
                pageNumber={pageNo}
                membershipLevel={membershipLevel}
              />
            )}
          </div>
        </div>
      </div>
      <DeskTopFooter />
    </div>
  );
};
export default ProductListSearch;
