import React, { useContext } from "react";
import "./header.css";
import { useHistory } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AuthContext } from "../../context/AuthContext";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core";

export default function Header({ title, color }) {
  const { countCart } = useContext(AuthContext);

  const useStyles = makeStyles(() => ({
    badgeStyle: {
      "&>.MuiBadge-anchorOriginTopRightRectangle": {
        top: 10,
      },
    },
  }));
  const classes = useStyles();

  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: Mobile ? "500px" : "100%",
        backgroundColor: color,
        position: "fixed",
        zIndex: "11111",
        marginBottom: "40px",
        userSelect: "none",
      }}
    >
      <div
        className="title-box"
        style={{
          height: "48px",
          maxWidth: Mobile ? "500px" : "100%",
          width: Mobile ? "500px" : "100%",
        }}
      >
        {/* <FontAwesomeIcon
          icon={faArrowLeft}
          className="back-icon ms-3"
          size="1x"
          transform="grow-4"
          onClick={() => {
            history.goBack();
          }}
        /> */}
        <KeyboardBackspaceIcon
          style={{ color: "white", width: "25px" }}
          onClick={() => {
            history.goBack();
          }}
        />
        {/* <img src="/assets/frontend/img/Icon/ic_back.png" style={{height:"19px",width:"19px",margin:"9px 14px"}} /> */}
        {/* <div>
        <IoIosArrowRoundBack size="40" 
          className="back-icon"
          // style={{marginLeft:"0px !important",marginRight:"0px !important" }}
          onClick={() => {
            history.goBack();
          }} /></div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <h1 className="title mb-0">{title}</h1>
          <div
            style={{
              paddingRight: "10px",
            }}
          >
            <Badge
              badgeContent={countCart}
              color="error"
              className={classes.badgeStyle}
            >
              <svg
                style={{
                  fill: "transparent",
                  cursor: "pointer",
                  height: "24px",
                  width: "20px",
                }}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  history.push("/cart");
                }}
              >
                <path
                  d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"
                  stroke="#F5F5F5"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
                  stroke="#F5F5F5"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
                  stroke="#F5F5F5"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9 8H21"
                  stroke="#F5F5F5"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Badge>

            <svg
              style={{
                fill: "transparent",
                cursor: "pointer",
                height: "24px",
                width: "24px",
              }}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                history.push("/chat");
              }}
            >
              <path
                d="M8.5 19H8C4 19 2 18 2 13V8C2 4 4 2 8 2H16C20 2 22 4 22 8V13C22 17 20 19 16 19H15.5C15.19 19 14.89 19.15 14.7 19.4L13.2 21.4C12.54 22.28 11.46 22.28 10.8 21.4L9.3 19.4C9.14 19.18 8.77 19 8.5 19Z"
                stroke="#F5F5F5"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.9965 11H16.0054"
                stroke="#F5F5F5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.9955 11H12.0045"
                stroke="#F5F5F5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.99451 11H8.00349"
                stroke="#F5F5F5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

// import React from "react";
// import "./header.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
// import { useHistory } from 'react-router-dom';

// export default function Header() {
//   const history=useHistory();
//   return (
//     <div className="toolbar">
//       <div className="title-box">
//         <FontAwesomeIcon
//           icon={faArrowLeft}
//           className="back-icon"
//           size="1x"
//           transform="grow-4"
//           onClick={()=>{history.push('/dashboard')}}
//         />
//         <h1 className="title text-uppercase mb-0">add new address</h1>
//       </div>
//     </div>
//   );
// }

//3-1-22 working

//  <div style={{
//   display:"flex",
//   justifyContent:"center",
//   alignItems:"center",
//   width:"100%",
//   backgroundColor:"#E70074",
//   position:"fixed",
//   zIndex: "11111",
//   marginBottom:"40px"
// }}>
//   <div className="title-box">
//     <FontAwesomeIcon
//       icon={faArrowLeft}
//       className="back-icon ms-3"
//       size="1x"
//       transform="grow-4"
//       onClick={() => {
//         history.goBack();
//       }}
//     />
//     <h1 className="title text-uppercase mb-0 ps-2">Order History</h1>
//   </div>
// </div>
