import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  card: {
    // boxShadow: "0px 1px 7px 1px #ccc",
    margin: "0 0 30px",
    background: "#fff",
  },
});

const Card = (props) => {
  const classes = useStyles();
  return (
    <div className={`${classes.card} ${props.className}`} style={props.style}>
      {props.children}
    </div>
  );
};

export default Card;
