import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CallIcon from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import EventIcon from "@material-ui/icons/Event";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
// import { GetSplashResponse } from "../../store/Selectors/getSplashData";
// import { getSplashDataUser } from "../../store/actions/getSplashDataActions";
// import { useDispatch } from "react-redux";
import i18n from "../language/localization";
import { withNamespaces } from "react-i18next";

const DeskTopFooter = ({ t }) => {
  const themeColor = JSON.parse(localStorage.getItem("themeColor"));
  const useStyles = makeStyles((theme) => ({
    Root: {
      height: "auto",
      display: "flex",
      background: "white",
      alignItems: "center",
      paddingTop: "2rem",
      flexDirection: "column",
      rowGap: "30px",
    },
    CopyRightDiv: {
      color: 'white',
      width: '110%',
      background: themeColor,
      borderTop: '1px solid white',
      textAlign: 'center',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
    FooterMenu: {
      display: "flex",
      flexDirection: "column",
      color: "#323232",
      rowGap: 7,
    },
    FooterList: {
      display: "flex",
      width: "1200px",
      margin: "0px auto",
      columnGap: "72px",
    },
    FooterMenuIcon: {
      display: "flex",
      flexDirection: "column",
      color: "#323232",
    },
    FooterMenuImage: {
      display: "flex",
      flexDirection: "column",
      color: "#323232",
      rowGap: 4,
    },
    FooterMenuSpan: {
      width: "180px",
      fontSize: 16,
      "&:hover": {
        //   borderBottom: "1px solid white",
        cursol: "pointer",
      },
    },
    footerMenuIcone: {
      display: "flex",
      columnGap: 2,
      alignItems: "center",
      width: "100%",
      fontSize: 16,
      "&>.MuiSvgIcon-root": {
        marginLeft: "0px !important",
      },
      "&:hover": {
        borderBottom: "1px solid white",
        cursol: "pointer",
      },
    },

    aLink_style: {
      color: "inherit !important",
    },
  }));
  const classes = useStyles();

  // Get Footer Data
  // const dispatch = useDispatch();
  useEffect(() => {
    let data = new FormData();
    // dispatch(getSplashDataUser(data));
  }, []);

  const landingPageValue = JSON.parse(localStorage.getItem("landing_page"));
  const footer = landingPageValue;

  const DataHelp = [{ slug: "How to buy", title: "How to buy" }];
  const DataCustomer = [
    {
      icon: <CallIcon />,
      // name: "+6281219082008",
      name: footer && footer.setting_customer ? footer.setting_customer.phone_services : null,
    },
    {
      icon: <MailIcon />,
      // name: "shopidotid@gmail.com",
      name: footer ? footer.email : null,
    },
    {
      icon: <WhatsAppIcon />,
      // name: "+6281219082008",
      name: footer ? footer.whats_app : null,
    },
    {
      icon: <EventIcon />,
      name: footer ? footer.working_day : null,
      // name: "Monday - Saturday (Sunday OFF)",
    },
    {
      icon: <WatchLaterIcon />,
      // name: "09.00 - 18.30",
      name: footer ? footer.working_hours : null,
    },
  ];
  const DataFollow = [
    {
      icon: <FacebookIcon />,
      name: "Facebook",
      aLink: footer ? "https://" + footer.facebook : null,
    },
    {
      icon: <InstagramIcon />,
      name: "Instagram",
      aLink: footer ? "https://" + footer.instagram : null,
    },
    {
      icon: <TwitterIcon />,
      name: "Twitter",
      aLink: footer ? footer.twitter : null,
    },
  ];
  const DataApp = [
    {
      img: "/assets/images/googleplay.png",
      aLink: footer && footer.vendor_setting_application ? footer.vendor_setting_application.apk : null,
    },
    {
      img: "/assets/images/appstore.png",
      aLink: footer && footer.vendor_setting_application ? footer.vendor_setting_application.apk : null,
    },
  ];

  const DataSstem = [
    {
      img: "/assets/images/norton.png",
    },
    {
      img: "/assets/images/comodo.png",
    },
    {
      img: "/assets/images/pci.png",
    },
  ];

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  }, []);

  return (
    <div className={classes.Root}>
      <div className={classes.FooterList}>
        <FooterMenu title={t('info')} data={landingPageValue ? landingPageValue.menu_pages : null} />
        <FooterMenu title={t('help')} data={DataHelp} />
        <FooterMenuWithIcon title={t('customer_care')} data={DataCustomer} />
        <FooterMenuWithIcon title={t('follow_us')} data={DataFollow} />
        <FooterMenuWithImage title={t('download_our_app')} data={DataApp} />
        {/* <FooterMenuWithImage title="Security System" data={DataSstem} /> */}
      </div>
      <div className={classes.CopyRightDiv}>
        Copyright @2024 All Right Reserved
      </div>
    </div>
  );
};
export default withNamespaces()(DeskTopFooter);
const FooterMenu = ({ title, data }) => {
  const useStyles = makeStyles((theme) => ({
    Root: {
      height: "auto",
      display: "flex",
      background: "white",
      alignItems: "center",
      paddingTop: "2rem",
      flexDirection: "column",
      rowGap: "30px",
    },
    CopyRightDiv: {
      color: "white",
      borderTop: "1px solid white",
      width: "100%",
      textAlign: "center",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    FooterMenu: {
      display: "flex",
      flexDirection: "column",
      color: "#323232",
      rowGap: 7,
    },
    FooterList: {
      display: "flex",
      width: "1200px",
      margin: "0px auto",
      columnGap: "72px",
    },
    FooterMenuIcon: {
      display: "flex",
      flexDirection: "column",
      color: "#323232",
    },
    FooterMenuImage: {
      display: "flex",
      flexDirection: "column",
      color: "#323232",
      rowGap: 4,
    },
    FooterMenuSpan: {
      width: "180px",
      fontSize: 16,
      "&:hover": {
        //   borderBottom: "1px solid white",
        cursol: "pointer",
      },
    },
    footerMenuIcone: {
      display: "flex",
      columnGap: 2,
      alignItems: "center",
      width: "100%",
      fontSize: 16,
      "&>.MuiSvgIcon-root": {
        marginLeft: "0px !important",
      },
      "&:hover": {
        borderBottom: "1px solid white",
        cursol: "pointer",
      },
    },

    aLink_style: {
      color: "inherit !important",
    },
  }));
  const classes = useStyles();
  return (
    <div style={{width:'100%'}}>
      <h5 style={{ color: "#323232" }}>{title}</h5>
      <div className={classes.FooterMenu}>
        {data
          ? data.map((dt) => {
              return <a href={`/${dt.slug}`} target="_blank" className={classes.aLink_style}><span className={classes.FooterMenuSpan}>{dt.title}</span></a>
              
            })
          : null}
      </div>
    </div>
  );
};
const FooterMenuWithIcon = ({ title, data }) => {
  const useStyles = makeStyles((theme) => ({
    Root: {
      height: "auto",
      display: "flex",
      background: "white",
      alignItems: "center",
      paddingTop: "2rem",
      flexDirection: "column",
      rowGap: "30px",
    },
    CopyRightDiv: {
      color: "white",
      borderTop: "1px solid white",
      width: "100%",
      textAlign: "center",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    FooterMenu: {
      display: "flex",
      flexDirection: "column",
      color: "#323232",
      rowGap: 7,
    },
    FooterList: {
      display: "flex",
      width: "1200px",
      margin: "0px auto",
      columnGap: "72px",
    },
    FooterMenuIcon: {
      display: "flex",
      flexDirection: "column",
      color: "#323232",
    },
    FooterMenuImage: {
      display: "flex",
      flexDirection: "column",
      color: "#323232",
      rowGap: 4,
    },
    FooterMenuSpan: {
      width: "180px",
      fontSize: 16,
      "&:hover": {
        //   borderBottom: "1px solid white",
        cursol: "pointer",
      },
    },
    footerMenuIcone: {
      display: "flex",
      columnGap: 2,
      alignItems: "center",
      width: "100%",
      fontSize: 16,
      "&>.MuiSvgIcon-root": {
        marginLeft: "0px !important",
      },
      "&:hover": {
        borderBottom: "1px solid white",
        cursol: "pointer",
      },
    },

    aLink_style: {
      color: "inherit !important",
    },
  }));
  const classes = useStyles();
  return (
    <div style={{width:'100%'}}>
      <h5 style={{ color: "#323232" }}>{title}</h5>
      <div className={classes.FooterMenuIcon}>
        {data.map((dt) => {
          return (
            <a href={dt.aLink} target="_blank" className={classes.aLink_style}>
              <div className={classes.footerMenuIcone}>
                {dt.icon}
                <span>{dt.name}</span>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};
const FooterMenuWithImage = ({ title, data }) => {
  console.log("data FooterMenuWithImage",data)
  const useStyles = makeStyles((theme) => ({
    Root: {
      height: "auto",
      display: "flex",
      background: "white",
      alignItems: "center",
      paddingTop: "2rem",
      flexDirection: "column",
      rowGap: "30px",
    },
    CopyRightDiv: {
      color: "white",
      borderTop: "1px solid white",
      width: "100%",
      textAlign: "center",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    FooterMenu: {
      display: "flex",
      flexDirection: "column",
      color: "#323232",
      rowGap: 7,
    },
    FooterList: {
      display: "flex",
      width: "1200px",
      margin: "0px auto",
      columnGap: "72px",
    },
    FooterMenuIcon: {
      display: "flex",
      flexDirection: "column",
      color: "#323232",
    },
    FooterMenuImage: {
      display: "flex",
      flexDirection: "column",
      color: "#323232",
      rowGap: 4,
    },
    FooterMenuSpan: {
      width: "180px",
      fontSize: 16,
      "&:hover": {
        //   borderBottom: "1px solid white",
        cursol: "pointer",
      },
    },
    footerMenuIcone: {
      display: "flex",
      columnGap: 2,
      alignItems: "center",
      width: "100%",
      fontSize: 16,
      "&>.MuiSvgIcon-root": {
        marginLeft: "0px !important",
      },
      "&:hover": {
        borderBottom: "1px solid white",
        cursol: "pointer",
      },
    },

    aLink_style: {
      color: "inherit !important",
    },
  }));
  const classes = useStyles();
  return (
    <div style={{width:'100%'}}>
      <h5 style={{ color: "#323232" }}>{title}</h5>
      <div className={classes.FooterMenuImage}>
        {data.map((dt) => {
          return (
            <a href={dt.aLink} target="_blank">
              <img src={dt.img} width={150}/>
            </a>
          );
        })}
      </div>
    </div>
  );
};
