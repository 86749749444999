import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { NavLink, useHistory } from "react-router-dom";
import Amount from "../../components/common/Amount";
// import { addWishlistProductUser } from "../../store/actions/addWishlistActions";
// import { GetWishList } from "../../store/actions/GetWishlist";
// import { GetProductDataResponse } from "../../store/Selectors/getproductdata";
// import { GetWishlistSelector } from "../../store/Selectors/GetWishlistSelector";
import NoDataFound from "../myAccount/compo/NoDataFound";
import { useStyles1 } from "../themecolor/FontSize";
import { getByClient as getProductsDetail } from "../../api/products";
import CardProduct from "../../components/card-product/CardProduct";

const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));
const useCSS = makeStyles((theme) => ({
  Main: {
    "&>ul li Button": {
      border: "1px solid " + ThemeColor + "",
      color: "black",
    },
    "&>ul li .MuiPaginationItem-page.Mui-selected": {
      border: "1px solid " + ThemeColor + "",
      background: ThemeColor,
      color: "#fff",
    },
  },

  hoverCard: {
    border: "1px solid #e8e7ea !important",
    transition: "all 0.1s ease-out",
    marginTop: "3px",
    "&:hover": {
      border: "0.5px solid " + ThemeColor + " !important",
      transform: "translateY(-2px) scale(1.005) translateZ(0)",
      cursor: "pointer",
    },
  },

  cardTitle: {
    "& .read-more-less--more": {
      display: "none",
    },
  },
}));

const usercode = JSON.parse(localStorage.getItem("usercode"));
const ProductData = ({ t, catId, sortBy, membershipLevel }) => {
  const classes1 = useStyles1();
  const classes = useCSS();

  // const data = GetProductDataResponse();
  const [productid, setProductId] = useState("");
  const [color, setColor] = useState(false);
  const [heart, setheart] = useState(true);
  const [data2, setData2] = useState([]);
  const [pageScroll, setPageScroll] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  // console.log("data", data);
  // const classes = useStyles();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);
  const [MyData, setMyData] = useState([]);
  const [MyPage, setMyPage] = useState();
  const [NewData, setNewData] = useState([]);
  const [find, setFind] = useState(false);
  const [loading, setLoading] = useState(true);

  // const dispatch = useDispatch();
  const whislist = {};
  const history = useHistory();

  const getUnique = (array, key) => {
    if (typeof key !== "function") {
      const property = key;
      key = function (item) {
        return item[property];
      };
    }
    return Array.from(
      array
        .reduce(function (map, item) {
          const k = key(item);
          if (!map.has(k)) map.set(k, item);
          return map;
        }, new Map())
        .values()
    );
  };
  const CatId = JSON.parse(localStorage.getItem("CatId"));
  const UserId = localStorage.getItem("userid");
  const Usercode = localStorage.getItem("usercode");

  useEffect(() => {
    FetchData();
  }, [catId, sortBy]);
  const FetchData = async () => {
    // var myHeaders = new Headers();
    // myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");

    // var formdata = new FormData();
    // formdata.append("user_id", UserId);
    // formdata.append("user_code", Usercode);
    // formdata.append("page", "1");

    // formdata.append("category", catId);
    // if (catId) {
    //   formdata.append("category", catId);
    // }
    // if (sortBy) {
    //   formdata.append("sort_by", sortBy);
    // }
    let sortByValue = null;
    let sortByAscDesc = null;
    switch (sortBy) {
      // case 1:
      //   sortByValue = `"order": "created_at"`
      //   sortByAscDesc = `"sort": "desc"`
      //   break;
      case 1:
        sortByValue = `"labels": "['Terbaru']"`;
        break;
      case 2:
        sortByValue = `"order": "sold_quantity"`;
        sortByAscDesc = `"sort": "desc"`;
        break;
      case 3:
        sortByValue = `"order": "price.${membershipLevel}"`;
        sortByAscDesc = `"sort": "asc"`;
        break;
      case 4:
        sortByValue = `"order": "price.${membershipLevel}"`;
        sortByAscDesc = `"sort": "desc"`;
        break;
      case 5:
        sortByValue = `"labels": "['Diskon']"`;
        break;
      default:
        break;
    }

    try {
      const response = await getProductsDetail(
        `{"page":1, "limit": 15, "category_id":${catId} ${
          sortByValue ? `,${sortByValue}` : ""
        } ${sortByAscDesc ? `,${sortByAscDesc}` : ""}}`
      );
      setMyPage(response.meta.total_pages);
      // setMyData(response.data);
      setNewData(response.data);
      setFind(false);
      setLoading(false);
      if (response.data && response.data.length < 5) {
        setFind(true);
      }
    } catch (error) {
      console.log("Error fetching getProductsDetail:", error);
    }

    // fetch(`${baseUrlApi()}/Api/Product/getProductData`, {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setMyPage(res.total_page);

    //     setMyData(res.data);
    //     setNewData(res.data);
    //     setFind(false);
    //     setLoading(false);
    //     if (res.data.length < 5) {
    //       setFind(true);
    //     }
    //   });
  };
  const [true1, setTrue1] = useState(false);

  useEffect(() => {
    let myData = new FormData();
    myData.append("user_code", usercode);
    // belum done add wishlist
    // dispatch(GetWishList(myData));
    setTimeout(() => {
      setTrue1(true);
    }, 1000);
  }, []);

  const addWishRed = (e, j) => {
    const usercode = JSON.parse(localStorage.getItem("usercode"));
    let data1 = new FormData();
    data1.append("product_id", e);
    data1.append("user_code", usercode);
    data1.append("date_time", "2018-12-25 10:23:00");
    // belum done add wishlist
    // dispatch(addWishlistProductUser(data1));
    let tmpArr = Object.assign([], NewData);
    let tmpObj = Object.assign({}, tmpArr[j]);
    tmpObj["is_wishlist"] = "1";
    tmpArr[j] = tmpObj;
    setNewData(tmpArr);
  };

  const addWishWhite = (e, j) => {
    const usercode = JSON.parse(localStorage.getItem("usercode"));
    let data1 = new FormData();
    data1.append("product_id", e);
    data1.append("user_code", usercode);
    data1.append("date_time", "2018-12-25 10:23:00");
    // belum done add wishlist
    // dispatch(addWishlistProductUser(data1));
    let tmpArr = Object.assign([], NewData);
    let tmpObj = Object.assign({}, tmpArr[j]);
    tmpObj["is_wishlist"] = "0";
    tmpArr[j] = tmpObj;
    setNewData(tmpArr);
  };
  const pageChange = async (event, value) => {
    // console.log("Pagination", event);

    setPageNo(value);
    // var myHeaders = new Headers();
    // myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");
    // var formdata = new FormData();
    // // formdata.append("user_id", UserId);
    // // formdata.append("user_code", Usercode);
    // formdata.append("category", catId);
    // formdata.append("page", value);
    //
    // fetch(`${baseUrlApi()}/Api/Product/getProductData`, {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setNewData(res.data);
    //     // for (var i = 0; i < res.data.length; i++) {
    //     //   // console.log("iii", res.data[i]);
    //     //   setNewData(res.data[i]);
    //     // //   if (!MyData.includes(res.data[i].id)) {
    //     // //     MyData.push(res.data[i]);

    //     // //     setNewData(getUnique(MyData, "id"));
    //     // //   }
    //     // }
    //   });

    let sortByValue = null;
    let sortByAscDesc = null;
    try {
      switch (sortBy) {
        // case 1:
        //   sortByValue = `"order": "created_at"`
        //   sortByAscDesc = `"sort": "desc"`
        //   break;
        case 1:
          sortByValue = `"labels": "['Terbaru']"`;
          break;
        case 2:
          sortByValue = `"order": "sold_quantity"`;
          sortByAscDesc = `"sort": "desc"`;
          break;
        case 3:
          sortByValue = `"order": "price.${membershipLevel}"`;
          sortByAscDesc = `"sort": "asc"`;
          break;
        case 4:
          sortByValue = `"order": "price.${membershipLevel}"`;
          sortByAscDesc = `"sort": "desc"`;
          break;
        case 5:
          sortByValue = `"labels": "['Diskon']"`;
          break;
        default:
          break;
      }
      const response = await getProductsDetail(
        `{"page":${value}, "limit": 18 ${
          catId ? `,"category_id":${catId}` : ""
        } ${sortByValue ? `,${sortByValue}` : ""} ${
          sortByAscDesc ? `,${sortByAscDesc}` : ""
        }}`
      );
      setMyPage(response.meta.total_pages);
      setNewData(response.data);
    } catch (error) {
      console.log("Error fetching getProductsDetail:", error);
    }
  };
  const getSingleProduct = (idProduct) => {
    // const usercode=JSON.parse(localStorage.getItem("usercode"));
    // const userid=JSON.parse(localStorage.getItem("userid"));
    // var data3 = new FormData();
    // data3.append("product_id", e);
    // if(userid){
    //   data3.append("user_id", userid);
    //   data3.append("user_code", usercode);
    // }
    // dispatch(singleProductDataUser(data3));
    // history.push("/productdetail");
    if (Mobile) {
      history.push(`/productdetail?${idProduct}`);
    } else {
      history.push(`/product-detail?${idProduct}`);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          className="container"
          style={{ paddingRight: "0px", paddingLeft: "0px", maxWidth: "100%" }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                columnGap: "10px",
                flexFlow: "wrap",
                marginBottom: "4rem",
              }}
            >
              {["", "", "", "", "", "", "", "", "", ""].map(() => (
                <Skeleton
                  animation="pulse"
                  height={430}
                  width={"calc(20% - 16.66px)"}
                  style={{
                    marginTop: "-5rem",
                    borderRadius: 5,
                    marginBottom: "-5rem",
                  }}
                />
              ))}
            </div>
          ) : NewData && NewData.length > 0 ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                overflow: "auto",
                flexWrap: "wrap",
                flexDirection: "row",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "0px",
                paddingLeft: "0px",
                paddingRight: "1px",
                height: find ? "auto" : "auto",
                clear: "both",
                paddingBottom: Mobile ? "0px" : "50px",
                // columnGap: "7px",
                // gap: "6.6px",
                rowGap: "5px",
              }}
            >
              {NewData.map((dt, index) => {
                return (
                  <CardProduct
                    key={index}
                    membershipLevel={membershipLevel}
                    product={dt.product}
                    limitTitle={25}
                    heightWrapperImage="150px"
                    handleClick={getSingleProduct}
                  />

                  // <div
                  //   key={index}
                  //   className={
                  //     classes.hoverCard + " " + "card border-0 shadow-card  "
                  //   }
                  //   style={{
                  //     width: "calc(21.2% - 16.66px)",
                  //     // padding:7,
                  //     // marginBottom: "6px",
                  //     // height:"235px",
                  //     clear: "both",
                  //     // minHeight: "288px",
                  //   }}
                  //   onClick={() =>
                  //     history.push(`/product-detail?${dt.product.id}`)
                  //   }
                  // >
                  //   <div>
                  //     <div
                  //       style={{
                  //         // height:192
                  //         height: "182px",
                  //         background: "#fff",
                  //         display: "flex",
                  //         justifyContent: "center",
                  //         alignItems: "center",
                  //         borderBottom: "0.1px solid #80808038",
                  //       }}
                  //     >
                  //       <img
                  //         src={
                  //           dt.product.media && dt.product.media.length > 0
                  //             ? process.env.REACT_APP_FILE_BASE_URL +
                  //               "/" +
                  //               dt.product.media[0]
                  //             : "/assets/images/dummy.png"
                  //         }
                  //         // className="card-img-top"
                  //         // className={classes.Main}
                  //         style={{
                  //           //  height:'100%',
                  //           //  width:'100%'
                  //           height: "auto",
                  //           maxHeight: "181px",
                  //           width: "100%",
                  //           borderRadius: "4px 4px 0 0",
                  //         }}
                  //         alt="img"
                  //         onClick={() => {
                  //           getSingleProduct(dt.product.id);
                  //         }}
                  //         // height:Object.keys(dt.product.name).length>18?"height: 160px":"177.5px"
                  //       />
                  //     </div>
                  //     {dt.product.stock_status === "Sold Out" ? (
                  //       <div
                  //         className="position-absolute d-flex justify-content-center align-items-center rounded-circle"
                  //         style={{
                  //           width: Mobile ? "5rem" : "4.5rem", // 8rem
                  //           height: Mobile ? "5rem" : "4.5rem", // 8rem
                  //           top: Mobile ? "47px" : "80px", // 53
                  //           left: Mobile ? "45px" : "56px",
                  //           fontSize: "12px", // 14
                  //           backgroundColor: "rgba(0, 0, 0, 0.5)",
                  //         }}
                  //       >
                  //         <span
                  //           className="text-light"
                  //           style={{ cursor: "pointer" }}
                  //         >
                  //           {t("sold_out")}
                  //         </span>
                  //       </div>
                  //     ) : (
                  //       ""
                  //     )}
                  //     {dt.product.has_video == 1 > 0 &&
                  //     dt.product.stock_status !== "Sold Out" ? (
                  //       <div
                  //         className="position-absolute d-flex justify-content-center align-items-center rounded-circle"
                  //         style={{
                  //           width: Mobile ? "5rem" : "4.5rem", // 8rem
                  //           height: Mobile ? "5rem" : "4.5rem", // 8rem
                  //           top: Mobile ? "47px" : "80px", // 53
                  //           left: Mobile ? "45px" : "56px",
                  //           fontSize: "12px", // 14
                  //           backgroundColor: "rgba(0, 0, 0, 0.5)",
                  //         }}
                  //       >
                  //         <span
                  //           className="text-light"
                  //           style={{ cursor: "pointer" }}
                  //         >
                  //           <PlayArrowIcon />
                  //         </span>
                  //       </div>
                  //     ) : (
                  //       ""
                  //     )}
                  //   </div>

                  //   {/* <div>
                  //         {dt.product.is_wishlist == "1" ? (
                  //           <img
                  //             src="/assets/frontend/img/wishlist_fav.png"
                  //             height="22px"
                  //             style={{
                  //               display: "flex",
                  //               justifyContent: "flex-end",
                  //               top: 0,
                  //               position: "absolute",
                  //               cursor: "pointer",
                  //               marginTop: "10px",
                  //               marginLeft: "10px",
                  //             }}
                  //             onClick={() => {
                  //               addWishWhite(dt.product.id, index);
                  //             }}
                  //           />
                  //         ) : (
                  //           <img
                  //             src="/assets/frontend/img/wishlist_list.png"
                  //             height="22px"
                  //             style={{
                  //               display: "flex",
                  //               justifyContent: "flex-end",
                  //               top: 0,
                  //               position: "absolute",
                  //               cursor: "pointer",
                  //               marginTop: "10px",
                  //               marginLeft: "10px",
                  //             }}
                  //             onClick={() => {
                  //               addWishRed(dt.product.id, index);
                  //             }}
                  //           />
                  //         )}
                  //       </div>
                  //        */}

                  //   <div
                  //     style={{
                  //       position: "absolute",
                  //       top: "5px",
                  //       right: "5px",
                  //       userSelect: "none",
                  //     }}
                  //   >
                  //     {dt.product.discount_type &&
                  //       isDiscountValid(
                  //         dt.product.discount,
                  //         dt.product.discount_start_date,
                  //         dt.product.discount_end_date
                  //       ) &&
                  //       dt.product.discount_type === "percentage" && (
                  //         <div
                  //           style={{
                  //             background: "red",
                  //             display: "flex",
                  //             justifyContent: "center",
                  //             alignItems: "center",
                  //             color: "#fff",
                  //             fontSize: "12px",
                  //             width: "58px",
                  //             height: "20px",
                  //             borderRadius: "3px",
                  //             marginTop: "1px",
                  //             marginBottom: "5px",
                  //             cursor: "pointer",
                  //             userSelect: "none",
                  //           }}
                  //         >
                  //           {dt.product.discount}%
                  //         </div>
                  //       )}

                  //     {dt.product.discount_type &&
                  //       isDiscountValid(
                  //         dt.product.discount,
                  //         dt.product.discount_start_date,
                  //         dt.product.discount_end_date
                  //       ) &&
                  //       dt.product.discount_type === "nominal" && (
                  //         <div
                  //           style={{
                  //             background: "red",
                  //             display: "flex",
                  //             justifyContent: "center",
                  //             alignItems: "center",
                  //             color: "#fff",
                  //             fontSize: "12px",
                  //             width: "58px",
                  //             height: "20px",
                  //             borderRadius: "3px",
                  //             marginTop: "1px",
                  //             marginBottom: "5px",
                  //             cursor: "pointer",
                  //             userSelect: "none",
                  //           }}
                  //         >
                  //           {parseInt(
                  //             (dt.product.discount /
                  //               dt.product.price[membershipLevel]) *
                  //               100
                  //           )}
                  //           %
                  //         </div>
                  //       )}
                  //     {/* new format label */}
                  //     {dt.product.labels &&
                  //       dt.product.labels.length > 0 &&
                  //       dt.product.labels
                  //         .filter(
                  //           (label) =>
                  //             label !== "Sold Out" && label !== "Diskon"
                  //         )
                  //         .map((item, index) => {
                  //           let bgColor = "#fdd535";
                  //           let color = "#fff";

                  //           switch (item) {
                  //             case "Sold Out":
                  //               bgColor = "#1A1818";
                  //               break;

                  //             case "Terbaru":
                  //               bgColor = "#fdd535";
                  //               break;

                  //             case "Terlaris":
                  //               bgColor = "red";
                  //               color = "yellow";
                  //               break;

                  //             case "Grosir":
                  //               bgColor = "#00BFFF";
                  //               break;

                  //             default:
                  //               break;
                  //           }
                  //           return (
                  //             <div
                  //               style={{
                  //                 background: bgColor,
                  //                 display: "flex",
                  //                 justifyContent: "center",
                  //                 alignItems: "center",
                  //                 color: color,
                  //                 fontSize: "12px",
                  //                 width: "58px",
                  //                 height: "20px",
                  //                 borderRadius: "3px",
                  //                 marginTop: "1px",
                  //                 marginBottom: "5px",
                  //                 cursor: "pointer",
                  //                 userSelect: "none",
                  //               }}
                  //             >
                  //               {item}
                  //             </div>
                  //           );
                  //         })}

                  //     {/* {dt.product.new_product === "1" ? (
                  //             <div
                  //               style={{
                  //                 background: " #fdd535",
                  //                 display: "flex",
                  //                 justifyContent: "center",
                  //                 alignItems: "center",
                  //                 color: "#fff",
                  //                 fontSize: "12px",
                  //                 width: "58px",
                  //                 height: "20px",
                  //                 borderRadius: "3px",
                  //                 marginTop: "1px",
                  //                 marginBottom: "5px",
                  //                 cursor: "pointer",
                  //                 userSelect: "none",
                  //               }}
                  //             >
                  //               {t("new1")}
                  //             </div>
                  //           ) : (
                  //             ""
                  //           )}
                  //           {dt.product.product_wholesale === 1 ? (
                  //             <div
                  //               style={{
                  //                 background: "#00BFFF",
                  //                 display: "flex",
                  //                 justifyContent: "center",
                  //                 alignItems: "center",
                  //                 color: "#fff",
                  //                 fontSize: "11px",
                  //                 width: "58px",
                  //                 height: "20px",
                  //                 borderRadius: "3px",
                  //                 marginTop: "1px",
                  //                 marginBottom: "5px",
                  //                 cursor: "pointer",
                  //                 userSelect: "none",
                  //               }}
                  //             >
                  //               {t("wholesale")}
                  //             </div>
                  //           ) : (
                  //             ""
                  //           )}
                  //           {dt.product.product_cod === "1" ? (
                  //             <div
                  //               style={{
                  //                 background: "#49d16e",
                  //                 display: "flex",
                  //                 justifyContent: "center",
                  //                 alignItems: "center",
                  //                 color: "#fff",
                  //                 fontSize: "12px",
                  //                 width: "58px",
                  //                 height: "20px",
                  //                 borderRadius: "3px",
                  //                 marginTop: "1px",
                  //                 marginBottom: "5px",
                  //                 cursor: "pointer",
                  //                 userSelect: "none",
                  //               }}
                  //             >
                  //               {t("COD")}
                  //             </div>
                  //           ) : (
                  //             ""
                  //           )}
                  //           {dt.product.percent_discount !== "0" ? (
                  //             <div
                  //               style={{
                  //                 background: "red",
                  //                 display: "flex",
                  //                 justifyContent: "center",
                  //                 alignItems: "center",
                  //                 color: "#fff",
                  //                 fontSize: "12px",
                  //                 width: "58px",
                  //                 height: "20px",
                  //                 borderRadius: "3px",
                  //                 marginTop: "1px",
                  //                 marginBottom: "5px",
                  //                 cursor: "pointer",
                  //                 userSelect: "none",
                  //               }}
                  //             >
                  //               {dt.product.percent_discount}%
                  //             </div>
                  //           ) : (
                  //             ""
                  //           )}
                  //           {dt.product.product_preorder === 1 ? (
                  //             <div
                  //               style={{
                  //                 background: "orange",
                  //                 display: "flex",
                  //                 justifyContent: "center",
                  //                 alignItems: "center",
                  //                 color: "#fff",
                  //                 fontSize: "10px",
                  //                 width: "58px",
                  //                 height: "20px",
                  //                 borderRadius: "3px",
                  //                 marginTop: "1px",
                  //                 marginBottom: "5px",
                  //                 cursor: "pointer",
                  //                 userSelect: "none",
                  //               }}
                  //             >
                  //               PO
                  //             </div>
                  //           ) : (
                  //             ""
                  //           )}
                  //           {dt.product.best_seller === "1" ? (
                  //             <div
                  //               style={{
                  //                 background: "red",
                  //                 display: "flex",
                  //                 justifyContent: "center",
                  //                 alignItems: "center",
                  //                 color: "yellow",
                  //                 fontSize: "10px",
                  //                 width: "58px",
                  //                 height: "20px",
                  //                 borderRadius: "3px",
                  //                 marginTop: "1px",
                  //                 marginBottom: "5px",
                  //                 cursor: "pointer",
                  //                 userSelect: "none",
                  //               }}
                  //             >
                  //               {t("best_seller1")}
                  //             </div>
                  //           ) : (
                  //             ""
                  //           )} */}
                  //   </div>

                  //   <div
                  //     className="card-body  mb-1"
                  //     style={{
                  //       padding: "10px",
                  //       minHeight: "88px",
                  //       display: "flex",
                  //       flexDirection: "column",
                  //       justifyContent: "start",
                  //     }}
                  //   >
                  //     <NavLink
                  //       className={classes1.textfont}
                  //       to="/product-detail"
                  //     >
                  //       <p
                  //         className={
                  //           classes1.textfont + "  " + classes.cardTitle
                  //         }
                  //         style={{
                  //           // minHeight: 43,
                  //           marginBottom: "0px",
                  //         }}
                  //       >
                  //         <ReactReadMoreReadLess
                  //           charLimit={25}
                  //           // readMoreText={"Read more ▼"}
                  //           // readLessText={"Read less ▲"}
                  //           readMoreClassName="read-more-less--more"
                  //           readLessClassName="read-more-less--less"
                  //         >
                  //           {dt.product.name}
                  //         </ReactReadMoreReadLess>
                  //         {/* {dt.product.name.length > 30
                  //               ? dt.product.name.slice(0, 30)
                  //               : dt.product.name} */}
                  //       </p>
                  //     </NavLink>
                  //     <div
                  //       className={classes1.pricePadding11}
                  //       style={{
                  //         display: "flex",
                  //         justifyContent: "start",
                  //         alignItems: "center",
                  //         flexDirection: "row",
                  //         flexShrink: 0,
                  //         overflow: "hidden",
                  //       }}
                  //     >
                  //       <div
                  //         className={classes1.pricediv}
                  //         style={{
                  //           display: "flex",
                  //           flexShrink: 0,
                  //           marginRight: "10px",
                  //           paddingTop: 3,
                  //           fontWeight: "600",
                  //         }}
                  //       >
                  //         <div>
                  //           {dt.product.discount_type &&
                  //             isDiscountValid(
                  //               dt.product.discount,
                  //               dt.product.discount_start_date,
                  //               dt.product.discount_end_date
                  //             ) &&
                  //             dt.product.discount_type === "nominal" && (
                  //               <p
                  //                 style={{
                  //                   marginBottom: "0px",
                  //                 }}
                  //               >
                  //                 {priceFormatLocal(
                  //                   dt.product.price[membershipLevel] -
                  //                     dt.product.discount
                  //                 )}
                  //               </p>
                  //             )}

                  //           {dt.product.discount_type &&
                  //             isDiscountValid(
                  //               dt.product.discount,
                  //               dt.product.discount_start_date,
                  //               dt.product.discount_end_date
                  //             ) &&
                  //             dt.product.discount_type === "percentage" && (
                  //               <p
                  //                 style={{
                  //                   marginBottom: "0px",
                  //                 }}
                  //               >
                  //                 {priceFormatLocal(
                  //                   dt.product.price[membershipLevel] -
                  //                     (dt.product.discount / 100) *
                  //                       dt.product.price[membershipLevel]
                  //                 )}
                  //               </p>
                  //             )}

                  //           {!isDiscountValid(
                  //             dt.product.discount,
                  //             dt.product.discount_start_date,
                  //             dt.product.discount_end_date
                  //           ) && (
                  //             <p
                  //               style={{
                  //                 marginBottom: "0px",
                  //               }}
                  //             >
                  //               {priceFormatLocal(
                  //                 dt.product.price[membershipLevel]
                  //               )}
                  //             </p>
                  //           )}
                  //         </div>
                  //       </div>

                  //       {isDiscountValid(
                  //         dt.product.discount,
                  //         dt.product.discount_start_date,
                  //         dt.product.discount_end_date
                  //       ) && (
                  //         <div style={{ display: "flex", flexShrink: 0 }}>
                  //           <b style={{ cursor: "pointer" }}>
                  //             <span className={classes1.cutdiv}>
                  //               {priceFormatLocal(
                  //                 dt.product.price[membershipLevel]
                  //               )}
                  //             </span>
                  //           </b>
                  //         </div>
                  //       )}
                  //     </div>
                  //   </div>
                  // </div>
                );
              })}
            </div>
          ) : (
            <NoDataFound />
          )}
        </div>
        <Pagination
          count={MyPage}
          variant="outlined"
          shape="rounded"
          page={pageNo}
          onChange={pageChange}
          className={classes.Main}
        />
      </div>
    </div>
  );
};

export default withNamespaces()(ProductData);
