import { useState } from "react";
import { baseUrlApi } from "../../helper/api";

export const ThemeColor1 = () => {
  const [color, setcolor] = useState("");
  let data = new FormData();
  fetch(`${baseUrlApi()}/Api/Dashboard/getSplashData`, {
    method: "post",
    headers: {
      key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
      "Access-Control-Allow-Origin": "*",
    },
    data: data,
  })
    .then((res) => res.json())
    .then((res) => setcolor(res.data.website_color));
  // let data = new FormData();
  // const response = await axios({
  //     method: 'post',
  //     url: `/Api/Dashboard/getSplashData`,
  //     headers: {
  //         'key': 'c4c5fc54cd5c071f3fe4785b8ae167e0',
  //         "Access-Control-Allow-Origin": "*"
  //     },
  //     data: data
  // })
  //     .then(function (json) {
  //         // console.log("index1 hoc data: ", json.data.data.website_color);
  //         setcolor(json.data.data.website_color);
  //     })
  //     .catch(function (response) {
  //         console.log(response);
  //     });

  return color;
};
