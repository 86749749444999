import React from "react";
import AuthDilog from "../../containers/home/AuthModal";
const ValidationLoginModal = () => {
  const [isOpen, setOpen] = React.useState(true);
  const LoginFunction = () => {
    setTimeout(() => {
      setOpen(false);
    }, 100);
  };
  const SignUpFunction = () => {
    setTimeout(() => {
      setOpen(false);
    }, 100);
  };
  return (
    <AuthDilog
      open={isOpen}
      tabIndexOpen={0}
      onClose={() => setOpen(false)}
      LoginFunction={LoginFunction}
      SignUpFunction={SignUpFunction}
    />
  );
};
export default ValidationLoginModal;
