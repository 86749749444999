import { makeStyles } from "@material-ui/core";

const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));

const useStyles = makeStyles({
  body: {
    backgroundColor: "#f5f5f5",

    "& h1, h2, h3, h4, h5, h6, p": {
      marginBottom: "0",
    },
  },

  root_sucessorderpay: {
    padding: "0 18px",
    paddingTop: "150px",
    paddingBottom: "30px",
  },

  card_style: {
    fontSize: "14px",
  },

  header: {
    padding: "20px 30px",
    borderBottom: "1.5px solid #ded8d8",
    display: "flex",
    gap: "15px",
    alignItems: "center",
  },

  card_content: {
    padding: "25px 50px",
    width: "560px",
    maxWidth: "100%",
    margin: "0 auto",
  },

  lable_amount: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1.5px solid #ded8d8",
    padding: "15px 0",

    "& .lable": {
      fontSize: "14px",
    },
    "& .value": {
      fontSize: "14px",
      fontWeight: '600'
    },
  },

  title_descrition: {
    textAlign: "center",
    marginBottom: "20px !important",
  },

  btn_group: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    marginTop: "25px",
    justifyContent: "center",
  },

  note_descrition: {
    border: "2px solid red",
    padding: "10px",
    borderRadius: "10px",
    background: "yellow",
    color: "red",
    margin: "15px 0 15px !important",
  },
});

export { useStyles };
