import React, { useState, useEffect } from "react";
import Header from "../header/header2";
import Checkbox from "@material-ui/core/Checkbox";
import { ThemeColor1 } from "../themecolor/index1";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import i18n from "./localization";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../loader";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const LanguageSelect = ({ t }) => {
  const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));
  const useStyles = makeStyles((theme) => ({
    root: {
      "&> .finish-btn": {
        height: "2.7rem",
        backgroundColor: `${ThemeColor ? ThemeColor : "#FFFFFF"} !important`
      }
    }
  }
  ));
  const history = useHistory();
  const [engl, setEngl] = useState(true)
  const [indo, setIndo] = useState(false);
  const lang = JSON.parse(localStorage.getItem("Language"));
  const handlechange = (e) => {
    if (e.target.name == "indo") {
      // localStorage.setItem("Language",JSON.stringify("id"))
      setEngl(false);
      setIndo(true);
    } else {
      // localStorage.setItem("Language",JSON.stringify("en"))
      setEngl(true);
      setIndo(false);
    }
  }
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (lang == "en") {
      setEngl(true);
      setIndo(false);
    } else {
      setEngl(false);
      setIndo(true);
    }
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
    setTimeout(() => {
      setOpen(true);
    }, 700);
  }, []);
  const landing = JSON.parse(localStorage.getItem("Lanpage"));
  const classes = useStyles();
  return (
    <div>
      {
        open ?
          <div>
            <Header title={t("language")} color={ThemeColor} />
            <div style={{ paddingTop: "48px" }}>
              {/* <div
                className="d-flex align-items-center justify-content-center"
              >
                <ul className="list-group list-group-flush w-100">
                  <div>
                    <li className="list-group-item d-flex justify-content-left align-items-center p-0" value="engl" onClick={() => { setEngl(true); setIndo(false); }}>
                      <Checkbox
                        {...label}
                        style={{
                          color: ThemeColor ? ThemeColor : "#FFFFFF",
                        }}
                        name="engl"
                        onChange={handlechange}
                        checked={engl}
                      />
                      <div className=" me-auto" style={{
                        marginTop: "3px",
                        marginLeft: " -10px"
                      }}>
                        <div className="mt-2">
                          <h5 className="fw-normal" style={{ fontSize: "18px" }}>English</h5>
                        </div>
                      </div>
                    </li>
                  </div>
                  <div style={{ marginTop: "-16px" }}>
                    <li className="list-group-item d-flex justify-content-left align-items-center p-0" value="indo" onClick={() => { setIndo(true); setEngl(false); }}>
                      <Checkbox
                        {...label}
                        style={{
                          color: ThemeColor ? ThemeColor : "#FFFFFF",
                        }}
                        name="indo"
                        onChange={handlechange}
                        checked={indo}
                      />
                      <div className=" me-auto" style={{
                        marginTop: "3px",
                        marginLeft: " -10px"
                      }}>
                        <div className="mt-2">
                          <h5 className="fw-normal" style={{ fontSize: "18px" }}>Indonesian</h5>
                        </div>
                      </div>
                    </li>
                  </div>
                </ul>
              </div> */}
              <ul className="list-group list-group-flush">
                
              <li
                style={{ cursor: "pointer", backgroundColor: '#fff',borderBottom: '1px solid #DEDEDE'}}
                value="engl" onClick={() => { setEngl(true); setIndo(false); }}
              >
                {/* <MdOutlineAccountCircle size="25" style={{width:"40px" }} /> */}
                
                <div className="d-flex" style={{height: '50px'}}>
                <Checkbox
                  {...label}
                  style={{
                    color: ThemeColor ? ThemeColor : "#FFFFFF",
                  }}
                  name="engl"
                  onChange={handlechange}
                  checked={engl}
                />

                <div className="d-flex justify-content-left align-items-center p-0" style={{width: '-webkit-fill-available'}}>
                <div className=" me-auto">
                  <p className="mb-0"
                    style={{ color: "rgba(119, 129, 137, 1)", textAlign: 'left',}}
                  >
                    English
                  </p>
                </div>
                </div>
                </div>
              </li>

              <li
                style={{ cursor: "pointer", backgroundColor: '#fff',borderBottom: '1px solid #DEDEDE'}}
                value="indo" onClick={() => { setIndo(true); setEngl(false); }}
              >
                {/* <MdOutlineAccountCircle size="25" style={{width:"40px" }} /> */}
                
                <div className="d-flex" style={{height: '50px'}}>
                <Checkbox
                  {...label}
                  style={{
                    color: ThemeColor ? ThemeColor : "#FFFFFF",
                  }}
                  name="indo"
                  onChange={handlechange}
                  checked={indo}
                />

                <div className="d-flex justify-content-left align-items-center p-0" style={{width: '-webkit-fill-available'}}>
                <div className=" me-auto">
                  <p className="mb-0"
                    style={{ color: "rgba(119, 129, 137, 1)", textAlign: 'left',}}
                  >
                    Indonesian
                  </p>
                </div>
                </div>
                </div>
              </li>

              </ul>
              <div className={classes.root + ' ' + ' finish'}>
                <button className="mt-2"
                  style={{
                    fontSize: '1rem',
                    width: '100%',
                    height: '70%',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '6px',
                    backgroundColor: `${ThemeColor ? ThemeColor : "#FFFFFF"}`
                  }}
                  onClick={() => {
                    // if (landing&&landing.landing_page == 1) {
                    //   history.push("/");
                    // } else {
                    //   history.push("/");
                    // }
                    history.push("/");
                    if (indo) {
                      localStorage.setItem("Language", JSON.stringify("id"))
                    } else {
                      localStorage.setItem("Language", JSON.stringify("en"))
                    }
                    i18n.changeLanguage(lang);
                  }}>{t("save")}</button>
              </div>
            </div>
          </div>
          : <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: "250px",
              paddingBottom: "300px",
              flexDirection: "column",
              height: "100vh",
              backgroundColor: "white"
            }}
          >
            <div><Loader /></div>
            <div>{t('loadingdot')}</div>
          </div>
      }
    </div>
  );
}
export default withNamespaces()(LanguageSelect);
