import React from "react";
import { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import DesktopHeaderWrap from "../../components/DeskTopHeaderWrap";
// import { getSplashDataUser } from "../../store/actions/getSplashDataActions";
// import { GetSplashResponse } from "../../store/Selectors/getSplashData";
import DeskTopFooter from "../home/DesktopFooter";
import "./slug.css";
const FooterSlug = () => {
  // const dispatch = useDispatch();
  useEffect(() => {
    let data = new FormData();
    // dispatch(getSplashDataUser(data));
  }, []);

  const data1 = {};
  const location = useLocation();
  const slugName = location.pathname.replace("/", "");

  return (
    <div>
      <DesktopHeaderWrap themeColor={"rgb(231, 0, 116)"} />
      <div className="main" style={{background: "rgb(245, 245, 245)"}}>
        <div style={{paddingTop:"195px" , width:"1200px" , margin:"0 auto"}}>
        {data1
          ? data1.menu_pages
            ? data1.menu_pages.map((dt) => {
              if (dt.slug == slugName) {
                return (
                  <div className="main-container">
                    <h1 className="slug-title">{dt.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: dt.content }}></div>
                  </div>
                );
              }
            })
            : null
          : null}
          </div>
      </div>
      <DeskTopFooter themeColor={"rgb(231, 0, 116)"}/>
    </div>

  );
};
export default FooterSlug;
