import { instance } from "../helper/api";

export const get = async (token, param) => {
  let resultData = {};
  const axiosConfig = {
    url: "/shopi/v1/vendor/bank/",
    method: "GET",
    params: JSON.parse(param),
  };

  try {
    const results = await instance.request(axiosConfig);
    resultData = results.data.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};

export const getCOD = async () => {
  let resultData = {};
  const axiosConfig = {
    url: "/v1/storefront/cash-on-delivery/",
    method: "GET",
  };

  try {
    const results = await instance.request(axiosConfig);
    resultData = results.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};

export const getMootaBank = async () => {
  let resultData = {};
  const axiosConfig = {
    url: "/v1/storefront/moota-payment/",
    method: "GET",
  };

  try {
    const results = await instance.request(axiosConfig);
    resultData = results.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error.message;
    }
  }

  return resultData;
};
