import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./features/auth/auth";
import cartReducer from "./features/cart/cart";
import allProductReducer from "./features/products/allProducts";
import newestProductsReducer from "./features/products/newestProducts";
import discountProductsReducer from "./features/products/discountProducts";
import bestSellerProductsReducer from "./features/products/bestSellerProducts";
import randomProductsReducer from "./features/products/randomProducts";
import snackbarReducer from "./features/snackbar/snackbar";

const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  allProducts: allProductReducer,
  newestProducts: newestProductsReducer,
  discountProducts: discountProductsReducer,
  bestSellerProducts: bestSellerProductsReducer,
  randomProducts: randomProductsReducer,
  snackbar: snackbarReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "cart"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
