import { blue } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import DesktopLoginForm from "../form/DesktopLogin";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles({
  Root: {
    "&>.MuiDialog-container .MuiDialog-paperWidthSm": {
      width: 370,
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "#d3d3d3d6",
    },
  },
  svgMargin: {
    "&>svg": {
      margin: "0px !important",
    },
  },

  CloseIconStyle: {
    fontSize: "22px",
    margin: "0px !important",
    zIndex: 1,
    boxShadow: "0px 0 3px 0px grey",
    borderRadius: "50%",
    padding: "2px",
    "& :hover": {
      backgroundColor: "red",
    },
  },
});

const AuthDilog = ({ open, tabIndexOpen, onClose,LoginFunction ,SignUpFunction}) => {
  const classes = useStyles();
  const userId = JSON.parse(localStorage.getItem("userid"));
  const landingPageData =  JSON.parse(localStorage.getItem("Lanpage"));
  
  return (
    <Dialog
     
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.Root}
    >
      {
        landingPageData&&landingPageData.landing_page===0?
        <div
        style={{
          textAlign: "right",
          padding: "7px",
          paddingBottom: "0",
          paddingTop: "2px",
          zIndex: 1,
        }}
        className={classes.svgMargin}
      >
        <CloseIcon className={classes.CloseIconStyle} onClick={onClose} />
      </div>
      :
      <div
      style={{
        textAlign: "right",
        padding: "7px",
        paddingBottom: "0",
        paddingTop: "2px",
        zIndex: 1,
      }}
      className={classes.svgMargin}
    >
      {/* <CloseIcon className={classes.CloseIconStyle} /> */}
      <br/>
    </div>
      }
     
      <DesktopLoginForm
        tabIndexOpen={tabIndexOpen}
        LoginFunction={LoginFunction}
        SignUpFunction={SignUpFunction}
      />
    </Dialog>
  );
};
export default AuthDilog;
