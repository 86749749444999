// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-box {
  display: flex;
  align-items: center;
  width: 100%;
}

.title {
  padding: 0;
  font-weight: normal;
  font-size: 1rem;
  color: #fff;
}
.back-icon {
  width: 3.125rem;
  height: 1.875rem;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/containers/header/header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,UAAU;EACV,mBAAmB;EACnB,eAAe;EACf,WAAW;AACb;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".title-box {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n.title {\n  padding: 0;\n  font-weight: normal;\n  font-size: 1rem;\n  color: #fff;\n}\n.back-icon {\n  width: 3.125rem;\n  height: 1.875rem;\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
