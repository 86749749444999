import { Grid, Snackbar } from "@material-ui/core";
import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
import ButtonCustom from "../../../../components/common/ButtonCustom";
import Input from "../../../../components/common/form/Input";
// import { changePasswordUser } from "../../../../store/actions/ChangePassActions";
import { useStyles } from "./changePassword.style";
import MuiAlert from "@material-ui/lab/Alert";
// import { ChangePassResponse } from "../../../../store/Selectors/changepass";
import { t } from "i18next";
import i18n from "../../../language/localization";
import LoaderDots from "../../../../components/common/LoaderDots";
import { updatePassword } from "../../../../api/authorization";

const ChangePasswordProfile = (props) => {
  const { webThemeColor, themeColor } = props;
  const classes = useStyles(props);
  // const dispatch = useDispatch();
  const [old, setOld] = useState();
  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(false);
  const [New, setNew] = useState();
  const [Confirm, setConfirm] = useState();
  const [isLoading, setLoading] = React.useState(false);
  const [resultUpdatePassword, setResultUpdatePassword] = useState({});
  const onChangeOld = (e) => {
    setOld(e.target.value);
  };
  const onChangeNew = (e) => {
    setNew(e.target.value);
  };
  const onChangeConfirm = (e) => {
    setConfirm(e.target.value);
  };
  const changePassword = async () => {
    setLoading(true);
    const userid = JSON.parse(localStorage.getItem("userid"));
    if (New === Confirm) {
      // let data = new FormData();
      // data.append("user_id", userid);
      // data.append("old_password", old);
      // data.append("new_password", New);
      // dispatch(changePasswordUser(data));
      const data = {
        password: New,
      };
      const response = await updatePassword(
        data,
        localStorage.getItem("token")
      );
      if (response.success) {
        setResultUpdatePassword(response);
        setOpen(true);
        setLoading(false);
      } else {
        setLoading(false);
        setResultUpdatePassword(response);
      }
    } else {
      setOpen2(true);
    }
  };
  // const passRes = ChangePassResponse();

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  }, []);
  useEffect(() => {
    // console.log("is_ useEffect");
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <div className={classes.body}>
      <header>
        {" "}
        <h3 className={classes.title}>{t("change_password")}</h3>
      </header>
      <Grid container spacing={2} className={classes.content}>
        {/* <Input
          type="password"
          leftLabel={t("old_password")}
          vlue={old}
          className={classes.input_style}
          onChange={onChangeOld}
        /> */}
        <Input
          type="password"
          leftLabel={t("new_password")}
          vlue={New}
          className={classes.input_style}
          onChange={onChangeNew}
        />
        <Input
          type="password"
          leftLabel={t("retype_new_password")}
          vlue={Confirm}
          className={classes.input_style}
          onChange={onChangeConfirm}
        />

        <Grid item md={4}></Grid>
        <Grid item md={8}>
          <ButtonCustom
            bg={webThemeColor}
            onClick={changePassword}
            disabled={isLoading ? true : false}
          >
            {t("confirm")} {isLoading ? <LoaderDots /> : null}
          </ButtonCustom>
        </Grid>
      </Grid>
      {resultUpdatePassword ? (
        resultUpdatePassword.success === false ? (
          <Snackbar
            open={open}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={() => setOpen(false)}
            autoHideDuration={2000}
          >
            <MuiAlert severity="error">{resultUpdatePassword.message}</MuiAlert>
          </Snackbar>
        ) : (
          <Snackbar
            open={open}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={() => setOpen(false)}
            autoHideDuration={2000}
          >
            <MuiAlert severity="success">
              {resultUpdatePassword.message}
            </MuiAlert>
          </Snackbar>
        )
      ) : null}

      <Snackbar
        open={open2}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setOpen2(false)}
        autoHideDuration={2000}
      >
        <MuiAlert severity="error">
          {t("new_password_confirm_password_not_same")}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ChangePasswordProfile;
