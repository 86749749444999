import React, { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
// import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from "@material-ui/icons//ShoppingCart";
import { useHistory } from "react-router-dom";
import { ThemeColor } from "../themecolor";
import { withNamespaces } from "react-i18next";
import i18n from "../language/localization";

function ProductOptionHeader({ t, color }) {
  const history = useHistory();
  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  }, []);
  return (
    <div>
      <div
        className="d-flex justify-content-between align-item-center"
        style={{ background: color, position: "fixed", zIndex: "11", width: "100%", maxWidth: "500px" }}
      >
        <AiOutlineClose
          fontSize="25"
          className="ms-0"
          fill="#fff"
          style={{ width: "30px" }}
          onClick={() => {
            history.goBack();
          }}
        />
        <p
          className="title text-center pt-2 text-white mb-0 "
          style={{
            MozUserSelect: "none",
            WebkitUserSelect: "none",
            MsUserSelect: "none",
            userSelect: "none",
            cursor: "pointer"
          }}
        >
          {t("product_option")}
        </p>
        {/* <ShoppingCartIcon style={{ color: "#fff" }} onClick={()=>{history.push("/cart")}} fontSize="medium" /> */}
        <img
          src={
            "/assets/frontend/img/cart_white.png"
          }
          style={{ height: "27px", marginTop: "6px", marginRight: "9px" }}
          onClick={() => { history.push("/cart") }}
        />
      </div>
    </div>
  );
}

export default withNamespaces()(ProductOptionHeader);
