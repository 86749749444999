import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import {
  Avatar,
  InputAdornment,
  TextField,
  Button,
  Grid,
} from "@material-ui/core";
import AuthDilog from "./AuthModal";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
// import { GetSplashResponse } from "../../store/Selectors/getSplashData";
// import { getSplashDataUser } from "../../store/actions/getSplashDataActions";
// import { GetProductDataResponse } from "../../store/Selectors/getproductdata";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { getMyCartProductUser } from "../../store/actions/GetMyCartActions";
import moment from "moment";
// import { GetMyCartProductResponse } from "../../store/Selectors/mycart";
// import { LoginResponse } from "../../store/Selectors/user";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import DialogAlert from "../../components/common/DialogAlert";
import { withNamespaces } from "react-i18next";
import i18n from "../language/localization";
import axios from "axios";
// import {baseUrlApi} from "../../helper/api"
import { getByClient as getProductsDetail } from "../../api/products";
import { useSelector, useDispatch } from "react-redux";
import { setAuthToken } from "../../stores/features/auth/auth";
import { useAuth } from "../../hooks/useAuth";
import { useStore } from "../../hooks/useStore";
import { getFileUrl } from "../../utils/storageHelper";

var Highlight = require("react-highlighter");

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    color: "black",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,

    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    "&>.MuiBadge-root .MuiBadge-anchorOriginTopRightRectangle": {
      top: 14,
      right: 15,
      background: "#e8eff4",
      color: "black",
    },
    // "&>.MuiBadge-root .MuiBadge-anchorOriginTopRightRectangle":{
    //   background:'#e8eff4',
    //   color:'black'
    // },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  notchedOutline: {
    borderColor: "#ff3a59 !important",
  },
  textSearch: {
    // "&>.MuiOutlinedInput-root": {
    //   height: 30,
    //   borderRadius: "5px 1px 1px 5px",
    //   padding: "0 8px !important",
    // },
    // "& input": {
    //   padding: "0 !important",
    //   height: 30,
    // },
    "& input::placeholder": {
      color: "gray !important",
      opacity: 1,
    },
  },
  textSearch2: {
    "&>.MuiAutocomplete-inputFocused": {
      borderColor: "red !important",
    },
  },
  hoverStyle: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  ImgWrap: {
    width: "auto",
    maxHeight: "60px",
    maxWidth: "200px",
    marginTop: "3px",

    "&:hover": {
      cursor: "pointer",
    },
  },
  svgRoot: {
    margin: "0px !important",
  },
}));

const DeskTopHeader = ({ t, wishlistCount, isFixed }) => {
  const auth = useAuth();
  const { store, landingPage } = useStore();

  const [counterCart, setCounterCart] = useState(0);

  useEffect(() => {
    if (auth.countCart) {
      setCounterCart(auth.countCart);
    }
  }, [auth.countCart]);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [menuanchorEl, setmenuAnchorEl] = React.useState(null);
  const [value, setValue] = useState("");
  const [myList, setmyList] = useState([]);
  const UserData = JSON.parse(localStorage.getItem("userdata"));
  // const themeColor=localStorage.getItem("themeColor");
  const themeColor = JSON.parse(localStorage.getItem("themeColor"));
  const history = useHistory();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  // const filterData = GetProductDataResponse();
  // const CartData = GetMyCartProductResponse();
  const [myDataFilter, setMyFilterData] = useState([]);
  const [pageCount, setPageCount] = useState(1);

  // const getHistorySearch = async () => {
  //     const userID = JSON.parse(localStorage.getItem("userid"));
  //     if(userID){
  //       let data = new FormData();
  //       data.append("user_id", userID);
  //       await axios({
  //         method: 'post',
  //         url: `${baseUrlApi()}/Api/Dashboard/getSearchHistory`,
  //         headers: {
  //             'key': 'c4c5fc54cd5c071f3fe4785b8ae167e0',
  //             "Access-Control-Allow-Origin": "*"
  //         },
  //         data: data
  //       }).then(function (response) {
  //         let responseData = response.data.data
  //         if(responseData.length>0){
  //           responseData = responseData.filter(data=>(data.name))
  //           setmyList(responseData)
  //         }
  //       })
  //       .catch(function (response) {
  //         console.error("response error", response);
  //       });
  //     }
  // }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  }, []);

  // useEffect(() => {
  //   let data = new FormData();
  //   // dispatch(getSplashDataUser(data));
  //   // (async function() {
  //     // belum done get history search
  //   //   await getHistorySearch()
  //   // })();
  // }, []);

  const data1 = null;
  const footer = data1 ? data1.footer : null;

  const handleProfileMenuOpen = async (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = async (event) => {
    setmenuAnchorEl(event.currentTarget);
  };

  const handleClose = async (activeValue) => {
    setmenuAnchorEl(null);
    history.push({
      pathname: "/my-profile",
      active: activeValue,
    });
  };

  const handleCloseWithoutDirect = async () => {
    setmenuAnchorEl(null);
  };

  const [menuanchorElDownloadApp, setmenuAnchorElDownloadApp] =
    React.useState(null);
  const handleClickDownloadApp = async (event) => {
    setmenuAnchorElDownloadApp(event.currentTarget);
  };
  const handleCloseDownloadAppWithoutDirect = async () => {
    setmenuAnchorElDownloadApp(null);
  };

  const location = useLocation();
  const slugName = location.pathname.replace("/", "");

  // useEffect(() => {
  //   if (data1) {
  //     if (data1.data.landing_page == 0) {
  //       console.log("datain");
  //     } else {
  //       if (UserData) {
  //         setOpen(false);
  //         mycart();
  //       } else {
  //           if(slugName == "privacy-policy" || slugName == "support" || slugName == "terms"  || slugName == "delete-account"
  //           || slugName == "reseller" || slugName == "tanya-jawab" || slugName == "cara-order"
  //           ){
  //               setOpen(false);
  //           }else{
  //               setOpen(true);
  //           }

  //       }
  //     }
  //   }
  // }, [data1]);

  useEffect(() => {
    if (UserData && landingPage) {
      setOpen(false);
    } else {
      if (landingPage) {
        if (
          landingPage.setting_customer &&
          landingPage.setting_customer.is_customer_required_login === true
        ) {
          setValueTabIndexOpen(0);
          setOpen(true);
        }
      }
    }
  }, [landingPage, UserData]);

  const [isOpen, setOpen] = React.useState(false);
  // const loginData = LoginResponse();
  const handleMobileMenuClose = async () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = async () => {
    setAnchorEl(null);
    await handleMobileMenuClose();
  };

  const LoginFunction = async () => {
    setOpen(false);
  };

  const SignUpFunction = async () => {
    setTimeout(() => {
      setOpen(false);
    }, 100);
  };

  const searchProduct = async (inputValue) => {
    const response = await getProductsDetail(
      `{"name":"${inputValue}", "limit": 50}`
    );
    setMyFilterData(response.data);
  };

  const [debounce, setDebounce] = useState();
  const OnChangeSearchInput = async (event, newInputValue) => {
    clearTimeout(debounce);
    setDebounce(
      setTimeout(async () => {
        await searchProduct(newInputValue);
      }, 1000)
    );
    setValue(newInputValue);
  };

  const searchPageClick = async (event, valueSelected) => {
    if (valueSelected.id) {
      localStorage.setItem(
        "desktopSearch",
        JSON.stringify(valueSelected.label)
      );
      // window.location.href = `/product-detail?${valueSelected.id}`;
      history.push(`/product-detail?${valueSelected.id}`);
    } else {
      localStorage.setItem("desktopSearch", JSON.stringify(valueSelected));
      history.push("/productsearch");
    }
  };

  const searchPageClickWithInsertStory = async (event, valueSelected) => {
    if (!valueSelected.id) {
      localStorage.setItem("desktopSearch", JSON.stringify(valueSelected));
      history.push("/productsearch");
    } else {
      // let headerValue = new Headers();
      // headerValue.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");
      // let formDataSearchProduct = new FormData();
      // formDataSearchProduct.append("search", valueSelected.label);
      // let productID = 0
      // const userID = JSON.parse(localStorage.getItem("userid"));

      // await fetch(`${baseUrlApi()}/Api/Dashboard/getSearchProduct`, {
      //   method: "POST",
      //   headers: headerValue,
      //   body: formDataSearchProduct,
      //   redirect: "follow",
      // })
      //   .then(async (res) => res.json())
      //   .then(async (res) => {
      //     productID = res.data[0].id

      //     if(productID>0){
      //       let formDataAddHistoryProduct = new FormData();
      //       formDataAddHistoryProduct.append("user_id", userID);
      //       formDataAddHistoryProduct.append("product_id", productID);

      //       await fetch(`${baseUrlApi()}/Api/Dashboard/addSearchHistory`, {
      //         method: "POST",
      //         headers: headerValue,
      //         body: formDataAddHistoryProduct,
      //         redirect: "follow",
      //       })
      //         .then(async (res) => res.json())
      //     }

      //   });

      localStorage.setItem(
        "desktopSearch",
        JSON.stringify(valueSelected.label)
      );
      // window.location.href = `/product-detail?${valueSelected.id}`;
      history.push(`/product-detail?${valueSelected.id}`);
    }
  };

  // const OnChangeSearch = async (e) => {
  //   // var myHeaders = new Headers();
  //   // myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");
  //   // var data3 = new FormData();
  //   // data3.append("search", e.target.value);
  //   // await fetch(`${baseUrlApi()}/Api/Dashboard/getSearchProduct`, {
  //   //   method: "POST",
  //   //   headers: myHeaders,
  //   //   body: data3,
  //   //   redirect: "follow",
  //   // })
  //   //   .then((res) => res.json())
  //   //   .then((res) => {
  //   //     setMyFilterData(res.data);
  //   //   });
  //   if (e.target.value) {
  //     const response = await getProductsDetail(
  //       `{"name":"${e.target.value}", "limit": 50}`
  //     );
  //     setMyFilterData(response.data);
  //     setValue(e.target.value);
  //   }
  // };

  const directStoreUrl = async () => {
    window.open(
      footer ? footer.store_url : null,
      "_blank",
      "noopener,noreferrer"
    );
  };
  const directAppStoreUrl = async () => {
    window.open(
      footer ? footer.app_store_url : null,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const [valueTabIndexOpen, setValueTabIndexOpen] = useState(0);
  const setOpenSignIn = async () => {
    setValueTabIndexOpen(0);
    setOpen(true);
  };
  const setOpenSignUp = async () => {
    setValueTabIndexOpen(1);
    setOpen(true);
  };
  const [openLogoutPopup, setOpenLogoutPopup] = useState(false);
  const openModalLogout = () => {
    setOpenLogoutPopup(true);
  };
  const closeModalLogout = () => {
    setOpenLogoutPopup(false);
  };
  const onOklogout = () => {
    auth.logout();

    closeModalLogout();
    // window.location.href = "/";
  };
  const handleClickChangeLanguage = (valueLanguageCode) => {
    localStorage.setItem("Language", JSON.stringify(valueLanguageCode));
    i18n.changeLanguage(valueLanguageCode);
    window.location.href = "/";
  };

  return (
    <div className={classes.grow}>
      <AppBar
        position="static"
        style={{
          background: "white",
          position: isFixed ? "fixed" : "unset",
          boxShadow: "none",
        }}
      >
        <div
          style={{
            background: themeColor,

            height: "35px",
            paddingTop: "5px",
          }}
        >
          <div
            style={{
              width: "1200px",
              display: "flex",
              margin: "0px auto",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ cursor: "pointer" }}
              onClick={handleClickDownloadApp}
              // onMouseOver={handleClickDownloadApp}
            >
              {t("download_app")}
            </div>
            <Menu
              id="simple-menu-download-app"
              anchorEl={menuanchorElDownloadApp}
              keepMounted
              open={Boolean(menuanchorElDownloadApp)}
              onClose={() => setmenuAnchorElDownloadApp(null)}
              MenuListProps={{
                onMouseLeave: handleCloseDownloadAppWithoutDirect,
              }}
            >
              <Grid container spacing={3}>
                <Grid item>
                  <img
                    alt="complex"
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/4/41/QR_Code_Example.svg"
                    }
                    width={180}
                    height={180}
                    style={{ paddingLeft: "10px" }}
                  />
                </Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography>{t("scanqr_or_download_app")}</Typography>
                      <img
                        alt="complex"
                        src={"/assets/images/googleplay.png"}
                        width={130}
                        onClick={directStoreUrl}
                      />
                      <img
                        alt="complex"
                        src={"/assets/images/appstore.png"}
                        width={130}
                        onClick={directAppStoreUrl}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Menu>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <span
                style={{
                  borderRight: "1px solid white",
                  paddingRight: 10,
                  fontSize: 14,
                }}
              >
                Shipping Rates
              </span> */}

              {JSON.parse(localStorage.getItem("Language")) == "id" ? (
                <span
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    columnGap: "7px",
                  }}
                  className={classes.hoverStyle}
                  onClick={() => {
                    handleClickChangeLanguage("en");
                  }}
                >
                  <LanguageOutlinedIcon className={classes.svgRoot} />
                  English
                </span>
              ) : (
                <span
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    columnGap: "7px",
                  }}
                  className={classes.hoverStyle}
                  onClick={() => {
                    handleClickChangeLanguage("id");
                  }}
                >
                  <LanguageOutlinedIcon className={classes.svgRoot} />
                  Indonesia
                </span>
              )}
              {/* {UserData ? (
                UserData.username ? (
                  <div>
                    <span
                      style={{
                        paddingRight: 10,
                        paddingLeft: 10,
                        fontSize: 14,
                        display: "flex",
                        alignItems: "center",
                        columnGap: "7px",
                      }}
                      onClick={handleClick}
                      className={classes.hoverStyle}
                    >
                      <Avatar
                        src={UserData.photo}
                        style={{ height: 24, width: 24 }}
                      />
                       <img
                        src="https://apps.shopi.id/gearbest/images/user.svg"
                        width={19}
                      /> 
                      {UserData.username}
                    </span>
                    <Menu
                      id="simple-menu"
                      anchorEl={menuanchorEl}
                      keepMounted
                      open={Boolean(menuanchorEl)}
                      onClose={() => setmenuAnchorEl(null)}
                    >
                      <MenuItem onClick={handleClose}>My account</MenuItem>
                    </Menu>
                  </div>
                ) : null
              ) : (
                null
              )} */}
            </div>
          </div>
        </div>

        {UserData ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "1200px",
              margin: "0px auto",
              height: 60,
              columnGap: 20,
            }}
          >
            {store ? (
              <img
                src={getFileUrl(store.logo)}
                width={200}
                onClick={() => history.push("/")}
                className={classes.ImgWrap}
                alt=""
              />
            ) : (
              <span
                style={{ cursor: "pointer", color: "black" }}
                onClick={() => history.push("/")}
              >
                {t("loadingdot")}
              </span>
            )}

            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              style={{ width: "70%" }}
              options={
                myDataFilter
                  ? myDataFilter.length > 0
                    ? myDataFilter.map((option) => ({
                        id: option.product.id,
                        label: option.product.name,
                      }))
                    : myList.map((option) => ({
                        id: option.product_id,
                        label: option.name,
                      }))
                  : myList.map((option) => ({
                      id: option.product_id,
                      label: option.name,
                    }))
              }
              onInputChange={OnChangeSearchInput}
              onChange={(e, value) => searchPageClickWithInsertStory(e, value)}
              getOptionLabel={(option) => {
                if (option.label) {
                  option = option.label;
                } else {
                  option = option;
                }
                return option;
              }}
              // className={classes.textSearch2}
              // value={(localStorage.getItem("desktopSearch")?JSON.parse(localStorage.getItem("desktopSearch")):null)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                  id="input-with-icon-textfield"
                  variant="outlined"
                  placeholder="Kamu lagi cari apa ?"
                  size="small"
                  className={classes.textSearch}
                  // onKeyPress={(e) => {
                  //   if (e.key == "Enter") {
                  //     searchPageClickWithInsertStory(e, e.target.value);
                  //   }
                  // }}
                />
              )}
              renderOption={(props, option) => {
                const matches = match(props.label, option.inputValue, {
                  insideWords: true,
                });
                const parts = parse(props.label, matches);
                return (
                  <li {...props.label}>
                    <div>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                            color: part.highlight ? themeColor : "black",
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  </li>
                );
              }}
            />

            <div className={classes.sectionDesktop}>
              <Badge
                badgeContent={wishlistCount}
                color="secondary"
                onClick={() => {
                  handleClose(3);
                }}
              >
                <svg
                  className={"MuiSvgIcon-root"}
                  style={{ fill: "transparent", fontSize: 31 }}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.62 20.8096C12.28 20.9296 11.72 20.9296 11.38 20.8096C8.48 19.8196 2 15.6896 2 8.68961C2 5.59961 4.49 3.09961 7.56 3.09961C9.38 3.09961 10.99 3.97961 12 5.33961C13.01 3.97961 14.63 3.09961 16.44 3.09961C19.51 3.09961 22 5.59961 22 8.68961C22 15.6896 15.52 19.8196 12.62 20.8096Z"
                    stroke="#2B2B2B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Badge>
              <Badge
                badgeContent={counterCart}
                color="secondary"
                onClick={() => {
                  history.push("/carts");
                }}
              >
                <svg
                  className={"MuiSvgIcon-root"}
                  style={{ fill: "transparent", fontSize: 31 }}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"
                    stroke="#2B2B2B"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
                    stroke="#2B2B2B"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
                    stroke="#2B2B2B"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 8H21"
                    stroke="#2B2B2B"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Badge>
              {UserData.username ? (
                <div>
                  <span
                    style={{
                      paddingTop: "8px",
                      paddingLeft: "8px",
                      fontSize: 16,
                      display: "flex",
                      alignItems: "center",
                      columnGap: "7px",
                      color: "black",
                    }}
                    onClick={handleClick}
                    // onMouseOver={handleClick}
                    className={classes.hoverStyle}
                  >
                    <Avatar
                      src={
                        UserData.profile_picture
                          ? process.env.REACT_APP_FILE_BASE_URL +
                            "/" +
                            UserData.profile_picture
                          : ""
                      }
                      style={{ height: 34, width: 34 }}
                    />
                    {UserData.username}
                  </span>
                  <Menu
                    id="simple-menu"
                    anchorEl={menuanchorEl}
                    keepMounted
                    open={Boolean(menuanchorEl)}
                    onClose={() => setmenuAnchorEl(null)}
                    MenuListProps={{ onMouseLeave: handleCloseWithoutDirect }}
                    PaperProps={{
                      style: {
                        marginTop: "50px",
                      },
                    }}
                  >
                    <MenuItem onClick={() => handleClose(0)}>
                      {t("my_profile")}
                    </MenuItem>
                    <MenuItem onClick={() => handleClose(2)}>
                      {t("my_order1")}
                    </MenuItem>
                    <MenuItem onClick={() => handleClose(1)}>
                      {t("my_address")}
                    </MenuItem>
                    <MenuItem onClick={openModalLogout}>
                      {t("logout2")}
                    </MenuItem>
                  </Menu>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "1200px",
              margin: "0px auto",
              height: 60,
              columnGap: 20,
            }}
          >
            {store ? (
              <img
                src={getFileUrl(store.logo)}
                width={200}
                onClick={() => history.push("/")}
                className={classes.ImgWrap}
                alt=""
              />
            ) : (
              <span
                style={{ cursor: "pointer", color: "black" }}
                onClick={() => history.push("/")}
              >
                {t("loadingdot")}
              </span>
            )}

            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              style={{ width: "70%" }}
              options={
                myDataFilter
                  ? myDataFilter.length > 0
                    ? myDataFilter.map((item, index) => {
                        return {
                          id: item.product.id,
                          label: item.product.name,
                        };
                      })
                    : myList.map((item, index) => {
                        return { id: item.product_id, label: item.name };
                      })
                  : myList.map((item, index) => {
                      return { id: item.product_id, label: item.name };
                    })
              }
              onInputChange={OnChangeSearchInput}
              onChange={(e, value) => searchPageClick(e, value)}
              getOptionLabel={(option) => {
                if (option.label) {
                  option = option.label;
                } else {
                  option = option;
                }
                return option;
              }}
              // className={classes.textSearch2}
              // value={(localStorage.getItem("desktopSearch")?JSON.parse(localStorage.getItem("desktopSearch")):null)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                  id="input-with-icon-textfield"
                  variant="outlined"
                  placeholder="Kamu lagi cari apa ?"
                  size="small"
                  className={classes.textSearch}
                  // onKeyPress={(e) => {
                  //   if (e.key == "Enter") {
                  //     searchPageClick(e, e.target.value);
                  //   }
                  // }}
                />
              )}
              renderOption={(props, option) => {
                const matches = match(props.label, option.inputValue, {
                  insideWords: true,
                });
                const parts = parse(props.label, matches);
                return (
                  <li {...props.label}>
                    <div>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                            color: part.highlight ? themeColor : "black",
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  </li>
                );
              }}
            />

            <div className={classes.sectionDesktop}>
              <Badge
                badgeContent={wishlistCount}
                color="secondary"
                onClick={() => {
                  setOpen(true);
                }}
              >
                {/* <FactCheckOutlinedIcon style={{ color: "black", fontSize: 31 }} /> */}
                <svg
                  className={"MuiSvgIcon-root"}
                  style={{ fill: "transparent", fontSize: 31 }}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.62 20.8096C12.28 20.9296 11.72 20.9296 11.38 20.8096C8.48 19.8196 2 15.6896 2 8.68961C2 5.59961 4.49 3.09961 7.56 3.09961C9.38 3.09961 10.99 3.97961 12 5.33961C13.01 3.97961 14.63 3.09961 16.44 3.09961C19.51 3.09961 22 5.59961 22 8.68961C22 15.6896 15.52 19.8196 12.62 20.8096Z"
                    stroke="#2B2B2B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Badge>
              <Badge
                badgeContent={counterCart}
                color="secondary"
                onClick={() => {
                  setOpen(true);
                }}
              >
                {/* <LocalMallOutlinedIcon style={{ color: "black", fontSize: 31 }} /> */}
                <svg
                  className={"MuiSvgIcon-root"}
                  style={{ fill: "transparent", fontSize: 31 }}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"
                    stroke="#2B2B2B"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
                    stroke="#2B2B2B"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
                    stroke="#2B2B2B"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 8H21"
                    stroke="#2B2B2B"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Badge>
              {/* <span
                  style={{
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontSize: 14,
                    display: "flex",
                    alignItems: "center",
                    columnGap: "7px",
                    color:'black'
                  }}
                  onClick={() => setOpen(true)}
                  className={classes.hoverStyle}
                >
                  <AccountCircleOutlinedIcon className={classes.svgRoot} />
                  Login / Sign Up
                </span> */}
            </div>
            <Button
              variant="outlined"
              size="small"
              style={{ color: themeColor, borderColor: themeColor }}
              onClick={() => setOpenSignIn()}
            >
              {t("login")}
            </Button>
            <Button
              variant="contained"
              size="small"
              style={{ background: themeColor, color: "white" }}
              onClick={() => setOpenSignUp()}
            >
              {t("register2")}
            </Button>
          </div>
        )}
      </AppBar>
      <AuthDilog
        open={isOpen}
        tabIndexOpen={valueTabIndexOpen}
        onClose={() => setOpen(false)}
        LoginFunction={LoginFunction}
        SignUpFunction={SignUpFunction}
      />

      <DialogAlert
        open={openLogoutPopup}
        close={closeModalLogout}
        yesno="true"
        no={closeModalLogout}
        ok={onOklogout}
      >
        {t("ask_logout")}
      </DialogAlert>
    </div>
  );
};
export default withNamespaces()(DeskTopHeader);
