import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Header from "../header/header2";
// import { orderPaymentConfirm } from "../../store/actions/OrderPaymentConfirmActions";
// import { getPaymentProductUser } from "../../store/actions/getPaymentActions";
// import { GetPaymentDataResponse } from "../../store/Selectors/getpaymentdata";
// import { GetConfirmPaymentResponse } from "../../store/Selectors/OrderConfirmPayment";
import { useHistory } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { BsImage } from "react-icons/bs";
import { FiImage } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import ClipLoader from "react-spinners/ClipLoader";
import Button from "@material-ui/core/Button";
import { ThemeColor1 } from "../themecolor/index1";
import Loader from "../loader";
import { useStore } from "../../hooks/useStore";

function PaymentConfirm() {
  const { store } = useStore();

  const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : "#FFFFFF",
      },
    },
  });
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100%",
      // backgroundColor: theme.palette.background.paper
    },
    filterBox: {
      border: "0.125rem solid #fff",
      backgroundColor: "#fff",
    },
  }));

  const classes = useStyles();
  const history = useHistory();
  // const dispatch = useDispatch();
  const paymentdata = JSON.parse(localStorage.getItem("ordersdata"));

  const [field, setField] = useState({
    amount: "",
    bankrec: "",
    banksen: "",
    accountname: "",
    notes: "",
    image: "",
  });
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [true1, setTrue1] = useState(false);
  useEffect(() => {
    let data1 = new FormData();
    // dispatch(getPaymentProductUser(data1));
    setField({
      ...field,
      amount:
        Object.keys(paymentdata[0].total.toString()).length > 0
          ? Object.keys(paymentdata[0].total.toString()).length <= 3
            ? paymentdata[0].total.toString()
            : Object.keys(paymentdata[0].total.toString()).length == 4
            ? paymentdata[0].total.toString().substr(-4, 1) +
              "." +
              paymentdata[0].total.toString().substr(-3, 3)
            : Object.keys(paymentdata[0].total.toString()).length == 5
            ? paymentdata[0].total.toString().substr(-5, 2) +
              "." +
              paymentdata[0].total.toString().substr(-3, 3)
            : Object.keys(paymentdata[0].total.toString()).length == 6
            ? paymentdata[0].total.toString().substr(-6, 3) +
              "." +
              paymentdata[0].total.toString().substr(-3, 3)
            : Object.keys(paymentdata[0].total.toString()).length == 7
            ? paymentdata[0].total.toString().substr(-7, 1) +
              "." +
              paymentdata[0].total.toString().substr(-6, 3) +
              "." +
              paymentdata[0].total.toString().substr(-3, 3)
            : Object.keys(paymentdata[0].total.toString()).length == 8
            ? paymentdata[0].total.toString().substr(-8, 2) +
              "." +
              paymentdata[0].total.toString().substr(-6, 3) +
              "." +
              paymentdata[0].total.toString().substr(-3, 3)
            : Object.keys(paymentdata[0].total.toString()).length == 9
            ? paymentdata[0].total.toString().substr(-9, 3) +
              "." +
              paymentdata[0].total.toString().substr(-6, 3) +
              "." +
              paymentdata[0].total.toString().substr(-3, 3)
            : ""
          : "",
      bankrec:
        Object.keys(paymentdata[0].bankdata).length > 0
          ? paymentdata[0].bankdata.bank_name +
            " " +
            paymentdata[0].bankdata.account_no +
            " an. " +
            paymentdata[0].bankdata.account_owner_name
          : "null null null",
    });
    setTimeout(() => {
      setTrue1(true);
    }, 700);
  }, []);

  const sitetitle = store?.name || process.env.REACT_APP_NICK_SITE_TITLE;
  const bankres = {};
  const confirmpayment = {};

  const [Image, setImage] = useState("");
  const [err, setErr] = useState("");
  const imageRef = useRef();
  const formRef = useRef();
  const onChangeFile = (e) => {
    setErr("");
    setImage(URL.createObjectURL(e.target.files[0]));
    setField({ ...field, image: e.target.files[0] });
  };

  const handleChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose1 = () => {
    setOpen2(false);
    history.push("/orderhistory");
  };

  const Save = () => {
    if (
      field.amount !== "" &&
      field.bankrec !== "" &&
      field.banksen !== "" &&
      field.accountname !== "" &&
      field.image !== ""
    ) {
      let data = new FormData();
      const userid = JSON.parse(localStorage.getItem("userid"));
      data.append("user_id", userid);
      data.append("photo", field.image);
      data.append("code", paymentdata[0].code);
      data.append("destbankid", "");
      data.append("total_transfer", paymentdata[0].total);
      data.append("senderbankid", "");
      data.append("date_time", "2020-09-02 12:35:00");
      data.append("account_name", field.accountname);
      data.append("note", field.notes);
      data.append("installment_id", "1");
      // dispatch(orderPaymentConfirm(data));
      setOpen1(true);
      setTimeout(() => (setOpen1(false), setOpen2(true)), 600);
    } else {
      setErr("Please upload evidence of transfer image");
    }
  };

  const selectBank = (e, j, k) => {
    setField({ ...field, bankrec: e + " " + j + " an ." + k });
    setOpen(false);
  };
  return (
    <div>
      {true1 ? (
        <ThemeProvider theme={theme}>
          <Header title="Payment Confirmation" color={ThemeColor} />
          <div style={{ paddingTop: "44px" }}>
            <div
              className={` p-0 m-3 border-0 bg-white shadow-card card d-flex align-items-center justify-content-center`}
            >
              <ValidatorForm className="w-100">
                <Grid container>
                  <Grid item xs={12} style={{ margin: "12px 15px" }}>
                    <TextValidator
                      label="No. Invoice"
                      name="Invoice"
                      fullWidth={true}
                      value={paymentdata[0].code}
                      variant="standard"
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                      InputLabelProps={{ style: { fontSize: 15 } }}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ margin: "11px 15px" }}>
                    <TextValidator
                      label="Transfer Amount"
                      name="amount"
                      fullWidth={true}
                      value={field.amount}
                      variant="standard"
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                      InputLabelProps={{ style: { fontSize: 15 } }}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ margin: "11px 15px" }}>
                    <TextValidator
                      label="Bank Reciever"
                      fullWidth={true}
                      // onChange={handleChange}
                      onClick={() => {
                        setOpen(true);
                      }}
                      name="bankrec"
                      value={field.bankrec}
                      variant="standard"
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                      InputLabelProps={{ style: { fontSize: 15 } }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ margin: "11px 15px" }}>
                    <TextValidator
                      label="Bank Sender"
                      fullWidth={true}
                      onChange={handleChange}
                      name="banksen"
                      value={field.banksen}
                      variant="standard"
                      validators={["required"]}
                      errorMessages={["Bank Sender is required"]}
                      InputLabelProps={{ style: { fontSize: 15 } }}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ margin: "11px 15px" }}>
                    <TextValidator
                      label="Account Name"
                      fullWidth={true}
                      onChange={handleChange}
                      name="accountname"
                      value={field.accountname}
                      variant="standard"
                      validators={["required"]}
                      errorMessages={["Account Name is required"]}
                      InputLabelProps={{ style: { fontSize: 15 } }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ margin: "11px 15px" }}>
                    <TextValidator
                      label="Notes"
                      fullWidth={true}
                      onChange={handleChange}
                      name="notes"
                      value={field.notes}
                      variant="standard"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                    />
                  </Grid>
                  <button style={{ display: "none" }} ref={formRef}>
                    Ok
                  </button>
                </Grid>
              </ValidatorForm>
            </div>
          </div>
          <div>
            <h5 className="ms-4 text-muted title-evidience">
              Evidence of Transfer
            </h5>
            <div className="shadow-card m-3 d-flex align-items-center justify-content-center card border-0">
              <img
                src={
                  field.image !== ""
                    ? Image
                    : "/assets/frontend/img/thumbnail.jpg"
                }
                className="rounded p-4 my-3 my-3 m-auto evidience-img"
                alt="..."
              />
              {/* <FiImage className="rounded p-4 my-3 my-3 m-auto" className="evidience-img" style={{opacity:"0.9",color:"lightgrey"}}/>
            }  */}
              {err !== "" ? (
                <span style={{ color: ThemeColor ? ThemeColor : "#FFFFFF" }}>
                  {err}
                </span>
              ) : (
                ""
              )}
              <input
                type="file"
                ref={imageRef}
                style={{ display: "none" }}
                onChange={onChangeFile}
              />
              <button
                style={{
                  backgroundColor: ThemeColor
                    ? ThemeColor
                    : "#FFFFFF" + " !important",
                }}
                className="upload-btn mt-1 text-uppercase"
                onClick={() => imageRef.current.click()}
              >
                upload
              </button>
            </div>
            <div className="finish">
              <button
                style={{
                  backgroundColor: ThemeColor
                    ? ThemeColor
                    : "#FFFFFF" + " !important",
                }}
                className="finish-btn mt-1 text-uppercase"
                onClick={() => {
                  formRef.current.click();
                  Save();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
          {open ? (
            <div>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ paddingBottom: "0px" }}
                >
                  <b>Select Option</b>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ marginBottom: "0px" }}
                  >
                    {bankres.data.map((bank, index) => (
                      <div>
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            onClick={() => {
                              selectBank(
                                bank.bank_name,
                                bank.account_no,
                                bank.account_owner_name
                              );
                            }}
                          >
                            <img
                              src={bank.picture}
                              height="50px"
                              width="50px"
                            />
                          </div>
                          <div
                            onClick={() => {
                              selectBank(
                                bank.bank_name,
                                bank.account_no,
                                bank.account_owner_name
                              );
                            }}
                          >
                            <div style={{ fontSize: "13px" }}>
                              {bank.bank_name}
                            </div>
                            <div style={{ fontSize: "13px" }}>
                              {bank.account_no +
                                " an. " +
                                bank.account_owner_name}
                            </div>
                          </div>
                          <div>
                            <Radio
                              onClick={() => {
                                selectBank(
                                  bank.bank_name,
                                  bank.account_no,
                                  bank.account_owner_name
                                );
                              }}
                              style={{ color: "var(--primary-color)" }}
                            />
                          </div>
                        </div>
                        <b>
                          <hr
                            style={{
                              marginTop: "0px",
                              marginBottom: "6px",
                              height: "2px",
                            }}
                          />
                        </b>
                      </div>
                    ))}
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            </div>
          ) : (
            ""
          )}
          {open1 ? (
            <div>
              <Dialog
                open={open1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{
                      color: "black",
                      fontWeight: "500",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "12px 24px",
                    }}
                  >
                    <div style={{ marginRight: "14px" }}>
                      <ClipLoader
                        color={ThemeColor ? ThemeColor : "#FFFFFF"}
                        height={50}
                        style={{
                          paddingLeft: "1px",
                          marginTop: "8px",
                          fontSize: "4",
                        }}
                      />{" "}
                    </div>
                    <div>Loading ...</div>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            </div>
          ) : (
            ""
          )}
          {open2 ? (
            <div>
              <Dialog
                open={open2}
                onClick={handleClose1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ paddingBottom: "0px" }}
                >
                  <b>{sitetitle}</b>
                </DialogTitle>
                <DialogContent
                  id="alert-dialog-description"
                  style={{
                    color: "black",
                    fontWeight: "500",
                  }}
                >
                  <div>{confirmpayment.ResponseMsg}</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      onClick={handleClose1}
                      style={{ color: ThemeColor ? ThemeColor : "#FFFFFF" }}
                    >
                      Ok
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          ) : (
            ""
          )}
        </ThemeProvider>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            paddingTop: "250px",
            paddingBottom: "300px",
            flexDirection: "column",
            height: "100vh",
            backgroundColor: "white",
          }}
        >
          <div>
            <Loader />
          </div>
          <div>Loading...</div>
        </div>
      )}
    </div>
  );
}

export default PaymentConfirm;
