import { Box, Button, ButtonBase, Grid, Dialog } from "@material-ui/core";
import moment from "moment";
import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Amount from "../../../../../../components/common/Amount";
import ButtonCustom from "../../../../../../components/common/ButtonCustom";
import Card from "../../../../../../components/common/Card";
import Input from "../../../../../../components/common/form/Input";
import Loading from "../../../../../../components/common/Loading";
// import { myOrderProductUser } from "../../../../../../store/actions/myOrderActions";
// import { MyOrderProductResponse } from "../../../../../../store/Selectors/myorder";
import i18n from "../../../../../language/localization";

import NoDataFound from "../../../../compo/NoDataFound";
import { useStyles } from "./unpaid.style";
import { t } from "i18next";
import SkeletonCircleText from "../../../../../../components/common/SkeletonCircleText";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  get as getOrders,
  cancel as cancelOrderRequest,
} from "../../../../../../api/order";
import { priceFormatLocal } from "../../../../../../utils/helper";

const Unpaid = (props) => {
  const { webThemeColor } = props;
  const classes = useStyles();
  const [isOff, setIsOff] = useState(0);

  const priceItemData = [
    { lable: "Total Harga (1 Barang)", price: "Rp 1.709.999" },
    { lable: "Total ongkos Kirim (250 Gr)", price: "Rp 10.000" },
    { lable: "Biaya Asuransi", price: "Rp 6.900" },
    { lable: "Total Pembayran", price: "Rp 1..726.899" },
    { lable: "Metode Pembayran", price: "Rp OVO Cash" },
  ];

  // Unpaid Data Fetch

  // const dispatch = useDispatch();
  const history = useHistory();
  let [responseData, setResponseData] = React.useState([]);
  let [isNext, isNextFunc] = React.useState(false);
  let [pageCount, setCount] = React.useState(1);
  let [page, setPage] = React.useState("");
  let [status, setStatus] = React.useState(false);
  let [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [uniqueCode, setUniquecode] = React.useState(0);
  const CheckoutPage = localStorage.getItem("checkoutPage");
  const membershipData = JSON.parse(localStorage.getItem("membership"));
  const [membershipLevel, setMembershipLevel] = useState(1);
  const loadMoreData = () => {
    setCount(pageCount + 1);
  };

  const loadData = async (pageCount) => {
    if (pageCount !== 1) isNextFunc(true);
    // const userid = JSON.parse(localStorage.getItem("userid"));
    // var data3 = new FormData();
    // data3.append("user_id", userid);
    // data3.append("type", "UNPAID");
    // data3.append("page", pageCount);
    // dispatch(myOrderProductUser(data3));
    // setTimeout(() => {
    //   setOpen(true);
    // }, 700);
    setLoading(true);
    try {
      const response = await getOrders(
        localStorage.getItem("token"),
        `{"order_status": "WAITING VALIDATION,UNPAID", "get_all_data": true}`
      );
      if (response.success) {
        setResponseData([...response.data]);
        isNextFunc(true);
        setPage(response.meta.total_pages);
        localStorage.setItem(
          "unpaidtotalrecord",
          JSON.stringify(response.meta.total_count)
        );
        setLoading(false);
      }
    } catch (error) {
      console.log("Error fetching get order:", error);
    }
  };
  React.useEffect(() => {
    loadData(pageCount);
  }, [pageCount]);

  // const data1 = MyOrderProductResponse();
  // if (open === true) {
  //   // setLoading(true)
  //   setResponseData([...responseData, ...data1["UNPAID"]]);
  //   isNextFunc(true);
  //   setPage(data1.total_page);
  //   setOpen(false);
  //   localStorage.setItem(
  //     "unpaidtotalrecord",
  //     JSON.stringify(data1.total_record)
  //   );
  //   setLoading(false);
  // }

  const [orderId, setOrderId] = useState([]);
  const setOrder = (e) => {
    for (var i = 0; i < responseData.length; i++) {
      if (responseData[i].id === e) {
        orderId.push(responseData[i]);
      }
    }
  };

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
    if (membershipData) {
      setMembershipLevel(membershipData.level);
    }
  }, []);

  // console.log("responseData_", responseData);

  useEffect(() => {
    // console.log("is_ useEffect");
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  // popup cancel order
  const [openPopupCancelOrder, setOpenPopupCancelOrder] = useState(false);
  const [orderID, setOrderID] = useState(false);
  const handleClickOpenPopupCancelOrder = async (orderid) => {
    setOpenPopupCancelOrder(true);
    setOrderID(orderid);
  };
  const handleClickClosePopupCancelOrder = async () => {
    setOpenPopupCancelOrder(false);
  };

  const cancelOrder = async () => {
    const data = {
      order_id: orderID,
    };
    const response = await cancelOrderRequest(
      data,
      localStorage.getItem("token")
    );
    if (response.success) {
      loadData(pageCount);
      setOpenPopupCancelOrder(false);
    }
  };

  return (
    <div className={classes.cardStyle}>
      {/* {responseData.length > 0 && (
        <Box className={classes.header}>
          <Input
            type="date"
            //   leftLabel="Email"
            className={classes.input_style}
          />
          <Input
            type="text"
            search="true"
            //   leftLabel="Email"
            className={classes.input_style}
          />
          <Button className={classes.btn_reset}>reset</Button>
        </Box>
      )} */}

      {loading ? (
        // <Loading />
        ["", "", "", "", ""].map(() => (
          <SkeletonCircleText
            style={{
              width: "100%",
              height: "207px",
              borderRadius: "8px",
            }}
          />
        ))
      ) : responseData.length > 0 ? (
        responseData.map((el) => (
          // MuiSvgIcon-root
          <Box className={classes.cardItem}>
            <header className="header_cardItem">
              <Grid container style={{ height: "35px" }}>
                <Grid item style={{ maxWidth: "16%", flexBasis: "16%" }}>
                  <div className="ShippingStart" style={{ paddingTop: "5px" }}>
                    <label style={{ fontSize: "17px" }}>#</label>
                    <label style={{ fontSize: "17px" }}>
                      {el.order.order_number}
                    </label>
                  </div>
                </Grid>
                <Grid item style={{ maxWidth: "21%", flexBasis: "21%" }}>
                  <div className="Shipping">
                    <svg
                      className={"MuiSvgIcon-root"}
                      style={{ fill: "transparent" }}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.33334 1.33203V3.33203"
                        stroke="#2B2B2B"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.6667 1.33203V3.33203"
                        stroke="#2B2B2B"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.33334 6.05859H13.6667"
                        stroke="#2B2B2B"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14 5.66536V11.332C14 13.332 13 14.6654 10.6667 14.6654H5.33333C3 14.6654 2 13.332 2 11.332V5.66536C2 3.66536 3 2.33203 5.33333 2.33203H10.6667C13 2.33203 14 3.66536 14 5.66536Z"
                        stroke="#2B2B2B"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.4631 9.13411H10.4691"
                        stroke="#2B2B2B"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.4631 11.1341H10.4691"
                        stroke="#2B2B2B"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.997 9.13411H8.00299"
                        stroke="#2B2B2B"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.997 11.1341H8.00299"
                        stroke="#2B2B2B"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.52953 9.13411H5.53552"
                        stroke="#2B2B2B"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.52953 11.1341H5.53552"
                        stroke="#2B2B2B"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <label style={{ fontSize: "15px" }}>
                      {moment(el.order.created_at).format("DD-MM-YYYY hh:mm")}
                    </label>
                  </div>
                </Grid>
                <Grid item style={{ maxWidth: "16%", flexBasis: "16%" }}>
                  <div className="Shipping">
                    <svg
                      className={"MuiSvgIcon-root"}
                      style={{ fill: "transparent" }}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.66666 6H4.66666"
                        stroke="#2B2B2B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.6666 7.31278V8.68616C14.6666 9.05282 14.3733 9.3528 14 9.36613H12.6933C11.9733 9.36613 11.3133 8.83947 11.2533 8.11947C11.2133 7.69947 11.3733 7.30613 11.6533 7.0328C11.9 6.77946 12.24 6.63281 12.6133 6.63281H14C14.3733 6.64615 14.6666 6.94612 14.6666 7.31278Z"
                        stroke="#2B2B2B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.6533 7.03202C11.3733 7.30536 11.2133 7.69869 11.2533 8.11869C11.3133 8.83869 11.9733 9.36536 12.6933 9.36536H14V10.332C14 12.332 12.6667 13.6654 10.6667 13.6654H4.66667C2.66667 13.6654 1.33334 12.332 1.33334 10.332V5.66536C1.33334 3.85203 2.42667 2.58536 4.12667 2.37203C4.3 2.34536 4.48 2.33203 4.66667 2.33203H10.6667C10.84 2.33203 11.0067 2.33869 11.1667 2.36536C12.8867 2.56536 14 3.8387 14 5.66536V6.63204H12.6133C12.24 6.63204 11.9 6.77869 11.6533 7.03202Z"
                        stroke="#2B2B2B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <label style={{ fontSize: "15px" }}>
                      {el.order.order_status}
                    </label>
                  </div>
                </Grid>
                <Grid item style={{ maxWidth: "25%", flexBasis: "25%" }}>
                  <div className="Shipping">
                    <svg
                      className={"MuiSvgIcon-root"}
                      style={{ fill: "transparent" }}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.78133 9.55453C5.78133 10.4145 6.44133 11.1079 7.26133 11.1079H8.93466C9.64799 11.1079 10.228 10.5012 10.228 9.75453C10.228 8.9412 9.87466 8.65453 9.34799 8.46786L6.66133 7.53453C6.13466 7.34786 5.78133 7.0612 5.78133 6.24786C5.78133 5.5012 6.36133 4.89453 7.07466 4.89453H8.74799C9.56799 4.89453 10.228 5.58786 10.228 6.44786"
                        stroke="#2B2B2B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.99997 4V12"
                        stroke="#2B2B2B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.99996 14.6654H5.99996C2.66663 14.6654 1.3333 13.332 1.3333 9.9987V5.9987C1.3333 2.66536 2.66663 1.33203 5.99996 1.33203H9.99996C13.3333 1.33203 14.6666 2.66536 14.6666 5.9987V9.9987C14.6666 13.332 13.3333 14.6654 9.99996 14.6654Z"
                        stroke="#2B2B2B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <label style={{ fontSize: "15px" }}>
                      {el.order.payment_method}
                    </label>
                  </div>
                </Grid>
                <Grid item style={{ maxWidth: "22%", flexBasis: "22%" }}>
                  <div className="ShippingEnd">
                    <svg
                      className={"MuiSvgIcon-root"}
                      style={{ fill: "transparent" }}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 9.33203H8.66667C9.4 9.33203 10 8.73203 10 7.9987V1.33203H4C3 1.33203 2.12667 1.88536 1.67334 2.69869"
                        stroke="#2B2B2B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.33334 11.332C1.33334 12.4387 2.22668 13.332 3.33334 13.332H4.00001C4.00001 12.5987 4.60001 11.9987 5.33334 11.9987C6.06668 11.9987 6.66668 12.5987 6.66668 13.332H9.33334C9.33334 12.5987 9.93334 11.9987 10.6667 11.9987C11.4 11.9987 12 12.5987 12 13.332H12.6667C13.7733 13.332 14.6667 12.4387 14.6667 11.332V9.33203H12.6667C12.3 9.33203 12 9.03203 12 8.66536V6.66536C12 6.2987 12.3 5.9987 12.6667 5.9987H13.5267L12.3867 4.00537C12.1467 3.59204 11.7067 3.33203 11.2267 3.33203H10V7.9987C10 8.73203 9.40001 9.33203 8.66668 9.33203H8.00001"
                        stroke="#2B2B2B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.33333 14.6667C6.06971 14.6667 6.66667 14.0697 6.66667 13.3333C6.66667 12.597 6.06971 12 5.33333 12C4.59695 12 4 12.597 4 13.3333C4 14.0697 4.59695 14.6667 5.33333 14.6667Z"
                        stroke="#2B2B2B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.6667 14.6667C11.4031 14.6667 12 14.0697 12 13.3333C12 12.597 11.4031 12 10.6667 12C9.9303 12 9.33334 12.597 9.33334 13.3333C9.33334 14.0697 9.9303 14.6667 10.6667 14.6667Z"
                        stroke="#2B2B2B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.6667 8V9.33333H12.6667C12.3 9.33333 12 9.03333 12 8.66667V6.66667C12 6.3 12.3 6 12.6667 6H13.5267L14.6667 8Z"
                        stroke="#2B2B2B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.33334 5.33203H5.33334"
                        stroke="#2B2B2B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.33334 7.33203H4.00001"
                        stroke="#2B2B2B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.33334 9.33203H2.66668"
                        stroke="#2B2B2B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {el.order.shipping_type !== "COURIER RAJA ONGKIR" ? (
                      <label style={{ fontSize: "15px" }}>
                        {el.order.shipping ? el.order.shipping.name : ""}
                      </label>
                    ) : (
                      <label style={{ fontSize: "15px" }}>
                        {
                          (el.order.shipping ? el.order.shipping.service : "") +
                            " " +
                            (el.order.shipping ? el.order.shipping.name : "")
                          // +
                          // " " +
                          // "(" +
                          // el.order.shipping.estimation +
                          // ") " +
                          // "days"
                        }
                      </label>
                    )}
                  </div>
                </Grid>
              </Grid>
            </header>

            <Grid container spacing={2} className={classes.content_cardItem}>
              <Grid item md={2}>
                <h5 className="boldTitle">{t("no_invoice")}</h5>
                <p className="description">#{el.order.order_number}</p>
              </Grid>
              <Grid item md={2}>
                <h5 className="boldTitle">{t("receiver_name")}</h5>
                <p className="description">{el.order.customer.name}</p>
              </Grid>
              <Grid item md={4}>
                <h5 className="boldTitle">{t("address")}</h5>
                <p className="description">
                  {el.order.customer_address
                    ? el.order.customer_address.address
                    : ""}
                </p>
              </Grid>
              <Grid item md={3}>
                <h5 className="boldTitle">{t("total_quantity")}</h5>
                <p className="description">{el.order.product_qty} Pcs</p>
              </Grid>
              <Grid item md={3}>
                <h5 className="boldTitle">{t("total_price")}</h5>
                <p className="description">
                  Rp <Amount amount={el.order.grand_total} />
                </p>
              </Grid>
            </Grid>

            {isOff === el.order.id && (
              <Box className={classes.content_see_hide}>
                <Grid container spacing={2}>
                  {/* <Grid item md={12} className={classes.item_see_hide}>
                    <Box className={classes.item}>
                      <div className={classes.square_box}></div>
                      <div className="content">
                        <h5 className="titleContent">
                          Dressed In a new Women's Skirt in a Long Ruffled
                          sleeve Dress...
                        </h5>

                        <p className="price">US$ 17.12 x 1</p>
                        <p className="description">Color: Photo Color Size:1</p>
                      </div>
                    </Box>
                  </Grid>
                  */}

                  {/* ITEM DETAIL  */}
                  {el.order_items.map((data) => (
                    <Grid item md={12} className={classes.item_see_hide}>
                      <Box className={classes.tableContent}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            style={{ maxWidth: "10%", flexBasis: "10%" }}
                          >
                            <img
                              src={
                                data.product.media
                                  ? process.env.REACT_APP_FILE_BASE_URL +
                                    "/" +
                                    data.product.media[0]
                                  : "/assets/images/dummy.png"
                              }
                              style={{
                                width: "80px",
                                height: "80px",
                                position: "relative",
                              }}
                            />
                          </Grid>
                          {data.product.discount_type &&
                          data.product.discount_type === "percentage" ? (
                            <div
                              style={{
                                background: " #D92D20",
                                color: "#fff",
                                fontSize: "12px",
                                width: "28px",
                                height: "19px",
                                position: "absolute",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "11px",
                                marginLeft: "55px",
                              }}
                            >
                              {data.product.discount}%
                            </div>
                          ) : (
                            ""
                          )}

                          {data.product.discount_type &&
                          data.product.discount_type === "nominal" ? (
                            <div
                              style={{
                                background: " #D92D20",
                                color: "#fff",
                                fontSize: "12px",
                                width: "28px",
                                height: "19px",
                                position: "absolute",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "11px",
                                marginLeft: "55px",
                              }}
                            >
                              {parseInt(
                                (data.product.discount / data.price) * 100
                              )}
                              %
                            </div>
                          ) : (
                            ""
                          )}

                          <Grid item md={4}>
                            <Box className={classes.description_style}>
                              <h4 className="title_de ">{data.product.name}</h4>

                              {data.product_variant ? (
                                <p className="values">
                                  <span>{t("variant")}: </span>
                                  {data.product_variant.attributes[0].value}
                                </p>
                              ) : null}
                            </Box>
                          </Grid>
                          <Grid item md={2}>
                            <p className={classes.pcs}>{data.quantity} pcs</p>
                          </Grid>
                          <Grid item md={2}>
                            <Box className={classes.priceContent_style}>
                              {data.product.discount_type &&
                              data.product.discount_type === "nominal" ? (
                                <p className="values">
                                  {priceFormatLocal(
                                    data.price - data.product.discount
                                  )}
                                </p>
                              ) : (
                                ""
                              )}

                              {data.product.discount_type &&
                              data.product.discount_type === "percentage" ? (
                                <p className="values">
                                  {priceFormatLocal(
                                    data.price -
                                      (data.product.discount / 100) * data.price
                                  )}
                                </p>
                              ) : (
                                ""
                              )}

                              {data.product.discount === 0 ? (
                                <p className="values">
                                  {priceFormatLocal(data.price)}
                                </p>
                              ) : (
                                ""
                              )}

                              {data.product.discount_type ? (
                                <p className="values price-wdout">
                                  {priceFormatLocal(data.price)}
                                </p>
                              ) : (
                                ""
                              )}
                            </Box>
                          </Grid>
                          <Grid item md={2}>
                            <p className={classes.gTotal}>
                              Rp&nbsp;&nbsp;
                              <Amount amount={el.grand_total} />
                            </p>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ))}

                  <Grid
                    item
                    md={12}
                    className={
                      classes.item_see_hide + " " + classes.price_item_see_hide
                    }
                  >
                    {/* ITEM DETAIL - Totals Right side */}
                    <Box className={classes.content_price}>
                      <Box className={classes.price_item}>
                        <p className="lable">{t("Total items")}</p>
                        <p className="price">{el.order.item_qty} Item</p>
                      </Box>
                      <Box className={classes.price_item}>
                        <p className="lable">{t("Total weight")}</p>
                        <p className="price">
                          {el.order_items.reduce(
                            (acc, val) => acc + val.product.weight,
                            0
                          )}{" "}
                          Gram
                        </p>
                      </Box>
                      <Box className={classes.price_item}>
                        <p className="lable">{t("Total order")}</p>
                        <p className="price">
                          Rp{" "}
                          <Amount
                            // amount={acc + val.price}
                            amount={el.order.total - el.order.discount}
                          />
                        </p>
                      </Box>

                      {/* <Box className={classes.price_item}>
                        <p className="lable">COD Fees</p>
                        <p className="price">
                          Rp{" "}
                          <Amount
                                // amount={acc + val.price}
                                amount={el.order.selfpickup.reduce(
                                  (acc, val) => acc + val.weight,
                                  0
                                )}
                                style={{ fontWeight: "600" }}
                              />
                        </p>
                      </Box> */}
                      <Box className={classes.price_item}>
                        <p className="lable">{t("voucher")}</p>
                        <p className="price">
                          Rp 0
                          {/* <Amount
                            amount={'0'}
                            style={{ fontWeight: "600" }}
                          /> */}
                        </p>
                      </Box>
                      <Box className={classes.price_item}>
                        <p className="lable">{t("shipping_cost")}</p>
                        <p className="price">
                          Rp <Amount amount={el.order.shipping_cost} />
                        </p>
                      </Box>
                      <Box className={classes.price_item}>
                        <p className="lable">{t("ppn_shipping_tax")}</p>
                        <p className="price">
                          Rp <Amount amount={el.order.shipping_tax} />
                        </p>
                      </Box>
                      <Box className={classes.price_item}>
                        <p className="lable">{t("fix_tax")}</p>
                        <p className="price">
                          Rp <Amount amount={el.order.tax} />
                        </p>
                      </Box>
                      {el.order.is_moota === true ? (
                        <Box className={classes.price_item}>
                          <p className="lable">{t("unique_code")}</p>
                          <p className="price">
                            Rp <Amount amount={el.order.unique_payment_code} />
                          </p>
                        </Box>
                      ) : (
                        ""
                      )}
                      <Box className={classes.price_item}>
                        <p className="lable">{t("grand_total")}</p>
                        <p className="price">
                          Rp <Amount amount={el.order.grand_total} />
                        </p>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}

            <Box className={classes.footer}>
              <Grid container spacing={2}>
                {el.order.order_status !== "CANCELED" &&
                el.order.is_moota === false ? (
                  <Grid item md={8}>
                    <div className={"btnGroup"}>
                      {/* <ButtonCustom bg={webThemeColor}>Confirm</ButtonCustom> */}
                      <Button
                        variant="outlined"
                        style={{
                          color: webThemeColor,
                          borderColor: webThemeColor,
                        }}
                        onClick={() =>
                          handleClickOpenPopupCancelOrder(el.order.id)
                        }
                      >
                        {t("cancel_order")}
                      </Button>
                      {/* <ButtonCustom bg={webThemeColor} className={classes.btn_payment}>
                      {t("payment_confirm")}
                    </ButtonCustom> */}
                    </div>
                  </Grid>
                ) : (
                  <Grid item md={8}>
                    <div className={"btnGroup"}>
                      {/* <ButtonCustom bg={webThemeColor}>Confirm</ButtonCustom> */}
                      {/* <Button variant="outlined" style={{color: webThemeColor, borderColor: webThemeColor}} onClick={()=>handleClickOpenPopupCancelOrder(el.order.id)}>{t('cancel_order')}</Button> */}
                    </div>
                  </Grid>
                )}

                <Grid item md={4}>
                  {isOff === el.order.id ? (
                    <Button
                      onClick={() => setIsOff(0)}
                      className="btn_see"
                      style={{ color: webThemeColor }}
                    >
                      {t("hide_details")}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setIsOff(el.order.id)}
                      className="btn_see"
                      style={{ color: webThemeColor }}
                    >
                      {t("see_details")}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        ))
      ) : (
        <NoDataFound />
      )}
      <Dialog open={openPopupCancelOrder} className={classes.modalRoot}>
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <InfoOutlinedIcon style={{ fontSize: 25, color: "orange" }} />
          {t("ask_cancel_order")}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            gap: "25px",
          }}
        >
          <Button
            variant="outlined"
            style={{ color: webThemeColor, borderColor: webThemeColor }}
            onClick={handleClickClosePopupCancelOrder}
          >
            {t("no")}
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: webThemeColor, color: "white" }}
            onClick={cancelOrder}
          >
            {t("cancel_order")}
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default Unpaid;
