import { createContext, useState } from "react";
import { getProfile, getProfileDetail, signIn } from "../api/authorization";
import { get as getCart } from "../api/cart";
import { getCountOrderByStatus } from "../api/badge";
import { setAuthToken } from "../stores/features/auth/auth";
import { useDispatch } from "react-redux";

const defaultValues = {
  user: null,
  token: null,
  carts: null,
  countCart: 0,
  countUnpaid: 0,
  initAuth: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  refreshCart: () => Promise.resolve(),
  refreshStatusCountOrder: () => Promise.resolve(),
};

const AuthContext = createContext(defaultValues);

const AuthProvider = (props) => {
  const dispatch = useDispatch();

  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [carts, setCarts] = useState(null);
  const [countCart, setCountCart] = useState(0);
  const [countUnpaid, setCountUnpaid] = useState(0);

  // carts
  const refreshCart = () => {
    console.log("refresh cart");

    getCart(localStorage.getItem("token"), `{"limit": 1, "page": 1}`).then(
      (data) => {
        if (data) {
          setCarts(data.data);

          setCountCart(data.data.cart_items?.length ?? 0);
        }
      }
    );
  };

  // my orders
  const refreshStatusCountOrder = () => {
    getCountOrderByStatus(localStorage.getItem("token")).then((res) => {
      if (res.success) {
        setCountUnpaid(res.data.UNPAID);
      }
    });
  };

  // auth login, logout, init

  const getTokenStore = () => localStorage.getItem("token");

  const setTokenStore = (token) => localStorage.setItem("token", token);

  const removeTokenStore = () => localStorage.removeItem("token");

  const handleSuccessLogin = (token, profileData) => {
    // update state
    setUser(profileData);
    setToken(token);

    // set redux auth
    dispatch(setAuthToken(token));

    // set localstorage
    localStorage.setItem("userid", JSON.stringify(profileData.customer.id));
    localStorage.setItem("usercode", JSON.stringify(profileData.customer.id));
    localStorage.setItem("userdata", JSON.stringify(profileData.customer));
    localStorage.setItem("membership", JSON.stringify(profileData.membership));
  };

  const handleLogout = () => {
    removeTokenStore();
    setUser(null);

    localStorage.removeItem("userid");
    localStorage.removeItem("usercode");
    localStorage.removeItem("userdata");
    localStorage.removeItem("membership");
    dispatch(setAuthToken(""));
  };

  const initAuth = () => {
    const storedToken = getTokenStore();

    if (storedToken) {
      getProfileDetail(storedToken).then((data) => {
        if (data) {
          handleSuccessLogin(storedToken, data.data);

          refreshCart();
          refreshStatusCountOrder();
        } else {
          logout();
        }
      });
    }
  };

  const login = async (user, password) => {
    const data = {
      user,
      password,
    };

    const response = await signIn(data);
    if (response.success) {
      // set token to localstorage
      setTokenStore(response.data.access_token);

      const myProfile = await getProfile(response.data.access_token);
      handleSuccessLogin(response.data.access_token, myProfile);

      return response;
    } else {
      return response;
    }
  };

  const logout = () => {
    handleLogout();
  };

  const values = {
    user,
    token,
    carts,
    countCart,
    countUnpaid,
    initAuth,
    login,
    logout,
    refreshCart,
    refreshStatusCountOrder,
  };

  return (
    <AuthContext.Provider value={values}>{props.children}</AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
