import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Header from "../header/header";
import { ThemeColor1 } from "../themecolor/index1";
import { withNamespaces } from "react-i18next";
import i18n from "../language/localization";
import Loader from "../loader";

const DesktopForgotPassword = ({ t,CancleClick }) => {
  const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : "#FFFFFF",
      },
    },
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100%",
      zIndex:1
    },
  }));
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const handleChange = (e) => {
    setEmail(e.target.value);
  }
  const submit = () => {
    //
  }
  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
    setTimeout(() => {
      setOpen(true);
    }, 700);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      {
        open ?
          <div className={classes.root}>
            <h2 style={{marginTop:'0rem',width:'90%',fontSize:25}}>{t("forgot_password")}</h2>
            <div className="d-flex align-items-center justify-content-center" style={{ paddingTop: "0px",marginBottom:'1rem' }}>
              <ValidatorForm
                className="w-100"
                onSubmit={submit}
                onError={(errors) => console.log(errors)}
              >
                <Grid item xs={12} style={{ margin: "15px 15px" }}>
                  <TextValidator
                    label={t("enter_phoneno_register_email")}
                    fullWidth={true}
                    onChange={handleChange}
                    name="email"
                    value={email}
                    variant="outlined"
                    size="small"
                    validators={["required"]}
                    errorMessages={["this field is required", "email is not valid"]}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                  />
                </Grid>
                <div className="finish" style={{marginBottom:'0rem',height:'auto',display:'flex',flexDirection:'column',rowGap:9}}>
                  <button className="finish-btn  text-uppercase" style={{ backgroundColor: ThemeColor ? ThemeColor : "#FFFFFF",height:'3rem' }}>
                    {t("continue")}
                  </button>
                  <button className="finish-btn  text-uppercase" style={{ backgroundColor: ThemeColor ? ThemeColor : "#FFFFFF",height:'3rem' }} onClick={CancleClick}>
                    {t("Cancel")}
                  </button>
                </div>
              </ValidatorForm>
            </div>
          </div>
          :
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: "250px",
              paddingBottom: "300px",
              flexDirection: "column",
              height:"100vh",
              backgroundColor: "white"
            }}
          >
            <div><Loader /></div>
            <div>Loading...</div>
          </div>
      }
    </ThemeProvider>
  );
}
export default withNamespaces()(DesktopForgotPassword)