// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
    display: inline-block;
    width: 100%;
    padding: 38px 0;
    background: #fff;
}
@media (min-width: 1366px)
{
    .main-container {
        max-width: 1260px;
        /* max-width: 1200px; */
    }
}


.main-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.slug-title {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    color: #333;
    font-size: 30px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
}
a{
    color: blue !important;
}`, "",{"version":3,"sources":["webpack://./src/containers/footerSlug/slug.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB;AACA;;IAEI;QACI,iBAAiB;QACjB,uBAAuB;IAC3B;AACJ;;;AAGA;IACI,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,qBAAqB;IACrB,WAAW;IACX,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,kCAAkC;IAClC,0BAA0B;AAC9B;AACA;IACI,sBAAsB;AAC1B","sourcesContent":[".main {\n    display: inline-block;\n    width: 100%;\n    padding: 38px 0;\n    background: #fff;\n}\n@media (min-width: 1366px)\n{\n    .main-container {\n        max-width: 1260px;\n        /* max-width: 1200px; */\n    }\n}\n\n\n.main-container {\n    width: 100%;\n    padding-right: 15px;\n    padding-left: 15px;\n    margin-right: auto;\n    margin-left: auto;\n}\n.slug-title {\n    display: inline-block;\n    width: 100%;\n    margin-bottom: 20px;\n    color: #333;\n    font-size: 30px;\n    font-weight: 500;\n    font-family: \"Poppins\", sans-serif;\n    text-transform: capitalize;\n}\na{\n    color: blue !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
