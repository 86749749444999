import React from "react";
import "./header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import { useHistory } from "react-router-dom";
import ShoppingCartOutlinedIcon from "@material-ui/icons//ShoppingCartOutlined";
import { ThemeColor } from "../themecolor";
import { IoIosArrowRoundBack } from "react-icons/io";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
export default function Header({ title, color }) {
  const history = useHistory();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.up('sm'));
 
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: Mobile?"500px":'100%',
        backgroundColor: color,
        position: "fixed",
        zIndex: "11111",
        marginBottom: "40px",
        userSelect: "none"
      }}
    >
      <div className="title-box" style={{ height: "48px" }}>
        <KeyboardBackspaceIcon
          style={{ color: "white", width: "25px" }}
          onClick={() => {
            history.goBack();
          }} />
        <h1 className="title mb-0" style={{paddingTop: '2px', paddingLeft: '10px'}}>{title}</h1>
      </div>
    </div>
  );
}
