import { makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import Carousel from "react-material-ui-carousel";

import "react-multi-carousel/lib/styles.css";
import { useHistory } from "react-router-dom";
// import { ProductFilterDataResponse } from "../../store/Selectors/productfilterdata";
import i18n from "../language/localization";
import { useStyles1 } from "../themecolor/FontSize";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { firstLetterUppercase, priceFormatLocal } from "../../utils/helper";
import { isDiscountValid } from "../../utils/pricing";
import CardProduct from "../../components/card-product/CardProduct";
import { capitalizeFirstLetter } from "../../utils/helper";

const ProductSlider = ({
  t,
  color,
  productsAllData,
  productsNewestData,
  productsDiscountData,
  productsBestSellerData,
  productsByCategoriesData,
}) => {
  const theme = useTheme();
  const membershipData = JSON.parse(localStorage.getItem("membership"));
  const [membershipLevel, setMembershipLevel] = useState(1);
  const useStyles = makeStyles((theme) => ({
    title_div_desktop: {
      display: "flex",
      justifyContent: "space-between",
      height: "30px",
      clear: "both",
      marginBottom: "0px",
      userSelect: "none",
      marginTop: "1rem",
    },
    Title_div: {
      display: "flex",
      justifyContent: "space-between",
      height: "30px",
      clear: "both",
      marginBottom: "0px",
      userSelect: "none",
      // cursor:"pointer"
    },
    Title: {
      fontSize: "20px",
      fontWeight: 600,
      paddingLeft: "5px",
      // cursor:"pointer",
      userSelect: "none",
      [theme.breakpoints.down("xs")]: {
        fontSize: "19px",
        fontWeight: 500,
        paddingLeft: "5px",
      },
    },
    Para: {
      fontSize: "16px",
      paddingRight: "5px",
      color: color,
      cursor: "pointer",
      userSelect: "none",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
    Container1: {
      height: "100%",
      width: "43.5%",
      display: "inline-block",
      userSelect: "none",
      // [theme.breakpoints.down("xs")]: {
      //   height: "100%",
      //   width: "40%",
      //   display: "inline-block",
      //   userSelect: "none",
      // },

      "& .card": {
        // boxShadow: "0 0 1px",
        cursor: "pointer",
        // border: "1px solid #00000038 !important",
      },
      // "& .card:hover": {
      //   border: "1px solid #ff3a59 !important",
      // },
    },

    desktop_slider_cards: {
      "& .card": {
        border: "1px solid transparent",
        transition: "all 0.1s ease-out",
      },
      "& .card:hover": {
        border: "1px solid " + color + " !important",
        transform: "translateY(-2px) scale(1.005) translateZ(0)",
        cursor: "pointer",
      },
      "& .card-body": {
        padding: "5px",
        // minHeight: "80px",
      },
      "& .cardTitle ": {
        // textTransform: 'uppercase'
      },
    },

    mobile_slider_cards: {
      "& .card": {
        border: "1px solid transparent",
      },
      "& .card-body": {
        // minHeight: '117px',
        padding: "5px 2px !important",
      },

      "& .cardTitle ": {
        //  textTransform: 'uppercase',
        fontSize: "13px !important",
      },
      "& .TowLineText ": {
        height: "38px",
      },
    },

    // Name1: {
    //   fontSize: "14px",
    //   fontWeight: 400,
    //   whiteSpace: "break-spaces",
    //   marginBottom: "0.25px",
    //   cursor: "pointer",
    //   overflow: "hidden",
    //   maxHeight: "45px",
    //   [theme.breakpoints.down("xs")]: {
    //     fontSize: "12px",
    //     fontWeight: 400,
    //     marginBottom: "0.25px",
    //     maxHeight: "37px",
    //     // height: "35px"
    //   },
    // },
    // Price1: {
    //   fontSize: "14px",
    //   color: "red",
    //   marginBottom: "0px",
    //   cursor: "pointer",
    //   [theme.breakpoints.down("xs")]: {
    //     fontSize: "12px",
    //   },
    // },
    card_img: {
      position: "relative",
      // height: "190px",
      width: "100%",
      userSelect: "none",
      [theme.breakpoints.down("xs")]: {
        // height: "142px",
      },
    },
    div_bottom: {
      marginBottom: "50px",
      marginTop: "20px",
      userSelect: "none",
      background: "rgb(247, 247, 247)",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "0px",
        marginTop: "0px",
      },
    },
    // realprice: {
    //   textDecoration: " line-through",
    //   color: "grey",
    //   fontSize: "14px",
    //   [theme.breakpoints.down("xs")]: {
    //     fontSize: "12px",
    //   },
    // }

    cardTitle: {
      "& .read-more-less--more": {
        display: "none",
      },
    },

    slider_style: {
      "& .MuiButtonBase-root": {
        // boxShadow: "0 0 1px 0px ",
        padding: "4px",

        "& .MuiSvgIcon-root": {
          margin: "0 !important",
        },
      },
      "& .css-1abc02a:hover button,  .css-hn784z:hover button": {
        boxShadow: "0 0 6px 0px",
        backgroundColor: "#ffffff",
        opacity: "1 !important",
        color: "#000000",
      },

      "& .MuiIconButton-root:hover": {
        // backgroundColor: "#ff3a59",
        backgroundColor: "#ffffff",
        color: "#000000",
        boxShadow: "0 0 1px 0px ",
      },
    },

    "@media(max-width: 768px)": {
      mobileTitle: {
        whiteSpace: "break-spaces !important",
        marginBottom: "3px",
      },
    },

    price_parent: {
      display: "flex",
      gap: "10px",
      alignItems: "flex-end",
      overflow: "hidden",
    },
  }));
  const classes = useStyles();
  const classes1 = useStyles1();
  const history = useHistory();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const [slide,setSlide]=useState(5)
  // const [prevSlide,setProveSlide]=useState(0)
  const data = {};
  // const [data,setData]=useState()
  // const GetProdutcData=()=>{
  //   const data=new FormData()
  //   data.append('page',1)
  //   fetch('Api/Product/getFilterProduct',{
  //     method: 'post',
  //     headers: {
  //       'key': 'c4c5fc54cd5c071f3fe4785b8ae167e0',
  //       "Access-Control-Allow-Origin":"*"
  //     },
  //     body:data
  //   })
  //   .then((res) => res.json())
  //   .then(res=>setData(res))
  // }
  // useEffect(()=>{
  //   GetProdutcData()
  // },[])
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      // paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      // paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 440, min: 0 },
      items: 1,
    },
  };
  const getSingleProduct = (e) => {
    if (mobile) {
      history.push(`/productdetail?${e}`);
    } else {
      history.push(`/product-detail?${e}`);
    }
  };

  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const proview = (category) => {
    localStorage.setItem("viewproid", category.id ? category.id : "");
    localStorage.setItem("viewproname", category.name || category.categoryName);
    localStorage.setItem("viewprochild", JSON.stringify(""));
    localStorage.setItem(
      "viewtabs",
      JSON.stringify(category.name || category.categoryName)
    );
    history.push("/products");
  };

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
    if (membershipData) {
      setMembershipLevel(membershipData.level);
    }
  }, []);

  // pagination slider product list
  const [numberNextPage, setNumberNextPage] = useState([]);

  const nextPageProductList = (id, productLength) => {
    const perPage = 5;
    const startInitial = 0;
    const endInitial = 5;
    const findIndex = numberNextPage.findIndex((data) => data.id === id);
    if (findIndex < 0) {
      setNumberNextPage((numberNextPage) => [
        ...numberNextPage,
        {
          id: id,
          active: startInitial,
          next: endInitial,
        },
      ]);
    } else {
      const activeCalc =
        numberNextPage[findIndex]["next"] >= productLength
          ? 0
          : numberNextPage[findIndex]["active"] + perPage;
      const endCalc =
        numberNextPage[findIndex]["next"] >= productLength
          ? 5
          : numberNextPage[findIndex]["next"] + perPage;

      let updatePage = [...numberNextPage];
      updatePage[findIndex] = {
        id: id,
        active: activeCalc,
        next: endCalc,
      };
      setNumberNextPage(updatePage);
    }
  };

  const prevPageProductList = (id, productLength) => {
    if (numberNextPage.length > 0) {
      const findIndex = numberNextPage.findIndex((data) => data.id === id);
      const activeCalc =
        numberNextPage[findIndex]["active"] === 0
          ? 0
          : numberNextPage[findIndex]["active"] - 5;
      const endCalc =
        numberNextPage[findIndex]["active"] === 0
          ? 5
          : numberNextPage[findIndex]["next"] - 5;

      let updatePage = [...numberNextPage];
      updatePage[findIndex] = {
        id: id,
        active: activeCalc,
        next: endCalc,
      };
      setNumberNextPage(updatePage);
    }
  };

  return (
    <React.Fragment>
      {/* produk terbaru */}
      <React.Fragment>
        {productsNewestData.length > 0 ? (
          <div className={classes.div_bottom}>
            <div style={{ marginLeft: "5px", marginRight: "5px" }}>
              {(() => {
                let myArray = [];
                myArray.push(
                  <div key={"newest"} style={{ paddingTop: "25px" }}>
                    {productsNewestData.length > 0 ? (
                      <div className={classes.Title_div}>
                        <h3 className={classes.Title}>
                          {firstLetterUppercase(t("new_arrivals"))}
                        </h3>
                        <p
                          onClick={() => {
                            proview({ name: "Produk Terbaru" });
                          }}
                          className={classes.Para}
                        >
                          {t("see_all")}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        width: "100vw",
                        maxWidth: "100vw",
                        overflow: "auto",
                        whiteSpace: "nowrap",
                        scrollbarWidth: "none",
                        display: "flex",
                        gap: "8px",
                        alignItems: "start",
                        // paddingBottom:
                        // productsNewestData[j].length > 0 && productsByCategoriesData[j].name !== "All product" ? "15px" : productsNewestData[j].length > 0 && productsByCategoriesData[j].name == "All product" ? "55px" : "0px",
                      }}
                    >
                      {(() => {
                        let myArray1 = [];

                        for (let j = 0; j < productsNewestData.length; j++) {
                          myArray1.push(
                            <div style={{ minWidth: "43.5%", width: "43.5%" }}>
                              <CardProduct
                                key={j}
                                membershipLevel={membershipLevel}
                                product={productsNewestData[j]}
                                desktopView={false}
                                width="100%"
                                handleClick={getSingleProduct}
                              />
                            </div>

                            // <div
                            //   key={j}
                            //   onClick={() => {
                            //     getSingleProduct(productsNewestData[j].id);
                            //   }}
                            //   className={
                            //     classes.Container1 +
                            //     " " +
                            //     classes.mobile_slider_cards
                            //   }
                            // >
                            //   <div style={{ padding: "0.25rem" }}>
                            //     <div className="card border-0 shadow-card">
                            //       <div>
                            //         <img
                            //           src={
                            //             productsNewestData[j].media &&
                            //             productsNewestData[j].media.length > 0
                            //               ? process.env
                            //                   .REACT_APP_FILE_BASE_URL +
                            //                 "/" +
                            //                 productsNewestData[j].media[0]
                            //               : "/assets/images/dummy.png"
                            //           }
                            //           alt="img"
                            //           style={{
                            //             verticalAlign: "top",

                            //             height: Mobile
                            //               ?
                            //                 "154px"
                            //               : productsNewestData[j].name.length <
                            //                 26
                            //               ? "210px"
                            //               : "190px",
                            //           }}
                            //           className={
                            //             classes.card_img + " card-img-top"
                            //           }
                            //         />
                            //         {productsNewestData[j].stock_status ===
                            //         "Sold Out" ? (
                            //           <div
                            //             className="position-absolute d-flex justify-content-center align-items-center rounded-circle "
                            //             style={{
                            //               width: "4.5rem",
                            //               height: "4.5rem",
                            //               backgroundColor: "rgba(0, 0, 0, 0.5)",
                            //               left: Mobile ? "20%" : "32%",
                            //               top: Mobile ? "19%" : "32%",
                            //               userSelect: "none",
                            //             }}
                            //           >
                            //             <span
                            //               className="text-light"
                            //               style={{
                            //                 fontSize: "12px", //14
                            //                 fontWeight: "bolder",
                            //                 cursor: "pointer",
                            //                 userSelect: "none",
                            //               }}
                            //             >
                            //               {t("sold_out")}
                            //             </span>
                            //           </div>
                            //         ) : (
                            //           ""
                            //         )}
                            //         {productsNewestData[j].has_video === true &&
                            //         productsNewestData[j].stock_status !==
                            //           "Sold Out" ? (
                            //           <div
                            //             className="position-absolute d-flex justify-content-center align-items-center rounded-circle "
                            //             style={{
                            //               width: "4.5rem",
                            //               height: "4.5rem",
                            //               backgroundColor: "rgba(0, 0, 0, 0.5)",
                            //               left: Mobile ? "20%" : "32%",
                            //               top: Mobile ? "19%" : "32%",
                            //               userSelect: "none",
                            //             }}
                            //           >
                            //             <span
                            //               className="text-light"
                            //               style={{
                            //                 fontSize: "12px", //14
                            //                 fontWeight: "bolder",
                            //                 cursor: "pointer",
                            //                 userSelect: "none",
                            //               }}
                            //             >
                            //               <PlayArrowIcon />
                            //             </span>
                            //           </div>
                            //         ) : (
                            //           ""
                            //         )}
                            //         <div
                            //           style={{
                            //             position: "absolute",
                            //             top: "5px",
                            //             right: "5px",
                            //             userSelect: "none",
                            //           }}
                            //         >
                            //           {productsNewestData[j].discount_type &&
                            //           isDiscountValid(
                            //             productsNewestData[j].discount,
                            //             productsNewestData[j]
                            //               .discount_start_date,
                            //             productsNewestData[j].discount_end_date
                            //           ) &&
                            //           productsNewestData[j].discount_type ===
                            //             "percentage" ? (
                            //             <div
                            //               style={{
                            //                 background: "red",
                            //                 display: "flex",
                            //                 justifyContent: "center",
                            //                 alignItems: "center",
                            //                 color: "#fff",
                            //                 fontSize: "12px",
                            //                 width: "58px",
                            //                 height: "20px",
                            //                 borderRadius: "3px",
                            //                 marginTop: "1px",
                            //                 marginBottom: "5px",
                            //                 cursor: "pointer",
                            //                 userSelect: "none",
                            //               }}
                            //             >
                            //               {productsNewestData[j].discount}%
                            //             </div>
                            //           ) : (
                            //             ""
                            //           )}

                            //           {productsNewestData[j].discount_type &&
                            //           isDiscountValid(
                            //             productsNewestData[j].discount,
                            //             productsNewestData[j]
                            //               .discount_start_date,
                            //             productsNewestData[j].discount_end_date
                            //           ) &&
                            //           productsNewestData[j].discount_type ===
                            //             "nominal" ? (
                            //             <div
                            //               style={{
                            //                 background: "red",
                            //                 display: "flex",
                            //                 justifyContent: "center",
                            //                 alignItems: "center",
                            //                 color: "#fff",
                            //                 fontSize: "12px",
                            //                 width: "58px",
                            //                 height: "20px",
                            //                 borderRadius: "3px",
                            //                 marginTop: "1px",
                            //                 marginBottom: "5px",
                            //                 cursor: "pointer",
                            //                 userSelect: "none",
                            //               }}
                            //             >
                            //               {parseInt(
                            //                 (productsNewestData[j].discount /
                            //                   productsNewestData[j].price[
                            //                     membershipLevel
                            //                   ]) *
                            //                   100
                            //               )}
                            //               %
                            //             </div>
                            //           ) : (
                            //             ""
                            //           )}

                            //           {productsNewestData[j].labels &&
                            //           productsNewestData[j].labels.length > 0
                            //             ? productsNewestData[j].labels
                            //                 .filter(
                            //                   (label) =>
                            //                     label !== "Sold Out" &&
                            //                     label !== "Diskon"
                            //                 )
                            //                 .map((item, index) => {
                            //                   let bgColor = "#fdd535";
                            //                   let color = "#fff";
                            //                   switch (item) {
                            //                     case "Terbaru":
                            //                       bgColor = "#fdd535";
                            //                       break;

                            //                     case "Terlaris":
                            //                       bgColor = "red";
                            //                       color = "yellow";
                            //                       break;

                            //                     case "Grosir":
                            //                       bgColor = "#00BFFF";
                            //                       break;

                            //                     default:
                            //                       break;
                            //                   }
                            //                   return (
                            //                     <div
                            //                       style={{
                            //                         background: bgColor,
                            //                         display: "flex",
                            //                         justifyContent: "center",
                            //                         alignItems: "center",
                            //                         color: color,
                            //                         fontSize: "12px",
                            //                         width: "58px",
                            //                         height: "20px",
                            //                         borderRadius: "3px",
                            //                         marginTop: "1px",
                            //                         marginBottom: "5px",
                            //                       }}
                            //                     >
                            //                       {item}
                            //                     </div>
                            //                   );
                            //                 })
                            //             : null}
                            //         </div>
                            //         <div
                            //           className="card-body p-2"
                            //           style={{
                            //             display: "flex",
                            //             flexDirection: "column",
                            //             justifyContent: "space-between",
                            //           }}
                            //         >
                            //           {productsNewestData[j].name.length >
                            //           20 ? (
                            //             <p
                            //               className={
                            //                 classes1.textfont +
                            //                 "  " +
                            //                 classes.mobileTitle +
                            //                 " cardTitle TowLineText"
                            //               }
                            //             >
                            //               {productsNewestData[j].name.length >
                            //               25
                            //                 ? productsNewestData[j].name.slice(
                            //                     0,
                            //                     25
                            //                   )
                            //                 : productsNewestData[j].name}
                            //             </p>
                            //           ) : (
                            //             <p
                            //               className={
                            //                 classes1.textfont +
                            //                 "  " +
                            //                 classes.mobileTitle +
                            //                 " cardTitle "
                            //               }
                            //             >
                            //               {productsNewestData[j].name.length >
                            //               25
                            //                 ? productsNewestData[j].name.slice(
                            //                     0,
                            //                     25
                            //                   )
                            //                 : productsNewestData[j].name}
                            //             </p>
                            //           )}
                            //           <div className={classes.price_parent}>
                            //             <div
                            //               className={
                            //                 classes1.pricediv + "  "
                            //               }
                            //               style={{
                            //                 marginTop: "5px",
                            //               }}
                            //             >
                            //               <b>
                            //                 {productsNewestData[j]
                            //                   .discount_type &&
                            //                 isDiscountValid(
                            //                   productsNewestData[j].discount,
                            //                   productsNewestData[j]
                            //                     .discount_start_date,
                            //                   productsNewestData[j]
                            //                     .discount_end_date
                            //                 ) &&
                            //                 productsNewestData[j]
                            //                   .discount_type === "nominal" ? (
                            //                   <p
                            //                     style={{
                            //                       marginBottom: "0px",
                            //                     }}
                            //                   >
                            //                     {priceFormatLocal(
                            //                       productsNewestData[j].price[
                            //                         membershipLevel
                            //                       ] -
                            //                         productsNewestData[j]
                            //                           .discount
                            //                     )}
                            //                   </p>
                            //                 ) : (
                            //                   ""
                            //                 )}

                            //                 {productsNewestData[j]
                            //                   .discount_type &&
                            //                 isDiscountValid(
                            //                   productsNewestData[j].discount,
                            //                   productsNewestData[j]
                            //                     .discount_start_date,
                            //                   productsNewestData[j]
                            //                     .discount_end_date
                            //                 ) &&
                            //                 productsNewestData[j]
                            //                   .discount_type ===
                            //                   "percentage" ? (
                            //                   <p
                            //                     style={{
                            //                       marginBottom: "0px",
                            //                     }}
                            //                   >
                            //                     {priceFormatLocal(
                            //                       productsNewestData[j].price[
                            //                         membershipLevel
                            //                       ] -
                            //                         (productsNewestData[j]
                            //                           .discount /
                            //                           100) *
                            //                           productsNewestData[j]
                            //                             .price[membershipLevel]
                            //                     )}
                            //                   </p>
                            //                 ) : (
                            //                   ""
                            //                 )}

                            //                 {!isDiscountValid(
                            //                   productsNewestData[j].discount,
                            //                   productsNewestData[j]
                            //                     .discount_start_date,
                            //                   productsNewestData[j]
                            //                     .discount_end_date
                            //                 ) ? (
                            //                   <p
                            //                     style={{
                            //                       marginBottom: "0px",
                            //                     }}
                            //                   >
                            //                     {priceFormatLocal(
                            //                       productsNewestData[j].price[
                            //                         membershipLevel
                            //                       ]
                            //                     )}
                            //                   </p>
                            //                 ) : (
                            //                   ""
                            //                 )}
                            //               </b>

                            //             </div>

                            //             {isDiscountValid(
                            //               productsNewestData[j].discount,
                            //               productsNewestData[j]
                            //                 .discount_start_date,
                            //               productsNewestData[j]
                            //                 .discount_end_date
                            //             ) && (
                            //               <div>
                            //                 <span className={classes1.cutdiv}>
                            //                   {priceFormatLocal(
                            //                     productsNewestData[j].price[
                            //                       membershipLevel
                            //                     ]
                            //                   )}
                            //                 </span>
                            //               </div>
                            //             )}
                            //           </div>
                            //         </div>
                            //       </div>
                            //     </div>
                            //   </div>
                            // </div>
                          );
                        }
                        return myArray1;
                      })()}
                    </div>
                  </div>
                );
                return myArray;
              })()}
            </div>
          </div>
        ) : null}
      </React.Fragment>

      {/* produk diskon */}
      <React.Fragment>
        {productsDiscountData.length > 0 ? (
          <div className={classes.div_bottom}>
            <div style={{ marginLeft: "5px", marginRight: "5px" }}>
              {(() => {
                let myArray = [];
                myArray.push(
                  <div key={"best_seller"} style={{ paddingTop: "15px" }}>
                    {productsDiscountData.length > 0 ? (
                      <div className={classes.Title_div}>
                        <h3 className={classes.Title}>
                          {firstLetterUppercase(t("product_discount"))}
                        </h3>
                        <p
                          onClick={() => {
                            proview({ name: "Discount Product" });
                          }}
                          className={classes.Para}
                        >
                          {t("see_all")}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        width: "100vw",
                        maxWidth: "100vw",
                        overflow: "auto",
                        whiteSpace: "nowrap",
                        scrollbarWidth: "none",
                        display: "flex",
                        gap: "8px",
                        alignItems: "start",
                        // paddingBottom:
                        // productsDiscountData[j].length > 0 && productsByCategoriesData[j].name !== "All product" ? "15px" : productsDiscountData[j].length > 0 && productsByCategoriesData[j].name == "All product" ? "55px" : "0px",
                      }}
                    >
                      {(() => {
                        let myArray1 = [];

                        for (let j = 0; j < productsDiscountData.length; j++) {
                          myArray1.push(
                            <div style={{ minWidth: "43.5%", width: "43.5%" }}>
                              <CardProduct
                                key={j}
                                membershipLevel={membershipLevel}
                                product={productsDiscountData[j]}
                                desktopView={false}
                                width="100%"
                                handleClick={getSingleProduct}
                              />
                            </div>
                          );
                        }
                        return myArray1;
                      })()}
                    </div>
                  </div>
                );
                return myArray;
              })()}
            </div>
          </div>
        ) : null}
      </React.Fragment>

      {/* produk best seller */}
      <React.Fragment>
        {productsBestSellerData.length > 0 ? (
          <div className={classes.div_bottom}>
            <div style={{ marginLeft: "5px", marginRight: "5px" }}>
              {(() => {
                let myArray = [];
                myArray.push(
                  <div key={"best_seller"} style={{ paddingTop: "15px" }}>
                    {productsBestSellerData.length > 0 ? (
                      <div className={classes.Title_div}>
                        <h3 className={classes.Title}>
                          {firstLetterUppercase(t("best_seller2"))}
                        </h3>
                        <p
                          onClick={() => {
                            proview({ name: "Best Seller" });
                          }}
                          className={classes.Para}
                        >
                          {t("see_all")}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        width: "100vw",
                        maxWidth: "100vw",
                        overflow: "auto",
                        whiteSpace: "nowrap",
                        scrollbarWidth: "none",
                        display: "flex",
                        gap: "8px",
                        alignItems: "start",
                        // paddingBottom:
                        // productsBestSellerData[j].length > 0 && productsByCategoriesData[j].name !== "All product" ? "15px" : productsBestSellerData[j].length > 0 && productsByCategoriesData[j].name == "All product" ? "55px" : "0px",
                      }}
                    >
                      {(() => {
                        let myArray1 = [];

                        for (
                          let j = 0;
                          j < productsBestSellerData.length;
                          j++
                        ) {
                          myArray1.push(
                            <div style={{ minWidth: "43.5%", width: "43.5%" }}>
                              <CardProduct
                                key={j}
                                membershipLevel={membershipLevel}
                                product={productsBestSellerData[j]}
                                desktopView={false}
                                width="100%"
                                handleClick={getSingleProduct}
                              />
                            </div>
                          );
                        }
                        return myArray1;
                      })()}
                    </div>
                  </div>
                );
                return myArray;
              })()}
            </div>
          </div>
        ) : null}
      </React.Fragment>

      {/* product by category */}
      <React.Fragment>
        {productsByCategoriesData.length > 0 ? (
          <div className={classes.div_bottom}>
            <div style={{ marginLeft: "5px", marginRight: "5px" }}>
              {(() => {
                let myArray = [];
                for (let i = 0; i < productsByCategoriesData.length; i++) {
                  myArray.push(
                    <div key={i} style={{ paddingTop: "15px" }}>
                      {productsByCategoriesData[i].products.length > 0 ? (
                        <div className={classes.Title_div}>
                          <h3 className={classes.Title}>
                            {capitalizeFirstLetter(
                              productsByCategoriesData[i].categoryName
                            )}
                          </h3>
                          <p
                            onClick={() => {
                              proview(productsByCategoriesData[i]);
                            }}
                            className={classes.Para}
                          >
                            {t("see_all")}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        style={{
                          width: "100vw",
                          maxWidth: "100vw",
                          overflow: "auto",
                          whiteSpace: "nowrap",
                          scrollbarWidth: "none",
                          display: "flex",
                          gap: "8px",
                          alignItems: "start",
                          // paddingBottom:
                          // productsByCategoriesData[i].products.length > 0 && productsByCategoriesData[i].name !== "All product" ? "15px" : productsByCategoriesData[i].products.length > 0 && productsByCategoriesData[i].name == "All product" ? "55px" : "0px",
                        }}
                      >
                        {(() => {
                          let myArray1 = [];

                          for (
                            let j = 0;
                            j < productsByCategoriesData[i].products.length;
                            j++
                          ) {
                            myArray1.push(
                              <div
                                style={{ minWidth: "43.5%", width: "43.5%" }}
                              >
                                <CardProduct
                                  key={j}
                                  membershipLevel={membershipLevel}
                                  product={
                                    productsByCategoriesData[i].products[j]
                                  }
                                  desktopView={false}
                                  width="100%"
                                  handleClick={getSingleProduct}
                                />
                              </div>
                            );
                          }
                          return myArray1;
                        })()}
                      </div>
                    </div>
                  );
                }
                return myArray;
              })()}
            </div>
          </div>
        ) : null}
      </React.Fragment>

      {/* produk all */}
      <React.Fragment>
        {productsAllData.length > 0 ? (
          <div className={classes.div_bottom}>
            <div style={{ marginLeft: "5px", marginRight: "5px" }}>
              {(() => {
                let myArray = [];
                myArray.push(
                  <div key={"all_products"} style={{ paddingTop: "15px" }}>
                    {productsAllData.length > 0 ? (
                      <div className={classes.Title_div}>
                        <h3 className={classes.Title}>{t("all_products")}</h3>
                        <p
                          onClick={() => {
                            proview({ name: "Semua Produk" });
                          }}
                          className={classes.Para}
                        >
                          {t("see_all")}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        width: "100vw",
                        maxWidth: "100vw",
                        overflow: "auto",
                        whiteSpace: "nowrap",
                        scrollbarWidth: "none",
                        display: "flex",
                        gap: "8px",
                        alignItems: "start",
                        // paddingBottom:
                        // productsAllData[j].length > 0 && productsByCategoriesData[i].products[j].name !== "All product" ? "15px" : productsAllData[j].length > 0 && productsByCategoriesData[j].name == "All product" ? "55px" : "0px",
                      }}
                    >
                      {(() => {
                        let myArray1 = [];

                        for (let j = 0; j < productsAllData.length; j++) {
                          myArray1.push(
                            <div style={{ minWidth: "43.5%", width: "43.5%" }}>
                              <CardProduct
                                key={j}
                                membershipLevel={membershipLevel}
                                product={productsAllData[j]}
                                desktopView={false}
                                width="100%"
                                handleClick={getSingleProduct}
                              />
                            </div>
                          );
                        }
                        return myArray1;
                      })()}
                    </div>
                  </div>
                );
                return myArray;
              })()}
            </div>
          </div>
        ) : null}
      </React.Fragment>
    </React.Fragment>
  );
};

export default withNamespaces()(ProductSlider);
