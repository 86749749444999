import { createSlice } from "@reduxjs/toolkit";
import { getProducts } from "../../../api/products";

const initialState = {
  discountProducts: [],
  loading: "idle",
};

export const discountProducts = createSlice({
  name: "discountProducts",
  initialState,
  reducers: {
    discountProductsLoading(state, action) {
      state.loading = action.payload;
    },

    discountProductsReceived(state, action) {
      state.discountProducts = action.payload;
    },
  },
});

export const { discountProductsLoading, discountProductsReceived } =
  discountProducts.actions;

const fetchDiscountProducts = () => async (dispatch) => {
  try {
    dispatch(discountProductsLoading("loading"));
    const products = await getProducts(
      JSON.stringify({
        page: 1,
        limit: 10,
        labels: '["Diskon"]',
        status: "live",
      })
    );

    if (products.success) {
      dispatch(discountProductsReceived(products.data || []));
      dispatch(discountProductsLoading("success"));
    } else {
      dispatch(discountProductsLoading("error"));
    }
  } catch (error) {
    console.log("async reducer ", error);
    dispatch(discountProductsLoading("error"));
  }
};

export { fetchDiscountProducts };

export default discountProducts.reducer;
