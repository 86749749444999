import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// import Tabs from "@material-ui/core/Tabs";
import Chip from "@material-ui/core/Chip";
import "./women.css";
// import Tab from "@material-ui/core/Tab";
import { useHistory } from "react-router-dom";
import New from "./productview/new";
import Discount from "./productview/discount";
import Bestseller from "./productview/bestseller";
import PriceLow from "./productview/pricelow";
import PriceHigh from "./productview/pricehigh";
// import { BsArrowDown } from "react-icons/bs";
// import { BsArrowUp } from "react-icons/bs";
// import { GetCategoryResponse } from "../../store/Selectors/getCategory";
import { withNamespaces } from "react-i18next";
import i18n from "../language/localization";
import "react-web-tabs/dist/react-web-tabs.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ProductByCategory from "./ProductByCategory";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MainPage from "./productview/mainpage";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import DesktopHeaderWrap from "../../components/DeskTopHeaderWrap";
import { makeStyles } from "@material-ui/core";
import DeskTopFooter from "../home/DesktopFooter";
import moment from "moment";
import { get as getLandingPage } from "../../api/landing-page";
import { getByClient as getProductsDetail } from "../../api/products";
import { getBadgeCart } from "../../api/badge";

const themeColorGlobal = JSON.parse(localStorage.getItem("themeColor"));
console.log("themeColorGlobal", themeColorGlobal);
const useStyles = makeStyles((theme) => ({
  Root: {
    [theme.breakpoints.down("xs")]: {
      minHeight: "100%",
      border: "none",
      width: "100%",
      maxWidth: "500px",
      minHeight: "100%",
      margin: "0px auto",
      border: "1px solid #d3d3d3a3",
    },
  },
  MainRoot: {
    paddingTop: "1rem",
    zIndex: -1,
    display: "flex",
    alignItems: "start",
    columnGap: "20px",
    width: "1200px",
    margin: "0px auto",
    paddingBottom: "6rem",
  },
  FilterRoot: {
    width: "290px",
    padding: "15px",
    flexFlow: "column",
    background: "#fff",
    height: "100vh",
  },
  filterHead: {
    fontSize: "22px",
    color: "#333",
    margin: "0 0 25px",
    fontWeight: 700,
    lineHeight: "28px",
  },
  nav: {
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: "0",
    marginBottom: "0",
    listStyle: "none",
  },
  nav_list: {
    width: "100%",
    margin: "0 0 20px",
  },
  labelText: {
    fontSize: "14px",
    borderBottom: "2px solid #ff3a59",
    padding: "0 0 10px",
    cursor: "pointer",
    position: "relative",
    fontFamily: '"Open Sans", sans-serif',
    display: "block",
    margin: "0 0 15px",
    color: "#ff3a59",
    transition: "all 0.25s ease-in",
  },
  hoverPointer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  sortByRoot: {
    color: "#000",
    borderRadius: "3px",
    fontSize: "14px",
    border: "1px solid #ccc",
    padding: "3px 10px",
    "&:hover": {
      background: (props) => props.colorTheme,
      color: "#fff",
      cursor: "pointer",
    },
    height: "2.125rem",
  },

  sortByAv: {
    borderRadius: "3px",
    fontSize: "14px",
    border: "1px solid #ccc",
    padding: "3px 10px",
    background: (props) => props.colorTheme,
    color: "#fff",
    cursor: "default",
    "&>svg": {
      margin: "0px !important",
      fontSize: "18px !important",
      marginLeft: "6px !important",
    },
    height: "2.125rem",
  },

  sortByRootIcon: {
    color: "#000",
    borderRadius: "3px",
    height: "2.125rem",
    fontSize: "14px",
    border: "1px solid #ccc",
    padding: "3px 10px",
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    "&>svg": {
      margin: "0px !important",
      fontSize: "18px !important",
    },
    "&:hover": {
      background: (props) => props.colorTheme,
      color: "#fff",
      cursor: "pointer",
    },
  },
  NaviPageItems: {
    "& button": {
      border: "none",
      background: "none",
      "& i": {
        color: "#999",
        margin: "0 10px",
      },
      "& span": {
        border: "1px solid #707070",
        borderRadius: "50%",
        padding: "4px 8px",
        marginRight: "5px",
      },
    },
    "& .btnNext": {
      marginLeft: "5px",
    },
  },
}));

const ProductView = ({ t }) => {
  const themeColor = JSON.parse(localStorage.getItem("themeColor"));
  console.log("themeColor", themeColor);
  const theme = createTheme({
    palette: {
      primary: {
        main: themeColor,
        color: "#000",
      },
    },
  });
  const [value, setValue] = React.useState("zero");

  const history = useHistory();
  const [val, setVal] = useState(false);
  const [tabsopen, setTabsOpen] = useState(false);
  const [tabschild, setTabsChild] = useState(false);
  const [TotalCartItems, setItems] = useState(0);
  const [inlineCategory, setInlineCategory] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const viewname = localStorage.getItem("viewproname");
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const Usercode = JSON.parse(localStorage.getItem("usercode"));

  const mycart = async () => {
    if (localStorage.getItem("token")) {
      const cartDataResult = await getBadgeCart(localStorage.getItem("token"));
      if (cartDataResult.success) {
        setItems(cartDataResult.data);
      }
    }
  };

  const classes = useStyles({ colorTheme: themeColor });

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "instant" });
  }, []);

  useEffect(() => {
    let isMounted = true;
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));

    if (localStorage.getItem("landing_page")) {
      setCategoriesData(
        JSON.parse(localStorage.getItem("landing_page"))["product_categories"]
      );
    }

    if (membershipData) {
      setMembershipLevel(membershipData.level);
    }
  }, [Usercode]);

  useEffect(() => {
    let isMounted = true;
    let timer1;
    if (Mobile) {
      timer1 = setTimeout(() => {
        if (
          viewname == "Best Seller" ||
          viewname == '"' + "Best Seller" + '"'
        ) {
          setValue("three");
          setField3(true);
          setSortBy(3);
          setTimeout(() => setTabsOpen(true), 100);
        } else if (
          viewname == "Discount Product" ||
          viewname == '"' + "Discount Product" + '"'
        ) {
          setValue("two");
          setField2(true);
          setSortBy(2);
          setTimeout(() => setTabsOpen(true), 100);
        } else if (
          viewname == "Produk Terbaru" ||
          viewname == '"' + "Produk Terbaru" + '"'
        ) {
          setValue("one");
          setField1(true);
          setSortBy(1);
          setTimeout(() => setTabsOpen(true), 100);
        } else {
          setValue("zero");
          setTimeout(() => setTabsOpen(true), 100);
        }
      }, 100);
    } else {
      timer1 = setTimeout(() => {
        if (
          viewname == "Best Seller" ||
          viewname == '"' + "Best Seller" + '"'
        ) {
          setValue("three");
          setField3(true);
          setSortBy(2);
          setTimeout(() => setTabsOpen(true), 100);
        } else if (
          viewname == "Discount Product" ||
          viewname == '"' + "Discount Product" + '"'
        ) {
          setValue("two");
          setField2(true);
          setSortBy(5);
          setTimeout(() => setTabsOpen(true), 100);
        } else if (
          viewname == "Produk Terbaru" ||
          viewname == '"' + "Produk Terbaru" + '"'
        ) {
          setValue("one");
          setField1(true);
          setSortBy(1);
          setTimeout(() => setTabsOpen(true), 100);
        } else {
          setValue("zero");
          setTimeout(() => setTabsOpen(true), 100);
        }
      }, 100);
    }
    return () => clearTimeout(timer1);
    return () => {
      isMounted = false;
    };
  }, []);

  const [field1, setField1] = useState(false);
  const [field2, setField2] = useState(false);
  const [field3, setField3] = useState(false);
  const [field4, setField4] = useState(false);

  const [inc, setInc] = useState(0);

  const viewprochild = localStorage.getItem("viewprochild");
  const viewprotabs = JSON.parse(localStorage.getItem("viewtabs"));
  const categoryData = {};
  // console.log(responseData);

  const [visible, setVisible] = useState(false);
  const [sortBy, setSortBy] = useState();
  const [isPrice, setIsPrice] = useState(false);
  const [isBestSeller, setIsBestSeller] = useState(false);
  const [isDiscount, setIsDiscount] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const CatId = JSON.parse(localStorage.getItem("CatId"));
  const categoryProductID = localStorage.getItem("viewproid");
  const categoryProductName = localStorage.getItem("viewproname");
  const membershipData = JSON.parse(localStorage.getItem("membership"));
  const [membershipLevel, setMembershipLevel] = useState(1);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 120) {
      setVisible(true);
    } else if (scrolled <= 120) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    let heightToHideFrom = 100;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      isVisible && // to limit setting state only the first time
        setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  const [totalRecord, setTotalRecord] = useState(0);
  useEffect(() => {
    // var myHeaders = new Headers();
    // myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");

    // var formdata = new FormData();
    // formdata.append("category", categoryProductID);
    // formdata.append("page", "1");

    let titleCategoryValue = 0;
    console.log("viewprotabs", viewprotabs);
    switch (viewprotabs) {
      case "New Arrivals":
        titleCategoryValue = 1;
        // setSortBy(1)
        setIsNew(true);
        break;

      case "Produk Terbaru":
        titleCategoryValue = 1;
        // setSortBy(1)
        setIsNew(true);
        break;

      case "Produk Terlaris":
        titleCategoryValue = 2;
        // setSortBy(2)
        setIsBestSeller(true);
        break;

      case "Discount Product":
        titleCategoryValue = 5;
        // setSortBy(5)
        setIsDiscount(true);
        break;

      case "Best Seller":
        titleCategoryValue = 2;
        // setSortBy(2)
        setIsBestSeller(true);
        break;
      default:
        console.log("masuk default");
        break;
    }

    // if(titleCategoryValue!=0){
    //   formdata.append("sort_by", titleCategoryValue);
    // }

    // fetch(`${baseUrlApi()}/Api/Product/getProductData`, {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setTotalRecord(res.total_record)
    //   });
    const getProductsDetailData = async () => {
      try {
        let sortByValue = null;
        let sortByAscDesc = null;
        switch (sortBy) {
          // case 1:
          //   sortByValue = `"order": "created_at"`
          //   sortByAscDesc = `"sort": "desc"`
          //   break;
          case 1:
            sortByValue = `"labels": "['Terbaru']"`;
            break;
          case 2:
            sortByValue = `"order": "sold_quantity"`;
            sortByAscDesc = `"sort": "desc"`;
            break;
          case 3:
            sortByValue = `"order": "price.${membershipLevel}"`;
            sortByAscDesc = `"sort": "asc"`;
            break;
          case 4:
            sortByValue = `"order": "price.${membershipLevel}"`;
            sortByAscDesc = `"sort": "desc"`;
            break;
          case 5:
            sortByValue = `"labels": "['Diskon']"`;
            break;
          default:
            break;
        }

        const response = await getProductsDetail(
          `{"page":1, "limit": 1 ${
            categoryProductID ? `,"category_id":${categoryProductID}` : ""
          } ${sortByValue ? `,${sortByValue}` : ""} ${
            sortByAscDesc ? `,${sortByAscDesc}` : ""
          }}`
        );
        setTotalRecord(response.meta.total_count);
      } catch (error) {
        console.log("Error fetching getProductsDetail on productview:", error);
      }
    };

    getProductsDetailData();
  }, [sortBy]);

  const handleClickSetSortByIsNew = (sortByValue, isNewValue) => {
    setSortBy(sortByValue);
    setIsNew(isNewValue);
  };

  const handleClickSetSortByIsDiscount = (sortByValue, isDiscountValue) => {
    setSortBy(sortByValue);
    setIsDiscount(isDiscountValue);
  };

  const handleClickSetSortByBestSeller = (sortByValue, isBestSellerValue) => {
    setSortBy(sortByValue);
    setIsBestSeller(isBestSellerValue);
  };

  const handleClickSetSortByPrice = (sortByValue, isPriceValue) => {
    setSortBy(sortByValue);
    setIsPrice(isPriceValue);
  };

  return (
    <ThemeProvider theme={theme}>
      {Mobile ? (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              maxWidth: Mobile ? "500px" : "100%",
              backgroundColor: themeColor,
              position: "fixed",
              zIndex: "11111",
              marginBottom: "40px",
            }}
          >
            <div
              className="title-box"
              style={{ height: "48px", maxWidth: Mobile ? "500px" : "100%" }}
            >
              <KeyboardBackspaceIcon
                style={{ color: "white", width: "25px" }}
                onClick={() => {
                  history.push("/");
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h1 className="title mb-0">
                  {viewname == "New Arrivals"
                    ? t("new_arrivals")
                    : viewname == "Best Seller"
                    ? t("best_seller2")
                    : viewname == "Discount Product"
                    ? t("discount_product")
                    : viewname == "All product" ||
                      viewname == "All Product" ||
                      viewname == '"All Product"'
                    ? t("all_product")
                    : viewname.replaceAll('"', "")}
                </h1>
                <div
                  style={{
                    paddingRight: "10px",
                  }}
                >
                  <svg
                    style={{
                      fill: "transparent",
                      cursor: "pointer",
                      height: "24px",
                      width: "24px",
                    }}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      history.push("/search");
                    }}
                  >
                    <path
                      d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                      stroke="#F5F5F5"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M22 22L20 20"
                      stroke="#F5F5F5"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    style={{
                      fill: "transparent",
                      cursor: "pointer",
                      height: "24px",
                      width: "24px",
                    }}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      history.push("/cart");
                    }}
                  >
                    <path
                      d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"
                      stroke="#F5F5F5"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
                      stroke="#F5F5F5"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
                      stroke="#F5F5F5"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 8H21"
                      stroke="#F5F5F5"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    style={{
                      fill: "transparent",
                      cursor: "pointer",
                      height: "24px",
                      width: "24px",
                    }}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      history.push("/chat");
                    }}
                  >
                    <path
                      d="M8.5 19H8C4 19 2 18 2 13V8C2 4 4 2 8 2H16C20 2 22 4 22 8V13C22 17 20 19 16 19H15.5C15.19 19 14.89 19.15 14.7 19.4L13.2 21.4C12.54 22.28 11.46 22.28 10.8 21.4L9.3 19.4C9.14 19.18 8.77 19 8.5 19Z"
                      stroke="#F5F5F5"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15.9965 11H16.0054"
                      stroke="#F5F5F5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.9955 11H12.0045"
                      stroke="#F5F5F5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.99451 11H8.00349"
                      stroke="#F5F5F5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div
            className="bg-white"
            style={{ paddingTop: "50px", boxShadow: "none" }}
          >
            {viewprochild == 1 ? (
              <div>
                <div className="scrollmenu">
                  {categoryData
                    ? categoryData.data.length > 0
                      ? categoryData.data.map((cat, index) => (
                          <Chip
                            onClick={() => {
                              localStorage.setItem(
                                "procatid",
                                JSON.stringify(cat.cat_id)
                              );
                              localStorage.setItem(
                                "procatname",
                                JSON.stringify(cat.name)
                              );
                              history.push("/productcategory");
                            }}
                            label={cat.name}
                            key={index}
                            className="border-1 border m-2 chip-css text-uppercase mx-1"
                            style={{ background: "rgb(250 250 250)" }}
                          />
                        ))
                      : ""
                    : ""}
                </div>
                <hr className="m-1" />
              </div>
            ) : (
              ""
            )}

            <div className="mb-1" style={{ height: "35px" }}>
              <div
                style={{
                  display: "flex",
                  // borderBottom: "1px solid lightgray",
                  boxShadow: "0px 0px 8px -1px #dbd5d5",
                  height: "37px",
                }}
              >
                <div
                  onClick={() => {
                    setField1(!field1);
                    if (field1 === false) {
                      setValue("one");
                      setSortBy(1);
                      // localStorage.setItem("viewtabs", JSON.stringify("one"));
                    } else {
                      setValue("zero");
                      setSortBy(null);
                      // localStorage.setItem("viewtabs", JSON.stringify("zero"));
                    }
                    setField2(false);
                    setField3(false);
                    setField4(false);
                  }}
                  style={{
                    textAlign: "center",
                    textTransform: " uppercase",
                    width: "25% ",
                    marginTop: "5px",
                    background: "white ",
                    fontSize: "12px ",
                    fontWeight: "600 ",
                  }}
                >
                  <div
                    style={{
                      // borderRight: "1px solid lightgray ",
                      color:
                        value == "one"
                          ? themeColor
                            ? themeColor
                            : "#FFFFFF"
                          : "black",
                      position: value == "zero" ? "unset" : "relative",
                      marginTop: "5px",
                      marginBottom: "6px",
                    }}
                  >
                    {t("new")}
                  </div>
                  <div
                    style={{
                      borderBottom:
                        value == "one"
                          ? `2px solid ${themeColor ? themeColor : "#FFFFFF"}`
                          : "none",
                      width: "100%",
                      marginTop: "8px",
                    }}
                  ></div>
                </div>

                <div
                  onClick={() => {
                    setField2(!field2);
                    if (field2 === false) {
                      setValue("two");
                      setSortBy(2);
                      // localStorage.setItem("viewtabs", JSON.stringify("two"));
                    } else {
                      setValue("zero");
                      setSortBy(null);
                      // localStorage.setItem("viewtabs", JSON.stringify("zero"));
                    }
                    setField1(false);
                    setField3(false);
                    setField4(false);
                  }}
                  style={{
                    textAlign: "center",
                    textTransform: " uppercase",
                    width: "25% ",
                    marginTop: "5px",
                    background: "white ",
                    fontSize: "12px ",
                    fontWeight: "600 ",
                  }}
                >
                  <div
                    style={{
                      // borderRight: "1px solid lightgray ",
                      color:
                        value == "two"
                          ? themeColor
                            ? themeColor
                            : "#FFFFFF"
                          : "black",
                      position: value == "zero" ? "unset" : "relative",
                      marginTop: "5px",
                      marginBottom: "6px",
                    }}
                  >
                    {t("discount")}
                  </div>
                  <div
                    style={{
                      borderBottom:
                        value == "two"
                          ? `2px solid ${themeColor ? themeColor : "#FFFFFF"}`
                          : "none",
                      width: "100%",
                      marginTop: "8px",
                    }}
                  ></div>
                </div>
                <div
                  onClick={() => {
                    setField3(!field3);
                    if (field3 === false) {
                      setValue("three");
                      setSortBy(3);
                      // localStorage.setItem("viewtabs", JSON.stringify("three"));
                    } else {
                      setValue("zero");
                      setSortBy(null);
                      // localStorage.setItem("viewtabs", JSON.stringify("zero"));
                    }
                    setField1(false);
                    setField2(false);
                    setField4(false);
                  }}
                  style={{
                    textAlign: "center",
                    textTransform: " uppercase",
                    width: "25% ",
                    marginTop: "5px",
                    background: "white ",
                    fontSize: "12px ",
                    fontWeight: "600 ",
                  }}
                >
                  <div
                    style={{
                      // borderRight: "1px solid lightgray ",
                      color:
                        value == "three"
                          ? themeColor
                            ? themeColor
                            : "#FFFFFF"
                          : "black",
                      position: value == "zero" ? "unset" : "relative",
                      marginTop: "5px",
                      marginBottom: "6px",
                    }}
                  >
                    {t("best_seller")}
                  </div>
                  <div
                    style={{
                      borderBottom:
                        value == "three"
                          ? `2px solid ${themeColor ? themeColor : "#FFFFFF"}`
                          : "none",
                      width: "100%",
                      marginTop: "8px",
                    }}
                  ></div>
                </div>
                <div
                  onClick={() => {
                    setVal(!val);
                    setInc(inc + 1);
                    if (inc > 1) {
                      setValue("zero");
                      // localStorage.setItem("viewtabs", JSON.stringify("zero"));
                      setInc(0);
                      setVal(false);
                    } else {
                      if (value === "zero") {
                        setValue("four");
                        setSortBy(4);
                      } else {
                        setValue("four");
                        setSortBy(5);
                      }
                      // localStorage.setItem("viewtabs", JSON.stringify("four"));
                    }
                    setField1(false);
                    setField2(false);
                    setField3(false);
                  }}
                  style={{
                    textAlign: "center",
                    textTransform: " uppercase",
                    width: "25% ",
                    marginTop: "5px",
                    background: "white ",
                    fontSize: "12px ",
                    fontWeight: "600 ",
                  }}
                >
                  <div
                    style={{
                      color:
                        value == "four"
                          ? themeColor
                            ? themeColor
                            : "#FFFFFF"
                          : "black",
                      position: value == "zero" ? "unset" : "relative",
                      marginTop: "5px",
                      marginBottom: "6px",
                    }}
                  >
                    {t("price")}
                    {value == "four" ? (
                      val ? (
                        <img
                          src="/assets/frontend/img/Icon/ic_prices_down.png"
                          height="13px"
                        />
                      ) : (
                        <img
                          src="/assets/frontend/img/Icon/ic_prices_up.png"
                          height="13px"
                        />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    style={{
                      borderBottom:
                        value == "four"
                          ? `2px solid ${themeColor ? themeColor : "#FFFFFF"}`
                          : "none",
                      width: "100%",
                      marginTop: "8px",
                    }}
                  ></div>
                </div>
              </div>

              {tabsopen ? (
                value == "zero" ? (
                  <MainPage membershipLevel={membershipLevel} sortBy={sortBy} />
                ) : value == "one" ? (
                  <New membershipLevel={membershipLevel} sortBy={sortBy} />
                ) : value == "two" ? (
                  <Discount membershipLevel={membershipLevel} sortBy={sortBy} />
                ) : value == "three" ? (
                  <Bestseller
                    membershipLevel={membershipLevel}
                    sortBy={sortBy}
                  />
                ) : value == "four" ? (
                  val ? (
                    <PriceLow
                      membershipLevel={membershipLevel}
                      sortBy={sortBy}
                    />
                  ) : (
                    <PriceHigh
                      membershipLevel={membershipLevel}
                      sortBy={sortBy}
                    />
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {/* // {value == "zero" ? <MainPage /> : ""}
    // {value == "one" ? <New /> : ""}
    // {value == "two" ? <Discount /> : ""}
    // {value == "three" ? <Bestseller /> : ""}
    // {value == "four" ? val ? <PriceLow /> : <PriceHigh /> : ""}
     */}
              <div style={{ width: "500px", margin: "0px auto" }}>
                <div
                  onClick={scrollToTop}
                  style={{
                    display: visible ? "inline" : "none",
                    height: Mobile ? "39px" : "41px",
                    padding: Mobile ? "10px" : "11px",
                    fontSize: "1rem",
                    zIndex: "1",
                    cursor: "pointer",
                    color: "red",
                    background: "white",
                    borderRadius: "50px",
                    boxShadow: "0px 0px 8px -1px #6c757dd9",
                    position: "fixed",
                    right: Mobile ? 0 : "",
                    left: Mobile ? "" : "82%",
                    bottom: Mobile ? "64px" : "0px",
                    marginRight: Mobile ? "10px" : "",
                    transform: Mobile ? "" : "translate(466%, -50%)",
                    margin: "0px auto",
                  }}
                >
                  <img
                    src={"/assets/frontend/img/top_arrow.png"}
                    style={{
                      height: Mobile ? "19px" : "20px",
                      width: Mobile ? "19px" : "20px",
                      marginBottom: Mobile ? "10px" : "8px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className={classes.Root}>
          <DesktopHeaderWrap
            clickTab={() => setInlineCategory(false)}
            cartNumber={TotalCartItems}
            isFixed={false}
            categoriesData={categoriesData}
            showCategory
          />

          <div className={classes.MainRoot}>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  background: "#fff",
                  padding: "7px",
                  margin: "0 0 5px",
                  display: "FLEX",
                  columnGap: "10PX",
                  alignItems: "left",
                  justifyContent: "left",
                }}
              >
                <label
                  style={{
                    padding: "7px",
                    color: "#333",
                    fontSize: "20px",
                  }}
                >
                  {categoryProductName}
                </label>
                <label
                  style={{
                    paddingTop: "13px",
                    color: "#393D4E",
                    fontSize: "15px",
                  }}
                >
                  {totalRecord} {t("product")}
                </label>
                <div
                  style={{
                    background: "#fff",
                    paddingLeft: "160px",
                    margin: "0 0 5px",
                    display: "FLEX",
                    columnGap: "10PX",
                    alignItems: "CENTER",
                    justifyContent: "center",
                  }}
                >
                  {/* <div
                    style={{
                      color: "#333",
                      fontWeight: 700,
                    }}
                  >
                    Sort by
                  </div> */}

                  {isNew ? (
                    <div
                      className={
                        sortBy == 1 ? classes.sortByAv : classes.sortByRoot
                      }
                      onClick={() => {
                        handleClickSetSortByIsNew(null, !isNew);
                      }}
                    >
                      {t("new")}
                    </div>
                  ) : (
                    <div
                      className={classes.sortByRootIcon}
                      onClick={() => {
                        handleClickSetSortByIsNew(1, !isNew);
                      }}
                    >
                      {t("new")}
                    </div>
                  )}

                  {isDiscount ? (
                    <div
                      className={
                        sortBy == 5 ? classes.sortByAv : classes.sortByRoot
                      }
                      onClick={() => {
                        handleClickSetSortByIsDiscount(null, !isDiscount);
                      }}
                    >
                      {t("discount")}
                    </div>
                  ) : (
                    <div
                      className={classes.sortByRootIcon}
                      onClick={() => {
                        handleClickSetSortByIsDiscount(5, !isDiscount);
                      }}
                    >
                      {t("discount")}
                    </div>
                  )}

                  {isBestSeller ? (
                    <div
                      className={
                        sortBy == 2 ? classes.sortByAv : classes.sortByRoot
                      }
                      onClick={() => {
                        handleClickSetSortByBestSeller(null, !isBestSeller);
                      }}
                    >
                      {t("best_seller")}
                    </div>
                  ) : (
                    <div
                      className={classes.sortByRootIcon}
                      onClick={() => {
                        handleClickSetSortByBestSeller(2, !isBestSeller);
                      }}
                    >
                      {t("best_seller")}
                    </div>
                  )}

                  {isPrice ? (
                    <div
                      className={
                        isPrice
                          ? classes.sortByAv
                          : sortBy == 4
                          ? classes.sortByAv
                          : classes.sortByRootIcon
                      }
                      onClick={() => {
                        handleClickSetSortByPrice(4, !isPrice);
                      }}
                    >
                      {t("price")} <ArrowDownwardIcon />
                    </div>
                  ) : (
                    <div
                      className={classes.sortByRootIcon}
                      onClick={() => {
                        handleClickSetSortByPrice(3, !isPrice);
                      }}
                    >
                      {t("price")} <ArrowUpwardIcon />
                    </div>
                  )}
                </div>
              </div>
              <div>
                {inlineCategory ? (
                  <ProductByCategory
                    catId={CatId}
                    sortBy={sortBy}
                    titleCategory={viewprotabs}
                    membershipLevel={membershipLevel}
                  />
                ) : (
                  <ProductByCategory
                    catId={CatId}
                    sortBy={sortBy}
                    titleCategory={viewprotabs}
                    membershipLevel={membershipLevel}
                  />
                )}
              </div>
            </div>
          </div>
          <DeskTopFooter />
        </div>
      )}
    </ThemeProvider>
  );
};

export default withNamespaces()(ProductView);
