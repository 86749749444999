import React, { useState, useEffect } from "react";
// import Header from "../header/CartHeader";
import "./Cart.css";
import { pink } from "@material-ui/core/colors";
import FormControl from "@material-ui/core/FormControl";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import { CartQuantityResponse } from "../../store/Selectors/CartQuantity";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ClipLoader from "react-spinners/ClipLoader";
import DeleteIcon from "@material-ui/icons/Delete";
import { withNamespaces } from "react-i18next";
import i18n from "../language/localization";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getDiskonRupiah, priceFormatLocal } from "../../utils/helper";
import {
  get as getCart,
  deleteData as deleteCart,
  deleteDatas as deleteBulkCarts,
  updateNote,
} from "../../api/cart";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setCheckoutCartItem } from "../../stores/features/cart/cart";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Cart = ({ t }) => {
  const dispatch = useDispatch();
  const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : "#FFFFFF",
        userSelect: "none",
      },
      background: {
        default: "#222222",
        userSelect: "none",
      },
    },
  });
  const useStyles = makeStyles((theme) => ({
    root: {
      "&> .price": {
        color: `red !important`,
      },
    },
    // badgeColor: {
    //   "&> .badge-red": {
    //     background: `${ThemeColor ? ThemeColor : "#FFFFFF"} !important`,
    //   },
    // },
    buttonColor: {
      "&> .finish-btn": {
        height: "2.7rem",
        backgroundColor: `${ThemeColor ? ThemeColor : "#FFFFFF"} !important`,
      },
    },
    qtyButton: {
      display: "flex",
      justifyContent: "flex-start",
      marginLeft: "24px",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
        marginLeft: "25px",
      },
      [theme.breakpoints.down(375)]: {
        justifyContent: "flex-start",
        marginLeft: "18px",
      },
    },
    textdiv: {
      marginBottom: "10px",
      marginLeft: "24px",
      [theme.breakpoints.down(375)]: {
        marginLeft: "16px",
      },
    },
    snackbarStyleViaContentProps: {
      backgroundColor: "white !important",
      textAlign: "center !important",
      color: "black !important",
      boxShadow: "none !important",
      display: "flex !important",
      justifyContent: "center !important",
      marginBottom: "64px !important",
      fontWeight: "500 !important",
      fontSize: "18px !important",
      borderRadius: "10px !important",
    },
    snackbarStyleViaContentProps1: {
      backgroundColor: "red !important",
      textAlign: "center !important",
      color: "white !important",
      boxShadow: "none !important",
      display: "flex !important",
      justifyContent: "center !important",
      fontWeight: "500 !important",
      fontSize: "17px !important",
      borderRadius: "10px !important",
    },
  }));
  const classes = useStyles();

  const history = useHistory();

  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [carts, setCarts] = useState([]);
  const [data1, setData1] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const landingPageData = JSON.parse(localStorage.getItem("landing_page"));

  const mycart = async () => {
    const res = await getCart(
      localStorage.getItem("token"),
      `{"get_all_data": true}`
    );
    let cartItems = res.data.cart_items || [];

    if (res.success) {
      cartItems = cartItems.map((item) => ({
        ...item,
        cart_item: { ...item.cart_item, checked_out: false },
      }));
      setData1(cartItems);
      setCarts(cartItems);

      setOpen6(false);
      setOpen(true);
    }
  };

  /** manipulation carts */
  const selectedCartItem = () =>
    carts.filter((cart) => cart.cart_item.checked_out === true);

  const totalQuantitySelected = () => {
    const mapQuantity = selectedCartItem().map(
      (item) => item.cart_item.checked_out_quantity
    );
    return mapQuantity.reduce((prev, next) => prev + next, 0);
  };

  const totalPriceSelected = () => {
    return selectedCartItem().reduce(
      (acc, val) =>
        acc +
        (val.cart_item.price - getDiskonRupiah(val)) *
          val.cart_item.checked_out_quantity,
      0
    );
  };

  const finalTotalQuantity = () => {
    const mapQuantity = carts.map(
      (item) => item.cart_item.checked_out_quantity
    );
    return mapQuantity.reduce((prev, next) => prev + next, 0);
  };

  const finalTotalPrice = () => {
    return carts.reduce(
      (acc, val) =>
        acc +
        (val.cart_item.price - getDiskonRupiah(val)) *
          val.cart_item.checked_out_quantity,
      0
    );
  };

  const [qtySum, setItemSum] = useState(0);
  const [priceSum, setPriceSum] = useState(0);
  /** end */

  const selectAllCheck = async (e) => {
    setIsCheckAll(e.target.checked);
    carts.map((cart) => {
      cart.cart_item.checked_out = e.target.checked;
      return cart;
    });
  };

  const membershipData = JSON.parse(localStorage.getItem("membership"));
  const [membershipLevel, setMembershipLevel] = useState(1);

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
    if (membershipData) {
      setMembershipLevel(membershipData.level);
    }
  }, []);

  useEffect(() => {
    mycart();
    localStorage.removeItem("bankdata");
    localStorage.removeItem("shipping");
    localStorage.removeItem("pay_spot");
    // const timer = setTimeout(() => {
    //   setOpen6(true);
    // }, 100);
    return () => clearTimeout(timer);
  }, []);

  // const [finalqty, setFinalQty] = useState("");
  // const [finalprice, setFinalPrice] = useState("");

  const [open4, setOpen4] = useState(false);
  const [dialogError, setDialogError] = useState({ show: false, message: "" });

  // const cartdata = async () => {
  //   // const Usercode = JSON.parse(localStorage.getItem("usercode"));
  //   // let data = new FormData();
  //   // data.append("user_code", Usercode);
  //   // data.append("current_date", moment().format("YYYY-MM-DD hh:mm:ss"));
  //   // dispatch(getMyCartProductUser(data));
  //   const UserId = JSON.parse(localStorage.getItem("userid"));
  //   const cartDataResult = await getCart(
  //     localStorage.getItem("token"),
  //     `{"get_all_data": true}`
  //   );
  //   if (cartDataResult.success) {
  //     if (
  //       cartDataResult.data.cart_items &&
  //       cartDataResult.data.cart_items.length > 0
  //     ) {
  //       setData1(cartDataResult.data.cart_items);
  //     }
  //   }
  // };

  const [openLoading, setOpenLoading] = useState(false);

  const updateCartCheckedOutQuantity = (cartItem, operation) => {
    const updatedCartDatas = carts.map((cart) => {
      if (cartItem.id === cart.cart_item.id) {
        cart.cart_item.checked_out_quantity =
          operation === "+"
            ? cartItem.checked_out_quantity + 1
            : cartItem.checked_out_quantity - 1;
      }
      return cart;
    });
    return updatedCartDatas;
  };

  const qtyOrderRekapMinus = async (cartItem) => {
    if (cartItem.checked_out_quantity > 1) {
      setCarts(updateCartCheckedOutQuantity(cartItem, "-"));
    }
  };

  const qtyOrderRekapPlus = async (cartItem) => {
    if (cartItem.checked_out_quantity < cartItem.quantity) {
      setCarts(updateCartCheckedOutQuantity(cartItem, "+"));
    }
  };

  const getSingleProduct = async (e) => {
    // const usercode = JSON.parse(localStorage.getItem("usercode"));
    // const userid = JSON.parse(localStorage.getItem("userid"));
    // localStorage.setItem("productsid",JSON.stringify([e]));
    // var data3 = new FormData();
    // data3.append("product_id", e);
    // data3.append("user_id", userid);
    // data3.append("user_code", usercode);
    // dispatch(singleProductDataUser(data3));
    // history.push("/productdetail");
    history.push(`/productdetail?${e}`);
  };

  const deleteSingleProduct = async (cartData) => {
    setOpenLoading(true);
    try {
      const data = {
        cart_id: cartData.cart_item.id,
      };

      const response = await deleteCart(data, localStorage.getItem("token"));

      if (response.success) {
        // setOpenLoading(false);
        // setOpen3(true);
        // await cartdata();
        // await mycart();

        const deletedCart = carts.filter(
          (item) => item.cart_item.id !== cartData.cart_item.id
        );
        setCarts(deletedCart);
      } else {
        setDialogError({ show: true, message: response.message });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenLoading(false);
    }
  };

  const deleteAll = async () => {
    if (selectedCartItem().length > 0) {
      setOpenLoading(true);

      try {
        const cartIds = selectedCartItem().map((item) => item.cart_item.id);
        const data = {
          cart_id: cartIds,
        };

        const response = await deleteBulkCarts(
          data,
          localStorage.getItem("token")
        );

        if (response.success) {
          // setOpenLoading(false);
          // await cartdata()
          // await mycart()
          const cartsDeleted = carts.filter(
            (item) => !cartIds.includes(item.cart_item.id)
          );

          setCarts(cartsDeleted);
          setOpen6(true);
        } else {
          setDialogError({ show: true, message: response.message });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setOpenLoading(false);
      }
    } else {
      setOpen4(true);
      setTimeout(() => setOpen4(false), 3000);
    }
  };

  const handleCloseDialogError = () => {
    setDialogError({ show: false, messge: "" });
  };

  const [orderData, setOrderData] = useState([]);
  const setData = () => {
    for (var i = 0; i < carts.length; i++) {
      if (carts[i].isChecked === true) {
        orderData.push(carts[i]);
      }
    }
  };

  const vertical = "bottom",
    horizontal = "center";
  // const vertical1='bottom',horizontal1='center' ;

  const [timer, setTimer] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const addNoteCart = async (e, j) => {
    setInputValue(j);
    // setOpenLoading(true);
    var result = [...carts];
    result = result.map((x) => {
      if (x.cart_item.id === e) x.cart_item.note = j;
      return x;
    });
    setCarts(result);
    // clearTimeout(timer);
    await NoteCart(e, j);
    // const newTimer = setTimeout(() => {
    //   NoteCart(e, j);
    //   setOpenLoading(true);
    // }, 500);
    // setTimer(newTimer);
  };

  const NoteCart = async (e, j) => {
    const data = {
      cart_id: e,
      note: j,
    };
    await updateNote(data, localStorage.getItem("token"));
  };

  const Refresh = async () => {
    setOpenLoading(true);
    try {
      await mycart();
    } catch (error) {
      console.log(error);
    } finally {
      setOpenLoading(false);
    }
    // await cartdata();
  };
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const onCheckItemCart = async (e) => {
    const { value, checked } = e.target;
    console.log("checked ", checked);
    const updatedCartItem = carts.map((cart) => {
      // eslint-disable-next-line eqeqeq
      if (cart.cart_item.id == value) {
        cart.cart_item.checked_out = checked;
      }
      return cart;
    });
    console.log(updatedCartItem);
    setCarts(updatedCartItem);

    if (carts.length === selectedCartItem().length) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  };

  const onCheckout = () => {
    setData();
    if (selectedCartItem().length > 0) {
      /**
       * all carts required for submitting data carts to backend
       * because request to backend must send all data cart and then
       * manipulate attribute checked_out in cart_item object
       * */
      dispatch(setCheckoutCartItem(carts));
      /**
       * carts was selected required to display on frontend side
       */
      localStorage.setItem("cartids", JSON.stringify(selectedCartItem()));
      // NoteCart();
      history.push("/orderconfirm");
    } else {
      setOpen1(true);
      setTimeout(() => setOpen1(false), 3000);
    }
  };

  const onPayOnSpot = () => {
    setData();
    if (selectedCartItem().length > 0) {
      /**
       * all carts required for submitting data carts to backend
       * because request to backend must send all data cart and then
       * manipulate attribute checked_out in cart_item object
       * */
      dispatch(setCheckoutCartItem(carts));
      /**
       * carts was selected required to display on frontend side
       */
      localStorage.setItem("cartids", JSON.stringify(selectedCartItem()));
      localStorage.setItem("pay_spot", JSON.stringify("active"));
      history.push("/orderconfirm");
    } else {
      setOpen1(true);
      setTimeout(() => setOpen1(false), 3000);
    }
  };

  return (
    <div style={{ marginBottom: "165px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: "500px",
          backgroundColor: ThemeColor ? ThemeColor : "#FFFFFF",
          position: "fixed",
          zIndex: "11111",
          marginBottom: "40px",
        }}
      >
        <div
          className="title-box"
          style={{ height: "48px", maxWidth: "500px" }}
        >
          <KeyboardBackspaceIcon
            style={{ color: "white", width: "25px" }}
            onClick={() => {
              // if (landing&&landing.landing_page == 0) {
              //   history.push("/");
              // } else {
              //   history.push("/dashboard");
              // }
              history.push("/");
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <h1 className="title mb-0">
              {t("cart")} ({totalQuantitySelected()}){" "}
              {priceFormatLocal(totalPriceSelected())}
            </h1>
            <div
              style={{
                paddingRight: "12px",
              }}
            >
              <img
                src={"/assets/frontend/img/refresh_cart.png"}
                height="22px"
                width="22px"
                // style={{
                //   color: "white",
                //   fontSize: "27px",
                //   marginLeft: "20px",
                //   marginRight: "7px",
                //   cursor: "pointer"
                // }}
                onClick={Refresh}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {carts.length > 0 ? (
        <ThemeProvider theme={theme}>
          <header
            style={{
              display: "block",
              paddingTop: "49px",
            }}
          >
            <div
              className="d-flex justify-content-between shadow-card p-0 mb-1 select-all"
              style={{
                marginTop: "0px",
                background: "white",
              }}
            >
              <div className="flex-box">
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <Checkbox
                      id="checkAll"
                      style={{
                        marginLeft: "-10px",
                        marginTop: "-3px",
                        color: ThemeColor ? ThemeColor : "#FFFFFF",
                      }}
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                      }}
                      name="AllSelect"
                      onChange={selectAllCheck}
                      checked={isCheckAll}
                    />
                    <label
                      htmlFor="checkAll"
                      className="mt-3"
                      style={{ marginLeft: "-10px" }}
                    >
                      {t("select_all")} ({carts.length})
                    </label>
                  </FormGroup>
                </FormControl>
              </div>
              <p
                className="pe-2 mb-0 ps-0 mt-3 p-0"
                onClick={() => {
                  deleteAll();
                }}
                style={{ cursor: "pointer", marginRight: "10px" }}
              >
                {t("delete")}
              </p>
            </div>
          </header>
          {carts.map((cartdata, index) => (
            <div
              className="item-box1"
              key={index}
              style={{
                background: "white",
                boxShadow: "rgb(85 85 85 / 10%) 0px 5px 52px",
                marginTop: "5px",
                marginBottom: "5px",
                borderTop: "1px solid #80808038",
                borderBottom: "1px solid #80808038",
              }}
            >
              <div
                className="flex-box"
                style={{ position: "relative", marginLeft: "-2px" }}
              >
                <Checkbox
                  {...label}
                  sx={{
                    color: ThemeColor ? ThemeColor : "#FFFFFF",
                    "&.Mui-checked": {
                      color: ThemeColor ? ThemeColor : "#FFFFFF",
                    },
                  }}
                  name={cartdata.product.name}
                  value={cartdata.cart_item.id}
                  onChange={(e) => onCheckItemCart(e)}
                  checked={cartdata.cart_item.checked_out}
                  style={{
                    width: "15px",
                    color: ThemeColor ? ThemeColor : "#FFFFFF",
                  }}
                />
                <div
                  style={{
                    position: "relative",
                    marginLeft: "8px",
                  }}
                >
                  <img
                    src={
                      cartdata.product.media &&
                      cartdata.product.media.length > 0
                        ? process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          cartdata.product.media[0]
                        : "/assets/images/dummy.png"
                    }
                    alt="item"
                    className="mt-1"
                    style={{
                      width: "80px",
                      height: "80px",
                      maxWidth: "80px",
                    }}
                    onClick={() => {
                      getSingleProduct(cartdata.product.id);
                    }}
                  />
                  {/* belum done whosale */}
                  {/* {cartdata.wholsale_details.length > 0 ? (
                      cartdata.wholsale_details[0].min_stock <= cartdata.quantity &&
                      cartdata.wholsale_details[0].max_stock >= cartdata.quantity ? (
                        <div
                          style={{
                            position: "absolute",
                            top: "7px",
                            right: "8px",
                            color: "white",
                            borderRadius: "3px",
                            fontSize: "12px",
                            backgroundColor: "rgb(0, 191, 255)",
                          }}
                        >
                          {t("wholesale")}
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )} */}
                </div>
                <div
                  className="my-1"
                  style={{ paddingRight: "16px", paddingLeft: "14px" }}
                >
                  <h1
                    className="item-box-title mb-0"
                    style={{
                      width: Mobile ? "230px" : "350px",
                      height: "31px",
                      overflow: "hidden",
                    }}
                  >
                    {cartdata.product.name.toUpperCase()}
                  </h1>
                  {cartdata.product_variant !== null ? (
                    <p
                      className="item-variant mb-0"
                      style={{ backgroundColor: "#EFEFEF" }}
                    >
                      &nbsp;
                      {t("variant") +
                        " " +
                        cartdata.product_variant.attributes[0].value}
                      &nbsp;
                    </p>
                  ) : (
                    ""
                  )}
                  <div className={classes.root + " " + " item-quantity"}>
                    <div
                      className="price mb-0 mt-1"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        gap: "4px",
                        alignItems: "center",
                      }}
                    >
                      {/* {minstock <= quantity && maxstock >= quantity
                        ? wprice
                        : rp_val} */}
                      <label
                        style={{
                          color: ThemeColor,
                        }}
                      >
                        {" "}
                        {cartdata.product.discount_type &&
                        cartdata.product.discount_type === "nominal" ? (
                          <p
                            style={{
                              marginBottom: "0px",
                              fontSize: "14px",
                            }}
                          >
                            {priceFormatLocal(
                              cartdata.cart_item.price -
                                cartdata.product.discount
                            )}
                          </p>
                        ) : (
                          ""
                        )}
                        {cartdata.product.discount_type &&
                        cartdata.product.discount_type === "percentage" ? (
                          <p
                            style={{
                              marginBottom: "0px",
                              fontSize: "14px",
                            }}
                          >
                            {priceFormatLocal(
                              cartdata.cart_item.price -
                                (cartdata.product.discount / 100) *
                                  cartdata.cart_item.price
                            )}
                          </p>
                        ) : (
                          ""
                        )}
                        {cartdata.product.discount === 0 ? (
                          <p
                            style={{
                              marginBottom: "0px",
                              fontSize: "14px",
                            }}
                          >
                            {priceFormatLocal(cartdata.cart_item.price)}
                          </p>
                        ) : (
                          ""
                        )}
                      </label>

                      {cartdata.product.discount_type !== "nominal" &&
                      cartdata.product.discount > 0 ? (
                        <span
                          className="badge badge-red"
                          style={{
                            borderRadius: "1px",
                            color: "white",
                            fontWeight: "400",
                            marginBottom: "7px",
                          }}
                        >
                          {cartdata.product.discount}%
                        </span>
                      ) : (
                        ""
                      )}

                      {cartdata.product.discount_type === "nominal" &&
                      cartdata.product.discount > 0 ? (
                        <span
                          className="badge badge-red"
                          style={{
                            borderRadius: "1px",
                            color: "white",
                            fontWeight: "400",
                            marginBottom: "7px",
                          }}
                        >
                          {parseInt(
                            (cartdata.product.discount /
                              cartdata.cart_item.price) *
                              100
                          )}
                          %
                        </span>
                      ) : (
                        ""
                      )}

                      {cartdata.product.discount_type ? (
                        <s
                          className="text-muted "
                          style={{ marginBottom: "7px" }}
                        >
                          {priceFormatLocal(cartdata.cart_item.price)}
                        </s>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    deleteSingleProduct(cartdata);
                  }}
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "-16px",
                  }}
                >
                  <DeleteIcon
                    style={{ color: ThemeColor ? ThemeColor : "#FFFFFF" }}
                  />
                </div>
              </div>
              <div className={classes.qtyButton}>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ paddingLeft: "93px" }}
                >
                  <p className="pe-2 text-uppercase m-0">REKAP</p>
                  <div
                    className="d-flex justify-content-center align-items-center text-center rounded"
                    style={{ paddingLeft: "6px" }}
                  >
                    <ButtonGroup
                      className="shadow-card"
                      variant="contained"
                      aria-label="outlined primary button group"
                      style={{ width: "14px", boxShadow: "none" }}
                    >
                      <Button
                        style={{
                          background: "#fff",
                          color: "#000",
                          border: "1px solid #DEDEDE",
                        }}
                        onClick={() => {
                          qtyOrderRekapMinus(cartdata.cart_item);
                        }}
                      >
                        <AiOutlineMinus size="12" />
                      </Button>
                      <Button
                        style={{
                          background: "#fff",
                          color: "#000",
                          borderTop: "1px solid #ccc",
                          borderBottom: "1px solid #ccc",
                          borderRight: "none",
                        }}
                      >
                        {cartdata.cart_item.checked_out_quantity}
                      </Button>
                      <Button
                        style={{
                          background: "#fff",
                          color: "#000",
                          border: "1px solid #DEDEDE",
                        }}
                        onClick={() => {
                          qtyOrderRekapPlus(cartdata.cart_item);
                        }}
                      >
                        <AiOutlinePlus size="12" />
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              </div>
              {/* belum done timer auto cancel */}
              <div
                className="d-flex justify-content-left align-items-center"
                style={{ marginTop: "10px" }}
              >
                <svg
                  style={{ width: "15px", height: "25px" }}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6666 7.99967C14.6666 11.6797 11.6799 14.6663 7.99992 14.6663C4.31992 14.6663 1.33325 11.6797 1.33325 7.99967C1.33325 4.31967 4.31992 1.33301 7.99992 1.33301C11.6799 1.33301 14.6666 4.31967 14.6666 7.99967Z"
                    stroke="#292D32"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.4734 10.1202L8.40675 8.88684C8.04675 8.6735 7.75342 8.16017 7.75342 7.74017V5.00684"
                    stroke="#292D32"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <label>Batal Otomatis</label>
                <label style={{ marginLeft: "auto", marginRight: "0" }}>
                  {moment
                    .utc(cartdata.cart_item.expired_at)
                    .format("YYYY-MM-DD HH:mm:ss")}
                </label>
              </div>
              <div className={classes.textdiv}>
                <TextField
                  id={`ids${index}`}
                  // className="mb-2"
                  label={t("optional_message_here") + ".."}
                  variant="standard"
                  fullWidth={true}
                  size="small"
                  value={cartdata.cart_item.note}
                  onChange={(e) => {
                    addNoteCart(cartdata.cart_item.id, e.target.value);
                  }}
                />
              </div>
            </div>
          ))}

          <div
            className="d-flex shadow-card justify-content-between p-2"
            style={{
              boxShadow: "rgb(85 85 85 / 10%) 0px 5px 52px",
              borderTop: "1px solid rgba(128, 128, 128, 0.22)",
              borderBottom: "1px solid rgba(128, 128, 128, 0.22)",
              marginBottom: "3px",
              background: "white",
            }}
          >
            <p className="ps-2 mb-0 text-muted">
              Total Qty :<span className="ms-2">{finalTotalQuantity()}</span>
            </p>
            <p className="mb-0">
              {"Total : "}
              {/* Rp.{finalprice} */}
              <b>
                <span className="ms-2 me-1">
                  {priceFormatLocal(finalTotalPrice())}
                </span>
              </b>
            </p>
          </div>

          <div
            style={{
              bottom: "0",
              position: "fixed",
              width: "100%",
              left: "50%",
              transform: "translatex(-50%)",
              background: "white",
            }}
          >
            <div
              className="d-flex shadow-card justify-content-between"
              style={{
                boxShadow: "rgb(85 85 85 / 10%) 0px 5px 52px",
                borderTop: "1px solid rgba(128, 128, 128, 0.22)",
              }}
            ></div>
            <div
              className={classes.buttonColor}
              style={{
                marginTop: "5px",
                padding: "0px 12px",
                // background: "white", borderTop: "1px solid rgba(128, 128, 128, 0.22)",
              }}
            >
              {landingPageData.setting_cms.is_show_checkout_button == true ? (
                <button
                  className="finish-btn text-uppercase mb-2"
                  style={{ height: "2.7rem", marginTop: "10px" }}
                  onClick={onCheckout}
                >
                  {landingPageData.setting_cms.checkout_button_text}
                </button>
              ) : (
                ""
              )}

              {landingPageData.setting_cms.is_show_pay_on_spot_button == 1 ? (
                <button
                  style={{
                    // height: "3.125rem"
                    height: "2.7rem",
                  }}
                  className="finish-btn text-uppercase mb-2"
                  onClick={onPayOnSpot}
                >
                  {landingPageData.setting_cms.pay_on_spot_button_text}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </ThemeProvider>
      ) : (
        <div
          style={{
            display: "block",
            paddingTop: "49px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "250px",
              paddingBottom: "300px",
              flexDirection: "column",
              height: "100vh",
              backgroundColor: "white",
            }}
          >
            Your cart is empty
          </div>
        </div>
      )}
      {open1 ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open1}
          ContentProps={{
            "aria-describedby": "message-id",
            className: classes.snackbarStyleViaContentProps,
          }}
          // style={{textAlign:"center",backgroundColor:"white",color:"black"}}
          message="Please select item first."
          key={vertical + horizontal}
        />
      ) : (
        ""
      )}
      {open4 ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open4}
          ContentProps={{
            "aria-describedby": "message-id",
            className: classes.snackbarStyleViaContentProps1,
          }}
          // style={{textAlign:"center",backgroundColor:"white",color:"black"}}
          message="Please select product want to remove"
          key={vertical + horizontal}
        />
      ) : (
        ""
      )}
      {openLoading ? (
        <div>
          <Dialog
            open={openLoading}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              id="alert-dialog-description"
              style={{
                color: "black",
                fontWeight: "500",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "12px 24px",
              }}
            >
              <div style={{ marginRight: "14px" }}>
                <ClipLoader
                  color={ThemeColor ? ThemeColor : "#FFFFFF"}
                  height={50}
                  style={{
                    paddingLeft: "1px",
                    marginTop: "8px",
                    fontSize: "4",
                  }}
                />{" "}
              </div>
              <div>Loading ...</div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ""
      )}
      {dialogError.show ? (
        <div>
          <Dialog
            open={dialogError.show}
            onClick={handleCloseDialogError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              id="alert-dialog-description"
              style={{
                color: "black",
                fontWeight: "500",
              }}
            >
              {dialogError.message}
              <Button onClick={handleCloseDialogError}>Ok</Button>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default withNamespaces()(Cart);
