import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useState, useEffect } from "react";
import { BsThreeDots } from "react-icons/bs";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import SkeletonCircleText from "../../components/common/SkeletonCircleText";
// import { getSplashDataUser } from "../../store/actions/getSplashDataActions";
// import { DashboardResponse } from "../../store/Selectors/dashboard";
// import { GetSplashResponse } from "../../store/Selectors/getSplashData";
import AuthDilog from "./AuthModal";
// import { LoginResponse } from "../../store/Selectors/user";
import i18n from "../language/localization";
import { withNamespaces } from "react-i18next";

const CardDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 25%;
  flex-basis: 25%;
  user-select: none;
`;

const ModalDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 89px;
  height: 100px;
  margin-right: 10px;
  margin-left: 10px;
  user-select: none;
  @media (min-width: 320px) {
    width: 49px;
    height: 74px;
    margin-right: 10px;
    margin-left: 10px;
  }
  @media (min-width: 350px) {
    width: 55px;
    height: 74px;
    margin-right: 10px;
    margin-left: 10px;
  }
  @media (min-width: 360px) {
    width: 58px;
    height: 74px;
    margin-right: 10px;
    margin-left: 10px;
  }
  @media (min-width: 375px) {
    width: 62px;
    height: 74px;
    margin-right: 10px;
    margin-left: 10px;
  }
  @media (min-width: 395px) {
    width: 66px;
    height: 74px;
    margin-right: 10px;
    margin-left: 10px;
  }
  @media (min-width: 410px) {
    width: 70px;
    height: 74px;
    margin-right: 10px;
    margin-left: 10px;
  }
  @media (min-width: 425px) {
    width: 89px;
    height: 100px;
    margin-right: 10px;
    margin-left: 10px;
  }
`;
const useStyles = makeStyles((theme) => ({
  Root: {
    marginTop: "1rem",
    alignItems: "center",
    paddingLeft: "2rem",
    marginBottom: "1rem",
    paddingRight: "2rem",
    paddingBottom: "0.5rem",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      background: "#f9f9f9",
      marginTop: "0px",
      paddingLeft: "0px",
      marginBottom: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
    },
  },
  Container: {
    display: "flex",
    flexWrap: "wrap",
    height: "200px",
    paddingLeft: "10px",
    maxHeight: "198px",
    overflow: "hidden",
    userSelect: "none",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      height: "170px",
      paddingLeft: "0px",
      paddingTop: "15px",
    },
  },
  Container2: {
    display: "flex",
    flexWrap: "wrap",
    // height: "200px",
    paddingLeft: "10px",
    userSelect: "none",
    // maxHeight: "198px",
    // overflow: "hidden",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      // height: "150px",
      paddingLeft: "0px",
      paddingTop: "5px",
      flexWrap: "wrap",
      // height: "200px",
      paddingLeft: "10px",
      userSelect: "none",
    },
  },
  Main_div: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100px",
    height: "100px",
    marginRight: "10px",
    marginLeft: "10px",
    userSelect: "none",
    [theme.breakpoints.down("xs")]: {
      width: "85px",
      height: "75px",
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
  Main_div2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "89px",
    height: "100px",
    marginRight: "10px",
    marginLeft: "10px",
    userSelect: "none",
    [theme.breakpoints.down("xs")]: {
      width: "60px",
      height: "74px",
      marginRight: "10px",
      marginLeft: "10px",
    },
  },
  Para: {
    fontSize: "12px",
    fontWeight: 500,
    textAlign: "center",
    paddingTop: "12px",
    marginBottom: "5px",
    cursor: "pointer",
    userSelect: "none",
  },
  Para2: {
    fontSize: "12px",
    fontWeight: 500,
    textAlign: "center",
    marginBottom: "5px",
    cursor: "pointer",
    paddingTop: "9px",
    userSelect: "none",
  },
  dialog: {
    bottom: 0,
    position: "absolute",
    margin: "0px !important",
    width: "500px",
    borderRadius: "30px 30px 0px 0px",
    userSelect: "none",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const MenuCategories = ({ t, shortcutsData }) => {
  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
  }, []);

  const userid = JSON.parse(localStorage.getItem("userid"));
  const landing = JSON.parse(localStorage.getItem("Lanpage"));

  const classes = useStyles();
  const history = useHistory();

  // const data = DashboardResponse();
  // const data1 = GetSplashResponse();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [open, setOpen] = useState(false);
  // const dispatch = useDispatch();

  // const orderdata = () => {
  //   let data = new FormData();
  //   data.append("user_id", userid);
  //   dispatch(getSplashDataUser(data));
  // };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      // paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      // paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 440, min: 0 },
      items: 1,
    },
  };

  const go = (data) => {
    // if (data.slug == 1) {
    // if (landing&&landing.landing_page == 0) {
    //   history.push("/");
    // } else {
    //   history.push("/dashboard");
    // }
    // history.push('/');
    // }
    // if (data.slug == 2) {
    //   history.push("/category");
    // }
    if (data.slug == "Produk") {
      localStorage.setItem("viewproid", JSON.stringify(""));
      localStorage.setItem("viewproname", JSON.stringify("All Product"));
      localStorage.setItem("viewprochild", JSON.stringify(""));
      history.push("/products");
    }
    if (data.slug == "Troli") {
      if (userid) {
        history.push("/cart");
      } else {
        history.push("/login");
      }
    }
    // if (data.slug == 5) {
    //   if (userid) {
    //     history.push("/wishlist");
    //   } else {
    //     if (landing&&landing.landing_page == 0) {
    //       history.push("/login");
    //     } else {
    //       history.push("/");
    //     }
    //   }
    // }
    if (data.slug == "Chat") {
      // history.push("/ ");
      history.push("/chat");
    }
    if (data.slug == "Pesanan") {
      if (userid) {
        history.push("/orderhistory");
      } else {
        history.push("/login");
      }
    }
    // if (data.slug == 8) {
    //   history.push("/comingsoon");
    // }
    // if (data.slug == 9) {
    //   history.push("/comingsoon");
    // }
    // if (data.slug == 10) {
    //   history.push("/notification");
    // }
    // if (data.slug == 11) {
    //   history.push("/developer");
    // }
    // if (data.slug == 12) {
    //   // history.push("/waybill");
    //   history.push("/comingsoon");
    // }
    if (data.slug == "Help") {
      // history.push("/help");
      history.push("/comingsoon-mobile");
    }
    // if (data.slug == 14) {
    //   history.push("/information");
    // }
    if (data.slug == "Terbaru") {
      localStorage.setItem("viewproid", JSON.stringify(""));
      localStorage.setItem("viewproname", JSON.stringify("Produk Terbaru"));
      localStorage.setItem("viewtabs", JSON.stringify("New Arrivals"));
      localStorage.setItem("viewprochild", JSON.stringify(""));
      history.push("/products");
    }
    if (data.slug == "Terlaris") {
      localStorage.setItem("viewproid", JSON.stringify(""));
      localStorage.setItem("viewproname", JSON.stringify("Best Seller"));
      localStorage.setItem("viewtabs", JSON.stringify("Best Seller"));
      localStorage.setItem("viewprochild", JSON.stringify(""));
      history.push("/products");
    }
    if (data.slug == "Promo") {
      localStorage.setItem("viewproid", JSON.stringify(""));
      localStorage.setItem("viewproname", JSON.stringify("Discount Product"));
      localStorage.setItem("viewtabs", JSON.stringify("Discount Product"));
      localStorage.setItem("viewprochild", JSON.stringify(""));
      history.push("/products");
    }
    // if(data.slug == 18){
    //   history.push("/comingsoon");
    // }
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const loginData = {};
  const LoginFunction = async () => {
    setTimeout(() => {
      if (loginData.ResponseCode == 1) {
        setIsOpen(false);
      } else {
        history.push("/login");
      }
    }, 100);
  };
  const SignUpFunction = async () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };

  return (
    <div>
      <div className={classes.Root}>
        {Mobile ? (
          <React.Fragment>
            {shortcutsData != null ? (
              <div
                className={classes.Container}
                // style={{
                //   backgroundColor: data1
                //     ? data1.data.shortcut_background
                //     : landing != null
                //     ? landing.shortcut_background
                //     : "",
                // }}
              >
                {shortcutsData.length >= 8
                  ? shortcutsData.slice(0, 7).map((item, index) => {
                      return (
                        // <div key={index} className={classes.Main_div}>
                        <CardDiv key={index}>
                          <div
                            onClick={() => {
                              go(item);
                            }}
                          >
                            <img
                              src={
                                item.image
                                  ? process.env.REACT_APP_FILE_BASE_URL +
                                    "/" +
                                    item.image
                                  : ""
                              }
                              alt="Icon"
                              style={{
                                borderRadius: "50%",
                                width: Mobile ? "38px" : "68px",
                                height: Mobile ? "37px" : "68px",
                                userSelect: "none",
                              }}
                            />
                          </div>
                          <div>
                            <p className={classes.Para}>{item.name}</p>
                          </div>
                        </CardDiv>
                      );
                    })
                  : shortcutsData.map((item, index) => {
                      return (
                        <CardDiv key={index} className={classes.Main_div}>
                          <div
                            onClick={() => {
                              go(item);
                            }}
                          >
                            <img
                              src={
                                item.image
                                  ? process.env.REACT_APP_FILE_BASE_URL +
                                    "/" +
                                    item.image
                                  : ""
                              }
                              alt="Icon"
                              style={{
                                borderRadius: "50%",
                                width: Mobile ? "38px" : "68px",
                                height: Mobile ? "37px" : "68px",
                                userSelect: "none",
                              }}
                            />
                          </div>
                          <div>
                            <p className={classes.Para}>{item.name}</p>
                          </div>
                        </CardDiv>
                      );
                    })}
                {shortcutsData.length >= 8 ? (
                  <CardDiv>
                    <div
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <BsThreeDots
                        style={{
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          userSelect: "none",
                        }}
                      />
                    </div>
                    <p className={classes.Para2}>{t("view_all_uppercase")}</p>
                  </CardDiv>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <Paper>
                <Skeleton
                  variant="rect"
                  height={200}
                  style={{ width: "100%" }}
                />
              </Paper>
            )}
          </React.Fragment>
        ) : (
          <Carousel responsive={responsive}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              {shortcutsData != null && shortcutsData.length > 0
                ? shortcutsData.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          go(item);
                        }}
                      >
                        <img
                          src={
                            item.image
                              ? process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                item.image
                              : ""
                          }
                          alt="Icon"
                          style={{
                            borderRadius: "50%",
                            width: Mobile ? "38px" : "68px",
                            height: Mobile ? "37px" : "68px",
                          }}
                        />
                        <div>
                          <p className={classes.Para}>{item.name}</p>
                        </div>
                      </div>
                    );
                  })
                : ["", "", "", "", "", "", "", "", "", "", "", ""].map((el) => (
                    <div>
                      <SkeletonCircleText
                        animation="wave"
                        variant="circle"
                        style={{
                          width: "50px",
                          height: "50px",
                          margin: "auto",
                        }}
                      />
                      <SkeletonCircleText animation="wave" variant="text" />
                    </div>
                  ))}
            </div>
          </Carousel>
        )}
      </div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle
          style={{
            padding: "0px 24px !important",
          }}
        >
          {t("shortcut")}
        </DialogTitle>
        <DialogContent>
          {shortcutsData ? (
            <div className={classes.Container2}>
              {shortcutsData.map((item, index) => {
                return (
                  <ModalDiv key={index}>
                    <div
                      onClick={() => {
                        go(item);
                      }}
                    >
                      <img
                        src={
                          item.image
                            ? process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              item.image
                            : ""
                        }
                        alt="Icon"
                        style={{
                          borderRadius: "50%",
                          width: Mobile ? "38px" : "68px",
                          height: Mobile ? "37px" : "68px",
                        }}
                      />
                    </div>
                    <div>
                      <p className={classes.Para}>{item.name}</p>
                    </div>
                  </ModalDiv>
                );
              })}
            </div>
          ) : (
            <Paper>
              <Skeleton variant="rect" height={200} style={{ width: "100%" }} />
            </Paper>
          )}
        </DialogContent>
      </Dialog>

      <AuthDilog
        open={isOpen}
        tabIndexOpen={0}
        onClose={() => setIsOpen(false)}
        LoginFunction={LoginFunction}
        SignUpFunction={SignUpFunction}
      />
    </div>
  );
};

export default withNamespaces()(MenuCategories);
