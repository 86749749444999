import React, { useState } from "react";
import { Box, Grid, TextField } from "@material-ui/core";
import Card from "../../../../components/common/Card";
import { useStyles } from "./CartLeft.style";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Right from "../right/CartRight";
import i18n from "../../../language/localization";
import { withNamespaces } from "react-i18next";
import SkeletonCircleText from "../../../../components/common/SkeletonCircleText";
import {
  get as getCart,
  deleteData as deleteCart,
  deleteDatas as deleteCarts,
  updateNote,
} from "../../../../api/cart";
import { getDiskonRupiah, priceFormatLocal } from "../../../../utils/helper";
import { isDiscountValid } from "../../../../utils/pricing";
import { useDispatch, useSelector } from "react-redux";
import {
  setCheckoutCartItem,
  setIsCheckout,
  setCounter,
} from "../../../../stores/features/cart/cart";

const Left = ({ t, webThemeColor }) => {
  const dispatch = useDispatch();
  const { counter } = useSelector((state) => state.cart);

  const classes = useStyles();
  const [openLoader, setOpenLoader] = React.useState(false);
  const [qutKeep, setQutKeep] = useState(1);
  const [qutRekepp, setQutRekepp] = useState(1);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [checkboxSelect, setCheckboxselect] = useState({});
  const [isCartLoader, setCartLoader] = useState(false);
  const [allCheckbox, setAllCheckbox] = useState([]);
  const [isCheckAllcheck, setAllChek] = useState(false);
  const [myQty, setMyQty] = useState(1);
  const [showMyQty, setShowMyQty] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [open, setOpen] = useState(true);
  const [gtQty, setGTQty] = useState(0);
  const [deleteCheck, setDeleteCheck] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deletedIds] = useState([]);
  const [Myindex, setIndex] = useState();
  const [isErrorOpen, setErrorOpen] = useState(false);
  const [myData, setMyData] = useState([]);
  const [isOpenCartError, setisOpenCartError] = useState(false);
  const [isDatashow, setisDatashow] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [cartDatas, setCartDatas] = useState([]);

  const selectAllCheck = async (e) => {
    setIsCheckAll(e.target.checked);
    cartDatas.map((cart) => {
      cart.cart_item.checked_out = e.target.checked;
      return cart;
    });
  };

  const selectedCartItem = () =>
    cartDatas.filter((cart) => cart.cart_item.checked_out === true);

  const onCheckItemCart = async (e) => {
    const { value, checked } = e.target;

    const updatedCartItem = cartDatas.map((cart) => {
      // eslint-disable-next-line eqeqeq
      if (cart.cart_item.id == value) {
        cart.cart_item.checked_out = checked;
      }
      return cart;
    });
    setCartDatas(updatedCartItem);

    if (cartDatas.length === selectedCartItem().length) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  };

  const updateCartCheckedOutQuantity = (cartItem, operation) => {
    const updatedCartDatas = cartDatas.map((cart) => {
      if (cartItem.id === cart.cart_item.id) {
        cart.cart_item.checked_out_quantity =
          operation === "+"
            ? cartItem.checked_out_quantity + 1
            : cartItem.checked_out_quantity - 1;
      }
      return cart;
    });
    return updatedCartDatas;
  };

  const addQtyRekap = async (cartItem) => {
    if (cartItem.checked_out_quantity < cartItem.quantity) {
      setCartDatas(updateCartCheckedOutQuantity(cartItem, "+"));
    }
  };

  const minQtyRekap = async (cartItem) => {
    if (cartItem.checked_out_quantity > 1) {
      setCartDatas(updateCartCheckedOutQuantity(cartItem, "-"));
    }
  };

  const deleteSingle = async (id, index) => {
    setOpenLoader(true);
    setIndex(index);
    try {
      const data = {
        cart_id: id,
      };
      const response = await deleteCart(data, localStorage.getItem("token"));
      if (response.success) {
        setOpen(true);
        const totalItemsCart = counter - 1;
        dispatch(setCounter(totalItemsCart));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenLoader(false);
    }
  };

  const deleteAll = async () => {
    setDeleteLoader(true);
    try {
      const data = {
        cart_id: selectedCartItem().map((cart) => cart.cart_item.id),
      };
      const itemsCartDeleted = data.cart_id.length;
      const totalItemsCart = counter - itemsCartDeleted;

      const response = await deleteCarts(data, localStorage.getItem("token"));

      if (response.success) {
        setOpen(true);
        setisDatashow(true);
        setCartDatas([]);
        setIsCheckAll(false);
        dispatch(setCounter(totalItemsCart));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoader(false);
    }
  };

  const checkOutClick = () => {
    if (selectedCartItem().length) {
      dispatch(setCheckoutCartItem(cartDatas));
      dispatch(setIsCheckout(true));
      // localStorage.setItem("oderDetail", JSON.stringify(myData));
      // localStorage.setItem("checkoutPage", true);
      history.push("/checkout");
    } else {
      setisOpenCartError(true);
    }
  };

  const payOnSpotClick = () => {
    if (selectedCartItem().length) {
      dispatch(setCheckoutCartItem(cartDatas));
      dispatch(setIsCheckout(false));
      // console.log("myData pay on spot click", myData)
      // localStorage.setItem("oderDetail", JSON.stringify(myData));
      // localStorage.setItem("checkoutPage", false);
      history.push("/checkout");
    } else {
      setisOpenCartError(true);
    }
  };

  const mycart = async () => {
    // const response = await getCart(
    //   localStorage.getItem("token"),
    //   `{"get_all_data": true}`
    // );
    const response = await getCart(localStorage.getItem("token"));
    if (response.success) {
      let arrayValueCartData = [];
      const dataCart = response.data.cart_items;

      if (dataCart && dataCart.length > 0) {
        dataCart.forEach((cart) => {
          cart.cart_item.checked_out = false;
          if (cart.cart_item.note) {
            arrayValueCartData.push({
              ...cart,
              text_field: true,
            });
          } else {
            arrayValueCartData.push({
              ...cart,
              text_field: true,
            });
          }
        });
      }
      console.log("data cart ", arrayValueCartData);
      setCartDatas(arrayValueCartData);
    }
  };

  const membershipData = JSON.parse(localStorage.getItem("membership"));
  const [membershipLevel, setMembershipLevel] = useState(1);

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));

    if (membershipData) {
      setMembershipLevel(membershipData.level);
    }

    if (open) {
      mycart();
    }

    return () => {
      setOpen(false);
    };
  }, [open ? cartDatas : null]);

  let history = useHistory();

  const handleClickItem = async (valueItem) => {
    history.push(`/product-detail?${valueItem.product_id}`);
  };

  const handleClickAddNoteOpenTextField = async (valueData) => {
    const findIndex = cartDatas.findIndex(
      (dataOrigin) => dataOrigin.id === valueData.id
    );
    let updateCartData = [...cartDatas];
    updateCartData[findIndex].textField = true;
    setCartData(updateCartData);
  };

  const handleChangeAddNote = async (event, valueData) => {
    // let data = new FormData();
    // data.append("cart_id", valueData.id);
    // data.append("note", event.target.value);
    // dispatch(addNotes(data));
    const data = {
      cart_id: valueData.cart_item.id,
      note: event.target.value,
    };
    await updateNote(data, localStorage.getItem("token"));
  };

  const catalog = cartDatas ? (
    cartDatas.length > 0 ? (
      cartDatas.map((dt, index) => {
        return (
          <React.Fragment>
            {index > 0 ? (
              <div
                style={{
                  borderTop: "1px solid #D1D1D1",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              ></div>
            ) : null}
            <Grid container spacing={2}>
              <Grid item style={{ maxWidth: "15%", flexBasis: "15%" }}>
                <div className={classes.checkbox_mainimage_sec}>
                  <div className="checkbox">
                    <input
                      key={index}
                      type="checkbox"
                      className="css-checkbox"
                      id={dt.cart_item.id}
                      value={dt.cart_item.id}
                      name={dt.cart_item.id}
                      onChange={onCheckItemCart}
                      checked={dt.cart_item.checked_out}
                    />
                    <label
                      for={dt.product.name.replace(/\s+/g, "")}
                      className="css-label"
                    ></label>
                  </div>
                  <img
                    src={
                      dt.product.media && dt.product.media.length > 0
                        ? process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          dt.product.media[0]
                        : "/assets/images/dummy.png"
                    }
                    alt=""
                    className={classes.main_image}
                    onClick={() => handleClickItem(dt)}
                  />
                  {dt.product.discount_type &&
                    isDiscountValid(
                      dt.product.discount,
                      dt.product.discount_start_date,
                      dt.product.discount_end_date
                    ) &&
                    dt.product.discount_type === "percentage" && (
                      <div
                        style={{
                          background: " #D92D20",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          fontSize: "12px",
                          width: "28px",
                          height: "19px",
                          borderRadius: "3px",
                          marginBottom: "74px",
                          marginLeft: "96px",
                          position: "absolute",
                        }}
                      >
                        {dt.product.discount}%
                      </div>
                    )}
                  {dt.product.discount_type &&
                    isDiscountValid(
                      dt.product.discount,
                      dt.product.discount_start_date,
                      dt.product.discount_end_date
                    ) &&
                    dt.product.discount_type === "nominal" && (
                      <div
                        style={{
                          background: " #D92D20",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          fontSize: "12px",
                          width: "28px",
                          height: "19px",
                          borderRadius: "3px",
                          marginBottom: "74px",
                          marginLeft: "96px",
                          position: "absolute",
                        }}
                      >
                        {parseInt(
                          (dt.product.discount / dt.cart_item.price) * 100
                        )}
                        %
                      </div>
                    )}
                </div>
              </Grid>
              <Grid item style={{ maxWidth: "33%", flexBasis: "33%" }}>
                <Box className={classes.description_style}>
                  <h4 className="title_de" onClick={() => handleClickItem(dt)}>
                    {dt.product.name}
                  </h4>
                  <p className="values">
                    {dt.product_variant ? (
                      <span>
                        {t("variant")}:{dt.product_variant.attributes[0].value}
                      </span>
                    ) : null}
                  </p>
                  <div className={classes.priceContent_style}>
                    <label className="values">
                      {dt.product.discount_type &&
                        isDiscountValid(
                          dt.product.discount,
                          dt.product.discount_start_date,
                          dt.product.discount_end_date
                        ) &&
                        dt.product.discount_type === "nominal" && (
                          <p
                            style={{
                              marginBottom: "0px",
                            }}
                          >
                            {priceFormatLocal(
                              dt.cart_item.price - dt.product.discount
                            )}
                          </p>
                        )}

                      {dt.product.discount_type &&
                        isDiscountValid(
                          dt.product.discount,
                          dt.product.discount_start_date,
                          dt.product.discount_end_date
                        ) &&
                        dt.product.discount_type === "percentage" && (
                          <p
                            style={{
                              marginBottom: "0px",
                            }}
                          >
                            {priceFormatLocal(
                              dt.cart_item.price -
                                (dt.product.discount / 100) * dt.cart_item.price
                            )}
                          </p>
                        )}

                      {!isDiscountValid(
                        dt.product.discount,
                        dt.product.discount_start_date,
                        dt.product.discount_end_date
                      ) && (
                        <p
                          style={{
                            marginBottom: "0px",
                          }}
                        >
                          {priceFormatLocal(dt.cart_item.price)}
                        </p>
                      )}
                    </label>
                    {isDiscountValid(
                      dt.product.discount,
                      dt.product.discount_start_date,
                      dt.product.discount_end_date
                    ) && (
                      <label
                        className="values"
                        style={{
                          textDecoration: "line-through",
                          paddingLeft: "10px",
                        }}
                      >
                        {priceFormatLocal(dt.cart_item.price)}
                      </label>
                    )}
                  </div>
                </Box>
              </Grid>
              <Grid item style={{ maxWidth: "28%", flexBasis: "28%" }}>
                <Box className={classes.quantityStyle}>
                  <div className="item">
                    <p className="lable">Rekap</p>
                    {index === Myindex && isCartLoader ? (
                      <ClipLoader size={27} color="#ff3a59" />
                    ) : (
                      <div className={classes.quantity}>
                        <div
                          className="minus-qty"
                          onClick={() => minQtyRekap(dt.cart_item)}
                        >
                          <RemoveIcon />
                        </div>
                        <div className="qty1">
                          {dt.cart_item.checked_out_quantity}
                        </div>
                        <div
                          className="plus-qty"
                          onClick={() => addQtyRekap(dt.cart_item)}
                        >
                          <AddIcon />
                        </div>
                      </div>
                    )}
                  </div>
                </Box>
              </Grid>
              <Grid item style={{ maxWidth: "16%", flexBasis: "16%" }}>
                <Box className={classes.totalPrice}>
                  <p
                    className="gTotal"
                    style={{
                      paddingLeft: 4,
                      fontSize: "16px",
                      color: webThemeColor,
                    }}
                  >
                    {dt.product.discount_type &&
                      isDiscountValid(
                        dt.product.discount,
                        dt.product.discount_start_date,
                        dt.product.discount_end_date
                      ) &&
                      dt.product.discount_type === "nominal" && (
                        <p
                          style={{
                            marginBottom: "0px",
                          }}
                        >
                          {priceFormatLocal(
                            dt.cart_item.checked_out_quantity *
                              (dt.cart_item.price - dt.product.discount)
                          )}
                        </p>
                      )}

                    {dt.product.discount_type &&
                      isDiscountValid(
                        dt.product.discount,
                        dt.product.discount_start_date,
                        dt.product.discount_end_date
                      ) &&
                      dt.product.discount_type === "percentage" && (
                        <p
                          style={{
                            marginBottom: "0px",
                          }}
                        >
                          {priceFormatLocal(
                            dt.cart_item.checked_out_quantity *
                              (dt.cart_item.price -
                                (dt.product.discount / 100) *
                                  dt.cart_item.price)
                          )}
                        </p>
                      )}

                    {dt.product.discount === 0 ? (
                      <p
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        {priceFormatLocal(
                          dt.cart_item.checked_out_quantity * dt.cart_item.price
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                  </p>
                </Box>
              </Grid>

              <Grid item style={{ maxWidth: "8%", flexBasis: "8%" }}>
                {index === Myindex && openLoader ? (
                  <ClipLoader size={27} color="#ff3a59" />
                ) : (
                  <svg
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => deleteSingle(dt.cart_item.id, index)}
                    style={{ cursor: "pointer", fill: "transparent" }}
                    class="MuiSvgIcon-root"
                  >
                    <path
                      d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047"
                      stroke="#F04438"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                      stroke="#F04438"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.8499 9.14062L18.1999 19.2106C18.0899 20.7806 17.9999 22.0006 15.2099 22.0006H8.7899C5.9999 22.0006 5.9099 20.7806 5.7999 19.2106L5.1499 9.14062"
                      stroke="#F04438"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.3301 16.5H13.6601"
                      stroke="#F04438"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.5 12.5H14.5"
                      stroke="#F04438"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item style={{ maxWidth: "3%", flexBasis: "3%" }}></Grid>
              <Grid item style={{ maxWidth: "85%", flexBasis: "85%" }}>
                {dt.cart_item.note || dt.textField ? (
                  <TextField
                    id="txtfield-add-note"
                    label={t("notes")}
                    type="text"
                    defaultValue={dt.cart_item.note}
                    variant="outlined"
                    style={{ width: "100%" }}
                    size="small"
                    onChange={(event) => handleChangeAddNote(event, dt)}
                    className={classes.TextFieldcustomActiveTextField}
                  />
                ) : (
                  <label
                    style={{
                      color: webThemeColor,
                      fontSize: "14px",
                      fontWeight: "400",
                      cursor: "pointer",
                    }}
                    onClick={() => handleClickAddNoteOpenTextField(dt)}
                  >
                    {t("write_notes")}
                  </label>
                )}
              </Grid>
              <Grid item style={{ maxWidth: "12%", flexBasis: "12%" }}></Grid>
            </Grid>
          </React.Fragment>
        );
      })
    ) : (
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Box className={classes.emptyCart}>
            <h6>{t("your_cart_is_empty")}</h6>
          </Box>
        </Grid>
      </Grid>
    )
  ) : (
    <Grid container spacing={2}>
      <Grid item md={2}>
        <SkeletonCircleText
          style={{
            marginLeft: "20%",
            width: "80%",
            height: "80px",
            margin: "auto",
          }}
        />
      </Grid>
      <Grid item md={10}>
        <SkeletonCircleText />
        <SkeletonCircleText />
        <SkeletonCircleText />
        <SkeletonCircleText style={{ width: "80%" }} />
      </Grid>
    </Grid>
  );

  return (
    <div style={{ display: "flex", columnGap: 12, width: "100%" }}>
      <div style={{ width: "100%", height: "100%" }}>
        <Card className={classes.cardStyle}>
          <header>
            <Grid
              container
              spacing={2}
              style={{ borderBottom: "1px solid #D1D1D1" }}
            >
              <Grid item style={{ maxWidth: "15%", flexBasis: "15%" }}>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    name="selectAll"
                    id="selectAll"
                    onChange={selectAllCheck}
                    checked={isCheckAll}
                  />
                  <label for="selectAll" className="css-label">
                    {t("Select All")}
                  </label>
                </div>
              </Grid>
              <Grid
                item
                style={{ fontSize: "14px", maxWidth: "33%", flexBasis: "33%" }}
              >
                {/* {t("Description")} */}
              </Grid>
              {/* <Grid item style={{fontSize: '14px', maxWidth: '16%', flexBasis: '16%'}}> */}
              {/* {t("Price")} */}
              {/* </Grid> */}
              <Grid
                item
                style={{ fontSize: "14px", maxWidth: "28%", flexBasis: "28%" }}
              >
                {/* Quantity */}
              </Grid>
              <Grid
                item
                style={{ fontSize: "14px", maxWidth: "10%", flexBasis: "10%" }}
              >
                {/* Total Price */}
              </Grid>
              <Grid
                item
                style={{
                  fontSize: "14px",
                  maxWidth: "14%",
                  flexBasis: "14%",
                  color: webThemeColor,
                }}
              >
                {deleteLoader ? (
                  <ClipLoader size={27} color={webThemeColor} />
                ) : null}
                {Boolean(selectedCartItem().length) && !deleteLoader && (
                  <label
                    style={{ cursor: "pointer", fontSize: "16px" }}
                    onClick={deleteAll}
                  >
                    {t("Delete All")}
                  </label>
                )}
              </Grid>
            </Grid>
          </header>

          <Box className={classes.tableContent}>{catalog}</Box>
        </Card>
      </div>

      <div style={{ width: "352px" }}>
        <Right
          webThemeColor={webThemeColor}
          qty={selectedCartItem().reduce(
            (acc, val) => acc + val.cart_item.checked_out_quantity,
            0
          )}
          total={selectedCartItem().reduce(
            (acc, val) =>
              acc +
              (val.cart_item.price - getDiskonRupiah(val)) *
                val.cart_item.checked_out_quantity,
            0
          )}
          checkOutClick={checkOutClick}
          payOnSpotClick={payOnSpotClick}
          cartDatas={cartDatas}
        />
      </div>
      <div></div>
    </div>
  );
};

export default withNamespaces()(Left);
