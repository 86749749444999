import React from "react";
import { Snackbar } from "@material-ui/core";
import { Button } from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";
import { setSnackbar } from "../stores/features/snackbar/snackbar";

export const Snackbars = () => {
  const dispatch = useDispatch();
  const { show, message } = useSelector((state) => state.snackbar);
  const handleClose = () => {
    dispatch(
      setSnackbar({
        show: false,
        message: "",
      })
    );
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={show}
      autoHideDuration={6000}
      onClose={handleClose}
      message={message}
      action={
        <Button color="secondary" size="small" onClick={handleClose}>
          OK
        </Button>
      }
    />
  );
};
