import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import HeadsetMicOutlinedIcon from "@material-ui/icons/HeadsetMicOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import DuoOutlinedIcon from "@material-ui/icons/DuoOutlined";
import PlaylistAddCheckOutlinedIcon from "@material-ui/icons/PlaylistAddCheckOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import Badge from "@material-ui/core/Badge";
import SettingsIcon from "@material-ui/icons/Settings";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { useHistory } from "react-router-dom";
import { Typography, Avatar } from "@material-ui/core";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
// import { getSplashDataUser } from "../../store/actions/getSplashDataActions";
// import { useDispatch } from "react-redux";
import { ThemeColor1 } from "../themecolor/index1";
import { withNamespaces } from "react-i18next";
import i18n from "../language/localization";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from '@material-ui/icons/Close';
import Loader from "../loader";

const drawerWidth = 400;

function Sidebar({ t }) {
  const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : "#FFFFFF",
        color: "#FFFFFF",
      },
    },
  });
  const Data = [
    { id: 0, text: "Home", icon: HomeOutlinedIcon },
    { id: 1, text: "Category", icon: DashboardIcon },
    { id: 3, text: "All Products", icon: DynamicFeedIcon },
    { id: 4, text: "Cart", icon: ShoppingCartOutlinedIcon },
    { id: 5, text: "Wishlist", icon: FavoriteBorderOutlinedIcon },
    { id: 6, text: "History Order", icon: AssignmentIcon },
    { id: 7, text: "Promotion", icon: PlaylistAddCheckOutlinedIcon },
    { id: 8, text: "Information", icon: VolumeUpIcon },
    { id: 9, text: "Settings", icon: SettingsIcon },
  ];
  const datauser = JSON.parse(localStorage.getItem("userdata"));
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const Laptop = useMediaQuery(theme.breakpoints.down("lg"));

  // const dispatch = useDispatch();
  const userid = JSON.parse(localStorage.getItem("userid"));
  const orderdata = () => {
    let data = new FormData();
    data.append("user_id", userid);
    // dispatch(getSplashDataUser(data));
  };
  const landing = JSON.parse(localStorage.getItem("Lanpage"));
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 1000);
  }, [])
  return (
    <ThemeProvider theme={theme}>
      {
        open ?
          <Box sx={{ display: "flex" }} style={{ width: "500px", height: "100%" }}>
            <CssBaseline />
            <Box component="nav" style={{ width: "400px", background: "white" }}>
              <div>
                <List
                  style={{
                    backgroundColor: ThemeColor ? ThemeColor : "#FFFFFF",
                    position: "sticky",
                    top: "0px",
                    userSelect: "none"
                  }}
                >
                  <ListItem className="p-0">
                    {datauser ? (
                      <ListItemIcon>
                        <Avatar
                          src={datauser.photo}
                          sx={{ width: 80, height: 80 }}
                          style={{
                            height: "100px",
                            width: "100px",
                            margin: "10px",
                            userSelect: "none"
                          }}
                          onClick={() => {
                            history.push("/editprofile");
                          }}
                        />
                      </ListItemIcon>
                    ) : (
                      <ListItemIcon>
                        <Avatar
                          src="/assets/frontend/img/Icon/userdefault.png"
                          sx={{ width: 80, height: 80 }}
                          style={{
                            height: "100px",
                            width: "100px",
                            margin: "10px",
                            color: "white",
                            userSelect: "none"
                          }}
                        />
                      </ListItemIcon>
                    )}
                    {datauser ? (
                      <ListItemText>
                        <div className="text-light m-0" style={{ fontSize: "18px", cursor: "pointer", userSelect: "none" }}>
                          {datauser.first_name} {datauser.last_name}
                        </div>
                        <div
                          className="m-0 text-light"
                          style={{ wordBreak: "break-all", fontSize: "18px", cursor: "pointer", userSelect: "none" }}
                        >
                          {datauser.email}
                        </div>
                      </ListItemText>
                    ) : (
                      <ListItemText
                        onClick={() => {
                          // if (landing&&landing.landing_page == 0) {
                          //   history.push("/login");
                          // } else {
                          //   history.push("/");
                          // }
                          history.push("/login");
                        }}
                      >
                        <div className="text-light m-0" style={{ fontSize: "18px", cursor: "pointer", userSelect: "none" }}>{t("welcome_guest")}</div>
                        <div className="m-0 text-light" style={{ fontSize: "18px", cursor: "pointer", userSelect: "none" }}>
                          {t("login")} | {t("register")}
                        </div>
                      </ListItemText>
                    )}
                    {/* <ListItemIcon>
                   <CloseIcon />
                 </ListItemIcon> */}
                  </ListItem>
                </List>
                <List>
                  <ListItem
                    button
                    style={{ userSelect: "none" }}
                    className="m-0 p-0 list"
                    onClick={() => {
                      // handleDrawerToggle();
                      // if (landing&&landing.landing_page == 0) {
                      //   history.push("/");
                      // } else {
                      //   history.push("/dashboard");
                      // }
                      history.push('/');
                    }}
                  >
                    <ListItemIcon style={{ marginLeft: "5px", minWidth: "0px", userSelect: "none" }}>
                      <img
                        src={"/assets/frontend/img/SIDEBAR MENU/home.png"}
                        height="29px"
                        fontSize="large"
                        className=" my-2 mx-2"
                        style={{ userSelect: "none" }}
                      />
                    </ListItemIcon>
                    <span style={{ fontSize: "18px", cursor: "pointer", userSelect: "none" }} >{t("home1")}</span>
                  </ListItem>

                  <ListItem
                    button
                    style={{ userSelect: "none" }}
                    className="m-0 p-0 list"
                    onClick={() => {
                      history.push("/category");
                    }}
                  >
                    <ListItemIcon style={{ marginLeft: "5px", minWidth: "0px", userSelect: "none" }}>
                      <img
                        src={"/assets/frontend/img/SIDEBAR MENU/category.png"}
                        height="29px"
                        fontSize="large"
                        className=" my-2 mx-2"
                        style={{ userSelect: "none" }}
                      />
                    </ListItemIcon>
                    {/* <ListItemText primary={t("category")} className="" style={{ fontSize: "18px" }} /> */}
                    <span style={{ fontSize: "18px", cursor: "pointer", userSelect: "none" }} >{t("category")}</span>
                  </ListItem>

                  <ListItem
                    button
                    style={{ userSelect: "none" }}
                    className="m-0 p-0 list"
                    onClick={() => {
                      localStorage.setItem(
                        "viewproname",
                        JSON.stringify("All Product")
                      );
                      localStorage.setItem("viewproid", JSON.stringify(""));
                      localStorage.setItem("viewprochild", JSON.stringify(""));
                      history.push("/products");
                    }}
                  >
                    <ListItemIcon style={{ marginLeft: "5px", minWidth: "0px", userSelect: "none" }}>
                      <img
                        src={"/assets/frontend/img/SIDEBAR MENU/all_product.png"}
                        height="29px"
                        fontSize="large"
                        className=" my-2 mx-2"
                        style={{ userSelect: "none" }}
                      />
                    </ListItemIcon>
                    {/* <ListItemText primary={t("all_product")} className="" style={{ fontSize: "18px" }} /> */}
                    <span style={{ fontSize: "18px", cursor: "pointer", userSelect: "none" }} >{t("all_product")}</span>
                  </ListItem>

                  <ListItem
                    button
                    style={{ userSelect: "none" }}
                    className="m-0 p-0 list"
                    onClick={() => {
                      if (datauser) {
                        history.push("/cart");
                      } else {
                        // if (landing&&landing.landing_page == 0) {
                        //   history.push("/login");
                        // } else {
                        //   history.push("/");
                        // }
                        history.push("/login");
                      }
                    }}
                  >
                    <ListItemIcon style={{ marginLeft: "5px", minWidth: "0px", userSelect: "none" }}>
                      <img
                        src={"/assets/frontend/img/SIDEBAR MENU/cart.png"}
                        height="29px"
                        fontSize="large"
                        className=" my-2 mx-2"
                        style={{ userSelect: "none" }}
                      />
                    </ListItemIcon>
                    {/* <ListItemText primary={t("cart")} className="" style={{ fontSize: "18px" }} /> */}
                    <span style={{ fontSize: "18px", cursor: "pointer", userSelect: "none" }} >{t("cart")}</span>
                  </ListItem>

                  <ListItem
                    button
                    style={{ userSelect: "none" }}
                    className="m-0 p-0 list"
                    onClick={() => {
                      if (datauser) {
                        history.push("/comingsoon-mobile");
                      } else {
                        // if (landing&&landing.landing_page == 0) {
                        //   history.push("/login");
                        // } else {
                        //   history.push("/");
                        // }
                        history.push("/login");
                      }
                    }}
                  >
                    <ListItemIcon style={{ marginLeft: "5px", minWidth: "0px", userSelect: "none" }}>
                      <img
                        src={"/assets/frontend/img/SIDEBAR MENU/wishlist.png"}
                        height="29px"
                        fontSize="large"
                        className=" my-2 mx-2"
                        style={{ userSelect: "none" }}
                      />
                    </ListItemIcon>
                    {/* <ListItemText primary={t("wishlist")} className="" style={{ fontSize: "18px" }}/> */}
                    <span style={{ fontSize: "18px", cursor: "pointer", userSelect: "none" }} >{t("wishlist")}</span>
                  </ListItem>

                  <ListItem
                    button
                    style={{ userSelect: "none" }}
                    className="m-0 p-0 list"
                    onClick={() => {
                      if (datauser) {
                        orderdata()
                        history.push("/orderhistory");
                      } else {
                        // if (landing&&landing.landing_page == 0) {
                        //   history.push("/login");
                        // } else {
                        //   history.push("/");
                        // }
                        history.push("/login");
                      }
                    }}
                  >
                    <ListItemIcon style={{ marginLeft: "5px", minWidth: "0px", userSelect: "none" }}>
                      <img
                        src={"/assets/frontend/img/SIDEBAR MENU/list_order.png"}
                        height="29px"
                        fontSize="large"
                        className=" my-2 mx-2"
                        style={{ userSelect: "none" }}
                      />
                    </ListItemIcon>
                    {/* <ListItemText primary={t("history_order")} className="" style={{ fontSize: "18px" }} /> */}
                    <span style={{ fontSize: "18px", cursor: "pointer", userSelect: "none" }} >{t("history_order")}</span>
                  </ListItem>
                  <ListItem
                    button
                    style={{ userSelect: "none" }}
                    className="m-0 p-0 list"
                    onClick={() => {
                      orderdata()
                      history.push("/setting");
                    }}
                  >
                    <ListItemIcon style={{ marginLeft: "5px", minWidth: "0px", userSelect: "none" }}>
                      <img
                        src={"/assets/frontend/img/SIDEBAR MENU/settings.png"}
                        height="29px"
                        fontSize="large"
                        className=" my-2 mx-2"
                        style={{ userSelect: "none" }}
                      />
                    </ListItemIcon>
                    {/* <ListItemText primary={t("settings1")} className="" style={{ fontSize: "18px" }} /> */}
                    <span style={{ fontSize: "18px", cursor: "pointer", userSelect: "none" }} >{t("settings1")}</span>
                  </ListItem>
                </List>
              </div>
            </Box>
            <Box component="nav" style={{ width: "100px", background: ThemeColor ? ThemeColor : "#FFFFFF" }}
              onClick={() => {
                // if (landing&&landing.landing_page == 0) {
                //   history.push("/");
                // } else {
                //   history.push("/dashboard");
                // }
                history.push('/');
              }}>
            </Box>
          </Box>
          : <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: "250px",
              paddingBottom: "300px",
              flexDirection: "column",
              height:"100vh",
              backgroundColor: "white"
            }}
          >
            <div><Loader /></div>
            <div>Loading...</div>
          </div>
      }
    </ThemeProvider>
  );
}

export default withNamespaces()(Sidebar);
