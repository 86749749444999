import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Unpaid from "./unpaid";
import Onprocess from "./onprocess";
import Ondelivery from "./ondelivery";
import Finish from "./finish";
import Cancel from "./cancel";
import NavigationTab from "../home/bottomnav/NavigationTab";
// import { GetSplashResponse } from "../../store/Selectors/getSplashData";
import { withNamespaces } from "react-i18next";
import i18n from "../language/localization";
import $ from "jquery";
import Searchbar from "../home/Searchbar";
import { get as getOrders } from "../../api/order";
import { getBadgeCart, getCountOrderByStatus } from "../../api/badge";

const OrderHistory = ({ t }) => {
  const ThemeColor = JSON.parse(localStorage.getItem("themeColor"));
  const [value, setValue] = React.useState(1);
  const [field, setField] = React.useState();
  const [open, setOpen] = useState(true);
  const [TotalCartItems, setItems] = useState(0);

  const history = useHistory();

  const setOrderTab = JSON.parse(localStorage.getItem("ordertab"));
  const userid = JSON.parse(localStorage.getItem("userid"));
  const lanpage = JSON.parse(localStorage.getItem("Lanpage"));
  const [unpaidTotalRecord, setunpaidTotalRecord] = React.useState(0);
  const [onProcessTotalRecord, setonProcessTotalRecord] = React.useState(0);
  const [finishTotalRecord, setfinishTotalRecord] = React.useState(0);
  const [cancelTotalRecord, setcancelTotalRecord] = React.useState(0);
  const [onDeliveryTotalRecord, setonDeliveryTotalRecord] = React.useState(0);

  if (setOrderTab !== null) {
    // setValue(setOrderTab);
    // localStorage.removeItem("ordertab");
    if (setOrderTab > 2) {
      $("#content").animate({
        scrollLeft: `+=${setOrderTab - 2}50px`,
      });
    }
    setTimeout(() => setValue(setOrderTab), 500);
    // setTimeout(()=>(localStorage.removeItem("ordertab")),600)
  }

  useEffect(() => {
    // let data = new FormData();
    // data.append("user_id", userid);
    // fetch(`${baseUrlApi()}/Api/Dashboard/getSplashData`, {
    //   method: "POST",
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   body: data,
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     if (res.ResponseCode == 1) {
    //       setField(res.data);
    //     }
    //   });
    i18n.changeLanguage(JSON.parse(localStorage.getItem("Language")));
    // setTimeout(() => {
    //   setOpen(true);
    // }, 700);
    if (localStorage.getItem("token")) {
      const getBadgeCartData = async () => {
        try {
          const response = await getBadgeCart(localStorage.getItem("token"));
          if (response.success) {
            setItems(response.data);
          }
        } catch (error) {
          console.log("Error fetching getBadgeCartData:", error);
        }
      };
      getBadgeCartData();
    }
  }, []);

  // const data1 = GetSplashResponse();

  const getCount = async () => {
    try {
      const response = await getCountOrderByStatus(
        localStorage.getItem("token")
      );
      if (response.success) {
        setunpaidTotalRecord(
          response.data["UNPAID"] +
            response.data["WAITING VALIDATION"] +
            response.data["ON VALIDATION"]
        );
        setonProcessTotalRecord(response.data["ON PROCESS"]);
        setonDeliveryTotalRecord(response.data["ON DELIVERY"]);
        setfinishTotalRecord(response.data["COMPLETED"]);
        setcancelTotalRecord(response.data["CANCELED"]);
      } else {
        throw response;
      }
    } catch (error) {
      console.log("Error fetching unpaid:", error);
    }
  };

  useEffect(() => {
    getCount();
  }, []);

  const tabCenter = (event) => {
    localStorage.removeItem("ordertab");
    if (event !== 2 || value !== 1) {
      if (event > value) {
        $("#content").animate({
          scrollLeft: "+=100px",
        });
        setValue(event);
      } else {
        $("#content").animate({
          scrollLeft: "-=100px",
        });
        setValue(event);
      }
    } else {
      setValue(event);
    }
  };

  // console.log(field);
  // console.log(field.unpaid);
  return (
    <React.Fragment>
      <Searchbar
        color={ThemeColor}
        title={t("order_history")}
        cartCount={TotalCartItems}
      />

      {/* <Header title={t("order_history")} color={ThemeColor} /> */}
      <div
        className="centerfortabs"
        id="content"
        style={{
          marginTop: "45px",
          backgroundColor: "white",
          position: "fixed",
          maxWidth: "500px",
        }}
      >
        <div
          className="internal"
          id="div1"
          style={{
            borderBottom: 1 == value ? "solid" : "",
            fontWeight: 1 == value ? "600" : "450",
            color: 1 == value ? (ThemeColor ? ThemeColor : "#FFFFFF") : "grey",
            width: "40%",
            paddingLeft: "5px",
          }}
          onClick={() => {
            tabCenter(1);
          }}
        >
          {`${t("unpaid")} (${unpaidTotalRecord})`}
        </div>
        <div
          className="internal"
          id="div2"
          style={{
            borderBottom: 2 == value ? "solid" : "",
            fontWeight: 2 == value ? "600" : "450",
            color: 2 == value ? (ThemeColor ? ThemeColor : "#FFFFFF") : "grey",
            width: "33%",
          }}
          onClick={() => {
            tabCenter(2);
          }}
        >
          {`${t("on_process")} (${onProcessTotalRecord})`}
        </div>
        <div
          className="internal"
          id="div3"
          style={{
            borderBottom: 3 == value ? "solid" : "",
            fontWeight: 3 == value ? "600" : "450",
            color: 3 == value ? (ThemeColor ? ThemeColor : "#FFFFFF") : "grey",
            width: "33%",
          }}
          onClick={() => {
            tabCenter(3);
          }}
        >
          {`${t("on_delivery")} (${onDeliveryTotalRecord})`}
        </div>
        <div
          className="internal"
          id="div4"
          style={{
            borderBottom: 4 == value ? "solid" : "",
            fontWeight: 4 == value ? "600" : "450",
            color: 4 == value ? (ThemeColor ? ThemeColor : "#FFFFFF") : "grey",
            width: "33%",
          }}
          onClick={() => {
            tabCenter(4);
          }}
        >
          {`${t("finish")} (${finishTotalRecord})`}
        </div>
        <div
          className="internal"
          id="div5"
          style={{
            borderBottom: 5 == value ? "solid" : "",
            fontWeight: 5 == value ? "600" : "450",
            color: 5 == value ? (ThemeColor ? ThemeColor : "#FFFFFF") : "grey",
            width: "33%",
          }}
          onClick={() => {
            tabCenter(5);
          }}
        >
          {`${t("cancel")} (${cancelTotalRecord})`}
        </div>
      </div>
      <div
        style={{
          paddingTop: "100px",
        }}
      >
        {value == 1 ? <Unpaid color={ThemeColor} /> : ""}
        {value == 2 ? <Onprocess color={ThemeColor} /> : ""}
        {value == 3 ? <Ondelivery color={ThemeColor} /> : ""}
        {value == 4 ? <Finish color={ThemeColor} /> : ""}
        {value == 5 ? <Cancel color={ThemeColor} /> : ""}
      </div>
      <NavigationTab />
    </React.Fragment>
  );
};
export default withNamespaces()(OrderHistory);
