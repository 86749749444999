export const validatePhoneNumber = (string) => {
  const regex = /([0-9]{10})/;
  return regex.test(string);
};

export const addCodeNumber = (phoneNumber) => {
  if (validatePhoneNumber(phoneNumber)) {
    const phone =
      phoneNumber.charAt(0) === "0"
        ? `+62${phoneNumber.slice(1)}`
        : phoneNumber;
    return phone;
  }
  return phoneNumber;
};
